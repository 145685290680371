import imageViewTemplate from './../../components/associate-documents/imageViewTemplate.html';
import saveDiagramTemplate from './../../components/diagram/popups/save-diagram-popup.html';
import SaveTemplateController from './../../components/diagram/popups/save-template.controller';
import drawingCategoriesTemplate from './../../components/diagram/popups/diagram-categories.html';
import proposalCategoriesTemplate from './../../components/proposal/popups/proposal-categories.html';
import DrawingCategoriesController from './../../components/diagram/popups/diagram-categories.controller';
import ProposalCategoriesController from './../../components/proposal/popups/proposal-categories.controller';

export default function(app) {
  app.factory('AttachDocumentService', AttachDocumentService);

  function AttachDocumentService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage, $rootScope, $mdSidenav, sharedDataService, dateFactory) {

    "ngInject";

    let scope, vm, imageExtension = ["JPG", "JPE", "JPEG", "BMP", "GIF", "PNG"];

    return {
      init: init,
      initDataForDetail: initDataForDetail,
      initDataForUpdate: initDataForUpdate,
      initForUpdate: initForUpdate,
      updateDocument: updateDocument,
      close: close,
      isFileImage: isFileImage,
      imageViewPopup: imageViewPopup,
      deleteDocument: deleteDocument,
      openTemplates: openTemplates,
      openCategories: openCategories,
      openProposalCategories: openProposalCategories
    }

    // Intialize common variable and for document.
    function init(_vm, _scope) {
      vm = _vm;
      scope = _scope;
      vm.documentCategory = $rootScope.rObj.records['Associated documents'];
    }

    function initForUpdate() {
      vm.goToAddProposal = function() {
        $state.go('main.' + $rootScope.selectedModule + '.addProposal', {
          docId: vm.id
        });
      };

      vm.editDiagram = function(drawingId) {
        $state.go('main.' + $rootScope.selectedModule + '.updateDiagram', {
          docId: vm.id,
          drawingId: drawingId
        });
      };

      vm.editProposal = function(proposalId) {
        $state.go('main.' + $rootScope.selectedModule + '.updateProposal', {
          docId: vm.id,
          proposalId: proposalId
        });
      };

      vm.copyDiagram = function(drawingId) {
        $state.go('main.' + $rootScope.selectedModule + '.addCopyDiagram', {
          docId: vm.id,
          drawingId: drawingId,
          isClone: true
        });
      };

      vm.copyProposal = function(proposalId) {
        $state.go('main.' + $rootScope.selectedModule + '.addCopyProposal', {
          docId: vm.id,
          proposalId: proposalId,
          isClone: true
        });
      };

      vm.goToTemplates = function() {
        sharedDataService.set('docUpdateParams', $state.params);
        // return;
        $state.go('main.' + $rootScope.selectedModule + '.addTemplatesList', {
          docId: vm.id,
          fromState: $rootScope.currentStateName.replaceAll('.', '-')
        });
      };

      vm.deleteDrawing = (ev, id) => {
        messages.mdconfirm(ev, "Do you want to delete this drawing?")
          .then(function(flag) {
            if (flag) {
              dataServices.delete({
                url: serverUrl.main + 'drawingsApi/drawings/' + id
              }).then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast('Drawing deleted successfully!');
                  // After deleting the document we are fetching the list of documents for sync the live data.
                  getDrawings();
                }
              });
            }
          });
      };

      vm.deleteProposal = (ev, id) => {
        messages.mdconfirm(ev, "Do you want to delete this proposal?")
          .then(function(flag) {
            if (flag) {
              dataServices.delete({
                url: serverUrl.main + 'documentBuilderApi/proposal-document/' + id
              }).then(function(response) {
                if (response && response.data && response.data.result) {
                  messages.simpleToast('Proposal deleted successfully!');
                  // After deleting the document we are fetching the list of documents for sync the live data.
                  getProposals();
                }
              });
            }
          });
      };

      vm.saveAsTemplate = (ev, drawing) => {
        $mdDialog.show({
          locals: {
            templateData: drawing
          },
          controller: SaveTemplateController,
          templateUrl: saveDiagramTemplate,
          parent: angular.element(document.body),
          targetEvent: ev,
          controllerAs: 'st'
        });
      };

      vm.toggleTemplate = (id) => {
        if (vm.diagramSelected && vm.diagramSelected === id) {
          vm.diagramSelected = undefined;
        } else {
          vm.diagramSelected = id;
        }
      };
    }

    // checking if file is image file
    function isFileImage(imageFileName) {
      let ext = imageFileName.split('.').pop().toUpperCase();
      return imageExtension.includes(ext);
    }

    function getDrawings() {
      const filterCriteria = angular.toJson({
        filters: [{
          field: "documentId",
          operator: "=",
          value: vm.id
        }]
      });

      dataServices.get({
          url: serverUrl.main + 'drawingsApi/drawings?filterCriteria=' + filterCriteria + '&',
          spinner: false
        })
        .then(function(response) {
          vm.isLoadedDrawings = true;
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.drawings = data.result;
          }
        }, function() {
          vm.isLoadedDrawings = true;
        });
    }

    function getProposals() {
      const filterCriteria = angular.toJson({
        filters: [{
          field: "documentId",
          operator: "=",
          value: vm.id
        }]
      });

      dataServices.get({
          url: serverUrl.main + 'documentBuilderApi/proposal-document?filterCriteria=' + filterCriteria + '&',
          spinner: false
        })
        .then(function(response) {
          vm.isLoadedProposals = true;
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.proposals = data.result;
          }
        }, function() {
          vm.isLoadedProposals = true;
        });
    }

    function initData(documentDetail, initDropdownMenu) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = documentDetail.data.result;

        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }

        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);

        for (let i = 0; i < vm.totalFileUpload.length; i++) {
          let image = vm.totalFileUpload[i];
          if (vm.isFileImage(image.fileOriginalName)) {
            vm.images.push(image);
          } else {
            vm.files.push(image);
          }
        }
      }

      getDrawings(vm);
      getProposals(vm);
    }

    function initDataForDetail(documentDetail, initDropdownMenu) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = documentDetail.data.result;

        if (angular.isFunction(initDropdownMenu)) {
          initDropdownMenu();
        }

        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);

        for (let i = 0; i < vm.totalFileUpload.length; i++) {
          let image = vm.totalFileUpload[i];
          if (vm.isFileImage(image.fileOriginalName)) {
            vm.images.push(image);
          } else {
            vm.files.push(image);
          }
        }
        getDrawings(vm);
        getProposals(vm);
      }
    }

    function initDataForUpdate(documentDetail) {
      if (documentDetail && documentDetail.data.result) {
        vm.document = dataServices.toSave(documentDetail.data.result, ['name', 'category', 'docExpiryDate', 'description', 'modelId', 'modelName', 'tags']);
        if (vm.document.docExpiryDate) {
          vm.docExpiryDate = new Date(vm.document.docExpiryDate * 1000);
        }
        vm.totalFileUpload = angular.copy(documentDetail.data.result.attachments);
        getDrawings(vm);
        getProposals(vm);
      }
    }

    // showing images on popup or showing fullscreen images.
    function imageViewPopup(ev, image) {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: ImageViewController,
        controllerAs: 'cd',
        templateUrl: imageViewTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        locals: {
          parentVm: vm,
          image: image
        }
      });
    }

    function ImageViewController($scope, $mdDialog, $rootScope, $timeout, parentVm, image) {
      "ngInject";
      let cd = this;
      cd.image = image;
      cd.cancel = cancel;

      function cancel() {
        $mdDialog.cancel();
      }
    }

    // create an document or update the document
    function updateDocument() {
      // if(!vm.document.attachments.length){
      //   messages.simpleToast('Please attach at lease one attachment.');
      //   return;
      // }
      if (vm.docExpiryDate) {
        vm.document.docExpiryDate = dateFactory.getEpoche(vm.docExpiryDate);
      }

      dataServices.post({
        url: serverUrl.main + 'assocDocApi/assoc/doc' + (vm.id ? '/' + vm.id : ''),
        method: (vm.id ? 'PUT' : 'POST'),
        data: {
          data: angular.toJson(vm.document)
        }
      }).then(function(response) {
        if (response && response.data && response.data.result) {
          messages.simpleToast('Document ' + (vm.id ? 'updated' : 'added') + ' successfully.');
          scope.$parent.vm.backScreen();
        }
      });
    }

    function close() {
      $mdSidenav('fileUploadSidebar').close();
    }

    // delete documents by user constent
    function deleteDocument(ev, id) {
      messages.mdconfirm(ev, "Do you want to delete this document?")
        .then(function(flag) {
          if (flag) {
            dataServices.delete({
              url: serverUrl.main + 'assocDocApi/assoc/doc/' + id
            }).then(function(response) {
              if (response && response.data && response.data.result) {
                messages.simpleToast('Document deleted successfully!');
                // After deleting the document we are fetching the list of documents for sync the live data.
                vm.setFilters('new', true);
              }
            });
          }
        });
    }

    function openTemplates() {
      $state.go('main.' + $rootScope.selectedModule + '.addManageTemplates');
    }

    function openCategories(ev) {
      $mdDialog.show({
        controller: DrawingCategoriesController,
        templateUrl: drawingCategoriesTemplate,
        locals: {
          vm: vm
        },
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'dc'
      });
    }

    function openProposalCategories(ev) {
      $mdDialog.show({
        controller: ProposalCategoriesController,
        templateUrl: proposalCategoriesTemplate,
        locals: {
          vm: vm
        },
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'dc'
      });
    }

  }
}