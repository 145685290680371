import fileAttachmentTemplate from './file-attachment.html'

export default function(app) {
  app.directive('fileAttachment', fileAttachment);

  function fileAttachment() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: fileAttachmentTemplate
    };
  }
}