export default function(app) {
  app.factory('searchTag', searchTag);

  function searchTag($state, serverUrl, dataServices, messages) {

    "ngInject";

    return {
      querySearch: querySearch,
      transformChip: transformChip
    };

    // START : This function is used for creating chip if you select it from search list or you are creating new tags.
    function transformChip(chip, array, maxlength) {
      if (angular.isString(chip)) {
        if (chip.length > 50) {
          messages.simpleToast("Tags Length Exceed !");
          return null;
        }

        if ((/[^a-zA-Z0-9 _-]/.test(chip))) {
          messages.simpleToast("Special Characters are not Allowed in Tags !");
          return null;
        }

        if (array && array.length >= maxlength) {
          messages.simpleToast("Maximum " + maxlength + ' tags can be added.');
          return null;
        }

        // if (array && array.length && array.indexOf(chip.toLowerCase()) != -1) {
        //   messages.simpleToast("Duplicate Tags are not allowed.");
        //   return null;
        // }
        return chip;
      }
      return null;
    }
    // END : This function is used for creating chip if you select it from search list or you are creating new tags.

    // START : This function is used for passing the query to dataFilter function.
    function querySearch(query) {
      return query ? dataFilter(query) : [];
    }
    // END : This function is used for passing the query to dataFilter function.

    // START:This function is used for searching the query on the databse search and returning the control to whom this is called.
    function dataFilter(query) {
      return dataServices.get({
        url: serverUrl.main + 'tagApi/tag/search?tagName=' + query.toLowerCase() + "&",
        spinner: false
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          return data.result;
        }
      });
    }
    // END: This function is used for searching the query on the databse search and returning the control to whom this is called.
  }

}
