export default function(app) {
  app.factory('spinnerLoader', spinnerLoader);

  function spinnerLoader(usSpinnerService, $timeout) {

    "ngInject";

    // Generally, totalSpinCount takes count of based on spinnerName. it help to on and again on to spinner. If spinner is on and again come request to on the same spinner. At that time spinnerName count will increase with 1, ( > 1), SO again spinner will not initiate. If spinner count is 0 then spinner is initiate and off also.

    let totalSpinCount = {
        globalSpin: 0
      },
      spinnerPromises = {};

    const MAX_TIME_IN_MS = 60000;

    return {
      start: start,
      stop: stop,
      hardStop: hardStop
    };

    // If params is undefined then default id is globalSpin. Which spinner comes in full screen. Other wise id will come name as spinnerName.
    function start(id = 'globalSpin') {
      // console.log(id);
      // Register the spinner.
      // console.log("bstart", totalSpinCount);

      if (!totalSpinCount[id]) {
        totalSpinCount[id] = 0;
      }
      // If totalSpinCount is zero or lessthan zero, then spinner should be start.
      if (!totalSpinCount[id] || totalSpinCount[id] < 0) {
        if (totalSpinCount[id] < 0)
          totalSpinCount[id] = 0;
        if (document.getElementById(id)) {
          // Spinner visibility should be visible.
          document.getElementById(id).style.visibility = "visible";
        }
        // Also spinner start. For app, we are using angular-spinner. This is simple function, who will start spinner.
        // console.log('spinner start', id);
        usSpinnerService.spin(id);
      }
      // Increase spinner Count.
      totalSpinCount[id]++;
      // console.log("astart", totalSpinCount);

      if (angular.isDefined(spinnerPromises[id])) {
        // console.log(id, 'cancelled present timeout and starting new one');
        $timeout.cancel(spinnerPromises[id]);
        delete spinnerPromises[id];
      }

      // console.log(id, 'started timeout');
      spinnerPromises[id] = $timeout(function() {
        if (totalSpinCount[id]) {
          stop(id);
        }
      }, MAX_TIME_IN_MS);

    }

    // Same as stop.
    function stop(id = 'globalSpin') {
      // console.log("bstop", totalSpinCount);
      if (!totalSpinCount[id]) {
        totalSpinCount[id] = 0;
      }

      // console.log(id);
      // If totalSpinCount is less than 0 or 1, then spinner should be stop. Nad hide visibility of background
      if (document.getElementById(id) && (totalSpinCount[id] == 1 || totalSpinCount[id] < 0)) {
        document.getElementById(id).style.visibility = "hidden";
      }

      // Stop spinner.
      // console.log('spinner stop', id);
      usSpinnerService.stop(id);
      // Decrease spinner count
      totalSpinCount[id]--;
      if (totalSpinCount[id] < 0) {
        totalSpinCount[id] = 0;
      }
      // console.log("astop", totalSpinCount);

      if (angular.isDefined(spinnerPromises[id])) {
        // console.log(id, 'cancelled timeout');
        $timeout.cancel(spinnerPromises[id]);
        delete spinnerPromises[id];
      }

    }

    function hardStop(id = 'globalSpin') {
      // console.log(id);
      if (totalSpinCount[id]) {
        for (let i = totalSpinCount[id]; i > 0; i--) {
          stop(id)
        }
      }
    }
  }
}