import scheduleNowTemplate from './schedule-now.html'

export default function(app) {
  app.directive('scheduleNow', scheduleNow);

  function scheduleNow() {
    return {
      restrict: 'E',
      templateUrl: scheduleNowTemplate
      // replace: true,
    };
  }
}