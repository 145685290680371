import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';

export default function(app) {
  app.factory('authInterceptor', authInterceptor);

  function authInterceptor($injector, $q, $rootScope, serverUrl, loginKey, authService, sharedDataService, localStorageService) {

    "ngInject";

    return {
      request: request,
      response: response,
      requestError: requestError,
      responseError: responseError
    };

    let cachedRequest = null;

    function getAccessTokenRenewApi() {
      let http = $injector.get("$http");
      return http({
        url: serverUrl.auc + 'auth/refresh',
        method: 'POST',
        headers: {
          'product': 'jacktrade_1',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: function(obj) {
          let str = [];
          for (let p in obj) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
          return str.join("&");
        },
        data: {
          refresh_token: $rootScope.accessData.refresh_token
        }
      });
    }

    function processNewAccessToken(result) {
      let currentTimeStamp = Math.trunc(Date.now() / 1000);

      $rootScope.accessData.access_token = result.access_token;
      $rootScope.accessData.refresh_token = result.refresh_token;

      $rootScope.accessData.accessTokenExpiresAt = currentTimeStamp + result.expires_in;
      $rootScope.accessData.refreshTokenExpiresAt = currentTimeStamp + result.session_expires_in;

      let accessDataFromLocal = localStorageService.get('accessData');
      let accessData = angular.copy($rootScope.accessData);
      accessData.access_token = aes.encrypt(result.access_token, loginKey).toString();
      accessData.refresh_token = aes.encrypt(result.refresh_token, loginKey).toString();
      accessData.user_id = accessDataFromLocal.user_id;
      accessData.client_id = accessDataFromLocal.client_id;

      sharedDataService.set('accessData', accessData);
    }

    function decryptCrypto(data) {
      return data ? aes.decrypt(data, loginKey).toString(utf8) : undefined;
    }

    function fetchAccessDataFromLocalStorage() {
      let accessData = localStorageService.get('accessData');
      if (accessData) {
        $rootScope.accessData = accessData;
        $rootScope.accessData.user_id = decryptCrypto(accessData.user_id);
        $rootScope.accessData.client_id = decryptCrypto(accessData.client_id);
        $rootScope.accessData.access_token = decryptCrypto(accessData.access_token);
        $rootScope.accessData.refresh_token = decryptCrypto(accessData.refresh_token);
      }
    }

    function request(config) {
      // console.log(config.url);
      if (config.url != (serverUrl.auc + 'auth/refresh') && config.url != (serverUrl.auc + 'auth/login') && (config.url.indexOf(serverUrl.main) != -1 || config.url.indexOf(serverUrl.common) != -1)) {

        // console.log(config.url);

        config.headers = config.headers || {};
        let currentTimeStamp = Math.trunc(Date.now() / 1000);

        if ($rootScope.accessData && $rootScope.accessData.accessTokenExpiresAt && currentTimeStamp >= $rootScope.accessData.accessTokenExpiresAt) {
          fetchAccessDataFromLocalStorage();
        }

        if ($rootScope.accessData && $rootScope.accessData.access_token && $rootScope.accessData.refreshTokenExpiresAt) {

          if (currentTimeStamp >= $rootScope.accessData.refreshTokenExpiresAt) {
            authService.logout(true, true, 'Refresh Token Expired!');
            return $q.reject({
              "error": "invalid_request",
              "error_description": "The refresh token is invalid.",
              "hint": "Token has expired",
              "message": "The refresh token is invalid."
            });
          } else if (currentTimeStamp >= $rootScope.accessData.accessTokenExpiresAt) {

            //Cache this request
            let deferred = $q.defer();
            if (!cachedRequest) {
              //Cache request for renewing Access Token and wait for Promise
              cachedRequest = getAccessTokenRenewApi();
            }

            //When Promise is resolved, new Access Token is returend
            cachedRequest.then(function(res) {
              cachedRequest = null;

              let data = res.data;
              if (data && data.result) {

                processNewAccessToken(data.result);
                config.headers.Authorization = 'Bearer ' + $rootScope.accessData.access_token;
                deferred.resolve(config);

              } else {
                deferred.resolve(config);
              }
            }, function(res) {
              //If any error occurs reject the Promise
              cachedRequest = null;
              deferred.reject(res);
            });
            return deferred.promise;

          } else {
            config.headers.Authorization = 'Bearer ' + $rootScope.accessData.access_token;
          }
        }
        // console.log(config);
      }
      return config;
    }

    function requestError(config) {
      return config;
    }

    function response(response) {
      return response;
    }

    function responseError(response) {
      if (response.config && (response.config.url.indexOf(serverUrl.main) != -1 || response.config.url.indexOf(serverUrl.common) != -1 || response.config.url.indexOf(serverUrl.auc) != -1)) {
        if (response.config.url == serverUrl.auc + 'auth/refresh') {
          authService.logout(true, true, 'Refresh Token Renew Failed!');
        } else {

          switch (response.status) {
            //Detect if reponse error is 401 (Unauthorized)
            case 401:

              //Cache this request
              let deferred = $q.defer(),
                http = $injector.get("$http");
              if (!cachedRequest) {
                //Cache request for renewing Access Token and wait for Promise
                cachedRequest = getAccessTokenRenewApi();
              }

              //When Promise is resolved, new Access Token is returend
              cachedRequest.then(function(res) {
                cachedRequest = null;

                let data = res.data;
                if (data && data.result) {

                  processNewAccessToken(data.result);

                  http(response.config).then(function(resp) {
                    //Resolve this request (successfully this time)
                    deferred.resolve(resp);
                  }, function(resp) {
                    deferred.reject(resp);
                  });

                } else {
                  authService.logout(true, true, 'renewAccessToken');
                  //If any error occurs reject the Promise
                  deferred.reject(response);
                }
              }, function(res) {
                //If any error occurs reject the Promise
                cachedRequest = null;
                deferred.reject(res);
              });
              return deferred.promise;
              break;

            case 403:

              $rootScope.userSession = {
                error: {
                  msg: 'Suspended',
                  detail: 'Your access has been revoked. Please contact the administrator.'
                }
              };

              authService.logout();
              break;
          }
        }
      }
      //If any error occurs reject the Promise
      return $q.reject(response);
      // return response;
    }

  }
}