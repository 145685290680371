export default function(app) {
  app.constant('tipScreens', {
    'main.dashboard.gettingstarted': 'dashboard.gettingstarted',
    'main.dashboard.mydashboard': 'dashboard.mydashboard',
    'main.dashboard.dashboardList': 'dashboard.dashboardList',
    'main.dashboard.highlights': 'dashboard.highlights',
    'main.dashboard.myworkboard': 'dashboard.myworkboard',
    'main.dashboard.activities': 'dashboard.activities',
    'main.dashboard.addActivity': 'dashboard.updateActivity',
    'main.dashboard.updateActivity': 'dashboard.updateActivity',
    'main.dashboard.getsupport': 'dashboard.getsupport',

    'main.quoteList': 'quote.quote_list',
    'main.quotes.guider': 'quote.guider',
    'main.quotes.editGuider': 'quote.edit_guider',
    'main.quotes.createQuoteGuider': 'quote.edit_guider',
    'main.quotes.action': 'quote.action_list',
    'main.quotes.document': 'quote.document',
    'main.quotes.documentDetail': 'quote.documentDetail',
    'main.quotes.documentAdd': 'quote.documentAdd',
    'main.quotes.documentUpdate': 'quote.documentUpdate',
    'main.quotes.servicesWorkorder': 'quote.servicesWorkorder',
    'main.quotes.servicesMaterialDashboard': 'quote.servicesMaterialDashboard',
    'main.quotes.servicesMaterialsAdd': 'quote.servicesMaterialsAdd',
    // 'main.quotes.visit': 'quote.visit',
    'main.quotes.meetings': 'quote.visit',
    'main.quotes.visitDetail': 'quote.visit_detail',
    'main.quotes.editVisit': 'quote.edit_visit',
    'main.quotes.addVisit': 'quote.edit_visit',
    'main.quotes.contact': 'quote.contacts',
    'main.quotes.editQuote': 'quote.edit_quote',
    'main.quotes.addQuote': 'quote.edit_quote',
    'main.quotes.summary': 'quote.summary',
    'main.quotes.history': 'quote.history',
    'main.quoteListMap': 'quote.quote_listMap',
    'main.quotes.submit': 'quote.submit',
    'main.quotes.payment': 'quote.payment',

    'main.quotes.services': 'quote.regular_service',
    'main.quotes.servicesDetail': 'quote.services_detail',
    'main.quotes.addServices': 'quote.services_add',
    'main.quotes.editServices': 'quote.services_add',
    'main.quotes.servicesEstimateDetail': 'quote.services_estimate_detail',
    'main.quotes.updateServicesEstimates': 'quote.updateServicesEstimates',
    'main.quotes.addRecurringServices': 'quote.add_recurring_services',
    'main.quotes.editRecurringServices': 'quote.add_recurring_services',
    'main.quotes.servicesProductsAdd': 'quote.services_products_add',
    'main.quotes.addProductDetail': 'quote.product_details',
    'main.quotes.addProductDetailCart': 'quote.product_details_cart',
    'main.quotes.addBundleDetail': 'quote.add_bundle_detail',
    'main.quotes.addBundleProductDetailCart': 'quote.add_bundle_product_detail_cart',
    'main.quotes.addPackageDetail': 'quote.add_package_detail_cart',
    'main.quotes.addPackageDetailCart': 'quote.add_package_detail_cart',
    'main.quotes.addPackageProductCustomize': 'quote.add_package_product_customize',
    'main.quotes.addPackageProductSelectedCustomize': 'quote.addPackageProductSelectedCustomize',
    'main.quotes.editServicesPromotion': 'quote.edit_services_promotion',
    'main.quotes.cartDashboard': 'quote.cart_dashboard',
    'main.quotes.editQuoteDashboard': 'quote.editQuoteDashboard',
    'main.quotes.editDashboardList': 'quote.editDashboardList',
    'main.quotes.addReportBlock': 'quote.addReportBlock',
    'main.quotes.addManageIndicator': 'quote.addManageIndicator',
    'main.quotes.servicesProductDashboard': 'quote.servicesProductDashboard',
    'main.quotes.servicesMaterialDashboard': 'quote.servicesMaterialDashboard',
    'main.quotes.servicesMaterialsAdd': 'quote.servicesMaterialsAdd',
    'main.quotes.addPackageDetailMaterial': 'quote.addPackageDetailMaterial',
    'main.quotes.addPackageDetailCartMaterial': 'quote.addPackageDetailCartMaterial',
    'main.quotes.addPackageProductCustomizeMaterial': 'quote.addPackageProductCustomizeMaterial',
    'main.quotes.addProductDetailMaterial': 'quote.addProductDetailMaterial',
    'main.quotes.addProductDetailCartMaterial': 'quote.addProductDetailCartMaterial',
    'main.quotes.addBundleDetailMaterial': 'quote.addBundleDetailMaterial',
    'main.quotes.addBundleProductDetailCartMaterial': 'quote.addBundleProductDetailCartMaterial',

    'main.jobList': 'job.job_list',
    'main.jobs.guider': 'job.guider',
    'main.jobs.editGuider': 'job.edit_guider',
    'main.jobs.createQuoteGuider': 'job.edit_guider',
    'main.jobs.action': 'job.action_list',
    'main.jobs.document': 'job.document',
    'main.jobs.documentDetail': 'job.documentDetail',
    'main.jobs.documentAdd': 'job.documentAdd',
    'main.jobs.documentUpdate': 'job.documentUpdate',
    'main.jobs.servicesWorkorder': 'job.servicesWorkorder',
    'main.jobs.servicesMaterialDashboard': 'job.servicesMaterialDashboard',
    'main.jobs.servicesMaterialsAdd': 'job.servicesMaterialsAdd',
    'main.jobs.meetings': 'job.visit',
    'main.jobs.visitDetail': 'job.visit_detail',
    'main.jobs.editVisit': 'job.edit_visit',
    'main.jobs.addVisit': 'job.edit_visit',
    'main.jobs.contact': 'job.contacts',
    'main.jobs.editQuote': 'job.edit_job',
    'main.jobs.addQuote': 'job.edit_job',
    'main.jobs.addjob': 'job.edit_job',
    'main.jobs.summary': 'job.summary',
    'main.jobs.history': 'job.history',
    'main.jobListMap': 'job.job_listMap',
    'main.jobs.submit': 'job.submit',
    'main.jobs.payment': 'job.payment',

    'main.jobs.services': 'job.regular_service',
    'main.jobs.servicesDetail': 'job.services_detail',
    'main.jobs.addServices': 'job.services_add',
    'main.jobs.editServices': 'job.services_add',
    'main.jobs.servicesEstimateDetail': 'job.services_estimate_detail',
    'main.jobs.updateServicesEstimates': 'job.edit_services_estimates',
    'main.jobs.addRecurringServices': 'job.add_recurring_services',
    'main.jobs.editRecurringServices': 'job.add_recurring_services',
    'main.jobs.servicesProductsAdd': 'job.services_products_add',
    'main.jobs.addProductDetail': 'job.product_details',
    'main.jobs.addProductDetailCart': 'job.product_details_cart',
    'main.jobs.addBundleDetail': 'job.add_bundle_detail',
    'main.jobs.addBundleProductDetailCart': 'job.add_bundle_product_detail_cart',
    'main.jobs.addPackageDetail': 'job.add_package_detail_cart',
    'main.jobs.addPackageDetailCart': 'job.add_package_detail_cart',
    'main.jobs.addPackageProductCustomize': 'job.add_package_product_customize',
    'main.jobs.addPackageProductSelectedCustomize': 'job.addPackageProductSelectedCustomize',
    'main.jobs.editServicesPromotion': 'job.edit_services_promotion',
    'main.jobs.cartDashboard': 'job.cart_dashboard',
    'main.jobs.editQuoteDashboard': 'job.editQuoteDashboard',
    'main.jobs.editDashboardList': 'job.editDashboardList',
    'main.jobs.addReportBlock': 'job.addReportBlock',
    'main.jobs.addManageIndicator': 'job.addManageIndicator',
    'main.jobs.servicesProductDashboard': 'job.servicesProductDashboard',
    'main.jobs.servicesMaterialDashboard': 'job.servicesMaterialDashboard',
    'main.jobs.servicesMaterialsAdd': 'job.servicesMaterialsAdd',
    'main.jobs.addPackageDetailMaterial': 'job.addPackageDetailMaterial',
    'main.jobs.addPackageDetailCartMaterial': 'job.addPackageDetailCartMaterial',
    'main.jobs.addPackageProductCustomizeMaterial': 'job.addPackageProductCustomizeMaterial',
    'main.jobs.addProductDetailMaterial': 'job.addProductDetailMaterial',
    'main.jobs.addProductDetailCartMaterial': 'job.addProductDetailCartMaterial',
    'main.jobs.addBundleDetailMaterial': 'job.addBundleDetailMaterial',
    'main.jobs.addBundleProductDetailCartMaterial': 'job.addBundleProductDetailCartMaterial',
    'main.jobs.servicesProductOrderingCartAdd': 'job.servicesProductOrderingCartAdd',
    'main.jobs.servicesMaterialOrderingCartAdd': 'job.servicesMaterialOrderingCartAdd',
    'main.jobs.servicesProductBundleOrderingCart': 'job.servicesProductBundleOrderingCart',
    'main.jobs.servicesMaterialBundleOrderingCart': 'job.servicesMaterialBundleOrderingCart',
    'main.jobs.servicesProductPackageOrderingCart': 'job.servicesProductPackageOrderingCart',
    'main.jobs.servicesMaterialPackageOrderingCart': 'job.servicesMaterialPackageOrderingCart',
    'main.jobs.servicesProductBundleDetailAllFulfill': 'job.servicesProductBundleDetailAllFulfill',
    'main.jobs.servicesMaterialBundleDetailAllFulfill': 'job.servicesMaterialBundleDetailAllFulfill',
    'main.jobs.servicesMaterialPackageDetailAllFulfill': 'job.servicesMaterialPackageDetailAllFulfill',
    'main.jobs.servicesProductPackageDetailAllFulfill': 'job.servicesProductPackageDetailAllFulfill',

    'main.calendarEvents': 'calender.calender_view',

    'main.resources.resources': 'resources.resources_list',
    'main.resources.resourcesResourcesList': 'resources.resources_list',
    'main.resources.resourcesDetails': 'resources.resources_details',
    'main.resources.resourcesActivities': 'resources.resourcesActivities',
    'main.resources.massignmentUserclock': 'resources.massignmentUserclock',
    'main.resources.massignmentAllassigned': 'resources.massignmentAllassigned',
    'main.resources.scheduling': 'resources.scheduling',
    'main.resources.schedulingAssigned': 'resources.schedulingAssigned',
    'main.resources.schedulingAllocate': 'resources.schedulingAllocate',
    'main.resources.massignment': 'resources.massignment',
    'main.resources.resourcesIntegration': 'resources.resourcesIntegration',
    'main.resources.resourcesGoogleSyncUpdate': 'resources.resourcesGoogleSyncUpdate',
    'main.resources.resourcesOutlookSyncUpdate': 'resources.resourcesOutlookSyncUpdate',
    'main.resources.resourcesZoomSyncUpdate': 'resources.resourcesZoomSyncUpdate',
    'main.resources.zoomApiIntegration': 'resources.zoomApiIntegration',
    'main.resources.googleApiIntegration': 'resources.googleApiIntegration',
    'main.resources.outlookApiIntegration': 'resources.outlookApiIntegration',
    'main.resources.resourcesAdd': 'resources.resources_add',
    'main.resources.resourceDetailEdit': 'resources.resource_detail_edit',
    'main.resourcesactionList': 'resources.action_list',
    'main.resources.compareSquoteDetail': 'resources.compare_task_details',
    'main.resources.compareSjobDetail': 'resources.compareTaskDetailsJobs',
    'main.resources.compareSquote': 'resources.compareSquote',
    'main.resources.compareSjob': 'resources.compareSjob',
    'main.resources.compareGantt': 'resources.compareGantt',
    'main.resources.action': 'resources.action',
    'main.resources.actionInactiveFolder': 'resources.actionInactiveFolder',
    'main.resources.actionInactiveFolderData': 'resources.actionInactiveFolderData',
    'main.resources.actionWorkboard': 'resources.actionWorkboard',
    'main.resources.documents': 'resources.documents',
    'main.resources.addManageTemplates': 'resources.addManageTemplates',
    'main.resources.bcontactGroup': 'resources.bcontactGroup',
    'main.resources.bcontactImport': 'resources.bcontactImport',

    'main.customers.customerUpload': 'customers.customerUpload',
    'main.customers.customerUploadw': 'customers.customerUpload',
    'main.customers.customersDocument': 'customers.customersDocument',
    'main.customers.customersDocumentDetail': 'customers.customersDocumentDetail',
    'main.customers.customersDocumentAdd': 'customers.customersDocumentUpdate',
    'main.customers.customersDocumentUpdate': 'customers.customersDocumentUpdate',
    'main.customers.dashboardList': 'customers.dashboardList',
    'main.customers.addManageIndicator': 'customers.addManageIndicator',
    'main.customers.addReportBlock': 'customers.addReportBlock',
    'main.customers.customerDashboardList': 'customers.customerDashboardList',
    'main.customers.customerAddManageIndicator': 'customers.customerAddManageIndicator',
    'main.customers.customerAddReportBlock': 'customers.customerAddReportBlock',
    'main.customers.channels': 'customers.channels',
    'main.customers.addConversations': 'customers.addConversations',
    'main.customers.channelsPosts': 'customers.channelsPosts',
    'main.customers.channelsReports': 'customers.channelsReports',
    'main.customers.channelsCustomers': 'customers.channelsCustomers',
    'main.customers.editProfile': 'customers.edit_profile',
    'main.customers.customerUploadList': 'customers.customer_upload_list',
    'main.customers.opportunities': 'customers.opportunities',
    'main.customers.opportunitiesPipeline': 'customers.opportunities_pipeline',
    'main.customers.opportunitiesList': 'customers.opportunities_list',
    'main.customers.addCard': 'customers.add_card',
    'main.customers.editCard': 'customers.add_card',
    'main.customers.opportunitiesDetail': 'customers.opportunities_detail',
    'main.customers.opportunitiesHistory': 'customers.opportunities_history',
    'main.customers.opportunitiesActionList': 'customers.opportunities_action_list',
    'main.customers.campaigns': 'customers.campaigns',
    'main.customers.campaignsCategory': 'customers.campaignsCategory',
    'main.customers.campaignsAdd': 'customers.campaignsAdd',
    'main.customers.campaignsUpdate': 'customers.campaignsAdd',
    'main.customers.campaignsDetail': 'customers.campaignsDetail',
    'main.customers.campaignsEmailReport': 'customers.campaignsEmailReport',
    'main.customers.campaignsSubscriberList': 'customers.campaignsSubscriberList',
    'main.customers.campaignsUnsubscriberFormUpdate': 'customers.campaignsUnsubscriberFormUpdate',
    'main.customers.campaignsActionList': 'customers.campaignsActionList',
    'main.customers.campaignsDrip': 'customers.campaignsDrip',
    'main.customers.campaignsDripDetail': 'customers.campaignsDetailDrip',
    'main.customers.campaignsDetailDripAdd': 'customers.campaignsDetailDripAdd',
    'main.customers.campaignsDetailDripUpdate': 'customers.campaignsDetailDripAdd',
    'main.customers.nurturingDesigns': 'customers.nurturingDesigns',
    'main.customers.nurturingDesignsCategories': 'customers.nurturingDesignsCategories',

    'main.customers.customersConversation': 'customers.customers_conversation',
    'main.customers.editCustomerTags': 'customers.edit_customer_tags',
    'main.customers.segmentation': 'customers.segmentation_list',
    'main.customers.segmentationList': 'customers.segmentation_list',
    'main.customers.segmentationDetail': 'customers.segmentation_detail',
    'main.customers.updateSegmentation': 'customers.update_segmentation',
    'main.customers.addSegmentation': 'customers.update_segmentation',
    'main.customers.segmentationManage': 'customers.segmentation_manage',
    'main.customers.customersDashboard': 'customers.customersDashboard',
    'main.customers.customers': 'customers.customers',
    'main.customers.customersDetail': 'customers.customersDetail',
    'main.customers.customersAssociates': 'customers.customersAssociates',
    'main.customers.customersHistory': 'customers.customersHistory',
    'main.customers.companies': 'customers.companies',
    'main.customers.companiesAssociates': 'customers.companiesAssociates',
    'main.customers.companiesHistory': 'customers.companiesHistory',
    'main.customers.companiesCprofile': 'customers.companiesCprofile',
    'main.customers.addCompany': 'customers.addCompany',
    'main.customers.updateCompany': 'customers.addCompany',

    'main.customers.addContact': 'customers.addContact',
    'main.customers.editContact': 'customers.editContact',
    'main.customers.customersHistory': 'customers.customersHistory',
    'main.customers.customersCommunicate': 'customers.customersCommunicate',
    'main.customers.opportunitiesDashboard': 'customers.opportunitiesDashboard',
    'main.customers.opportunitiesCommunicate': 'customers.opportunitiesCommunicate',
    'main.customers.leadgeneration': 'customers.leadgeneration',
    'main.customers.leadgenerationBusinesssearchdetails': 'customers.leadgenerationBusinesssearchdetails',

    'main.business': 'business.inventory_cataloging',
    'main.business.inventoryCataloging': 'business.inventory_cataloging',
    'main.business.inventoryCatalogingManagement': 'business.inventory_cataloging_management',
    'main.business.inventoryCatalogingServices': 'business.inventory_cataloging_services',
    'main.business.inventoryCatalogingInstructions': 'business.inventory_cataloging_instructions',
    'main.business.addCatalog': 'business.add_catalog',
    'main.business.updateCatalog': 'business.add_catalog',
    'main.business.addAttachedService': 'business.add_attached_service',
    'main.business.updateAttachedService': 'business.add_attached_service',
    'main.business.addProductInstruction': 'business.add_product_instruction',
    'main.business.updateProductInstruction': 'business.add_product_instruction',
    'main.business.inventoryMaterials': 'business.inventoryMaterials',
    'main.business.inventoryMaterialsDetail': 'business.inventoryMaterialsDetail',
    'main.business.addMaterial': 'business.addMaterial',
    'main.business.updateMaterial': 'business.addMaterial',
    'main.business.inventoryMaterialsSkuDetail': 'business.inventoryMaterialsSkuDetail',
    'main.business.addMaterialSku': 'business.addMaterialSku',
    'main.business.updateMaterialSku': 'business.addMaterialSku',
    'main.business.addCloneMaterialSku': 'business.addCloneMaterialSku',
    'main.business.fulfillmentDeliveryusage': 'business.fulfillmentDeliveryusage',
    'main.business.fulfillmentReturnproduct': 'business.fulfillmentReturnproduct',
    'main.business.fulfillmentRentalreturn': 'business.fulfillmentRentalreturn',
    'main.business.fulfillmentRentalreturnDetail': 'business.fulfillmentRentalreturnDetail',
    'main.business.inventoryProducts': 'business.inventory_products',
    'main.business.inventoryProductsDetail': 'business.inventory_products_details',
    'main.business.updateProduct': 'business.add_product',
    'main.business.addProduct': 'business.add_product',
    'main.business.addProductSku': 'business.addProductSku',
    'main.business.updateProductSku': 'business.addProductSku',
    'main.business.inventoryProductsSkuDetail': 'business.inventoryProductsSkuDetail',
    'main.business.addCloneProductSku': 'business.addCloneProductSku',
    'main.business.addPackage': 'business.addPackage',
    'main.business.updatePackage': 'business.updatePackage',
    'main.business.inventoryProductsPackageView': 'business.inventoryProductsPackageView',
    'main.business.addBundle': 'business.addBundle',
    'main.business.updateBundle': 'business.updateBundle',
    'main.business.inventoryProductsBundleView': 'business.inventoryProductsBundleView',
    'main.business.mediaUpdate': 'business.mediaUpdate',
    'main.business.mediaUpload': 'business.mediaUpload',
    'main.business.mediaAllMedia': 'business.mediaAllMedia',
    'main.business.mediaAssociation': 'business.mediaAssociation',
    'main.business.mediaEditTags': 'business.mediaEditTags',
    'main.business.addSuppliers': 'business.addSuppliers',
    'main.business.updateSuppliers': 'business.addSuppliers',
    'main.business.suppliers': 'business.suppliers',
    'main.business.suppliersDetail': 'business.suppliersDetail',
    'main.business.suppliersCatalog': 'business.suppliersCatalog',
    'main.business.addCatalogItem': 'business.addCatalogItem',
    'main.business.updateCatalogItem': 'business.addCatalogItem',
    'main.business.suppliersCatalogDetail': 'business.suppliersCatalogDetail',
    'main.business.purchaseorder': 'business.purchaseorder',
    'main.business.addPurchaseOrder': 'business.addPurchaseOrder',
    'main.business.updatePurchaseOrder': 'business.addPurchaseOrder',
    'main.business.purchaseorderDetails': 'business.purchaseorderDetails',
    'main.business.suppliersRelatedOrders': 'business.suppliersRelatedOrders',
    'main.business.suppliersAutoOrders': 'business.suppliersAutoOrders',
    'main.business.purchaseorderManageItems': 'business.purchaseorderManageItems',
    'main.business.purchaseorderPublish': 'business.purchaseorderPublish',
    'main.business.purchaseorderPaymentpo': 'business.purchaseorderPaymentpo',
    'main.business.inventoryProductsCurrentstock': 'business.inventoryProductsCurrentstock',
    'main.business.inventoryFulfillment': 'business.inventoryFulfillment',
    'main.business.inventoryFulfillmentItemorder': 'business.inventoryFulfillmentItemorder',
    'main.business.inventoryProductsWelcomeImport': 'business.inventoryProductsWelcomeImport',
    'main.business.inventoryProductsImportInventory': 'business.inventoryProductsImportInventory',
    'main.business.inventoryProductsImportSummary': 'business.inventoryProductsImportSummary',
    'main.business.inventoryProductsSuccessRecords': 'business.inventoryProductsSuccessRecords',
    'main.business.inventoryProductsErrorRecords': 'business.inventoryProductsErrorRecords',
    'main.business.inventoryProductsWarningsRecords': 'business.inventoryProductsWarningsRecords',
    'main.business.inventoryMaterialsWelcomeImport': 'business.inventoryMaterialsWelcomeImport',
    'main.business.inventoryMaterialsImportInventory': 'business.inventoryMaterialsImportInventory',
    'main.business.inventoryMaterialsImportSummary': 'business.inventoryMaterialsImportSummary',
    'main.business.inventoryMaterialsSuccessRecords': 'business.inventoryMaterialsSuccessRecords',
    'main.business.inventoryMaterialsErrorRecords': 'business.inventoryMaterialsErrorRecords',
    'main.business.inventoryMaterialsWarningsRecords': 'business.inventoryMaterialsWarningsRecords',
    'main.business.inventoryNewList': 'business.inventoryNewList',
    'main.business.operationsPayout': 'business.operationsPayout',
    'main.business.operationsPayin': 'business.operationsPayin',
    'main.business.purchaseorderSubmitpo': 'business.purchaseorderSubmitpo',
    'main.business.inventoryCurrentstock': 'business.inventoryCurrentstock',
    'main.business.fulfillmentServiceorderview': 'business.fulfillmentServiceorderview',
    'main.business.fulfillmentItemorderview': 'business.fulfillmentItemorderview',
    'main.business.inventoryProductsBulkProductImport': 'business.inventoryProductsBulkProductImport',
    'main.business.inventoryMaterialsBulkProductImport': 'business.inventoryMaterialsBulkProductImport',
    'main.business.inventoryProductsBulkFullSummary': 'business.inventoryProductsBulkFullSummary',
    'main.business.inventoryMaterialsBulkFullSummary': 'business.inventoryMaterialsBulkFullSummary',
    'main.business.inventoryProductsBulkProductErrorSummary': 'business.inventoryProductsBulkProductErrorSummary',
    'main.business.inventoryMaterialsBulkProductErrorSummary': 'business.inventoryMaterialsBulkProductErrorSummary',
    'main.business.inventoryProductsBulkProductSuccessSummary': 'business.inventoryProductsBulkProductSuccessSummary',
    'main.business.inventoryMaterialsBulkProductSuccessSummary': 'business.inventoryMaterialsBulkProductSuccessSummary',
    'main.business.inventoryProductsBulkProductWarningSummary': 'business.inventoryProductsBulkProductWarningSummary',
    'main.business.inventoryMaterialsBulkProductWarningSummary': 'business.inventoryMaterialsBulkProductWarningSummary',
    'main.business.inventoryProductsSkuBulkImport': 'business.inventoryProductsSkuBulkImport',
    'main.business.inventoryMaterialsSkuBulkImport': 'business.inventoryMaterialsSkuBulkImport',
    'main.business.inventoryProductsSkuBulkImportSummary': 'business.inventoryProductsSkuBulkImportSummary',
    'main.business.inventoryMaterialsSkuBulkImportSummary': 'business.inventoryMaterialsSkuBulkImportSummary',
    'main.business.inventoryProductsBulkSkuErrorSummary': 'business.inventoryProductsBulkSkuErrorSummary',
    'main.business.inventoryMaterialsBulkSkuErrorSummary': 'business.inventoryMaterialsBulkSkuErrorSummary',
    'main.business.inventoryProductsBulkSkuSuccessSummary': 'business.inventoryProductsBulkSkuSuccessSummary',
    'main.business.inventoryMaterialsBulkSkuSuccessSummary': 'business.inventoryMaterialsBulkSkuSuccessSummary',
    'main.business.inventoryProductsBulkSkuWarningSummary': 'business.inventoryProductsBulkSkuWarningSummary',
    'main.business.inventoryMaterialsBulkSkuWarningSummary': 'business.inventoryMaterialsBulkSkuWarningSummary',
    'main.business.inventoryCatalogingAttachedServiceDetails': 'business.inventoryCatalogingAttachedServiceDetails',
    'main.business.operationsQuickbook': 'business.operationsQuickbook',
    'main.business.warranty': 'business.warranty',
    'main.business.purchaseorderDocuments': 'business.purchaseorderDocuments',
    'main.business.purchaseorderDocumentsDetail': 'business.purchaseorderDocumentsDetail',
    'main.business.purchaseorderDocumentsAdd': 'business.purchaseorderDocumentsUpdate',
    'main.business.purchaseorderDocumentsUpdate': 'business.purchaseorderDocumentsUpdate',
    'main.business.suppliersDocuments': 'business.suppliersDocuments',
    'main.business.suppliersDocumentsAdd': 'business.suppliersDocumentsUpdate',
    'main.business.suppliersDocumentsUpdate': 'business.suppliersDocumentsUpdate',
    'main.business.suppliersDocumentsDetail': 'business.suppliersDocumentsDetail',

    'main.settings.setupProfileDetails': 'settings.setupProfileDetails',
    'main.settings.setupEntitiesView': 'settings.setupEntitiesView',
    'main.settings.updateEntities': 'settings.updateEntities',
    'main.settings.setupLocations': 'settings.setupLocations',
    'main.settings.setupLocationsView': 'settings.setupLocationsView',
    'main.settings.addLocation': 'settings.addLocation',
    'main.settings.updateLocation': 'settings.addLocation',
    'main.settings.setupLocationsRegions': 'settings.setupLocationsRegions',
    'main.settings.setupInvoicesView': 'settings.setupInvoicesView',
    'main.settings.updateInvoices': 'settings.updateInvoices',
    'main.settings.setupPermissions': 'settings.setupPermissions',
    'main.settings.cloneRole': 'settings.cloneRole',
    'main.settings.updateRole': 'settings.updateRole',
    'main.settings.setupMaintenance': 'settings.setupMaintenance',
    'main.settings.automationApplications': 'settings.automationApplications',
    'main.settings.automationAccess': 'settings.automationAccess',
    'main.settings.addApiKey': 'settings.addApiKey',
    'main.settings.updateApiKey': 'settings.addApiKey',
    'main.settings.detailApiKey': 'settings.detailApiKey',
    'main.settings.activityApiKey': 'settings.activityApiKey',
    'main.settings.addWebConversion': 'settings.addWebConversion',
    'main.settings.updateWebConversion': 'settings.addWebConversion',
    'main.settings.automationApplicationsWebConversionDetail': 'settings.automationApplicationsWebConversionDetail',
    'main.settings.automationNotifications': 'settings.automationNotifications',
    'main.settings.addNotification': 'settings.addNotification',
    'main.settings.updateNotification': 'settings.addNotification',
    'main.settings.automationNotificationsView': 'settings.automationNotificationsView',
    'main.settings.dataDataSets': 'settings.dataDataSets',
    'main.settings.structureCommunication': 'settings.structureCommunication',
    'main.settings.structureServices': 'settings.structureServices',
    'main.settings.structureServiceRates': 'settings.structureServiceRates',
    'main.settings.automationPromotions': 'settings.automationPromotions',
    'main.settings.automationPromotionsListView': 'settings.automationPromotionsListView',
    'main.settings.automationPromotionsView': 'settings.automationPromotionsView',
    'main.settings.addPromotion': 'settings.addPromotion',
    'main.settings.updatePromotion': 'settings.addPromotion',
    'main.settings.structurePolicies': 'settings.structurePolicies',
    'main.settings.structurePoliciesDetails': 'settings.structurePoliciesDetails',
    'main.settings.addPolicy': 'settings.addPolicy',
    'main.settings.updatePolicy': 'settings.addPolicy',
    'main.settings.structurePoliciesAttributeDetails': 'settings.structurePoliciesAttributeDetails',
    'main.settings.addAttributePolicy': 'settings.addAttributePolicy',
    'main.settings.updateAttributePolicy': 'settings.addAttributePolicy',
    'main.settings.dataTagManagement': 'settings.dataTagManagement',
    'main.settings.updateCommonTag': 'settings.updateCommonTag',
    'main.settings.dataCustomForms': 'settings.dataCustomForms',
    'main.settings.addCustomForm': 'settings.addCustomForm',
    'main.settings.updateCustomForm': 'settings.addCustomForm',
    'main.settings.automationGuidedActions': 'settings.automationGuidedActions',
    'main.settings.automationGuidedActionsList': 'settings.automationGuidedActionsList',
    'main.settings.automationGuidedActionsView': 'settings.automationGuidedActionsView',
    'main.settings.addGuidedAction': 'settings.addGuidedAction',
    'main.settings.updateGuidedAction': 'settings.addGuidedAction',
    'main.settings.automationGuidedActionsItemview': 'settings.automationGuidedActionsItemView',
    'main.settings.addGuidedActionItem': 'settings.addGuidedActionItem',
    'main.settings.updateGuidedActionItem': 'settings.addGuidedActionItem',
    'main.settings.addPayment': 'settings.addPayment',
    'main.settings.viewPayment': 'settings.viewPayment',
    'main.settings.updatePayment': 'settings.addPayment',
    'main.settings.structureBankingViewRegion': 'settings.structureBankingViewRegion',
    'main.settings.tructureBankingAllTransactions': 'settings.structureBankingAllTransactions',
    'main.settings.bankingAddBrainTree': 'settings.bankingAddBrainTree',
    'main.settings.bankingAddFirstData': 'settings.bankingAddFirstData',
    'main.settings.updateSetup': 'settings.updateSetup',
    'main.settings.structureBankingPaymentGatewayList': 'settings.structureBankingPaymentGatewayList',
    'main.settings.structureBankingTransactions': 'settings.structureBankingTransactions',
    'main.settings.addWarrantyPolicy': 'settings.addWarrantyPolicy',
    'main.settings.updateWarrantyPolicy': 'settings.updateWarrantyPolicy',
    'main.settings.structurePoliciesWarrantyPolicyDetails': 'settings.structurePoliciesWarrantyPolicyDetails',
    'main.settings.structureDeliveryZone': 'settings.structureDeliveryZone',
    'main.settings.structureAddZone': 'settings.structureAddZone',
    'main.settings.structureUpdateZone': 'settings.structureUpdateZone',
    'main.settings.structureDeliveryZoneDetail': 'settings.structureDeliveryZoneDetail',
    'main.settings.structureAddMethod': 'settings.structureAddMethod',
    'main.settings.structureUpdateMethod': 'settings.structureAddMethod',
    'main.settings.structureDeliveryZoneMethoddetail': 'settings.structureDeliveryZoneMethoddetail',
    'main.settings.structureDeliveryCalculator': 'settings.structureDeliveryCalculator',
    'main.settings.structureTaxesZoneCategoryAdd': 'settings.structureTaxesZoneCategoryAdd',
    'main.settings.structureTaxesZoneCategoryUpdate': 'settings.structureTaxesZoneCategoryAdd',
    'main.settings.structureTaxesZoneClassificationAdd': 'settings.structureTaxesZoneClassificationAdd',
    'main.settings.structureTaxesZoneClassificationUpdate': 'settings.structureTaxesZoneClassificationAdd',
    'main.settings.structureTaxesZone': 'settings.structureTaxesZone',
    'main.settings.structureTaxesZoneAdd': 'settings.structureTaxesZoneAdd',
    'main.settings.structureTaxesZoneUpdate': 'settings.structureTaxesZoneAdd',
    'main.settings.structureTaxesZoneDetail': 'settings.structureTaxesZoneDetail',
    'main.settings.structureTaxesZoneMethodAdd': 'settings.structureTaxesZoneMethodAdd',
    'main.settings.structureTaxesZoneMethodUpdate': 'settings.structureTaxesZoneMethodAdd',
    'main.settings.structureTaxesZoneMethoddetail': 'settings.structureTaxesZoneMethoddetail',
    'main.settings.structureBankingQuickBookAdd': 'settings.structureBankingQuickBookAdd',
    'main.settings.structureBankingQuickBookUpdate': 'settings.structureBankingQuickBookAdd',
    'main.settings.structureBankingQuickBookApiIntegration': 'settings.structureBankingQuickBookApiIntegration',
    'main.settings.setupWorkordersView': 'settings.setupWorkordersView'
  });
}