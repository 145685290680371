export default function(app) {
  app.factory('sharedDataService', sharedDataService);

  function sharedDataService(localStorageService) {

    "ngInject";

    const sharedData = {};

    return {
      getAll: getAll,
      clearAll: clearAll,
      get: getAttribute,
      set: setAttribute,
      delete: deleteAttribute,
      restoreSharedData: restoreSharedData
    };

    // Get complete sharedData
    function getAll() {
      return sharedData;
    }

    // Clear shared data and localstorage completely
    function clearAll() {
      let ndsData = localStorageService.get('ndsData');
      let dismissStep = localStorageService.get('dismissStep');
      let dismissCampaignStep = localStorageService.get('dismissCampaignStep');
      // Clearing sharedData one by one as a constant cannot be reassigned
      angular.forEach(sharedData, function(val, key) {
        delete sharedData[key];
      });
      // Clear localStorage
      localStorageService.clearAll(/^(?!.*(credential|redirectUrl|dashboardScreens|localData))/);
      localStorageService.set('dismissStep', (dismissStep || false));
      localStorageService.set('dismissCampaignStep', (dismissCampaignStep || false));
      localStorageService.set('ndsData', (ndsData || {}));
    }

    // Get an attribute's value from shared data
    function getAttribute(key) {
      if (angular.isUndefined(sharedData[key]) || sharedData[key] == null) {
        sharedData[key] = localStorageService.get(key);
      }
      return sharedData[key];
    }

    //Set an attribute into shared data
    function setAttribute(key, value) {
      sharedData[key] = value;
      localStorageService.set(key, value);
    }

    // Delete an attribute from shared data
    function deleteAttribute(key) {
      delete sharedData[key];
      localStorageService.remove(key);
    }

    // Restore shared data from localstorage to memory
    function restoreSharedData() {
      angular.forEach(localStorageService.keys(), function(key) {
        sharedData[key] = localStorageService.get(key);
      });
    }

  }

}