export default function(app) {
  app.constant('fulfillmentActions', {
    'swap': {
      'title': 'Swapped',
      'class': 'deliver'
    },
    'cancel': {
      'title': 'Canceled',
      'class': 'deliver',
      'action': 'action',
      'label1': 'Canceled',
      'label2': 'Reason',
      // 'label3': '',
      'icon': 'media:icon-cancel'
    },
    'return': {
      'title': 'Returned',
      'class': 'return',
      'action': 'action',
      'label1': 'Remove',
      'label2': 'Return',
      // 'label3': '',
      'icon': 'media:icon-return'
    },
    'delivery': {
      'title': 'Delivered',
      'class': 'deliver',
      'action': 'action',
      'label1': 'Return',
      'label2': 'Delivery Method',
      // 'label3': '',
      'icon': 'media:icon-return'
    }
  });
}