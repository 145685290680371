function MainController($rootScope, $state, sharedDataService) {

  "ngInject";

  const vm = this;

  if ($rootScope.currentStateName == 'main') {
    $state.go('/');
  }

  if (!sharedDataService.get('isFromMobile')) {
    vm.scroll = function(isLeft) {
      $('.table-responsive').animate({
        scrollLeft: (isLeft ? '-' : '+') + '=600px'
      }, 500);
    };
  }

}

export default MainController;