export default function(app) {
  app.constant('maxlength', {
    password: 8,
    xs: 25,
    sm: 85,
    md: 255,
    lg: 1024,
    xl: 2048,
    xxl: 5000
  });
}