'use strict';

// index.html page to dist folder
import '!!file-loader?name=[name].[ext]!../favicon.ico';

// main App module
import "./index.module";
// Bootstrap application with name of "myNgApp"
angular.element(document).ready(() => {
  angular.bootstrap(document, ['myNgApp'], {
    strictDi: true
  });
});