export default function quoteResolver() {
  this.loadQuoteList = loadQuoteList;
  // this.loadStartModule = loadStartModule;

  this.$get = function() {
    return this;
  };
}

// function loadStartModule($q, $ocLazyLoad) {
//   "ngInject";
//   const deferred = $q.defer();
//   require.ensure([], (require) => {
//     const asyncModule = require('ng-file-upload');
//     $ocLazyLoad.load({
//       name: 'ngFileUpload'
//     }).then(function () {
//       deferred.resolve(asyncModule);
//     });
//   });
//   return deferred.promise;
// }

function loadQuoteList($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./quote.module');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule.default.controller);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'quotes.module');
  return deferred.promise;
}
