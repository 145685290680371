import listCheckTemplate from './list-check.html';
export default function(app) {
  app.directive('listCheck', listHeader);

  function listHeader() {
    return {
      restrict: 'E',
      templateUrl: listCheckTemplate,
      replace: true
    };
  }
}