export default function(app) {
  app.factory('AlertService', AlertService);

  function AlertService(dataServices, serverUrl, messages, $rootScope, alerts) {

    "ngInject";

    return {
      dismiss: dismiss,
      remindMe: remindMe,
      checkStatus: checkStatus
    };

    function updateAlert(vm, alertName, remindMeLater) {
      vm.hideNotification[alertName] = true;

      let isUpdate, alertsStatus = {};
      if (angular.isObject($rootScope.toursProgress) && $rootScope.toursProgress.id) {
        isUpdate = true;
      }

      angular.forEach(alerts, (alert) => {
        alertsStatus[alert] = $rootScope.toursProgress && $rootScope.toursProgress.alerts && $rootScope.toursProgress.alerts[alert] || {
          dismissed: false
        };
      });

      if (remindMeLater) {
        alertsStatus[alertName].remindAt = moment().add(1, "minute").unix(); // moment().add(24, "hour").unix();
      } else {
        alertsStatus[alertName].dismissed = true;
      }

      dataServices.post({
          url: serverUrl.main + 'toursApi/tours' + (isUpdate ? ('/' + $rootScope.toursProgress.id) : ''),
          data: {
            data: angular.toJson({
              userId: $rootScope.usrObj.userId,
              alerts: alertsStatus
            })
          },
          method: isUpdate ? 'PUT' : '',
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && (data.response_code == 200 || data.response_code == 201)) {
            // messages.simpleToast('Saved Progress.');
          } else {
            vm.hideNotification[alertName] = false;
          }
        }, function() {
          vm.hideNotification[alertName] = false;
        });
    }

    function dismiss(vm, alertName) {
      return function() {
        updateAlert(vm, alertName);
      };
    }

    function remindMe(vm, alertName) {
      return function() {
        updateAlert(vm, alertName, true);
      };
    }

    function checkStatus(vm, alertName) {
      vm.hideNotification = vm.hideNotification || {};
      if (angular.isObject($rootScope.toursProgress) && $rootScope.toursProgress.id) {
        let alerts = $rootScope.toursProgress.alerts;
        if (alerts && alerts[alertName]) {
          vm.hideNotification[alertName] = alerts[alertName].dismissed;
          if (!alerts[alertName].dismissed && alerts[alertName].remindAt) {
            vm.hideNotification[alertName] = moment().unix() < alerts[alertName].remindAt;
          }
        }
      }
    }

  }
}