export default function(app) {
  app.constant('statusIcons', {
    visits: {
      'scheduled': 'set-a:icon-ico-tick',
      'completed': 'set-a:icon-ico-expired',
      'canceled': 'set-a:icon-ico-expired',
      'rescheduled': 'set-a:icon-ico-rescheduled',
      'deleted': 'set-a:icon-ico-expired',
      'incompleted': 'set-a:icon-ico-expired',
      'expired': 'set-a:icon-ico-expired',
      'started': 'set-a:icon-ico-tick',
      'void': 'set-a:icon-cross-circle'
    },
    services: {
      '10': 'set-a:icon-ico-expired', //incomplete
      '11': 'set-a:icon-ico-expired',
      '20': 'set-a:icon-ico-expired',
      '25': 'set-a:icon-ico-expired',
      '30': 'set-a:icon-ico-expired',
      '40': 'set-a:icon-ico-expired',
      '42': 'set-a:icon-ico-expired',
      '45': 'set-a:icon-cross-circle',
      '48': 'set-a:icon-ico-rescheduled',
      '49': 'set-a:icon-ico-expired',
      '50': 'set-a:icon-ico-tick',
      '55': 'set-a:icon-ico-tick',
      '75': 'set-a:icon-ico-expired'
    },
    products: {
      '1': 'set-a:icon-ico-tick',
      '2': 'set-a:icon-ico-tick',
      '4': 'set-a:icon-ico-expired',
      '6': 'set-a:icon-ico-expired',
      '10': 'set-a:icon-ico-expired',
      '12': 'set-a:icon-ico-rescheduled', //incomplete
      '13': 'set-a:icon-ico-expired',
      '22': 'set-a:icon-ico-expired'
    }
  });

}