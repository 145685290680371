import companyAddressListTemplate from './companyAddressListTemplate.html';

export default function(app) {
  app.directive('companyAddressList', companyAddressList);

  function companyAddressList() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: companyAddressListTemplate
    };
  }
}
