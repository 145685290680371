export default function(app) {
  app.constant('errorMessage', {
    // Business Module
    "alphaNumeric": "Field should accept Alphanumeric characters",
    "onlyNumeric": "Field should accept Only numeric characters",
    "onlyNumericShort": "Enter numbers only",
    "alphabets": "Field accepts Alphabetic characters",
    "alphaNumericSpecial": "Field should accept Alphanumeric, valid special characters",
    "validUrl": "Field should accept All Valid URLs protocal name://domain name.domain type",
    "numberFloat": "Field should accept numbers (Int,Float)",
    "number": "Field should accept numbers(Integers)",
    "pickParentCatalog": "Please select catalog",
    "mobile": "Your contact must be between 10 and 15 digit long.",
    "extension": "Field should accept Valid Number of Digits",
    "extMinMax": "Extension must be between 3 to 6 digit long.",
    "ZipMinMax": "Zip Code must be between 3 to 6 digit long.",
    "email": "Enter valid email: Alphanumeric @domain_name.domain_type",
    "onlyPositiveNumber": "Field should accept Positive Numeric Value",
    "pickDropdown": "Please select atleast one",
    "bdate": "Please select Date of Birth",
    "date": "Please select a date",
    "required": "This field is required",
    "requiredShort": "Required field",
    "minValue": "Minimum value for this field is ",
    "minValueShort": "Min value is ",
    "maxValue": "Maximum value for this field is ",
    "maxValueShort": "Max value is ",
    "endDate": "End date should be less than start date",
    "startDate": "Start date should be less than end date",
    "pattern": "Field pattern is not matching",
    "urlPattern": "Please enter valid Url. eg. https://www.google.com",
    "minlength": "Field requires minimum length ",
    "maxlength": "Field exceeds maximum length ",
    "maxDecimalPoint": "Maximum Decimal point for this field is ",
    "requireMatch" : "Please select from the list."
  });
}
