export default function(app) {
  app.directive('infiniteScroll', function() {
    return {
      scope: {
        infiniteScrollFunction: "&",
        infiniteScrollDisable: "="
      },
      link: function(scope, element, attrs) {

        // var elemName = element[0].localName;

        $(element).scroll(function() {
          // console.log('scrolled', scope.infiniteScrollDisable);

          let scrollTop = $(element).scrollTop();

          const minScrollDistance = 300;

          // get more records when scroll reaches to bottom edge
          if (scope.infiniteScrollFunction) {
            let outerHeight = $(element).outerHeight(true),
              scrollHeight = $(element).prop('scrollHeight');

            // remove chunk functionality after new list gets integrated
            if (!scope.infiniteScrollDisable && (scrollTop + outerHeight >= scrollHeight * 0.95)) {
              // console.log('called', scrollTop + outerHeight, scrollHeight);
              scope.infiniteScrollFunction();
            }
          }

          // show or hide scroll to top button
          if (scrollTop >= minScrollDistance) {
            $('#scroll-top').addClass('active');
          } else {
            $('#scroll-top').removeClass('active');
          }
        });
      }
    };
  });

}