export default function(app) {
  app.factory('TimezoneService', TimezoneService);

  function TimezoneService($rootScope, $filter, dataServices, serverUrl) {

    "ngInject";

    return {
      init: init,
      loadTimezones: loadTimezones,
      setLocalTimezone: setLocalTimezone,
      getTimeZoneName: getTimeZoneName,
      getTimeZoneShortName: getTimeZoneShortName
    };

    function getTimeZoneName() {
      return new Date().toString().match(/\((.+)\)/)[1];
    }

    // https://stackoverflow.com/questions/1954397/detect-timezone-abbreviation-using-javascript
    function getTimeZoneShortName() {
      const {
        1: tz
      } = new Date().toString().match(/\((.+)\)/);

      // In Chrome browser, new Date().toString() is
      // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

      // In Safari browser, new Date().toString() is
      // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

      if (tz.includes(' ')) {
        return tz
          .split(' ')
          .map(([first]) => first)
          .join('');
      } else {
        return tz;
      }
    }

    function loadTimezones(vm) {
      /**
       * Create filter function for a query string
       */
      function filterTimezones(query) {
        let lowercaseQuery = query.toLowerCase();

        return function(zone) {
          return (zone.regionLowerCase.indexOf(lowercaseQuery) !== -1) ||
            (zone.gmt.indexOf(lowercaseQuery) !== -1);
        };
      }

      /**
       * Search for zones.
       */
      vm.searchTimezones = function(query) {
        return query ? vm.timezones.filter(filterTimezones(query)) : vm.timezones;
      };

      vm.timezones = vm.timezones || [];
      vm.loadTimezones = function() {
        if (!vm.timezones.length) {
          vm.isLoading = true;
          dataServices.get({
              url: serverUrl.main + 'timezoneApi/timezone?',
              spinner: false
            })
            .then(function(response) {
              vm.isLoading = false;
              let data = response.data;
              if (data && data.response_code == 200) {
                vm.timezones = data.result.filter(function(zone) {
                  zone.regionLowerCase = zone.region.toLowerCase();
                  return zone;
                });
              }
            }, function(error) {
              vm.isLoading = false;
            });
        }
      };
    }

    function setLocalTimezone(vm, addressId) {
      let localTimezone = new Date().toString().split('GMT')[1];
      vm.timezoneInfo = {
        localTimeZone: localTimezone.substr(0, 3) + ':' + localTimezone.substr(3)
      };

      vm.setDefaultTimezoneInfo = function() {
        let timezone = $rootScope.bObj.bAddsMapped[addressId].timeZone;
        if (timezone) {
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + timezone.offset / 60;
          vm.timezoneInfo.destTimeZone = timezone.gmt + ' (' + timezone.region + ')';
        }
      };
    }

    // Initiate variables required
    function init(vm, addressId) {
      setLocalTimezone(vm, addressId);

      vm.prepareLocalStartTimeData = function() {
        if (vm.startDate) {
          let startDate = new Date(vm.startDate.toDateString() + (vm.startTime ? ' ' + vm.startTime : ''));
          vm.timezoneInfo.startTime = Math.trunc(startDate.setMinutes(startDate.getMinutes() - vm.timezoneInfo.timezoneOffset) / 1000);
        }
      };

      vm.prepareLocalEndTimeData = function() {
        if (vm.endDate) {
          let endDate = new Date(vm.endDate.toDateString() + (vm.endTime ? ' ' + vm.endTime : ''));
          vm.timezoneInfo.endTime = Math.trunc(endDate.setMinutes(endDate.getMinutes() - vm.timezoneInfo.timezoneOffset) / 1000);
        }
      };

      vm.selectTimezone = function(loc) {
        if (loc) {
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + loc.offset / 60;
          vm.timezoneInfo.destTimeZone = loc.gmt + ' (' + loc.region + ')';
          vm.prepareLocalStartTimeData();
          vm.prepareLocalEndTimeData();
        }
      };

      vm.setDefaultTimezone = function() {
        vm.selectedTimezone = vm.searchTextZone = null;
        vm.setDefaultTimezoneInfo();
        vm.prepareLocalStartTimeData();
        vm.prepareLocalEndTimeData();
      };

      vm.searchTextChanged = function() {
        if (vm.searchTextZone === '') {
          vm.setDefaultTimezone();
        }
      };

      vm.setTimezoneInfoFromDetail = function(detail) {
        console.log(detail.timeZone);
        // set timezone info from detail in edit
        if (detail.timeZone) {
          vm.selectedTimezone = detail.timeZone;
          // vm.startDate.getTimezoneOffset() - vm.startDate taken just to avoid creation of new date object
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + detail.timeZone.offset / 60;
          vm.timezoneInfo.destTimeZone = detail.timeZone.gmt + ' (' + detail.timeZone.region + ')';
        } else {
          vm.setDefaultTimezoneInfo();
        }

        if (detail.startTime) {
          // prepare visit start date from visit detail in edit
          vm.startDate = new Date(detail.startTime * 1000);

          const startTime = Math.trunc(vm.startDate.setMinutes(vm.startDate.getMinutes() + vm.timezoneInfo.timezoneOffset) / 1000);
          vm.startTime = $filter('time24Hrs')(startTime);
          vm.prepareLocalStartTimeData();

          // prepare end date from detail in edit
          vm.endDate = new Date(detail.endTime * 1000);
          const endTime = Math.trunc(vm.endDate.setMinutes(vm.endDate.getMinutes() + vm.timezoneInfo.timezoneOffset) / 1000);
          vm.endTime = $filter('time24Hrs')(endTime);
          vm.prepareLocalEndTimeData();
        }
      };

      vm.setTimezoneInfoFromDetailRegular = function(detail) {
        console.log(detail.timeZone);
        // set timezone info from detail in edit
        if (detail.timeZone) {
          vm.selectedTimezone = detail.timeZone;
          // vm.startDate.getTimezoneOffset() - vm.startDate taken just to avoid creation of new date object
          vm.timezoneInfo.timezoneOffset = new Date().getTimezoneOffset() + detail.timeZone.offset / 60;
          vm.timezoneInfo.destTimeZone = detail.timeZone.gmt + ' (' + detail.timeZone.region + ')';
        } else {
          vm.setDefaultTimezoneInfo();
        }

        if (detail.startTime) {
          // prepare visit start date from visit detail in edit
          vm.startDate = new Date(detail.startTime[0] * 1000);

          const startTime = Math.trunc(vm.startDate.setMinutes(vm.startDate.getMinutes() + vm.timezoneInfo.timezoneOffset) / 1000);
          vm.startTime = $filter('time24Hrs')(startTime);
          vm.prepareLocalStartTimeData();

          // prepare end date from detail in edit
          vm.endDate = new Date(detail.startTime[0] * 1000);
          const endTime = Math.trunc(vm.endDate.setMinutes(vm.endDate.getMinutes() + vm.timezoneInfo.timezoneOffset) / 1000);
          vm.endTime = $filter('time24Hrs')(endTime);
          vm.prepareLocalEndTimeData();
        }
      };

      vm.getTimezoneInfo = function() {
        return vm.selectedTimezone ? dataServices.toSave(vm.selectedTimezone, ['gmt', 'offset', 'region', 'timezoneId', 'timezone']) : null;
      };

      loadTimezones(vm);
    }

  }
}