import emailStatusTemplate from './email-status.html';

export default function(app) {
  app.directive('emailStatus', emailStatus)
    .controller("emailStatusController", emailStatusController);

  function emailStatus() {
    return {
      restrict: 'E',
      // replace: true, // replace needs single root element
      templateUrl: emailStatusTemplate,
      controller: 'emailStatusController',
      controllerAs: 'es'
    };
  }

  function emailStatusController(dataServices, serverUrl) {

    "ngInject";

    let es = this;

    dataServices.get({
        url: serverUrl.main + "emailStatisticsApi/email/business/statistics?",
        spinner: false
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          es.isLoaded = true;
          es.emailStatus = data.result;
        }
      });

  }
}