export default function(app) {
  app.directive('googleDrivePicker', ['googleDriveSetting', '$rootScope', function(
    googleDriveSetting, $rootScope) {
    return {
      restrict: 'A',
      scope: {
        onLoaded: '&',
        onCancel: '&',
        onPicked: '&'
      },
      link: function(scope, element, attrs) {
        var accessToken = null;

        /**
         * Load required modules
         */
        function instanciate() {
          gapi.load('auth', {
            'callback': onApiAuthLoad
          });
          gapi.load('picker');
        }

        /**
         * OAuth autorization
         * If user is already logged in, then open the Picker modal
         */
        function onApiAuthLoad() {
          var authToken = gapi.auth.getToken();

          if (authToken) {
            handleAuthResult(authToken);
          } else {
            gapi.auth.authorize({
              'client_id': googleDriveSetting.clientId,
              'scope': googleDriveSetting.scopes,
              'immediate': false
            }, handleAuthResult);
          }
        }

        /**
         * Google API OAuth response
         */
        function handleAuthResult(result) {
          if (result && !result.error) {
            accessToken = $rootScope.gapiAccessToken = result.access_token;
            openDialog();
          }
        }

        /**
         * Everything is good, open the files picker
         */
        function openDialog() {
          var picker = new google.picker.PickerBuilder()
            .setLocale(googleDriveSetting.locale)
            .setOAuthToken(accessToken)
            .setCallback(pickerResponse)
            .setOrigin(googleDriveSetting.origin);

          if (angular.isUndefined(attrs.singleSelect) && googleDriveSetting.features.indexOf('MULTISELECT_ENABLED') == -1) {
            googleDriveSetting.features.push('MULTISELECT_ENABLED');
          }

          if (googleDriveSetting.features.length > 0) {
            angular.forEach(googleDriveSetting.features, function(
              feature, key) {
              picker.enableFeature(google.picker.Feature[
                feature]);
            });
          }

          if (angular.isDefined(attrs.imageOnly)) {
            picker.addView(new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES).setIncludeFolders(true));
          } else if (googleDriveSetting.views.length > 0) {
            angular.forEach(googleDriveSetting.views, function(
              view, key) {
              view = eval('new google.picker.' + view);
              picker.addView(view);
            });
          }

          picker.build().setVisible(true);
        }

        /**
         * Callback invoked when interacting with the Picker
         * data: Object returned by the API
         */
        function pickerResponse(data) {
          gapi.client.load('drive', 'v2', function() {
            if (data.action == google.picker.Action.LOADED &&
              scope.onLoaded) {
              (scope.onLoaded || angular.noop)();
            }
            if (data.action == google.picker.Action.CANCEL &&
              scope.onCancel) {
              (scope.onCancel || angular.noop)();
            }
            if (data.action == google.picker.Action.PICKED &&
              scope.onPicked) {
              (scope.onPicked || angular.noop)({
                docs: data.docs
              });
            }
            scope.$apply();
          });
        }

        gapi.load('auth');
        gapi.load('picker');

        element.bind('click', function(e) {
          instanciate();
        });
      }
    };
  }]);
}