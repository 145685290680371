import assignedOpportunityTemplate from './assigned-opportunity.html';

export default function(app) {
  app.directive('assignedOpportunity', assignedOpportunity);

  function assignedOpportunity() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: assignedOpportunityTemplate
    };
  }
}
