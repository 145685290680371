// export const AVAILABLE_TEMPLATES = {
//   url: 'drawingTemplateApi/drawing/templates?default=true&',
//   method: 'GET',
//   name: 'availTemplates',
//   filterCriteria: {
//     filters: [{
//       field: 'isArchived',
//       operator: '=',
//       value: false
//     }, {
//       field: 'isSystem',
//       operator: '=',
//       value: true
//     }],
//     skip: 0,
//     limit: 15
//   },
//   onlyPagination: true,
//   getSavedSettings: true
// };
//
// export const SAVED_TEMPLATES = {
//   url: 'drawingTemplateApi/drawing/templates',
//   method: 'GET',
//   name: 'savedTemplates',
//   filterCriteria: {
//     filters: [{
//       field: 'isArchived',
//       operator: '=',
//       value: false
//     }, {
//       field: 'isSystem',
//       operator: '!=',
//       value: true
//     }],
//     skip: 0,
//     limit: 15
//   },
//   onlyPagination: true,
//   getSavedSettings: true
// };
//
// export const DRAWING_TEMPLATE = {
//   url: 'drawingTemplateApi/drawing/template/:templateId',
//   name: 'template',
//   method: 'GET',
//   params: ['templateId']
// };

export const PROPOSAL_CATEGORIES = {
  url: 'documentBuilderApi/proposal-document/category',
  name: 'categories',
  method: 'GET'
};

export const PROPOSAL = {
  url: 'documentBuilderApi/proposal-document/:proposalId',
  name: 'proposal',
  method: 'GET',
  params: ['proposalId']
};