export default function(app) {
  app.directive('filesInput', function() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function postLink(scope, elem, attrs, ngModel) {
        elem.on("change", function(e) {
          var files = elem[0].files;
          ngModel.$setViewValue(files);
        })
      }
    };
  });
}