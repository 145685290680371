import {
  Configuration,
  OpenAIApi
} from 'openai';

import {
  OPENAI_API_KEY
} from './../../env.js';

export default function(app) {
  app.factory('OpenAIService', OpenAIService);

  const configuration = new Configuration({
    apiKey: OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  function OpenAIService() {

    "ngInject";

    return {
      createChatCompletion,
      // generateImage
    };

    function createChatCompletion(messages) {
      return openai.createChatCompletion({
        model: "gpt-4o",
        messages,
        max_tokens: 500,
        temperature: 0
      }).then(response => {
        return response.data.choices[0].message.content;
      }).catch(error => {
        return error;
      });
    }

    // function generateImage(prompt) {
    //   return openai.images.generate({
    //     model: "dall-e-3",
    //     prompt,
    //     // response_format: 'b64_json',
    //     n: 1,
    //     size: "1024x1024",
    //   }).then(response => {
    //     return response;
    //   }).catch(error => {
    //     return error;
    //   });
    // }

  }
}