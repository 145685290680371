import findIndex from 'lodash/findIndex';
import emailCustomerTemplate from '../../components/popup-view/email-customer-popup.html';
import emailPopupTemplate from '../../components/popup-view/email-popup.html';

export default function(app) {
  app.factory('EmailService', EmailService);

  function EmailService(dataServices, serverUrl, $mdDialog, $rootScope, $mdSidenav, messages, FileUploadService) {

    "ngInject";

    return {
      init: init,
      checkSpam: checkSpam,
      loadContacts: loadContacts,
      sendEmailToCustomer: sendEmailToCustomer,
      showEmailPopup: showEmailPopup
    };

    function showEmailPopup(vm, extras = {}) {
      $mdDialog.show({
        locals: {
          mv: vm,
          extras
        },
        controller: SendEmailController,
        templateUrl: emailPopupTemplate,
        parent: angular.element(document.body),
        controllerAs: 'vm'
      });
    }

    function SendEmailController($scope, $rootScope, messages, $state, $mdDialog, $mdSidenav, $timeout, FileUploadService, emojis, commonService, OpenAIService, maxlength, mv, extras) {

      'ngInject';

      let vm = this;

      vm.emojis = emojis;
      vm.maxlength = maxlength;
      vm.emailDetail = mv.emailDetail;
      vm.templates = mv.emailTemplates;

      vm.entity = mv.entity;
      vm.entities = mv.entities;

      init(vm);
      FileUploadService.init(vm, 'data');

      const renderUrlBase = serverUrl.main + 'emailTemplateApi/email/template/render/';

      function getUrlMap(urlType) {
        let urlMap = {};

        switch (extras.moduleName) {
          case 'customerCommunicate':
            urlMap = {
              renderUrl: renderUrlBase + vm.selectedTemplate.templateId + '?modelName=customer&modelId=' + mv.customerId + '&locationId=' + vm.entity.addressId[0] + '&',
              emailUrl: serverUrl.main + 'contactApi/customer/submit/' + mv.customerId
            };
            break;

          case 'opportunityCommunicate':
            urlMap = {
              renderUrl: renderUrlBase + vm.selectedTemplate.templateId + '?modelName=opportunities&modelId=' + mv.cardId + '&',
              emailUrl: serverUrl.main + 'opportunityApi/opportunity/submit/' + mv.cardId
            };
            break;

          case 'workOrderUpdate':
            let filterCriteria = angular.toJson({
              filters: [{
                field: "isArchived",
                operator: "=",
                value: false
              }, {
                field: "modelName",
                operator: "=",
                value: 77
              }, {
                field: "type",
                operator: "=",
                value: extras.isCompleted ? "complete" : "status"
              }]
            });

            urlMap = {
              renderUrl: serverUrl.main + 'emailTemplateApi/email/template/parse/render?modelName=work_orders&modelId=' + mv.serviceId + '&locationId=' + mv.service.addressId + '&filterCriteria=' + filterCriteria + '&',
              emailUrl: serverUrl.main + 'workOrdersApi/business/workOrder/email/' + mv.quoteId + '/' + mv.serviceId
            };
            break;
        }

        return urlMap[urlType];
      }

      vm.selectTemplate = function(template) {
        vm.gpt = false;
        vm.selectedTemplate = template;
        vm.selectedTemplate.usrPreferred = (vm.selectedTemplate.resources || []).includes($rootScope.usrObj.userId);
        vm.renderEmail();
      };

      // Handle email templates list in popup n outside
      function handleTemplatesList(isBzPreferred) {
        if (isBzPreferred) {
          if (vm.selectedTemplate.bzPreferred) {
            vm.templates.bzPreferred.push(vm.selectedTemplate);

            if (!vm.selectedTemplate.usrPreferred) {
              let index = findIndex(vm.templates.general, function(template) {
                return template.id == vm.selectedTemplate.id;
              });

              vm.templates.general.splice(index, 1);
            }
          } else {
            let index = findIndex(vm.templates.bzPreferred, function(template) {
              return template.id == vm.selectedTemplate.id;
            });

            vm.templates.bzPreferred.splice(index, 1);

            if (!vm.selectedTemplate.usrPreferred) {
              vm.templates.general.push(vm.selectedTemplate);
            }
          }
        } else {
          if (vm.selectedTemplate.usrPreferred) {
            vm.templates.usrPreferred.push(vm.selectedTemplate);

            if (!vm.selectedTemplate.bzPreferred) {
              let index = findIndex(vm.templates.general, function(template) {
                return template.id == vm.selectedTemplate.id;
              });

              vm.templates.general.splice(index, 1);
            }
          } else {
            let index = findIndex(vm.templates.usrPreferred, function(template) {
              return template.id == vm.selectedTemplate.id;
            });

            vm.templates.usrPreferred.splice(index, 1);

            if (!vm.selectedTemplate.bzPreferred) {
              vm.templates.general.push(vm.selectedTemplate);
            }
          }
        }
      }

      vm.preferTemplate = (isBzPreferred) => {
        let template = dataServices.toSave(vm.selectedTemplate, ['id', 'name', 'status', 'bzPreferred', 'emailSubject', 'emailBody', 'queryModel', 'resources']);

        if (isBzPreferred) {
          template.bzPreferred = vm.selectedTemplate.bzPreferred = !template.bzPreferred;
        } else {
          vm.selectedTemplate.usrPreferred = !vm.selectedTemplate.usrPreferred;

          template.resources = template.resources || [];
          let index = template.resources.indexOf($rootScope.usrObj.userId);
          if (index === -1) {
            template.resources.push($rootScope.usrObj.userId);
          } else {
            template.resources.splice(index, 1);
          }
        }

        dataServices.put({
            url: serverUrl.main + "emailTemplateApi/email/template/" + vm.selectedTemplate.templateId,
            data: {
              data: angular.toJson(template)
            }
          })
          .then((response) => {
            if (response.data && response.data.response_code == 200) {
              messages.simpleToast('Updated Successfully.');
              handleTemplatesList(isBzPreferred);
            }
          }, (error) => {
            if (isBzPreferred) {
              vm.selectedTemplate.bzPreferred = !template.bzPreferred;
            } else {
              vm.selectedTemplate.usrPreferred = !vm.selectedTemplate.usrPreferred;
            }
          });
      };

      vm.insertContact = () => {
        vm.emailDetail[vm.insertTo].push(vm.selectedItem);
        vm.toggleSidenav('right');
      };

      vm.copyResponse = (id) => {
        /* Get the section */
        let element = document.getElementById(id);

        /* Select the section */
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(element);
        window.getSelection().addRange(range);

        /* Copy the text inside the text field */
        document.execCommand('copy');

        window.getSelection().removeAllRanges();
      };

      vm.gptData = [];
      vm.messages = [{
        role: "system",
        content: "You are a helpful assistant designed to generate email templates."
      }];

      const scrollSmoothlyToBottom = (id) => {
        const element = $(`#${id}`);
        element.animate({
          scrollTop: element.prop("scrollHeight")
        }, 500);
      };

      vm.sendPromptToGPT = () => {
        if (!vm.gptInput) {
          return;
        }

        let prompt = vm.gptInput;
        vm.messages.push({
          role: "user",
          content: prompt
        });

        vm.gptData.push({
          prompt
        });
        vm.gptInput = '';
        scrollSmoothlyToBottom('gpt-content');

        OpenAIService.createChatCompletion(vm.messages).then(response => {
          $scope.$apply(() => {
            vm.messages.push({
              role: "assistant",
              content: response
            });

            vm.gptData[vm.gptData.length - 1].response = response.replace(/\n/g, "<br>");
            scrollSmoothlyToBottom('gpt-content');
          });
        });
      };

      // ${mv.contact.firstName} ${mv.contact.lastName}
      // request regional passport office to resend my passport which was returned due to incorrect address
      // I had an incomplete business meeting last month. I want you to write an email to meet again and proceed things further.
      // . Give result in json format {subject, msg}
      // const result = angular.fromJson(response.content);
      // console.log(result);
      // vm.emailDetail.subject = result.subject;
      // vm.emailDetail.msg = result.msg.replace(/\n/g, "<br>");

      vm.selectEntity = (entity) => {
        vm.entity = entity;
        vm.initLogo();
        vm.toggleSidenav('entities');
        vm.renderEmail();
      };

      vm.selectEmoji = function(emoji) {
        vm.emailDetail.subject = commonService.insertAtCursor('email-sub', emoji.code);
      };

      vm.initLogo = () => {
        let index = findIndex($rootScope.bObj.invoiceSettings, function(setting) {
          return setting.entityId == vm.entity.id;
        });
        console.log(vm.entity.id, index);

        vm.logo = index != -1 ? $rootScope.bObj.invoiceSettings[index].invoiceLogo : undefined;
      };

      if (vm.entity) {
        vm.initLogo();
      }

      vm.renderEmail = () => {
        dataServices.get({
            url: getUrlMap('renderUrl'),
            spinnerName: 'email-popup-spinner'
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              vm.emailDetail.subject = data.result.emailSubject;
              vm.emailDetail.msg = data.result.emailBody;
            }
          });
      };

      // if (vm.selectedTemplate) {
      $timeout(function() {
        vm.renderEmail();
      }, 0);
      // }

      vm.checkSpamAndSendEmail = function() {
        checkSpam(vm, vm.emailDetail.msg, FileUploadService.uploadFilesAndSave(vm, send));
      };

      function send() {
        if (vm.data.attachments) {
          vm.emailDetail.attachments = vm.data.attachments;
        }

        dataServices.post({
            url: getUrlMap('emailUrl'),
            data: {
              data: angular.toJson(vm.emailDetail)
            }
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              messages.simpleToast('Email sent successfully.');
              $mdDialog.cancel();
              if (angular.isFunction(extras.callback)) {
                extras.callback();
              }
            }
          });
      }

      loadContacts(vm, 'email-popup-spinner');

      vm.toggleRight = FileUploadService.buildToggler(vm, 'fileUploadSidebar');

      vm.toggleSidenav = (navID) => {
        $mdSidenav(navID).toggle();
      };

      vm.cancel = function() {
        $mdDialog.cancel();
      };

      $('body').on('click', '.template-header', function(e) {
        $(this).siblings('.template-list').slideToggle();
        $(this).find('.icon-down').toggleClass('open');
      });

      if (mv.selectedTemplate) {
        vm.selectedTemplate = angular.copy(mv.selectedTemplate);
        vm.selectedTemplate.usrPreferred = (vm.selectedTemplate.resources || []).includes($rootScope.usrObj.userId);

        $timeout(function() {
          $(`.template-header.${mv.selectedTemplateType}-templates`).click();
        }, 100);
      }

      $scope.$on('$destroy', function() {
        $('body').off('click', '.template-header');
      });
    }

    // Initiate variables required
    function init(vm, isPopup = true) {
      vm.spamRating = 0;
      vm.maxSpamPercentage = 10;

      if (isPopup) {
        vm.selectedItem = {
          to: null,
          cc: null
        };
        vm.searchText = {
          to: null,
          cc: null
        };

        vm.transformChip = transformChip;
        vm.searchContacts = searchContacts;
      }
    }

    function countWords(cont) {
      cont = cont.replace(/<[^>]*>/g, ' ');
      cont = cont.replace(/\s+/g, ' ');
      cont = cont.trim();
      return cont.split(' ').length;
    }

    // Check spam and calculate it's percentage
    function checkSpam(vm, msg, callback) {
      if (!msg && angular.isFunction(callback)) {
        callback();
        return;
      }

      dataServices.post({
          url: serverUrl.main + 'emailSpamApi/email/spam/filter',
          data: {
            data: angular.toJson({
              body: msg
            })
          }
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            let spamWords = data.result.body,
              noOfspamWords = 0;

            if (angular.isArray(spamWords) && spamWords.length) {
              angular.forEach(spamWords, (word) => {
                let regex = new RegExp('[^a-zA-Z0-9>]' + word, 'gi');
                msg = msg.replace(regex, ' <span class="spam-word">' + word + '</span>');
                noOfspamWords += (msg.match(regex) || []).length;

                regex = new RegExp('[>]' + word, 'gi');
                msg = msg.replace(regex, '><span class="spam-word">' + word + '</span>');
                noOfspamWords += (msg.match(regex) || []).length;
              });
            }

            const wordCount = countWords(msg || '');
            vm.spamRating = noOfspamWords / wordCount * 100;
            if (vm.spamRating >= vm.maxSpamPercentage) {
              vm.bodyWithSpam = msg;
            } else if (angular.isFunction(callback)) {
              callback();
            }
          }
        });
    }

    function mapContacts(array) {
      return array.filter(function(contact) {
        if (contact.firstName && contact.lastName) {
          contact.name = contact.firstName + ' ' + contact.lastName;
          contact._lowername = contact.name.toLowerCase();
          contact._loweremail = contact.email.toLowerCase();
        }
        return contact;
      });
    }

    function loadContacts(vm, spinnerName) {
      dataServices.get({
          url: serverUrl.main + 'conversationApi/conversations/contactsAndResources?',
          spinnerName: spinnerName
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.contacts = mapContacts(data.result);
          } else {
            vm.contacts = [];
          }
        });
    }

    /**
     * Return the proper object when the transformChip is called.
     */
    function transformChip(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }

      // Otherwise, create a new one
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(chip) ? {
        email: chip,
        type: 'external'
      } : null;
    }

    /**
     * Create filter function for a query string
     */
    function filterContacts(query) {
      let lowercaseQuery = query.toLowerCase();

      return function(contact) {
        return (contact && contact._lowername && contact._lowername.indexOf(lowercaseQuery) === 0) ||
          (contact && contact._loweremail && contact._loweremail.indexOf(lowercaseQuery) === 0);
      };
    }

    /**
     * Search for contacts.
     */
    function searchContacts(vm, query) {
      return query ? vm.contacts.filter(filterContacts(query)) : [];
    }

    /**
     * Init email detail and bounce status
     */
    function initEmailDetail(vm) {
      vm.emailDetail = {
        to: [],
        cc: [],
        msg: ''
      };

      vm.emailBounceStatus = {
        primary: vm.contact.emailVerificationStatus == 5,
        secondContact: vm.contact.secondContact,
        secondary: vm.contact.secondContact && vm.contact.secondContact.emailVerificationStatus == 5
      };
      vm.areAllEmailBounced = vm.emailBounceStatus.primary && (!vm.emailBounceStatus.secondContact || vm.emailBounceStatus.secondary);

      if (!vm.areAllEmailBounced) {
        if (!vm.emailBounceStatus.primary) {
          vm.emailDetail.to.push({
            email: vm.contact.email[0].id,
            images: vm.contact.images,
            avatar: vm.contact.avatar,
            type: 'contacts'
          });
        }

        if (vm.emailBounceStatus.secondContact && !vm.emailBounceStatus.secondary) {
          vm.emailDetail.to.push({
            email: vm.contact.secondContact.email[0].id,
            images: vm.contact.secondContact.images,
            avatar: vm.contact.secondContact.avatar,
            type: 'contacts'
          });
        }
      }
    }

    /**
     * Init email detail and bounce status
     */
    function sendEmailToCustomer(ev, vm, customer) {
      if (vm.hasWriteAccess) {
        vm.contact = customer;
        initEmailDetail(vm);

        $mdDialog.show({
          locals: {
            mv: vm,
            emailDetail: vm.emailDetail
          },
          controller: SendEmailToCustomerController,
          templateUrl: emailCustomerTemplate,
          parent: angular.element(document.body),
          targetEvent: ev,
          controllerAs: 'vm'
        });
      }
    }

    function SendEmailToCustomerController($scope, $rootScope, messages, $state, $mdDialog, $mdSidenav, $timeout, maxlength, FileUploadService, emojis, commonService, mv, emailDetail) {

      'ngInject';

      let vm = this;

      vm.maxlength = maxlength;
      vm.emojis = emojis;

      vm.selectEmoji = function(emoji) {
        vm.emailDetail.subject = commonService.insertAtCursor('email-sub', emoji.code);
      };

      vm.emailDetail = emailDetail;

      init(vm);
      FileUploadService.init(vm, 'data');

      vm.checkSpamAndSendEmail = function() {
        checkSpam(vm, vm.emailDetail.msg, FileUploadService.uploadFilesAndSave(vm, send));
      };

      function send() {
        if (vm.data.attachments) {
          vm.emailDetail.attachments = vm.data.attachments;
        }

        dataServices.post({
            url: serverUrl.main + 'contactApi/customer/submit/' + mv.contact.customerId,
            data: {
              data: angular.toJson(vm.emailDetail)
            }
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              messages.simpleToast('Email sent successfully.');
              $mdDialog.cancel();
            }
          });
      }

      loadContacts(vm, 'email-customer-spinner');

      vm.toggleRight = FileUploadService.buildToggler(vm, 'fileUploadSidebar');

      vm.toggleRightEmojis = buildToggler('emojis');

      function buildToggler(navID) {
        return function() {
          $mdSidenav(navID).toggle();
        };
      }

      vm.isCloseRight = function(id) {
        return $mdSidenav(id).close();
      };

      vm.cancel = function() {
        $mdDialog.cancel();
      };
    }
  }
}