import assignedVisitsTemplate from './assigned-visits.html'

export default function(app) {
  app.directive('assignedVisits', assignedVisits);

  function assignedVisits() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: assignedVisitsTemplate
    };
  }
}
