import emptyStateTemplate from './emptyState.html';

export default function(app) {
  app.directive('emptyState', emptyState)
    .controller("emptyStateController", emptyStateController);

  function emptyState() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        errorStatement: '@',
        tipStatement: '@',
        vm: '='
      },
      templateUrl: emptyStateTemplate,
      controller: 'emptyStateController',
      controllerAs: 'es'
    };
  }

  function getErrorAndTipStatements(vm, isStarter) {
    const defaultErrorStatement = 'Make something happen here@';
    const defaultTipStatement = 'Get started quickly with add or update provided on top of the screen.';
    let errorStatement, tipStatement;

    // Error and tip statements based on conditions
    if (vm.errorStatement || vm.tipStatement) {
      errorStatement = angular.isString(vm.errorStatement) ? vm.errorStatement : defaultErrorStatement;
      tipStatement = angular.isString(vm.tipStatement) ? vm.tipStatement : defaultTipStatement;
    } else if (vm.filters && vm.filters[vm.listName] && vm.defaultFc[vm.listName] && vm.filters[vm.listName].length > vm.defaultFc[vm.listName].filters.length) {
      errorStatement = 'No records for the filter criteria!';
      tipStatement = 'Change the filter criteria and try again!';
    } else if (vm.isRequiredApiFailed === true || (angular.isObject(vm.isRequiredApiFailed) && vm.isRequiredApiFailed[vm.listName] === true)) {
      errorStatement = 'Something went wrong!';
      tipStatement = 'Please try again later.';
    } else if (vm.searchText && vm.searchText[vm.listName]) {
      errorStatement = 'No search results for the keyword <b>' + vm.searchText[vm.listName] + '</b>!';
      tipStatement = 'Change the keyword and try again!';
    } else if (!isStarter) {
      errorStatement = defaultErrorStatement;
      tipStatement = defaultTipStatement;
    }

    return {
      error: errorStatement,
      tip: tipStatement
    };
  }

  function emptyStateController($scope, $element, $attrs, $filter) {

    "ngInject";

    let es = this;

    if ($scope.vm) {

      // Don't add, remove or change data of vm
      let vm = angular.extend({}, $scope.vm);

      es.starter = $attrs.starter;

      // Attributes are converted to smaller case automatically
      vm.errorStatement = $filter('translate')($attrs.errorstatement);
      vm.tipStatement = $filter('translate')($attrs.tipstatement) || vm.tip;

      es.statements = getErrorAndTipStatements(vm, es.starter);

      if (!es.statements.error && !es.statements.tip) {
        $scope.vm.isStarter = $scope.vm.isStarter || {};
        $scope.vm.isStarter[es.starter] = true;
      }

    }

  }
}