import signatureTemplate from './../../components/custom-form/custom-form-signature-popup.html';

export default function(app) {
  app.factory('CustomFormService', customFormService);

  function customFormService(dataServices, $filter, $sce, $q, $mdDialog, timeList, AttachDocumentService, CFFileUploadService) {

    "ngInject";

    return {
      init: init,
      initForUpdate: initForUpdate,
      beforeSave: beforeSave,
      prepareFieldsForViewScreen: prepareFieldsForViewScreen
    };

    function initDefaultVal(question) {
      question.fieldValue = question.fieldValue || [];
    }

    function toggleOption(item, list) {
      let idx = list.indexOf(item);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
    }

    function isOptionExists(item, question) {
      // return question.defaultValue.indexOf(item) > -1;
      if (question && angular.isArray(question.fieldValue) && item) {
        return question.fieldValue.indexOf(item) > -1;
      }
    }

    function init(vm, customForm, location) {

      vm.timeList = timeList;
      vm.toggleOption = toggleOption;
      vm.initDefaultVal = initDefaultVal;
      vm.isOptionExists = isOptionExists;
      vm.signaturePanelCustomForm = signaturePanelCustomForm;

      vm.close = AttachDocumentService.close;
      vm.isFileImage = AttachDocumentService.isFileImage;
      vm.imageViewPopup = AttachDocumentService.imageViewPopup;
      vm.toggleFileUploadSidebar = CFFileUploadService.buildToggler(vm, 'fileUploadSidebar');

      if (customForm && customForm.data.result) {
        if (location) {
          vm.customForm = undefined;
          angular.forEach(customForm.data.result, (item, i) => {
            if (location == item.addressId) {
              vm.customForm = item;
            }
          });
        } else {
          vm.customForm = customForm.data.result[0];
        }

        vm.customFormSignEnabled = vm.customForm && vm.customForm.sign;

        if (vm.customForm) {
          angular.forEach(vm.customForm.customFields, function(field, index) {
            if (field.fieldType === 'Upload Files / Images') {
              console.log('init', index);
              CFFileUploadService.init(vm, index);
            } else if (field.fieldOptions && field.defaultValue) {
              field.fieldValue = field.defaultValue;
            }
          });
        }
      }
    }

    function initForUpdate(vm, customForm) {
      if (customForm) {
        vm.customForm = angular.copy(customForm);
        angular.forEach(vm.customForm.customFields, function(field, index) {
          if (field.fieldType === 'Upload Files / Images') {
            console.log('initFiles', index);
            CFFileUploadService.initFiles(vm, index);
          } else if (field.fieldType == 'Date' && field.fieldValue) {
            field.fieldValue = new Date(field.fieldValue * 1000);
          }
        });
      }
    }

    function beforeSave(vm, dataToSave) {
      console.log(vm.customForm);
      const deferred = $q.defer();

      if (vm.customForm) {
        CFFileUploadService.uploadFilesAndSave(vm, function() {
          console.log('callback');
          dataToSave.customForm = dataServices.toSave(vm.customForm, ['formTitle', 'formDescription', 'formTarget', 'customFields', 'customFormSign']);

          angular.forEach(dataToSave.customForm.customFields, function(field) {
            if (field.fieldType == 'Date' && angular.isDate(field.fieldValue)) {
              field.fieldValue = field.fieldValue.getTime() / 1000;
            }
          });
          deferred.resolve(dataToSave);
        });
      } else {
        deferred.resolve(false);
      }

      return deferred.promise;
    }

    function prepareFieldsForViewScreen(vm, customForm) {
      if (customForm) {
        vm.imageViewPopup = AttachDocumentService.imageViewPopup;
        vm.customForm = angular.copy(customForm);
        angular.forEach(vm.customForm.customFields, function(field) {
          if (field.fieldValue) {
            if (field.fieldOptions && angular.isArray(field.fieldValue)) {
              field.fieldValue = field.fieldValue.join(', ');
            } else if (field.fieldType == 'Date') {
              field.fieldValue = $filter('normalFormDate3')(field.fieldValue);
            } else if (field.fieldType == 'Rich Text Format') {
              field.fieldValue = $sce.trustAsHtml(field.fieldValue);
            } else if (field.fieldType == 'Time') {
              field.fieldValue = $filter('getHoursFromMinutes')(parseInt(field.fieldValue), 'withSuffix');
            }
          } else if (field.fieldType == 'Upload Files / Images') {
            field.files = [];
            field.images = [];
            angular.forEach(field.attachments, (file, i) => {
              if (AttachDocumentService.isFileImage(file.fileOriginalName)) {
                field.images.push(file);
              } else {
                field.files.push(file);
              }
            });
          }
        });
      }
    }

    function signaturePanelCustomForm(ev, vm) {
      $mdDialog.show({
        templateUrl: signatureTemplate,
        controller: SignatureController,
        controllerAs: 'cd',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          vm: vm
        }
      });
    }

    function SignatureController($scope, serverUrl, messages, Upload, $mdDialog, dataServices, vm) {

      "ngInject";

      let cd = this;

      cd.cancel = cancel;
      cd.save = save;
      cd.reset = reset;
      cd.parentVm = vm;

      function assignedData() {
        if (vm.customForm.customFormSign && vm.customForm.customFormSign.webUrl) {
          Upload.urlToBlob(vm.customForm.customFormSign.webUrl).then(function(blob) {
            Upload.base64DataUrl(blob).then(function(base64) {
              cd.dataUrl = $scope.dataUrl = base64;
            });
          });
        }
      }

      assignedData();

      function save() {
        let signature = $scope.accept();

        if (!cd.dataUrl && signature.isEmpty) {
          messages.simpleToast('Signature is not available for update.');
          return;
        }

        dataServices.upload({
            url: serverUrl.main + 'mediaApi/upload/base64',
            data: {
              data: angular.toJson([{
                type: 'png',
                data: $scope.dataUrl
              }])
            }
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              if (signature.isEmpty) {
                vm.customForm.customFormSign = null;
              } else if (data.result.success) {
                vm.customForm.customFormSign = data.result.success[0];
                vm.customForm.customFormSign.date = moment().unix();
              }
              cd.cancel();
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }

      function reset() {
        $scope.dataUrl = angular.copy(cd.dataUrl);
      }
    }

  }
}