'use strict';
// import only static routes which is build in starting app.js
import mainTemplate from './../pages/main/main.html';
import upgradeLicenseTemplate from './../pages/main/upgrade-license/upgrade-license.html';
import noAccessTemplate from './../pages/main/no-access/no-access.html';

// Import all routes in root route
import routes from './routes/routeList';

// Import root provider
import ResolverProvider from './providers/resolver.provider';

function routeConfig($urlRouterProvider, $stateProvider, resolverProvider) {
  'ngInject';

  let resolve = resolverProvider.stateResolve;

  // 'templates/main.html' // template cache - replaced it with mainTemplate
  $stateProvider
    .state('main', resolve('main', mainTemplate, 'MainController'))
    .state('main.upgradeLicense', resolve('upgrade-license', upgradeLicenseTemplate, 'UpgradeLicenseController'))
    .state('main.noAccess', resolve('no-access', noAccessTemplate, 'NoAccessController'));

  // Default landing state/page
  $urlRouterProvider.otherwise('/');
}
// If have any module route then need to import route here.
var indexRoutes = angular.module('index.routes', [ResolverProvider.name])
  .config(routeConfig)
  .config(routes.loginRoute)
  .config(routes.dashboardRoute)
  .config(routes.quoteRoute)
  .config(routes.businessRoute)
  .config(routes.settingsRoute)
  .config(routes.customersRoute)
  .config(routes.resourcesRoute)
  .config(routes.servicesRoute)
  .config(routes.calendarRoute)
  .config(routes.jobRoute)
  .config(routes.jobService);

export default indexRoutes;