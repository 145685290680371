export default function(app) {
  app.constant('EVENT_TYPE_ICONS', {
    action: 'basic:icon-ico-teams',
    opportunity: 'basic:icon-ico-customers',
    conversation: 'basic:icon-ico-customers',
    event: 'basic:icon-ico-calendar',
    purchaseOrder: 'basic:icon-ico-operations',
    'google-event': 'new-set:icon-ico-google',
    'outLook-event': 'new-set:icon-ico-microsoft'
  });
}