export default function(app) {
  app.directive('maxLength', function() {
    return {
      restrict: "A",
      require: 'ngModel',
      link: function(scope, elem, attrs, ctrl) {
        var limit = parseInt(attrs.maxLength);
        ctrl.$parsers.push(function(value) {
          if (value.length > limit) {
            value = value.substr(0, limit);
            ctrl.$setViewValue(value);
            ctrl.$render();
          }
          return value;
        });
      }
    };
  });
}
