import findIndex from 'lodash/findIndex';

export default function(app) {
  app.filter('fullQuoteDate', fullQuoteDate)
    .filter('dateFormat', dateFormat)
    .filter('visitTypeName', visitTypeName);

  function visitTypeName($rootScope) {
    return function(visitType) {
      return visitType ? $rootScope.bObj.onsiteTypes[findIndex($rootScope.bObj.onsiteTypes, function(obj) {
        return obj.id == visitType;
      })].name : "";
    };
  }

  function dateFormat(monthNames) {
    "ngInject";
    return function(epoche) {
      var day = new Date(epoche * 1000);

      var date = day.getDate();
      var month = day.getMonth();
      var year = day.getFullYear();
      return epoche ? date + " " + monthNames[month].substr(0, 3) + " " +
        year : "";
    };
  }

  function fullQuoteDate(monthNames) {
    "ngInject";
    return function(epoche) {
      var date = new Date(epoche * 1000);
      var month = date.getMonth();
      var year = date.getFullYear();
      date = date.getDate().toString();
      date = date.length == 1 ? "0" + date : date;
      return epoche ? date + " " + monthNames[month].substr(0, 3) + "'" +
        year.toString().substr(2, 2) : "-";
    };
  }

}