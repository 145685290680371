export default function(app) {
  app.directive("dropBoxPicker", ["DropBoxSettings", function(
    DropBoxSettings) {
    return {
      restrict: "A",
      scope: {
        dbpickerFiles: "=",
        onPicked: '&',
        imageOnly: '@'
      },
      link: function(scope, element, attrs) {

        function instanciate() {
          Dropbox.choose(dropboxOptions);
        }

        var dropboxOptions = {
          success: dropboxsuccess,
          cancel: function() {},
          linkType: DropBoxSettings.linkType,
          sizeLimit: DropBoxSettings.sizeLimit,
          multiselect: angular.isDefined(attrs.singleSelect) ? false : DropBoxSettings.multiselect,
          extensions: angular.isDefined(attrs.imageOnly) ? DropBoxSettings.imgExtensions : DropBoxSettings.extensions,
        };

        function dropboxsuccess(files) {
          (scope.onPicked || angular.noop)({
            docs: files
          });
          scope.$apply();
        }

        element.bind("click", function() {
          instanciate();
        });
      }
    };
  }]);
}