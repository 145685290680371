'use strict';

// idleService
function runBlock($location, $log, $transitions, $timeout, $filter, $ocLazyLoad, $rootScope, $http, $state, $mdDialog, footerHideScreens, ipDataService, businessConfig, spinnerLoader, sharedDataService, authService, offlineService, commonService, DeviceDetectionService, FirebaseMessagingService, QuillService) {

  'ngInject';

  let flagForGlobalLoader, previousState, currentState, flagForLoader = false;

  // Get IP and location details. Check for threat and restrict access
  ipDataService.getIPData();

  // Restore shared data from localstorage
  sharedDataService.restoreSharedData();

  // Find from which device or os request came from
  DeviceDetectionService.init();

  // Activate idle Listeners for session timout management
  // idleService.activateListeners();

  // Initiate offline check to check whether internet is down
  offlineService.initiateChecks();

  // Fetch application constants
  businessConfig.fetchApplicationElements();

  // Initiate firebase FCM
  FirebaseMessagingService.init();

  // Quill Editor modules configuration
  $rootScope.quillModules = QuillService.getConfig();

  // Stop both global and module spinner
  function hardStopSpinnerOnError() {
    $timeout(() => {
      console.log('stopping spinner');
      spinnerLoader.hardStop();
      spinnerLoader.hardStop($rootScope.customSpinnerName);
      stopSpinner();
    }, 500);
  }

  $transitions.onStart({}, (trans) => {

    $rootScope.currentStateName = trans.to().name;
    currentState = $rootScope.currentStateName.split('.');

    // If screen requires authentication but user has not logged in, redirect to login
    if (currentState[0] == 'main') {
      if (!authService.isLoggedIn()) {
        $log.log('not authenticated');
        if ($location.url() != '/') {
          sharedDataService.set('redirectUrl', $location.url());
        }
        console.log('redirectUrl', $location.url(), $rootScope.currentStateName);
        hardStopSpinnerOnError();
        $state.go('/');
      } else if ($rootScope.userSession && $rootScope.userSession.error) {
        $rootScope.userSession.error = false;
      }
    }

    sharedDataService.set('currentStateName', trans.to().name);
    $rootScope.previousStateName = trans.from().name;
    previousState = $rootScope.previousStateName.split('.');
    sharedDataService.set('previousStateName', $rootScope.previousStateName);

    // Update title to be displayed in browser if session is not expired.
    // There is different title for session timeout which is shown by library.
    if (!$rootScope.userSession || $rootScope.userSession && !$rootScope.userSession.error) {
      commonService.setTitle();
    }

    // Loader for inner pages of modules
    let loaderForInnerFrame = previousState && currentState && (currentState[1] != 'quotes' || currentState[1] != 'jobs') && previousState[1] == currentState[1] && !/update|add|edit/.test(currentState[2]) && !/update|add|edit/.test(previousState[2]);

    // console.log('flagForGlobalLoader before', flagForGlobalLoader);
    if (flagForGlobalLoader == undefined) {
      flagForGlobalLoader = false;
      // $rootScope.flagForGlobalLoader = false;
    } else {
      flagForGlobalLoader = flagForGlobalLoader = !loaderForInnerFrame;
      $rootScope.customSpinnerName = loaderForInnerFrame ? currentState[1] + '-spinner' : undefined;
    }
    // console.log('flagForGlobalLoader after', flagForGlobalLoader);

    if (flagForLoader && flagForGlobalLoader) {
      spinnerLoader.start();
    }

    // TODO: Remove once list service and get list is changed
    // for chunks to be loaded by list.services
    $rootScope.isChunksLoaded = undefined;

    // Hide scroll to top button if it exists
    if (document.querySelector('#scroll-top')) {
      // document.querySelector('#scroll-top').style.display = 'none';
      $('#scroll-top').removeClass('active');
    }
  });

  $transitions.onSuccess({}, (from) => {

    // start or stop session management based on user login status
    // idleService.watchOrUnwatch();

    // hide footer for screens mentioned in footerHideScreens
    $rootScope.footerEnabled = footerHideScreens.indexOf(currentState[2]) == -1;

    // Display active footer menu
    if (currentState.length > 1) {
      let stateMap = {
        dashboard: 'mainboard',
        quoteList: 'quotes',
        jobList: 'jobs',
        jobsSales: 'jobs',
        business: 'operations',
        resources: 'teams',
        calendarEvents: 'calendar'
      };
      // $rootScope.selectedFooterMenu = currentState[1] == 'quoteList' ? 'quotes' : (currentState[1] == 'jobList' ? 'jobs' : currentState[1]);
      $rootScope.selectedFooterMenu = stateMap[currentState[1]] || currentState[1];
      console.log($rootScope.selectedFooterMenu);
      $rootScope.selectedModule = currentState[1];
    }

    $timeout(() => {
      // console.log(flagForLoader, flagForGlobalLoader);
      if (flagForLoader && flagForGlobalLoader) {
        spinnerLoader.stop()
        // $log.log(angular.module('myNgApp').requires);
      } else {
        stopSpinner();
        flagForLoader = true;
      }

      if (!sharedDataService.get('isFromMobile')) {
        $rootScope.isScrollableListPresent = $('md-content[infinite-scroll] table.data-table').length && $('table.data-table').width() > $('.table-responsive').width();
        console.log($('md-content[infinite-scroll] table.data-table').length, $('table.data-table').width(), $('.table-responsive').width(), $rootScope.isScrollableListPresent);
      }
    }, 500);

  });

  // Handling api failure during state change
  $transitions.onError({}, function(trans) {
    console.log(trans, typeof trans._error.detail);

    // trans._error.type == 2 -> "The transition has been superseded by a different transition"
    // This happens when a state transition happens before success or failure of the previous transition
    if (trans._error.type == 2) {
      hardStopSpinnerOnError();
    }

    // Handle api failure that's required for the screen
    if (trans._error.detail == "REQUIRED_API_FAILED") {
      hardStopSpinnerOnError();

      // On api failure go to the state where it come from
      // $state.go(trans.from().name, $state.params, {
      //   reload: true
      // });
    }

    // Handle no permission
    if (trans._error.detail == "UPGRADE_LICENSE") {
      hardStopSpinnerOnError();
      $state.go('main.upgradeLicense');
    }

    if (trans._error.detail == "NO_PERMISSION") {
      hardStopSpinnerOnError();
      $state.go('main.noAccess');
    }

    if (trans._error.detail == "NO_PERMISSION_OTHER_RESOURCES") {
      hardStopSpinnerOnError();
      $state.go('main.resources.accessForbidden');
    }
  });
}

export default runBlock;