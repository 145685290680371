export default function(app) {
  app.constant('regxMatch', {
    password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$",
    alphaNumeric: "[A-Za-z0-9]*",
    onlyNumeric: "^[0-9]*$",
    alphabets: "[A-Za-z ]*",
    numericSpecials: "[^A-Za-z]*",
    alphabeticSpecials: "/^[^0-9]*$/",
    email: "/^([0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*@(([0-9a-zA-Z])+([-\\w]*[0-9a-zA-Z])*\\.)+[a-zA-Z]{2,9})$/"
  });
}