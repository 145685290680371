
export default function(app) {
  app.directive('onlyDigits', function() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits = val.toString().replace(/[^0-9]/g, '');
            if(attr.inputLength < digits.length){
              digits = digits.substring(0, attr.inputLength);
            }

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            return parseInt(digits, 10);
          }
          return undefined;
        }
        ctrl.$parsers.push(inputValue);
      }
    };
  });
}
