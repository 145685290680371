import overlayBackground from './overlay.background.directive';
import contenteditable from './content.editable.directive';
import infiniteScroll from './infinite-scroll.directive';
import stringToNumber from "./string.to.number.directive";
import dropboxPicker from "./dropboxPicker";
import googleDrivePricker from "./googleDrivePicker";
import filesInput from './files-input.directive';
import onlyDigit from './onlyDigit.directive';
import decimalDigits from './decimal.digits.directive';
import maxLength from './maxLength.directive';
import allowAlphabets from './allowAlphabets.directive';
import allowDigitSpecials from './allowDigitSpecials.directive'
import price from './price.directive';
import ngHtmlCompile from './ngHtmlCompile.directive';
import lazyLoadDataAutocomplete from './lazy-load-autocomplete.directive';
import ngClickCopy from './copy.directive';

export default function(app) {
  overlayBackground(app);
  contenteditable(app);
  infiniteScroll(app);
  stringToNumber(app);
  dropboxPicker(app);
  googleDrivePricker(app);
  filesInput(app);
  onlyDigit(app);
  decimalDigits(app);
  maxLength(app);
  allowAlphabets(app);
  allowDigitSpecials(app);
  price(app);
  ngHtmlCompile(app);
  lazyLoadDataAutocomplete(app);
  ngClickCopy(app);
}