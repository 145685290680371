export default function(app) {
  app.factory('authService', authService);

  function authService($rootScope, $location, $timeout, sharedDataService, noAuthScreens, serverUrl, spinnerLoader) {

    "ngInject";

    let passwordTests = [/[0-9]/, /[a-z]/, /[A-Z]/, /[^A-Z-0-9]/i];
    let strengthValues = ['Weak', 'Medium', 'Strong', 'Very Strong'];
    let strengthThemes = ['danger', 'warning', 'info', 'success'];

    return {
      logout: logout,
      isLoggedIn: isLoggedIn,
      checkPasswordStrength: checkPasswordStrength
    };

    function checkPasswordStrength(vm, password) {
      if (password == null) {
        return 1;
      }

      let strength = 0;
      for (let i in passwordTests) {
        if (passwordTests[i].test(password)) {
          strength++;
        }
      }

      if (password.length < 8 && strength) {
        strength--;
      }

      vm.passwordStrength = strength * 25;
      vm.strengthTheme = strengthThemes[strength - 1];
      vm.strengthValue = strengthValues[strength - 1];

      return strength;
    }

    // Returns whether user logged in based on credential,/old comment continuation/ access_token and current screen name
    function isLoggedIn() {
      let accessData = sharedDataService.get('accessData');
      let currentTimeStamp = Math.trunc(Date.now() / 1000);

      return Boolean(accessData && accessData.email_verified && accessData.terms_accepted && accessData.user_id && !accessData.change_password && accessData.refreshTokenExpiresAt && currentTimeStamp < accessData.refreshTokenExpiresAt);
    }

    function logoutFromLocal(isRedirect, isSessionTimedOut, from) {
      // Set error in user session to show on login screen
      if (isSessionTimedOut) {
        $rootScope.userSession = {
          error: {
            msg: 'Session Timeout',
            detail: 'Your session has expired! Please login again.'
          }
        };
      }

      // Save url in which user was
      let currentStateName = sharedDataService.get('currentStateName');
      if (isRedirect && currentStateName && noAuthScreens.indexOf(currentStateName) == -1) {
        sharedDataService.set('redirectUrl', $location.url());
      }

      // Clear shared data(data in memory and localstorage) and redirect to login screen
      sharedDataService.clearAll();
      $location.url('/');
    }

    // Logout user by terminating session
    function logout(isRedirect = false, isSessionTimedOut = false, from) {

      if ($rootScope.accessData && $rootScope.accessData.access_token) {
        let currentTimeStamp = Math.trunc(Date.now() / 1000);
        if (currentTimeStamp >= $rootScope.accessData.accessTokenExpiresAt) {
          logoutFromLocal(isRedirect, isSessionTimedOut, from);
          return;
        }
      } else {
        logoutFromLocal(isRedirect, isSessionTimedOut, from);
        return;
      }

      spinnerLoader.start();
      $.ajax({
          type: 'POST',
          url: serverUrl.auc + 'auth/logout',
          beforeSend: function(xhr) {
            xhr.setRequestHeader('product', 'jacktrade_1');
            xhr.setRequestHeader('Authorization', 'Bearer ' + $rootScope.accessData.access_token);
          }
        })
        .done(function() {
          console.log("success");
        })
        .fail(function() {
          console.log("error");
        })
        .always(function() {
          console.log("complete");
          spinnerLoader.stop();
          $timeout(function() {
            logoutFromLocal(isRedirect, isSessionTimedOut, from);
          }, 0);
        });
    }

  }
}