import customFormFilesTemplate from './custom-form-files.html';
export default function(app) {
  app.directive('customFormFiles', customFormFiles);

  function customFormFiles() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: customFormFilesTemplate
    };
  }
}