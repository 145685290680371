import clockTimePerServiceTemplate from './clock-time-per-service.html'

export default function(app) {
  app.directive('clockTimePerService', clockTimePerService);

  function clockTimePerService() {
    return {
      restrict: 'E',
      templateUrl: clockTimePerServiceTemplate,
      replace: true
    };
  }
}