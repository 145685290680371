import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';

export default function(app) {
  app.factory('businessConfig', businessConfig);

  function businessConfig($http, localStorageService, dataServices, serverUrl, $rootScope, loginKey, bobjMap, spinnerLoader, authService, messages) {

    "ngInject";

    return {
      getBusinessElem: getBusinessElem,
      fetchApplicationElements: fetchApplicationElements
    };

    function getBusinessElem(hooks) {
      if (hooks.bzobj && hooks.usrObj && hooks.reasonObj) {
        $rootScope.bObj = hooks.bzobj;
        bobjMap.mapBusinessData();
        localStorageService.set('bObj', hooks.bzobj);

        $rootScope.usrObj = hooks.usrObj;
        bobjMap.mapUserData();
        localStorageService.set('usrObj', hooks.usrObj);

        bobjMap.mapReasonData(hooks.reasonObj);

        const userProperties = {
          Name: hooks.usrObj.firstName + ' ' + hooks.usrObj.lastName,
          Email: hooks.usrObj.userEmail,
          BusinessId: $rootScope.accessData.client_id
        };

        // heap.identify(hooks.usrObj.userEmail);
        // heap.addUserProperties(userProperties);

        if (window.isTawkLoaded) {
          Tawk_API.setAttributes(userProperties, (error) => {});
        }

        return getApplicationObj();
      } else {
        authService.logout();
      }
    }

    function getApplicationObj() {
      return dataServices.get({
        url: serverUrl.main + "utilApi/applicationConstants?",
        spinner: false
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          $rootScope.aObj = data.result;
          localStorageService.set('aObj', data.result);
          return fetchAllUIScreens();
        } else {
          console.error('No Application Constants!');
          // messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
          authService.logout();
        }
      });
    }

    function fetchAllUIScreens() {
      return dataServices.get({
        url: serverUrl.common + "uiSettingApi/uiscreens?",
        spinner: false
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          $rootScope.screenConst = data.result;
          localStorageService.set('uiScreenConst', data.result);
          // end login spinner started in login controller
          spinnerLoader.stop();
          return true;
        } else {
          console.error('No Table Settings!');
          // messages.simpleToast('Technical Error! Please contact administrator.', 'danger');
          authService.logout();
        }
      });
    }

    function fetchApplicationElements() {
      let accessData = localStorageService.get('accessData');
      let bObj = localStorageService.get('bObj');
      let usrObj = localStorageService.get('usrObj');
      let rObj = localStorageService.get('rObj');
      let aObj = localStorageService.get('aObj');
      let screenConst = localStorageService.get('uiScreenConst');

      let currentStateName = localStorageService.get('currentStateName'),
        isAuthScreen;
      if (currentStateName) {
        isAuthScreen = currentStateName.split('.')[0] == 'main';
      }

      // took out accessData from general flow for change password screen scenario
      // need token there to identify email(in BE)
      if (accessData) {
        $rootScope.accessData = accessData;
        $rootScope.accessData.user_id = decryptCrypto(accessData.user_id);
        $rootScope.accessData.client_id = decryptCrypto(accessData.client_id);
        $rootScope.accessData.access_token = decryptCrypto(accessData.access_token);
        $rootScope.accessData.refresh_token = decryptCrypto(accessData.refresh_token);
      }

      if (accessData && bObj && aObj && usrObj && rObj && screenConst) {
        $rootScope.bObj = bObj;
        $rootScope.usrObj = usrObj;
        $rootScope.rObj = rObj;
        $rootScope.aObj = aObj;
        $rootScope.screenConst = screenConst;
      } else if (isAuthScreen) {
        authService.logout(true);
      }
    }

    function decryptCrypto(data) {
      return data ? aes.decrypt(data, loginKey).toString(utf8) : undefined;
    }
  }
}