export default function resourceResolver() {
  this.loadResourcesModule = loadResourcesModule;
  // this.loadStartModule = loadStartModule;
  this.$get = function() {
    return this;
  };
}

// function loadStartModule($q, $ocLazyLoad) {
//   "ngInject";
//   const deferred = $q.defer();
//   require.ensure([], (require) => {
//     const asyncModule = require('ng-file-upload');
//     $ocLazyLoad.load({
//       name: 'ngFileUpload'
//     }).then(function() {
//       deferred.resolve(asyncModule);
//     });
//   });
//   return deferred.promise;
// }

function loadResourcesModule($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./resources.module');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule.default.controller);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'resources.module');
  return deferred.promise;
}