 export default function(app) {
   app.provider('googleDriveSetting', function() {
     this.apiKey = 'KmgJHAVMp1_anqYnEqhAicHx';
     this.clientId = '661430010180-fj471697bg8unl4fia1h5j97cf4ot99n.apps.googleusercontent.com';
     this.scopes = ['https://www.googleapis.com/auth/drive'];
     this.features = [];
     // 'DocsView(google.picker.ViewId.PDFS).setIncludeFolders(true)',
     this.views = [
       "DocsView().setIncludeFolders(true)",
       "DocsUploadView().setIncludeFolders(true)"
     ];
     this.locale = 'en'; // Default to English

     /**
      * Provider factory $get method
      * Return Google Picker API settings
      */
     this.$get = ['$window', function($window) {
       return {
         apiKey: this.apiKey,
         clientId: this.clientId,
         scopes: this.scopes,
         features: this.features,
         views: this.views,
         locale: this.locale,
         origin: this.origin || $window.location.protocol + '//' + $window.location.host
       };
     }];

     /**
      * Set the API config params using a hash
      */
     this.configure = function(config) {
       for (var key in config) {
         this[key] = config[key];
       }
     };
   });
 }