export default function(app) {
  app.factory('dynamicLoadModules', dynamicLoadModules);

  function dynamicLoadModules($window, $state, $rootScope) {

    "ngInject";

    return {
      reloadFailedChunks: reloadFailedChunks
    };

    function reloadFailedChunks(error) {
      // prepare url to be opened when chunk loading failed and assign it to window object
      $window.location.href = $window.location.origin + $window.location.pathname + $state.href($rootScope.currentStateName);
      console.log(error, angular.toJson(error));
      // reload screen
      $window.location.reload();
    }
  }
}