import proTipsTemplate from './pro-tips.html';
export default function(app) {
  app.directive('proTips', proTips);

  function proTips() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: proTipsTemplate
    };
  }
}