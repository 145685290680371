import forEach from 'lodash/forEach';

export default function(app) {
  app.factory('bobjMap', bobjMap);

  function bobjMap($rootScope, localStorageService) {

    "ngInject";

    return {
      mapUserData: mapUserData,
      mapReasonData: mapReasonData,
      mapBusinessData: mapBusinessData
    };

    function mapReasonData(reasonObj) {
      $rootScope.rObj = {
        ver: reasonObj.ver,
        records: {}
      };
      angular.forEach(reasonObj.records, function(reason) {
        $rootScope.rObj.records[reason.reasonList] = $rootScope.rObj.records[reason.reasonList] || {};
        $rootScope.rObj.records[reason.reasonList][reason.id] = reason;
      });
      localStorageService.set('rObj', $rootScope.rObj);
    }

    function mapBusinessData() {

      $rootScope.bObj.aliasMap = {};
      forEach($rootScope.bObj.aliases, (item) => {
        $rootScope.bObj.aliasMap[item.aliasSlug] = item.aliasValue;
      });
      console.log($rootScope.bObj.aliasMap);

      let resDataMapped = {
        resourceData: {},
        categoryData: {},
        orgData: {}
      };

      let resourceData = $rootScope.bObj.resourceData;
      forEach(resourceData.resourceTypesData, function(item) {
        resDataMapped.resourceData[item.id] = item;
      });

      forEach(resourceData.categoryData, function(item) {
        resDataMapped.categoryData[item.id] = item;
      });

      forEach(resourceData.orgDataData, function(item) {
        resDataMapped.orgData[item.id] = item;
      });
      $rootScope.bObj.resDataMapped = resDataMapped;

      $rootScope.bObj.regionCodeMapped = {};
      forEach($rootScope.bObj.regionCode, function(item) {
        $rootScope.bObj.regionCodeMapped[item.id] = item;
      });

      $rootScope.bObj.allAttributesMapped = {};
      forEach($rootScope.bObj.allAttributes, function(item) {
        $rootScope.bObj.allAttributesMapped[item.id] = item.attributeName;
      });

      $rootScope.bObj.serialNumAttributesMapped = {};
      forEach($rootScope.bObj.serialNumAttributes, function(item) {
        $rootScope.bObj.serialNumAttributesMapped[item.id] = item.attributeName;
      });

      $rootScope.bObj.bAddsMapped = {};
      $rootScope.bObj.bAddsByRegions = {};
      $rootScope.bObj.bAddsMappedActive = {};
      $rootScope.bObj.bAddsActive = [];

      forEach($rootScope.bObj.businessAddress, function(location) {
        // let tempLocation = dataServices.toSave(location, ['entityId', 'addressId', 'locationCode', 'status', 'name']);

        $rootScope.bObj.bAddsByRegions[location.regionCode] = $rootScope.bObj.bAddsByRegions[location.regionCode] || [];
        $rootScope.bObj.bAddsByRegions[location.regionCode].push(location);

        $rootScope.bObj.bAddsMapped[location.addressId] = location;
        if (location.status == 2) {
          $rootScope.bObj.bAddsMappedActive[location.addressId] = location;
          $rootScope.bObj.bAddsActive.push(location);
        }
      });

    }

    function mapUserData() {
      $rootScope.usrObj.bAddsMapped = {};
      $rootScope.usrObj.bAddsByRegions = {};
      $rootScope.usrObj.bAddsMappedActive = {};
      $rootScope.usrObj.bAddsActive = [];

      forEach($rootScope.usrObj.businessAddress, function(location) {
        $rootScope.usrObj.bAddsByRegions[location.regionCode] = $rootScope.usrObj.bAddsByRegions[location.regionCode] || [];
        $rootScope.usrObj.bAddsByRegions[location.regionCode].push(location);

        $rootScope.usrObj.bAddsMapped[location.addressId] = location;
        if (location.status == 2) {
          $rootScope.usrObj.bAddsMappedActive[location.addressId] = location;
          $rootScope.usrObj.bAddsActive.push(location);
        }
      });
    }
  }
}