export default function(app) {
  app.provider("DropBoxSettings", function() {

    this.box_linkType = 'shared';
    this.box_multiselect = true;
    this.box_clientId = null;
    this.linkType = 'direct';
    this.multiselect = true;
    this.sizeLimit = 5e+6;
    this.extensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', 'txt', '.csv', '.jpeg', '.jpg', '.png', '.gif'];
    this.imgExtensions = ['.jpeg', '.jpg', '.png', '.gif'];

    this.$get = function() {
        return {
          linkType: this.linkType,
          multiselect: this.multiselect,
          sizeLimit: this.sizeLimit,
          extensions: this.extensions,
          imgExtensions: this.imgExtensions,
          box_linkType: this.box_linkType,
          box_multiselect: this.box_multiselect,
          box_clientId: this.box_clientId
        };
      },
      this.configure = function(e) {
        for (let key in e) {
          this[key] = e[key];
        }
      };
  });
}