import updateAddressTemplate from './updateAddressTemplate.html';
export default function(app) {
  app.directive('updateAddress', updateAddress);

  function updateAddress() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: updateAddressTemplate
    };
  }
}
