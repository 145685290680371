import forEach from 'lodash/forEach';

export default function(app) {
  app.factory('FrameService', frameService);

  function frameService($rootScope, $mdSidenav, messages, $state, $stateParams, unsavedChangesService, NavigationService, localStorageService, sharedDataService) {

    "ngInject";

    return {
      initScreen: initScreen,
      changeOption: changeOption,
      backScreen: backScreen
    };

    function initScreen(vm, screensList, moduleName, firstState) {

      vm.isFromApp = sharedDataService.get('isFromApp');

      // Initialise search and filter variables
      vm.searchText = vm.searchText || {};
      vm.textToSearch = vm.textToSearch || {};
      vm.toggleFilter = {};

      if ($rootScope.isRequiredApiError) {
        messages.showActionToast('Error! : ' + $rootScope.isRequiredApiError, 'Close', 'default');
        $rootScope.isRequiredApiError = false;
      }

      let currentState = $state.current.name,
        currentScreen, regex = new RegExp('main.' + moduleName);

      if (regex.test(currentState)) {
        if (currentState == 'main.' + moduleName) {
          if (currentState == 'main.quotes') {
            $state.go('main.quoteList');
          } else if (currentState == 'main.jobs') {
            $state.go('main.jobList');
          } else {
            $state.go('main.' + moduleName + '.' + firstState);
          }
        }

        let screenName = currentState.split('.').pop().split(/(?=[A-Z])/).join('_').toUpperCase();
        let stateName = screenName.split('_');
        currentScreen = screensList[screenName];

        if (currentScreen && currentScreen.cbac) {
          $rootScope.hasWriteAccess = vm.hasWriteAccess = $rootScope.accessData.cbac[currentScreen.cbac] === 'full_access';
        } else {
          $rootScope.hasWriteAccess = vm.hasWriteAccess = true;
        }
        // console.log(vm.hasWriteAccess, screenName, currentScreen);

        vm.focusSideBar = stateName.shift();
        vm.focusInnerSideBar = currentScreen && !currentScreen.noFocusInnerSideBar ? (currentScreen.joinForFocus ? stateName.join('_') : stateName[0]) : false;

        if (currentScreen) {
          if (currentScreen.isJoinedInnerScreen) {
            let stateNameCopy = angular.copy(stateName);
            stateNameCopy.pop();
            vm.focusInnerSideBar = stateNameCopy.join('_');
          }

          if (currentScreen.suggestionApiParams) {
            angular.forEach(currentScreen.suggestionApiParams, function(params, listName) {
              forEach(params, function(param) {
                currentScreen.suggestionApi[listName] = currentScreen.suggestionApi[listName].replace(':' + param, $state.params[param]);
              });
            })
          }

          vm.suggestionApi = currentScreen.suggestionApi;
          vm.searchHistoryApiKey = currentScreen.searchHistoryApiKey;
          vm.forcedDisableLeftSideNav = currentScreen.forcedDisableLeftSideNav;
        }
        // console.log(vm.focusSideBar, vm.focusInnerSideBar);
        // Force disable left sidenav
        vm.isLeftSideNavEnable = !/UPDATE|EDIT|ADD|CREATE|UPLOAD|CLONE/.test(screenName);
        vm.screenName = screenName;
      }
    }

    function isAllowedToAccess(stateName) {
      return $rootScope.accessData.cbac[stateName] == 'full_access' || $rootScope.accessData.cbac[stateName] == 'read_access';
    }

    function goToResource() {
      $state.go('main.resources.resourcesDetails', {
        resourceId: $rootScope.usrObj.resourceId
      });
    }

    function changeOption(vm, screensList, target, moduleName, _params) {

      let targetState, stateName;

      targetState = target.click;
      if (target.cbac) {
        if (isAllowedToAccess(target.cbac)) {
          targetState = target.click;

          // handle other_resources no permission condition
          if (targetState == 'resources' && $rootScope.accessData.cbac.other_resources && !isAllowedToAccess('other_resources')) {
            goToResource();
            return;
          }
        } else if (($rootScope.usrObj.isOwner || $rootScope.usrObj.isAdmin) && $rootScope.accessData.cbac[target.cbac] == 'no_license' && target.salesPage) {
          targetState = target.salesPage;
        }
      }

      // console.log(moduleName);

      if (targetState) {
        $mdSidenav('left').close();
      } else {
        // vm.sidenavMenu.activeMenu = vm.sidenavMenu.activeMenu != target.name ? target.name : false;
        target.isOpen = !target.isOpen;
        return;
      }
      // console.log('Target State : ', targetState);
      let dashboardScreens = localStorageService.get('dashboardScreens') || {};

      if (targetState == 'opportunities') {
        stateName = ($rootScope.usrObj.dashboard[45] && localStorageService.get('dashboardDisableOpportunities') ? 'main.customers.opportunitiesDashboard' : 'main.customers.opportunities');
      } else if (targetState == 'customers') {
        stateName = ($rootScope.usrObj.dashboard[10] && localStorageService.get('dashboardDisableCustomer') ? 'main.customers.customersDashboard' : 'main.customers.customers');
      } else if (moduleName == 'resources' && (targetState == 'action' || targetState == 'actionWorkboard')) {
        stateName = (localStorageService.get('dashboardDisableWorkboard') ? 'main.resources.action' : 'main.resources.actionWorkboard');
      } else {
        stateName = dashboardScreens[targetState] || ('main.' + moduleName + '.' + targetState);
      }
      // dashboardDisableWorkboard
      let state = targetState.split(/(?=[A-Z])/).join('_').toUpperCase(),
        leftBtn;

      if (screensList[state]) {
        leftBtn = screensList[state].leftButton;
      }

      let params = {
        noRetainFC: true
      };

      if (angular.isObject(_params)) {
        angular.merge(params, _params)
      }

      if (leftBtn && leftBtn.params) {
        angular.forEach(leftBtn.params, function(param, index) {
          params[param] = leftBtn.paramsVal ? parseInt(leftBtn.paramsVal[index]) : (params[param] || $state.params[param]);
        });
      }

      // console.log(stateName, params, leftBtn, $stateParams, $state.params);
      if ($state.get(stateName)) {
        $state.go(stateName, params);
      } else {
        messages.nativeAlert("WORK_IN_PROGRESS.");
      }
    }

    function navigateBack(stateName, params = {}) {
      return function() {
        if (stateName) {
          $state.go(stateName, params);
        } else {
          NavigationService.back();
        }
      };
    }

    // Navigate Back
    function backScreen(vm, isSaved, stateName, params) {
      if (!isSaved && angular.isFunction(vm.isUnsavedChanges) && vm.isUnsavedChanges()) {
        unsavedChangesService.confirm({
          question: 'Do you want to discard the changes?',
          successHandler: navigateBack(stateName, params)
        });
      } else {
        navigateBack(stateName, params)();
      }
    }

  }
}