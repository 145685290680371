import emailBounceCountTemplate from './../../components/popup-view/emailBounceCountTemplate.html';

export default function(app) {
  app.factory('emailThrottle', emailThrottle);

  function emailThrottle($state, $rootScope, $mdDialog, sharedDataService, messages) {
    "ngInject";
    let ndsData = {};
    return {
      init: init
    };

    function init(ndsObj) {
      console.log(ndsObj);
      ndsData = {
        ...sharedDataService.get('ndsData'),
        ...ndsObj
      };
      sharedDataService.set('ndsData', ndsData);

      let currentTotalBounce = ndsObj.total_bounce;

      let isTotalBounceWarningShow = isWarningNeedToShow(currentTotalBounce);
      if (isTotalBounceWarningShow && ndsObj.bounce_rate == 0 || (ndsObj.bounce_rate > 0 && ndsObj.bounce_rate <= 0.3 && !ndsData[ndsObj.bounce_rate])) {
        showEmailBounceToast(ndsObj);
      }
    }

    function storeFlagForWarning(currentTotalBounce) {
      ndsData[currentTotalBounce] = true;
      sharedDataService.set('ndsData', ndsData);
    }

    function isWarningNeedToShow(currentTotalBounce) {
      let warningShowOnBounce = [5, 10, 15, 20],
        isExist = warningShowOnBounce.indexOf(currentTotalBounce);

      if (isExist != -1 && !ndsData[currentTotalBounce]) {
        return true;
      }

      if (currentTotalBounce >= 5 && currentTotalBounce < 10) {
        if (!ndsData[5] && isExist == -1) {
          storeFlagForWarning(5);
          return true;
        }
        return false;
      } else if (currentTotalBounce >= 10 && currentTotalBounce < 15) {
        if (!ndsData[10] && isExist == -1) {
          storeFlagForWarning(10);
          return true;
        }
        return false;
      } else if (currentTotalBounce >= 15 && currentTotalBounce < 21) {
        if (!ndsData[15] && isExist == -1) {
          console.log(15);
          storeFlagForWarning(15);
          return true;
        }
        return false;
      } else {
        return false;
      }
    }

    function showEmailBouncePopup(ndsObj) {
      // $mdDialog.cancel().then(function(){});
      return $mdDialog.show({
        controller: ShowEmailBounceController,
        controllerAs: 'cd',
        templateUrl: emailBounceCountTemplate,
        clickOutsideToClose: false,
        parent: angular.element(document.body),
        locals: {
          ndsObj: ndsObj
        }
      });
    }

    function showEmailBounceToast(ndsObj) {
      messages.showActionToast(' Warning - Your email  bounce threshold is high', 'Read More', 'danger', 0, showEmailBouncePopup(ndsObj), 'top right');
    }

    function ShowEmailBounceController($scope, $mdDialog, sharedDataService, ndsObj) {
      'ngInject';
      let cd = this;

      cd.throttleMax = 0.3;
      cd.ndsObject = {
        bounceCount: ndsObj.total_bounce,
        bounceRate: ndsObj.bounce_rate
      };

      cd.cancel = cancel;

      function cancel() {
        let ndsValueConfirm = sharedDataService.get('ndsData') || {};
        ndsValueConfirm[cd.ndsObject.bounceRate > 0 ? cd.ndsObject.bounceRate : cd.ndsObject.bounceCount] = true;
        sharedDataService.set('ndsData', ndsValueConfirm);
        $mdDialog.cancel();
      }

    }
  }
}