export default function(app) {
  app.factory('locale', locale);

  function locale($filter) {

    "ngInject";

    return {
      translate: translate
    };

    function translate(msg) {
      return $filter('translate')(msg);
    }
  }

}