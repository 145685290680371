export default function(app) {
  app.factory('QuillService', quillService);

  function quillService(messages, dataServices, serverUrl, FILE_SIZES) {

    "ngInject";

    return {
      getConfig: getConfig
    };

    function getConfig() {
      return {
        toolbar: [
          [{
            'font': []
          }],

          [{
            'size': ['10px', false, '16px', '20px', '26px', '36px']
          }],

          [{
            'header': [1, 2, 3, 4, 5, 6, false]
          }],

          ['bold', 'italic', 'underline', 'strike'],

          [{
            'color': []
          }, {
            'background': []
          }],

          [{
            'list': 'ordered'
          }, {
            'list': 'bullet'
          }],

          [{
            'indent': '-1'
          }, {
            'indent': '+1'
          }],

          [{
            'align': []
          }],

          ['emoji'],

          [{
            'direction': 'rtl'
          }],

          [{
            'script': 'sub'
          }, {
            'script': 'super'
          }],

          ['blockquote', 'code-block'],

          ['link', 'image'], // link, image and video

          ['clean']
        ],
        "emoji-toolbar": true,
        "emoji-shortname": true,
        magicUrl: true,
        blotFormatter: {},
        imageUploader: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              if (file.size > FILE_SIZES.max) {
                let error = `File size exceeds ${FILE_SIZES.maxMB}.`;
                messages.simpleToast(error, 'danger');
                reject(error);
                return;
              }

              new Compressor(file, {
                quality: .6,
                success(compressedFile) {
                  console.log(compressedFile);

                  dataServices.upload({
                      url: serverUrl.main + 'mediaApi/upload/file',
                      data: {
                        file: [compressedFile]
                      },
                      spinner: false,
                      isShowError: false
                    }, true)
                    .then((response) => {
                      let data = response.data;
                      if (response.status == '406') {
                        let error = 'File couldn\'t be uploaded: System has detected the file as unsafe.';
                        messages.simpleToast(error, 'danger');
                        reject(error);
                      } else if (data && data.response_code == 200) {
                        resolve(data.result.success[0].webUrl);
                      }
                    }, (error) => {
                      reject(error);
                    });

                },
                error(e) {
                  reject(e.message);
                }
              });

            });

          },
        }
      };
    }

  }
}