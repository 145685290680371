import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

export default function(app) {

  app.filter('nameAbbreviation', nameAbbreviation)
    .filter('nameAbbreviationAUC', nameAbbreviationAUC)
    .filter('suffix', suffix)
    .filter('name', name)
    .filter('age', age)
    .filter('dd', dd)
    .filter('monYY', monYY)
    .filter('phoneFormat', phoneFormat)
    .filter('setVal', setVal)
    .filter('currencyForm', currencyForm)
    .filter('fullName', fullName)
    .filter('locationShortName', locationShortName)
    .filter('locationName', locationName)
    .filter('columnTime', columnTime)
    .filter('stringS', stringS)
    .filter('stringXS', stringXS)
    .filter('normalFormDate', normalFormDate)
    .filter('normalFormDate1', normalFormDate1)
    .filter('normalFormDate2', normalFormDate2)
    .filter('normalFormDate3', normalFormDate3)
    .filter('normalFormDate4', normalFormDate4)
    .filter('communicationDate', communicationDate)
    .filter('time24Hrs', time24Hrs)
    .filter('comma', comma)
    .filter('getFullName', getFullName)
    .filter('getFullNameAUC', getFullNameAUC)
    .filter('getInvAttrName', getInvAttrName)
    .filter('countryName', countryName)
    .filter('serviceTypeValue', serviceTypeValue)
    .filter('tzOffsetTS', tzOffsetTS)
    .filter('time', time)
    .filter('allDays', allDays)
    .filter('getDayName', getDayName)
    .filter('getHoursFromMinutes', getHoursFromMinutes)
    .filter('webUrl', webUrl)
    .filter('getSuffFromMinutes', getSuffFromMinutes)
    .filter('resourceServiceType', resourceServiceType)
    .filter('resourceCategory', resourceCategory)
    .filter('rateType', rateType)
    .filter('serviceStatus', serviceStatus)
    .filter('recurringPlanName', recurringPlanName)
    .filter('scopeOfWorkType', scopeOfWorkType)
    .filter('monthYear', monthYear)
    .filter('imageAltText', imageAltText)
    .filter('reasonName', reasonName)
    .filter('objlength', objlength)
    .filter('trusted', trusted)
    .filter('camelcase', camelcase)
    .filter('productStatusList', productStatusList)
    .filter('productStatus', productStatus)
    .filter('orderImportStatusList', orderImportStatusList)
    .filter('orderImportStatus', orderImportStatus)
    .filter('resourceStatus', resourceStatus)
    .filter('chargeOnDateList', chargeOnDateList)
    .filter('paymentTimeRef', paymentTimeRef)
    .filter('attributeTypes', attributeTypes)
    .filter('attributeType', attributeType)
    .filter('quoteStatus', quoteStatus)
    .filter('visitStatus', visitStatus)
    .filter('supplierType', supplierType)
    .filter('instructionType', instructionType)
    .filter('instructionsList', instructionsList)
    .filter('instructionsDefault', instructionsDefault)
    .filter('inventoryType', inventoryType)
    .filter('itemType', itemType)
    .filter('reasonListType', reasonListType)
    .filter('gateways', gateways)
    .filter('poStatusList', poStatusList)
    .filter('poStatus', poStatus)
    .filter('documentStatus', documentStatus)
    .filter('poPayment', poPayment)
    .filter('productSetList', productSetList)
    .filter('materialSetList', materialSetList)
    .filter('productSet', productSet)
    .filter('productType', productType)
    .filter('purchaseStatus', purchaseStatus)
    .filter('visitTypeValue', visitTypeValue)
    .filter('quotesStatus', quotesStatus)
    .filter('shortPrice', shortPrice)
    .filter('shortNumber', shortNumber)
    .filter('orgName', orgName)
    .filter('queryType', queryType)
    .filter('taggingFunctions', taggingFunctions)
    .filter('taggingCategories', taggingCategories)
    .filter('taggingCategory', taggingCategory)
    .filter('convInteger', convInteger)
    .filter('customerStatuses', customerStatuses)
    .filter('customerStatus', customerStatus)
    .filter('resResourceDataFilter', resResourceDataFilter)
    .filter('quotesPayment', quotesPayment)
    .filter('paymentTypes', paymentTypes)
    .filter('positiveValue', positiveValue)
    .filter('promotionStatus', promotionStatus)
    .filter('sourceOfLeadFilter', sourceOfLeadFilter)
    .filter('promotionInnerStatus', promotionInnerStatus)
    .filter('cardStatuses', cardStatuses)
    .filter('cardStatus', cardStatus)
    .filter('uppercase', uppercase)
    .filter('additionalServiceType', additionalServiceType)
    .filter('leadimportStatus', leadimportStatus)
    .filter('leadlistStatus', leadlistStatus)
    .filter('specialEquipmentTypeValue', specialEquipmentTypeValue)
    .filter('isSales', isSales)
    .filter('removeTags', removeTags)
    .filter('nameAbbreviationImport', nameAbbreviationImport)
    .filter('getFullNameImport', getFullNameImport)
    .filter('roundOff', roundOff)
    .filter('timeInMinutes', timeInMinutes)
    .filter('emailStatus', emailStatus)
    .filter('transportTypeValue', transportTypeValue)
    .filter('schedulingStatus', schedulingStatus)
    .filter('taskScheduleType', taskScheduleType)
    .filter('campaignStatus', campaignStatus)
    .filter('businessContactStatus', businessContactStatus)
    .filter('invoiceDate', invoiceDate)
    .filter('calendarEventType', calendarEventType)
    .filter('valueOrNA', valueOrNA)
    .filter('serialNumberStatus', serialNumberStatus)
    .filter('serialNumberStatusList', serialNumberStatusList)
    .filter('kitStatusList', kitStatusList)
    .filter('kitStatus', kitStatus)
    .filter('warrantyCategories', warrantyCategories)
    .filter('warrantyCategory', warrantyCategory)
    .filter('priceTypes', priceTypes)
    .filter('priceType', priceType)
    .filter('addPriceTypes', addPriceTypes)
    .filter('addPriceType', addPriceType)
    .filter('fulfillmentStatus', fulfillmentStatus)
    .filter('fulfillmentStatuses', fulfillmentStatuses)
    .filter('companyCategories', companyCategories)
    .filter('companyCategory', companyCategory)
    .filter('getWordCounts', getWordCounts)
    .filter('fileName', fileName);

  function fileName() {
    return function(url) {
      return url.split('/').pop()
    }
  }

  function getWordCounts() {
    return function(data) {
      if (data) {
        data = data.replace(/<[^>]*>/g, ' ');
        data = data.replace(/\s+/g, ' ');
        data = data.trim();
      }
      return data ? data.split(' ').length : 0;
    }
  }

  function companyCategories() {
    return {
      1: 'Companies',
      2: 'Accounts',
      3: 'Projects',
      4: 'Others'
    };
  }

  function companyCategory($filter) {

    "ngInject";

    return function(key) {
      return $filter('companyCategories')[key] || 'N/A';
    }
  }

  function fulfillmentStatuses() {
    return {
      pending: 'Pending',
      partial: 'Partially Fullfilled',
      fully: 'Fully Fullfilled'
    };
  }

  function fulfillmentStatus($filter) {
    "ngInject";
    return function(status) {
      return $filter('fulfillmentStatuses')[status];
    }
  }

  function priceTypes() {
    return {
      "flat_per_task": "Flat Per Quantity",
      "flat": "Flat Per Day",
      "hour": "Hourly",
      "composite": "Composite"
    };
  }

  function addPriceTypes() {
    return {
      "flat_per_task": "Flat Per Quantity",
      "flat": "Flat Per Day",
      "percentage": "Percentage",
    };
  }

  function priceType($filter) {
    "ngInject";
    return function(status) {
      return $filter('priceTypes')[status];
    }
  }

  function addPriceType($filter) {
    "ngInject";
    return function(status) {
      return $filter('addPriceTypes')[status];
    }
  }

  function warrantyCategories() {
    return {
      1: 'Warranty',
      2: 'Maintainance',
      3: 'SLA'
    };
  }

  function warrantyCategory($filter) {
    "ngInject";
    return function(status) {
      return $filter('warrantyCategories')[status];
    }
  }

  function kitStatus($filter) {
    "ngInject";
    return function(status) {
      return $filter('kitStatusList')[status];
    }
  }

  function kitStatusList() {
    return {
      1: 'Available',
      2: 'Committed',
      3: 'Fulfilled',
      4: 'Warrantied',
      5: 'Returned',
      6: 'Discarded',
      7: 'Internal',
      8: 'Unassembled',
      9: 'mix_status',
      10: 'on_hold'
    }
  }

  function serialNumberStatusList() {
    return {
      1: 'Available',
      2: 'Committed',
      3: 'Fulfilled',
      4: 'Warrantied',
      5: 'Returned',
      6: 'Discarded',
      7: 'Internal',
      8: 'Bundled',
      9: 'Unavailable',
      10: 'on_hold'
    };
  }

  function serialNumberStatus($filter) {
    "ngInject";

    return function(status) {
      return $filter('serialNumberStatusList')[status];
    }
  }

  function calendarEventType() {
    return function(event) {
      const eventsMap = {
        action: 'Action Item',
        opportunity: 'Opportunity',
        event: 'Personal Event',
        conversation: 'Conversation',
        purchaseOrder: 'Purchase Order'
      };

      if (event.type == 'service') {
        return (event.isJob ? 'Job' : 'Quote') + ' Service';
      } else if (event.type == 'visit') {
        return (event.isJob ? 'Job' : 'Quote') + ' Meeting';
      } else {
        return eventsMap[event.type];
      }
    };
  }

  function taskScheduleType() {
    "ngInject";
    return function(type) {
      return {
        0: 'Non Repeat Schedule',
        1: 'Repeat Schedule',
        3: 'Recurring',
        4: 'Custom'
      } [type];
    };
  }

  function campaignStatus() {
    "ngInject";
    return function(type) {
      return {
        12: 'Draft',
        2: 'Active',
        4: 'Inactive',
        22: 'Expired'
      } [type];
    };
  }

  function schedulingStatus($rootScope) {
    return function(type) {
      return $rootScope.aObj.assignmentStatus[type];
    };
  }

  function emailStatus() {
    return function(status) {
      return {
        1: 'UNDETERMINED',
        2: 'GOOD',
        3: 'DOMAIN_BLOCKED',
        4: 'EMAIL_BLOCKED',
        5: 'BOUNCED'
      } [status];
    };
  }

  function roundOff() {
    return function(number) {
      if (!number) {
        return;
      }
      return Math.round(number);
    };
  }

  function sourceOfLeadFilter($rootScope) {
    'ngInject';
    return function(id) {
      if (!id) {
        return;
      }
      return $rootScope.rObj.records.sourceOfLead[id] ? $rootScope.rObj.records.sourceOfLead[id].reason : 'N/A';
    };
  }

  function removeTags() {
    return function(text) {
      let removedTagsText = text ? String(text).replace(/<[^>]+>/gm, '') : '';
      let removeAndText = String(removedTagsText).replace(/&amp;/g, '&');
      return removedTagsText ? String(removeAndText).replace(/&nbsp;/g, '') : '';
    };
  }

  function cardStatuses() {
    return {
      20: 'CANCELED',
      22: 'EXPIRED',
      24: 'VOIDED',
      90: 'COMPLETED',
      150: 'PROSPECT',
      151: 'NEGOTIATION',
      152: 'CONTRACTED',
      153: 'CLOSED'
    }
  }

  function cardStatus($filter) {
    "ngInject";
    return function(status) {
      return $filter('cardStatuses')[status];
    }
  }

  function promotionStatus() {
    return function(type) {
      return {
        2: 'ACTIVE',
        4: 'DISABLED',
        13: 'EXHASUSTED',
        22: 'EXPIRED'
      } [type];
    };
  }

  function promotionInnerStatus() {
    return function(type) {
      return {
        1: 'APPLIED',
        2: 'INVALID',
        3: 'UNAVAILABLE',
        4: 'FINISHED',
        5: 'USED',
        6: 'RETURNED',
        22: 'EXPIRED'
      } [type];
    };
  }

  function resResourceDataFilter($rootScope) {
    'ngInject';
    return function(resId) {
      if (!resId) {
        return;
      }
      return $rootScope.bObj.resDataMapped.resourceData[resId].type;
    };
  }

  function positiveValue() {
    'ngInject';
    return function(input) {
      if (!input) {
        return 0;
      }

      return Math.abs(input);
    };
  }

  function customerStatuses() {
    return {
      1: 'New',
      2: 'Active',
      3: 'Inactive',
      5: 'Buyer',
      6: 'Archived',
      44: 'Lead',
      66: 'Buying',
    }
  }

  function businessContactStatus() {
    return function(type) {
      return {
        'active': 'Active',
        'inactive': 'Inactive',
        'zombie': 'Zombie',
      } [type];
    };
  }

  function customerStatus($filter) {
    "ngInject";
    return function(status) {
      return $filter('customerStatuses')[status];
    }
  }

  function convInteger() {
    return function(key) {
      return parseInt(key);
    };
  }

  function taggingFunctions() {
    return {
      SYSTEM: [{
        key: 9,
        displayVal: 'Opportunities',
      }, {
        key: 15,
        displayVal: 'Services'
      }, {
        key: 31,
        displayVal: 'Service Promotions'
      }, {
        key: 14,
        displayVal: 'Meetings'
      }, {
        key: 27,
        displayVal: 'Product Line Items'
      }, {
        key: 17,
        displayVal: 'Product Promotions'
      }, {
        key: 28,
        displayVal: 'Material Line Items'
      }, {
        key: 30,
        displayVal: 'Material Promotions'
      }, {
        key: 32,
        displayVal: 'Campaigns'
      }, {
        key: 36,
        displayVal: 'Lead Collector'
      }],

      CUSTOM: [{
        key: 1,
        displayVal: 'Customers'
      }, {
        key: 9,
        displayVal: 'Opportunities'
      }, {
        key: 16,
        displayVal: 'Quotes'
      }, {
        key: 18,
        displayVal: 'Jobs'
      }, {
        key: 8,
        displayVal: 'Products'
      }, {
        key: 29,
        displayVal: 'Materials'
      }, {
        key: 27,
        displayVal: 'Product Ordering'
      }, {
        key: 28,
        displayVal: 'Material Ordering'
      }, {
        key: 11,
        displayVal: 'Media'
      }, {
        key: 36,
        displayVal: 'Lead Collector'
      }]
    };
  }

  function taggingCategories() {
    return {
      'CUSTOM': 'CUSTOM',
      1: 'CUSTOMER',
      9: 'OPPORTUNITY',
      16: 'QUOTE',
      18: 'JOB',
      15: 'SERVICE',
      31: 'SERVICE_PROMOTION',
      14: 'MEETING',
      8: 'PRODUCT',
      17: 'PRODUCT_PROMOTION',
      27: 'PRODUCT_LINE_ITEM',
      29: 'MATERIAL',
      28: 'MATERIAL_LINE_ITEM',
      30: 'MATERIAL_PROMOTION',
      32: 'CAMPAIGN',
      11: 'MEDIA',
      36: 'LEAD_COLLECTOR'
    };
  }

  function taggingCategory($filter) {
    "ngInject";
    return function(key) {
      return $filter('taggingCategories')[key];
    }
  }

  function queryType() {
    return function(key) {
      return {
        'OR': 'MATCH_ANY',
        'AND': 'MATCH_ALL',
        'NOT': 'MATCH_EXCEPT'
      } [key];
    };
  }

  function orgName($rootScope) {
    'ngInject';
    return function(orgId) {
      if (!orgId) {
        return;
      }
      return $rootScope.bObj.resDataMapped.orgData[orgId].orgName;
    };
  }

  function isSales($rootScope) {
    'ngInject';
    return function(orgId) {
      if (!orgId) {
        return;
      }
      return $rootScope.bObj.resDataMapped.orgData[orgId].isSales ? 'Sales' : 'Non-Sales';
    };
  }

  function shortNumber() {
    return function(number) {
      if (angular.isNumber(number)) {
        let abs = Math.abs(number);
        if (abs >= Math.pow(10, 12)) {
          // trillion
          number = (number / Math.pow(10, 12)).toFixed(1) + "T";
        } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
          // billion
          number = (number / Math.pow(10, 9)).toFixed(1) + "B";
        } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
          // million
          number = (number / Math.pow(10, 6)).toFixed(1) + "M";
        } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
          // thousand
          number = (number / Math.pow(10, 3)).toFixed(1) + "K";
        }
        return number;
      }
    };
  }

  function shortPrice() {
    return function(number = 0, currencySymbol = '') {
      if (angular.isNumber(number)) {
        let abs = Math.abs(number);
        if (abs >= Math.pow(10, 12)) {
          // trillion
          number = (number / Math.pow(10, 12)).toFixed(1) + "T";
        } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
          // billion
          number = (number / Math.pow(10, 9)).toFixed(1) + "B";
        } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
          // million
          number = (number / Math.pow(10, 6)).toFixed(1) + "M";
        } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
          // thousand
          number = (number / Math.pow(10, 3)).toFixed(1) + "K";
        } else if (abs < Math.pow(10, 4) && abs >= Math.pow(10, 2)) {
          // thousand
          // number = "99+";
          if (currencySymbol) {
            number = number.toFixed(1);
          }
        }
        return currencySymbol + number;
      }
    };
  }

  function visitTypeValue($rootScope) {
    'ngInject';
    return function(visitType) {
      return visitType ? $rootScope.bObj.onsiteTypes[findIndex($rootScope.bObj.onsiteTypes, function(obj) {
        return obj.id == visitType;
      })].name : "";
    };
  }

  function itemType() {
    return {
      "product": "Product",
      "material": "Material"
      // "both": "Both"
    };
  }

  function leadimportStatus() {
    return function(key) {
      return {
        "imported": "Imported",
        "notImported": "Not Imported",
        "partiallyImported": "Partially Imported",
        "fullyImported": "Fully Imported"
      } [key];
    };
  }

  function leadlistStatus() {
    return function(key) {
      return {
        "schedule": "Scheduled",
        "complete": "Complete",
        "cancel": "Canceled",
        'exhausted': 'Exhausted'
      } [key];
    };
  }

  function reasonListType() {
    return function(key) {
      const categories = {
        "customerTypes": "Customer Type",
        "additionalCosts": "Additional Costs",
        "additionalCost": "Aditional Cost",
        "BusinessType": "Business Type",
        "cancel": "Cancel",
        "void": "Void",
        "ReturnPayments": "Return Payment",
        "educations": "Educations",
        "eventTypes": "Event Types",
        "geographicMarket": "Geographic Market",
        "industryTypes": "Industry Types",
        "position": "Position",
        "return": "Return",
        "sourceOfLead": "Source Of Lead",
        "rma": "Return Merchandise Authorization",
        // "PoPaymentType": "Po Payment Type",
        "ReturnPayments": "Return Payments",
        // "PoDocType": "Po Document Type",
        "PoCancelReason": "Po Cancel Reason",
        "PoVoidReason": "Po Void Reason",
        "serviceCanceled": "Service Canceled",
        "serviceVoid": "Service Void",
        "visitCanceled": "Meeting Cancel",
        "visitVoid": "Meeting Void",
        "organizations": "Organizations",
        "status": "Status",
        "jobCustomerMerchandiseReturn": "Job Customer Merchandise Return",
        "jobServiceItemStatus": "Job Service Item Status",
        "medium": "Medium",
        "Business Contact Status": "Business Contact Status",
        "Order Type": "Order Type",
        // "Business Contact Categories": "Business Contact Categories",
        "Associated documents": "Associated documents",
        "work_order_status": "Work Order Status",
        "operational_event": "Operational Event",
        "warranty_tracking": "Warranty Tracking"
      };
      return categories[key] || key;
    };
  }

  function gateways() {
    return function(key) {
      return {
        "brain-tree": "Brain Tree",
        "first-data": "First Data"
      } [key];
    };
  }

  function productSetList() {
    return {
      "reg": "Regular",
      "bndl": "Bundle",
      "package": "Package"
    };
  }

  function materialSetList() {
    return {
      "reg": "Regular",
      "rental": "Rental"
    };
  }

  function productSet($filter) {
    "ngInject";
    return function(key) {
      return $filter('productSetList')[key];
    };
  }

  function productType() {
    return function(key) {
      return {
        "reg": "Regular",
        "bndl": "Bundle",
        "package": "Package",
        "material": "Material",
        "rental_material": "Rental Material"
      } [key];
    };
  }

  function inventoryType($filter) {
    "ngInject";
    return function(key) {
      return $filter('itemType')[key];
    };
  }

  function instructionsDefault() {
    return {
      "global": "Global",
      "joiners": "Joiners",
      "message": "Message",
      "preparation": "Preparation",
      "additions": "Additions"
    };
  }

  function instructionsList($rootScope) {
    "ngInject";
    return $rootScope.bObj.resourceData.instructional_catagories || {};
  }

  function instructionType($filter) {
    "ngInject";
    return function(key) {
      return $filter('instructionsList')[key] || $filter('instructionsDefault')[key];
    };
  }

  function visitStatus($rootScope) {
    return function(status) {
      return status ? $rootScope.aObj.onsiteStatus[status] : "-";
    };
  }

  function quoteStatus($rootScope) {
    return function(status) {
      if (!status) {
        return;
      }
      return $rootScope.aObj.quoteStatus[status];
    };
  }

  function attributeTypes() {
    return {
      1003: 'ALPHA_NUMERIC',
      1005: 'BOOLEAN',
      1001: 'CHARACTER',
      1004: 'FLOATING_POINT',
      1002: 'INTEGER'
    }
  }

  function supplierType() {
    return function(key) {
      return {
        1: 'Manufacturer',
        2: 'Partner',
        3: 'Vendor',
        4: 'Distributor',
        5: 'Supplier'
      } [key];
    };
  }

  function supstatus() {
    return function(key) {
      return {
        1: 'Yes',
        2: 'NO'
      } [key];
    };
  }

  function attributeType($filter) {
    "ngInject";
    return function(key) {
      return $filter('attributeTypes')[key];
    };
  }

  function chargeOnDateList() {
    return {
      "before_start_date": "ON_24_HOURS_BEFORE_START_DATE_OF_THE_SERVICE.",
      "after_start_date": "ON_24_HOURS_AFTER_START_DATE_OF_THE_SERVICE.",
      "before_end_date": "ON_24_HOURS_BEFORE_END_DATE_OF_THE_SERVICE.",
      "after_end_date": "ON_24_HOURS_AFTER_END_DATE_OF_THE_SERVICE.",
      "start_date": "ON_START_DATE_OF_THE_SERVICE.",
      "end_date": "ON_END_DATE_OF_THE_SERVICE."
    };
  }

  function paymentTimeRef() {
    return function(key) {
      return {
        "quarterWayIntoExpectedDate": "Quarter Way into Expected Date",
        "halfWayBeforeExpectedDate": "Half Way Before Expected Date",
        "quarterWayBeforeExpectedDate": "Quarter Way Before Expected Date",
        "expectedDayOfPO": "Expected Day of PO",
        "after15DayOfPOExpectedDate": "After 15 Day of PO Expected Date",
        "after30DayOfPOExpectedDate": "After 30 Day of PO Expected Date",
        "after60DayOfPOExpectedDate": "After 60 Day of PO Expected Date",
        "after75DayOfPOExpectedDate": "After 75 Day of PO Expected Date"
      } [key];
    };
  }

  function paymentTypes() {
    return function(key) {
      return {
        "fullPayment": "Fully Paid",
        "partialPayment": "Partially Paid",
        "refund": "Refund"
      } [key];
    };
  }

  function camelcase() {
    return function(input = '') {
      if (!angular.isString(input)) {
        return;
      }
      return input.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };
  }

  function uppercase() {
    return function(input = '') {
      if (!angular.isString(input)) {
        return;
      }
      return input.replace(/\w\S*/g, function(txt) {
        return txt.toUpperCase();
      });
    };
  }

  function trusted($sce) {
    'ngInject';
    return function(html_code) {
      return $sce.trustAsHtml(html_code);
    };
  }

  function objlength() {
    return function(object) {
      return Object.keys(object).length;
    };
  }

  function monthYear(miniMonthName) {
    "ngInject";
    return function(epoche) {
      if (!epoche) return '-';
      let d = new Date(epoche * 1000);
      return miniMonthName[d.getMonth()] + " " + d.getFullYear();
    };
  }

  function scopeOfWorkType($rootScope) {
    "ngInject";
    return function(type) {
      return find($rootScope.bObj.scopeOfWork, {
        id: type
      }).name;
    };
  }

  function recurringPlanName($rootScope) {
    "ngInject";
    return function(recurringPlan) {
      let planName = "";

      if (recurringPlan && Array.isArray(recurringPlan)) {
        for (let i = 0; i < recurringPlan.length; i++) {
          if (recurringPlan[i].status != "terminated") {
            planName = recurringPlan[i].plan_name;
          }
        }
      }
      return planName;
    };
  }

  function serviceStatus($rootScope) {
    "ngInject";
    return function(status) {
      if (!status) return '-';
      return $rootScope.aObj.taskStatus[status];
    };
  }

  function rateType($rootScope) {
    "ngInject";
    return function(type) {
      return $rootScope.bObj.rateTypes[type].name;
    };
  }

  function resourceCategory($rootScope) {
    "ngInject";
    return function(id) {
      return $rootScope.bObj.resDataMapped.categoryData[id].name;
    };
  }

  function resourceServiceType($rootScope) {
    "ngIject";
    return function(id) {
      return id ? $rootScope.bObj.resDataMapped.resourceData[id].type : '-';
    };
  }

  function additionalServiceType($rootScope) {
    "ngIject";
    return function(id) {
      return $rootScope.bObj.additionalService[findIndex($rootScope.bObj.additionalService, function(obj) {
        return obj.id == id;
      })].name;
    };
  }

  function specialEquipmentTypeValue($rootScope) {
    "ngIject";
    return function(id) {
      return $rootScope.bObj.splEquipTypes[findIndex($rootScope.bObj.splEquipTypes, function(obj) {
        return obj.id == id;
      })].type;
    };
  }

  function transportTypeValue($rootScope) {
    "ngIject";
    return function(id) {
      return $rootScope.bObj.transportTypes[findIndex($rootScope.bObj.transportTypes, function(obj) {
        return obj.id == id;
      })].type;
    };
  }

  function dd() {
    "ngInject";
    return function(epoche) {
      return epoche ? new Date(epoche * 1000).getDate() : "-";
    };
  }

  function timeInMinutes() {
    "ngInject";
    return function(epoche) {
      let day = new Date(epoche * 1000);
      let mins = day.getMinutes();
      mins = mins + (day.getHours() * 60);
      return mins;
    };
  }

  function tzOffsetTS() {
    return function(epoche, tzOffsetInSec) {
      if (!tzOffsetInSec) {
        return epoche;
      }

      return new Date().getTimezoneOffset() * 60 + parseInt(tzOffsetInSec) + epoche;
    };
  }

  function time() {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) {
        return '-';
      }

      let day = new Date(epoche * 1000);

      let hrs = day.getHours();
      let mins = day.getMinutes();
      let amPM = hrs > 11 ? " PM" : " AM";

      if (hrs > 12) {
        hrs = hrs - 12;
      } else if (hrs === 0) {
        hrs = 12;
      }

      return (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins) + amPM;
    };
  }

  function serviceTypeValue($rootScope) {
    "ngInject";
    return function(serviceType) {
      return serviceType ? $rootScope.bObj.serviceType[findIndex(
        $rootScope.bObj.serviceType,
        function(obj) {
          return obj.id == serviceType;
        })].serviceName : "";
    };
  }

  function time24Hrs() {
    return function(epoche) {
      let day = new Date(epoche * 1000);

      let hrs = day.getHours();
      let mins = day.getMinutes();

      if (hrs < 10) {
        hrs = "0" + hrs;
      }

      if (mins < 10) {
        mins = "0" + mins;
      }
      return epoche ? hrs + ":" + mins : "";
    };
  }

  function columnTime() {
    return function(epoche) {
      let day = new Date(epoche * 1000);

      let hrs = day.getHours();
      let mins = day.getMinutes();
      let amPM = hrs > 11 ? " PM" : " AM";

      if (hrs > 12) {
        hrs = hrs - 12;
      }

      if (hrs < 10) {
        hrs = "0" + hrs;
      }

      if (mins < 10) {
        mins = "0" + mins;
      }

      return epoche ? hrs + ":" + mins + amPM : "";
    };
  }

  function countryName($rootScope) {
    "ngInject";
    return function(id, key = 'name') {
      return id && $rootScope.bObj.regionCodeMapped[id] ? $rootScope.bObj.regionCodeMapped[id][key] : "";
    };
  }

  function locationName($rootScope) {
    "ngInject";
    return function(bId) {
      return bId && $rootScope.bObj.bAddsMapped[bId] ? $rootScope.bObj.bAddsMapped[bId].name : "";
    };
  }

  function locationShortName($rootScope) {
    "ngInject";
    return function(bId) {
      return bId && $rootScope.bObj.bAddsMapped[bId] ? $rootScope.bObj.bAddsMapped[bId].locationCode : "";
    };
  }

  function fullName($filter) {
    "ngInject";
    return function(object, suffix) {
      if (!object) return '';
      return (suffix ? $filter('suffix').object.suffix : '') + $filter('name')(object.firstName) + (object.middleName ? $filter('name')(object.middleName) : '') + $filter('name')(object.lastName);
    };
  }

  function currencyForm($filter) {
    "ngInject";
    return function(cost, symbol) {
      return angular.isNumber(cost) ? $filter('currency')(cost, symbol) : 'N/A';
    };
  }

  function valueOrNA() {
    "ngInject";
    return function(value, defaultValue = 'N/A') {
      console.log(value, defaultValue, angular.isDefined(value) ? value : defaultValue);
      return angular.isDefined(value) && value !== null ? value : defaultValue;
    };
  }

  function nameAbbreviation() {
    "ngInject";
    return function(nameObj) {
      if (!nameObj) return '';
      // replace(/[^a-zA-Z ]/g, "")
      return (nameObj.firstName ? nameObj.firstName.charAt(0) :
        '') + (nameObj.lastName ? nameObj.lastName.charAt(0) :
        '');
    };
  }

  function nameAbbreviationAUC() {
    "ngInject";
    return function(nameObj) {
      if (!nameObj) return '';
      // replace(/[^a-zA-Z ]/g, "")
      return (nameObj.first_name ? nameObj.first_name.charAt(0) :
        '') + (nameObj.last_name ? nameObj.last_name.charAt(0) :
        '');
    };
  }

  function nameAbbreviationImport() {
    "ngInject";
    return function(nameObj) {
      if (!nameObj) return '';
      return (nameObj.first_name && nameObj.first_name.value ? nameObj.first_name.value.charAt(0) :
        '') + (nameObj.last_name && nameObj.last_name.value ? nameObj.last_name.value.charAt(0) :
        '');
    };
  }

  function suffix($rootScope) {
    "ngInject";
    return function(suffix_id) {
      return suffix_id !== undefined ? $rootScope.aObj.contactSuffix[
        suffix_id] : "";
    };
  }

  function name() {
    "ngInject";
    return function(name) {
      if (!name) return '';
      name = name.split(' ');
      let fullName = '';
      angular.forEach(name, function(value) {
        fullName += ' ' + value.charAt(0).toUpperCase() + value.slice(
          1);
      });
      return fullName;
    };
  }

  function age() {
    "ngInject";
    return function(createdDate) {
      createdDate = new Date(createdDate * 1000).setHours(0, 0, 0, 0) / 1000;
      let epoche = new Date().getTime() / 1000;
      epoche = parseInt((epoche - createdDate) / (60 * 60 * 24));
      if (epoche !== 0 && !epoche) {
        return "-";
      }
      return (epoche <= 1) ? (epoche + " Day") : (epoche + " Days");
    };
  }

  function monYY(monthNames) {
    "ngInject";
    return function(epoche) {
      let day = new Date(epoche * 1000);
      let month = day.getMonth();
      let year = day.getFullYear();
      return epoche && monthNames[month] ? monthNames[month].substr(0, 3) +
        "'" + year.toString().substr(2, 2) : "";
    };
  }

  function setVal() {
    return function(val, defaultVal) {
      return val ? val : defaultVal;
    };
  }

  function phoneFormat($rootScope) {
    return function(val) {
      return val || '-';
      // if (!val) return;
      // let locale = getLocale();
      // let registerFilterLocaleCode = function() {
      //   val = val.replace(/^0+/, '');
      //   val = val.replace(/[^0-9]/g, '');
      //   let code = (locale == 'en-us' || locale == 'en-ca') ? '1' : (
      //       locale == 'en-gb') ? '44' : (locale == 'zh-hk') ? '852' :
      //     '1';
      //   if (val.length == 10 || (val.length == 8 && locale == 'zh-hk')) {
      //     return code + val;
      //   } else if (val.length < 10) {
      //     return code + val;
      //   } else {
      //     return val;
      //   }
      // };
      // val = registerFilterLocaleCode();
      // if (val.length > 10) {
      //   if (val.charAt(0) == 1 && val.length == 11) {
      //     return '+' + val.charAt(0) + " " + val.substr(1, 3) + '-' + val
      //       .substr(4, 3) + "-" + val.substr(7, 4);
      //   } else if (val.substr(0, 2) == '44' && val.length == 12) {
      //     return '+' + val.substr(0, 2) + " " + val.substr(2, 3) + " " +
      //       val.substr(5, 3) + " " + val.substr(8, 4);
      //   } else if (val.substr(0, 3) == '852' && val.length == 11) {
      //     return '+' + val.substr(0, 3) + " " + val.substr(2, 3) + " " +
      //       val.substr(5, 3) + " " + val.substr(8, 4);
      //   } else if (val.length == 11) {
      //     return '+' + val.substr(0, 1) + " " + val.substr(1, 3) + " " +
      //       val.substr(4, 3) + " " + val.substr(7, 4);
      //   } else if (val.length == 12) {
      //     return '+' + val.substr(0, 2) + " " + val.substr(2, 3) + " " +
      //       val.substr(5, 3) + " " + val.substr(8, 4);
      //   }
      // }
    };
  }

  function stringS() {
    return function(string) {
      return string ? (string.length > 15 ? string.slice(0, 15) + '...' :
        string) : '';
    };
  }

  function stringXS() {
    return function(string) {
      return string ? (string.length > 10 ? string.slice(0, 10) + '...' :
        string) : '';
    };
  }

  function invoiceDate(miniMonthName) {
    "ngInject";
    return function(epoche) {
      if (epoche == 'NA') return 'NA';
      if (epoche == 'LT') return 'Life Time';
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000);
      return d.getDate() + " " + miniMonthName[d.getMonth()] + ", " + d.getFullYear();
    };
  }

  function normalFormDate(miniMonthName) {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000);
      return d.getDate() + " " + miniMonthName[d.getMonth()] + ", " + d.getFullYear();
    };
  }

  function normalFormDate1(miniMonthName) {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000);
      return miniMonthName[d.getMonth()] + "' " + d.getFullYear();
    };
  }

  function normalFormDate2(monthNames) {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000);
      return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
    };
  }

  function normalFormDate3(miniMonthName) {
    "ngInject";
    return function(date) {
      let d;
      if (angular.isDate(date)) {
        d = date;
      } else if (angular.isNumber(date)) {
        d = new Date(date * 1000);
      }
      return d ? miniMonthName[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear() : '-';
    };
  }

  function normalFormDate4(miniMonthName) {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000);
      return d.getDate() + " " + miniMonthName[d.getMonth()] + "'" + d.getFullYear().toString().substr(2, 2);
    };
  }

  function communicationDate(miniMonthName, $filter) {
    "ngInject";
    return function(epoche) {
      if (!angular.isNumber(epoche)) return '-';
      let d = new Date(epoche * 1000),
        today = new Date();
      if (d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()) {
        return $filter('time')(epoche);
      }
      return miniMonthName[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear();
    };
  }

  function getFullName($filter) {
    "ngInject";
    return function(object, suffix) {
      if (!object) return '';
      return (suffix ? $filter('suffix').object.suffix : '') + $filter(
        'name')(object.firstName) + (object.middleName ? $filter(
        'name')(object.middleName) : '') + $filter('name')(object.lastName);
    };
  }

  function getFullNameAUC($filter) {
    "ngInject";
    return function(object, suffix) {
      if (!object) return '';
      return (suffix ? $filter('suffix').object.suffix : '') + $filter(
        'name')(object.first_name) + (object.middle_name ? $filter(
        'name')(object.middle_name) : '') + $filter('name')(object.last_name);
    };
  }

  function getFullNameImport($filter) {
    "ngInject";
    return function(object) {
      if (!object) return '';
      return (object.first_name && object.first_name.value ? $filter(
        'name')(object.first_name.value) : '') + (object.middle_name && object.middle_name.value ? $filter(
        'name')(object.middle_name.value) : '') + (object.last_name && object.last_name.value ? $filter('name')(object.last_name.value) : '');
    };
  }

  function comma($rootScope) {
    "ngInject";
    return function(name) {
      return name ? (name + ", ") : "";
    };
  }

  function getInvAttrName($rootScope) {
    "ngInject";
    return function(attrId) {
      if ($rootScope.bObj.allAttributes && !$rootScope.bObj.attrModified) {

        $rootScope.bObj.attrModified = {};

        for (let i = 0; i < $rootScope.bObj.allAttributes.length; i++) {
          $rootScope.bObj.attrModified[$rootScope.bObj.allAttributes[i].id] = $rootScope.bObj.allAttributes[i];
        }
      }

      if (attrId && $rootScope.bObj.attrModified && $rootScope.bObj.attrModified[attrId]) {
        return $rootScope.bObj.attrModified[attrId].attributeName;
      }

      return;
    };
  }

  function allDays() {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
      "Friday", "Saturday"
    ];
  }

  function getDayName($filter) {
    "ngInject";
    return function(key) {
      return $filter('allDays')[key];
    };
  }

  function getHoursFromMinutes() {
    return function(minutes, isSuffixNeeded) {
      if (!angular.isNumber(minutes)) {
        return '-';
      }

      let hours = Math.floor(minutes / 60);
      let minute = minutes % 60;
      let suff = '';

      suff = (hours >= 0 && hours < 12) ? 'AM' : 'PM';
      if (hours > 12)
        hours -= 12;
      if (hours < 10)
        hours = '0' + hours;
      if (minute < 10)
        minute = '0' + minute;
      return hours + ':' + minute + (isSuffixNeeded == 'withSuffix' ? suff : '');
    };
  }

  function webUrl(serverUrl) {
    "ngInject";
    return function(img, url) {
      return img ? serverUrl.base + 'jacktrade/' + img[url] : false;
    };
  }

  function getSuffFromMinutes() {
    return function(minutes) {
      let hours = minutes / 60;
      let suff = '';
      suff = (hours >= 0 && hours < 12) ? 'AM' : 'PM';
      return suff;
    };
  }

  function imageAltText() {
    return function(string) {
      if (!string) {
        return;
      }
      let name = string.replace(/[^a-zA-Z ]/g, '').split(' ').filter(function(chunk) {
        return chunk;
      });
      return (name.length > 1) ? (name[0][0] + name[1][0]) : (name[0] ? name[0].slice(0, 2) : string.slice(0, 2));
    };
  }

  function reasonName($rootScope) {

    "ngInject";

    return function(reasonId, category) {
      if (!reasonId || !category) {
        return 'N/A';
      }

      function getReason(reasonId) {
        if ($rootScope.rObj.records && $rootScope.rObj.records[category] && $rootScope.rObj.records[category][reasonId]) {
          return $rootScope.rObj.records[category][reasonId].reason;
        }
      }

      if (angular.isArray(reasonId)) {
        return reasonId.map(function(rId) {
          return getReason(rId);
        }).join(', ');
      }

      return getReason(reasonId) || 'N/A';
    };
  }

  function productStatusList() {
    return {
      1: "New",
      2: "Active",
      4: "Disabled",
      6: "Archived",
      10: "Deleted",
      12: "Draft",
      22: "Expired"
    };
  }

  function productStatus($filter) {
    "ngInject";
    return function(key) {
      return $filter('productStatusList')[key];
    };
  }

  function orderImportStatusList() {
    return {
      1: "ERROR",
      2: "SUCCESS",
      3: "EXISTING",
      4: "DUPLICATE",
      5: "WARNING",

    };
  }

  function orderImportStatus($filter) {
    "ngInject";
    return function(key) {
      return $filter('orderImportStatusList')[key];
    };
  }

  function resourceStatus() {
    return function(key) {
      return {
        2: "ACTIVE",
        3: "INACTIVE",
        4: "DISABLED",
        6: "ARCHIVED",
        7: "SUSPENDED",
        8: "UNARCHIVED"
      } [key];
    };
  }

  // TODO: Commented temp. Uncomment in next releases when this is done
  function poStatusList() {
    return {
      1: "Draft",
      2: "Submitted",
      3: "Modified",
      4: "Partially Received",
      5: "Fully Received",
      6: "Pending",
      7: "Fully Published",
      // 8: "Auto Submitted",
      9: "Completed",
      // 10: "Reopen",
      11: "Void",
      12: "Canceled",
      13: "Closed"
    };
  }

  function poStatus($filter) {
    "ngInject";
    return function(type) {
      return $filter('poStatusList')[type];
    };
  }

  function documentStatus() {
    return function(type) {
      if (type === undefined) {
        return 'N/A';
      }

      return {
        2: 'Active',
        22: 'Expired'
      } [type];
    };
  }

  function poPayment() {
    return function(type) {
      return {
        1: "Unpaid",
        2: "Partially-Paid",
        3: "Returned",
        4: "Overdue",
        5: "Fully Paid",
        6: "Over Paid"
      } [type];
    };
  }

  function purchaseStatus() {
    return function(type) {
      return {
        1: "Open",
        2: "Closed"
      } [type];
    };
  }

  function quotesStatus() {
    return function(type) {
      return {
        10: "Incomplete",
        11: "Draft",
        20: "Submitted",
        21: "Modified",
        22: "Re-Submitted",
        24: "Expired",
        25: "Void",
        26: "Deleted",
        27: "Rejected",
        28: "Canceled",
        29: "Job Incomplete",
        30: "Accepted",
        31: "Started",
        32: "Modified",
        33: "Job Expired",
        34: "Partially Completed",
        35: "Completed",
        36: "Job Submitted",
        37: "Job Re-Submitted",
        40: "Closed",
        41: "Canceled",
        45: "Void"
      } [type];
    };
  }

  function quotesPayment() {
    return function(type) {
      return {
        0: 'N/A',
        1: "Canceled",
        2: "Void",
        3: "Pending",
        4: "Partially Paid",
        5: "Fully Paid",
        6: 'Over Paid'
      } [type];
    };
  }
}