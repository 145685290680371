export const prioritySetup = {
  url: 'opportunityApi/board/priority/setup/single',
  name: 'prioritySetup',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "boardId",
      operator: "="
    }]
  },
  filterValue: ['boardId']
};

export const priorityStatus = {
  url: 'opportunityApi/board/priority/status',
  name: 'priorityStatus',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "boardId",
      operator: "="
    }]
  },
  filterValue: ['boardId']
};

export const ageVisuals = {
  url: 'opportunityApi/board/age/visual',
  name: 'ageVisuals',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "boardId",
      operator: "="
    }]
  },
  filterValue: ['boardId']
};

export const amountVisuals = {
  url: 'opportunityApi/board/amount/visual',
  name: 'amountVisuals',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "boardId",
      operator: "="
    }]
  },
  filterValue: ['boardId']
};

export const boardCategories = {
  url: 'opportunityApi/board/category?stats=true&',
  method: 'GET',
  name: 'boardCategories'
};

export const boards = {
  url: 'opportunityApi/opportunity/boards?location=true&stats=yes&',
  name: 'boards',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const SMART_CARDS = {
  url: 'leadCollectorApi/lead_collector',
  name: 'smartCards',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const SMART_CARD = {
  url: 'leadCollectorApi/lead_collector/:id',
  name: 'smartCard',
  method: 'GET',
  params: ['id']
};

export const entities = {
  url: 'businessEntityApi/business/entity',
  method: 'GET',
  name: 'entities'
};

export const oppPipelineSettings = {
  url: 'opportunityApi/opportunity/position',
  name: 'oppPipelineSettings',
  method: 'GET'
};

export const DESIGNS_DRAFT = {
  url: 'templateBuilderApi/template',
  method: 'GET',
  name: 'designsDraft',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

// export const DESIGNS_FREE = {
//   common: true,
//   url: 'adminTemBuilderApi/email/design',
//   method: 'GET',
//   name: 'designsFree',
//   filterCriteria: {
//     filters: [{
//       field: 'active',
//       operator: '=',
//       value: true
//     }, {
//       field: 'premium',
//       operator: '=',
//       value: false
//     }],
//     skip: 0,
//     limit: 15
//   },
//   onlyPagination: true,
//   getSavedSettings: true
// };

export const DESIGNS_PREMIUM = {
  common: true,
  url: 'adminTemBuilderApi/email/design',
  method: 'GET',
  name: 'designsPremium',
  filterCriteria: {
    filters: [{
        field: 'active',
        operator: '=',
        value: true
      }
      // , {
      //   field: 'premium',
      //   operator: '=',
      //   value: true
      // }
    ],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const SYSTEM_CATEGORIES = {
  common: true,
  url: 'adminTemBuilderApi/email/category',
  method: 'GET',
  name: 'systemCategories'
}

export const CATEGORIES = {
  url: 'templateBuilderApi/template/category',
  method: 'GET',
  name: 'categories',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

// Passed for nav
export const OPPORTUNITIES_LIST = {
  // location=true&
  url: 'opportunityApi/opportunity/cards?stats=yes&',
  method: 'GET',
  name: 'opportunities',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'boardId',
      operator: '='
    }]
  },
  filterValue: [false, 'boardId'],
  pagination: 'OPP_LIST',
  requiredFor: ['main.customers.opportunitiesList'],
  forcedBackFor: {
    'main.customers.opportunitiesList': ['main.customers.opportunitiesPipeline']
  }
};

// Passed for nav
export const cards = {
  url: 'opportunityApi/opportunity/cards?stats=yes&',
  method: 'GET',
  name: 'cards',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'boardId',
      operator: '='
    }]
  },
  filterValue: [false, 'boardId'],
  requiredFor: ['main.customers.opportunitiesPipeline'],
  getSavedSettings: true,
  onlyPagination: false
};

export const boardDetail = {
  url: 'opportunityApi/opportunity/boards/:boardId',
  name: 'boardDetail',
  method: 'GET',
  params: ['boardId']
};

export const cardDetail = {
  url: 'opportunityApi/opportunity/cards/:cardId',
  name: 'cardDetail',
  method: 'GET',
  params: ['cardId'],
  requiredFor: ['main.customers.opportunitiesDetail', 'main.customers.editCard', 'main.customers.opportunitiesHistory'],
  forcedBackFor: {
    'main.customers.editCard': ['main.customers.opportunitiesDetail'],
    'main.customers.opportunitiesDetail': ['main.customers.opportunitiesPipeline', 'main.customers.opportunitiesList']
  }
};

export const labels = {
  url: 'opportunityApi/opportunity/boards/:boardId/labels',
  name: 'labels',
  method: 'GET',
  params: ['boardId']
};

// Passed for nav
export const OPPORTUNITY_HISTORY = {
  url: 'activityApi/activity',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'model_id',
        operator: '=',
      }, {
        field: 'model',
        operator: '=',
        value: 'opportunity_card'
      },
      {
        field: 'activity_type',
        operator: '=',
        value: 'general'
      }
    ],
    skip: 0,
    limit: 15
  },
  pagination: 'ACTIVITY_LIST',
  filterValue: ['cardId'],
  requiredFor: ['main.customers.opportunitiesHistory']
};

export const CUSTOMER_LIST = {
  url: 'contactApi/customers?type=list&stats=yes&',
  method: 'GET',
  name: 'customers',
  filterCriteria: {
    filters: [{
      field: 'status',
      operator: '!=',
      value: 3
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'CONTACT_LIST',
  requiredFor: ['main.customers.customers']
};

export const CUSTOMER_PROFILE = {
  url: 'contactApi/customers/details/:customerId?flow=customer&',
  name: 'profileData',
  params: ['customerId'],
  method: 'GET',
  requiredFor: ['main.customers.editContact'],
  forcedBackFor: {
    'main.customers.editContact': ['main.customers.customersDetail']
  }
};

export const CUSTOMER_DETAIL = {
  // segments=yes&
  url: 'contactApi/customers/details/:customerId?',
  name: 'customerDetail',
  params: ['customerId'],
  method: 'GET'
};

export const CUSTOMER_DETAIL_DRAFT = {
  // segments=yes&
  url: 'draftContactApi/draft/contact/:customerId?',
  name: 'customerDetail',
  params: ['customerId'],
  method: 'GET'
};

export const CUSTOMER_DETAIL_SEGMENTS = {
  url: 'contactApi/customers/details/:customerId?segments=yes&',
  name: 'customerDetail',
  params: ['customerId'],
  method: 'GET'
};

export const ASSOCIATE_MEMBERS = {
  url: 'contactApi/customer/associates/:customerId',
  name: 'associateMembers',
  params: ['customerId'],
  method: 'GET'
};

export const ASSOCIATE_ACCOUNTS = {
  url: 'contactApi/customer/associates/:customerId?accounts=true&',
  name: 'associateAccounts',
  params: ['customerId'],
  method: 'GET'
};

export const ASSOCIATE_NURTURING = {
  url: 'contactApi/customer/associates/nurturing/:customerId',
  name: 'associateNurturing',
  params: ['customerId'],
  method: 'GET'
};

// Passed for nav
export const customerHistory = {
  url: 'activityHistoryApi/history',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'customerId',
      operator: '='
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['customerId'],
  paramTypes: ['int'],
  requiredFor: ['main.customers.customersHistory']
};

export const customerCommunicate = {
  url: 'contactApi/customer/submit/:customerId',
  name: 'customerCommunicate',
  method: 'GET',
  params: ['customerId']
}

export const customerEmailTemplates = {
  url: 'emailTemplateApi/email/template?variations=true&',
  name: 'emailTemplates',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "modelName",
      "operator": "=",
      "value": 10
    }, {
      "field": "status",
      "operator": "=",
      "value": 2
    }],
    "sort": [{
      "field": "name",
      "order": 1
    }]
  }
};

export const customerCommunicationList = {
  url: 'contactApi/customer/communication/:customerId?stats=yes&',
  method: 'GET',
  name: 'communications',
  params: ['customerId'],
  filterCriteria: {
    filters: [{
      field: 'active',
      operator: '=',
      value: true
    }],
    defaultSort: [{
      field: 'updatedAt',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const opportunityCommunicate = {
  url: 'opportunityApi/opportunity/submit/:cardId',
  name: 'opportunityCommunicate',
  method: 'GET',
  params: ['cardId']
}

export const opportunityEmailTemplates = {
  url: 'emailTemplateApi/email/template?variations=true&',
  name: 'emailTemplates',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'modelName',
      operator: '=',
      value: 45
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    sort: [{
      field: "name",
      order: 1
    }]
  }
};

export const CONVERSATION_NAVIGATION = {
  url: 'conversationApi/conversations/nav/:customerId',
  name: 'conversationNavigation',
  method: 'GET',
  params: ['customerId'],
  requiredFor: ['main.customers.customersConversation']
};

export const CONVERSATION_NAVIGATION_OPPORTUNITIES = {
  url: 'conversationApi/conversations/nav/:customerId',
  name: 'conversationNavigation',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'modelId',
      operator: '='
    }]
  },
  filterValue: ['cardId'],
  params: ['customerId'],
  requiredFor: ['main.customers.opportunitiesNotes']
};

export const CUSTOMER_TAGS = {
  url: 'tagApi/tags/customerTags/:customerId',
  name: 'CUSTOMER_TAGS',
  params: ['customerId'],
  method: 'GET',
  requiredFor: ['main.customers.editCustomerTags'],
  forcedBackFor: {
    'main.customers.editCustomerTags': ['main.customers.customersDetail']
  }
};

export const SYSTEM_TAGS = {
  url: 'tagApi/tags/show/customer/:customerId',
  name: 'SYSTEM_TAGS',
  params: ['customerId'],
  method: 'GET'
  // requiredFor: ['main.customers.customersDetail'],
  // forcedBackFor: {
  //   'main.customers.customersDetail': ['main.customers.customers']
  // }
};

export const CUSTOMER_CARDS = {
  url: 'contactApi/customer/global/cards/:customerId',
  name: 'CUSTOMER_CARDS',
  params: ['customerId'],
  method: 'GET'
};

export const SEGMENTATION_CATEGORIES = {
  url: 'segChannelCategoryApi/channel/category?stats=true',
  method: 'GET',
  name: 'segmentationscategory',

  requiredFor: ['main.customers.segmentation', 'main.customers.segmentationList'],
  forcedBackFor: {
    'main.customers.segmentationList': ['main.customers.segmentation'],
    'main.customers.addSegmentation': ['main.customers.segmentation', 'main.customers.segmentationList']
  }
};

export const SEGMENTATION_LIST = {
  url: 'segmentationApi/segmentation?stats=yes&',
  method: 'GET',
  name: 'segmentations',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'SEGMENTATION_LIST',
  requiredFor: ['main.customers.segmentation', 'main.customers.segmentationList'],
  forcedBackFor: {
    'main.customers.segmentationList': ['main.customers.segmentation'],
    'main.customers.addSegmentation': ['main.customers.segmentation', 'main.customers.segmentationList']
  }
};
export const SEGMENTATION_CATEGORY = {
  url: 'segmentationApi/segmentation?stats=yes&',
  method: 'GET',
  name: 'boradCategory',
};

export const SEGMENTATION = {
  url: 'segmentationApi/segmentation/:id',
  method: 'GET',
  name: 'segmentation',
  params: ['id'],
  requiredFor: ['main.customers.updateSegmentation', 'main.customers.segmentationDetail'],
  forcedBackFor: {
    'main.customers.updateSegmentation': ['main.customers.segmentationDetail']
  }
};

export const products = {
  url: 'inventoryApi/products?prodType=["product"]&',
  method: 'GET',
  name: 'products',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  pagination: 'PRODUCTS_LIST',
};

export const regions = {
  url: 'businessRegionApi/business/region',
  method: 'GET',
  name: 'regions',
  requiredFor: ['main.customers.updateSegmentation'],
  forcedBackFor: {
    'main.customers.updateSegmentation': ['main.customers.segmentationDetail']
  }
};

export const exposeKeys = {
  url: 'exposedKeysApi/exposedKeys/segmentation',
  method: 'GET',
  name: 'exposeKeys',
  requiredFor: ['main.customers.updateSegmentation', 'main.customers.segmentationDetail'],
  forcedBackFor: {
    'main.customers.updateSegmentation': ['main.customers.segmentationDetail']
  }
};

// Passed for nav
export const DYNAMIC_CUSTOMERS = {
  url: 'contactApi/customers?type=list&',
  method: 'GET',
  name: 'dynamicCustomers',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }, {
      "field": "segments",
      "operator": "="
    }, {
      "field": "segmentsAdded",
      "operator": "!="
    }, {
      "field": "segmentsRestrict",
      "operator": "!="
    }],
    "sort": [],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: [false, 'id', 'id', 'id']
};

// Passed for nav
export const ADDED_CUSTOMERS = {
  url: 'contactApi/customers?type=list&',
  method: 'GET',
  name: 'addedCustomers',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }, {
      "field": "segments",
      "operator": "!="
    }, {
      "field": "segmentsAdded",
      "operator": "="
    }, {
      "field": "segmentsRestrict",
      "operator": "!="
    }],
    "sort": [],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: [false, 'id', 'id', 'id']
};

// Passed for nav
export const RESTRICTED_CUSTOMERS = {
  url: 'contactApi/customers?type=list&',
  method: 'GET',
  name: 'restrictedCustomers',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }, {
      "field": "segments",
      "operator": "!="
    }, {
      "field": "segmentsAdded",
      "operator": "!="
    }, {
      "field": "segmentsRestrict",
      "operator": "="
    }],
    "sort": [],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: [false, 'id', 'id', 'id']
};

// Passed for nav
export const SEGMENTATION_CUSTOMERS_SIDENAV = {
  url: 'contactApi/customers?type=list&',
  method: 'GET',
  name: 'customers',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      "field": "segments",
      "operator": "!="
    }, {
      "field": "segmentsRestrict",
      "operator": "!="
    }, {
      "field": "segmentsAdded",
      "operator": "!="
    }]
  },
  filterValue: [false, 'id', 'id', 'id'],
  pagination: 'CONTACT_LIST'
};

// Passed for nav
export const segmentationHistory = {
  url: 'activityHistoryApi/history',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_id",
      "operator": "="
    }, {
      "field": "function_name",
      "value": "segmentation",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id']
};

// Passed for nav
export const OPP_ACTION_LIST = {
  url: 'actionsApi/function/action',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "value": false,
      "operator": "="
    }, {
      "field": "modelId",
      "operator": "="
    }, {
      "field": "status",
      "value": 1,
      "operator": "="
    }, {
      "field": "modelName",
      "value": 45,
      "operator": "="
    }, {
      "field": "is_system",
      "value": true,
      "operator": "!="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }]
  },
  filterValue: [false, 'cardId'],
  pagination: 'ACTION_LIST',
  requiredFor: ['main.customers.opportunitiesActionList']
};

export const CUSTOMER_ACTION_LIST = {
  url: 'actionsApi/function/action',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "value": false,
      "operator": "="
    }, {
      "field": "modelId",
      "operator": "="
    }, {
      "field": "status",
      "value": 1,
      "operator": "="
    }, {
      "field": "modelName",
      "value": 10,
      "operator": "="
    }, {
      "field": "is_system",
      "value": true,
      "operator": "!="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }]
  },
  filterValue: [false, 'customerId'],
  pagination: 'ACTION_LIST',
  requiredFor: ['main.customers.customersActionList']
};

export const CUSTOM_FORM_QUOTE = {
  url: 'customFieldsApi/customFields?',
  name: 'CUSTOM_FORM_QUOTE',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'quotes_guider_form',
      operator: "="
    }, {
      field: "status",
      value: 2,
      operator: "="
    }]
  }
};

export const CUSTOM_FORM_CUSTOMER = {
  url: 'customFieldsApi/customFields?',
  name: 'CUSTOM_FORM_CUSTOMER',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'formTarget',
      value: 'contact_form',
      operator: "="
    }, {
      field: 'status',
      value: 2,
      operator: '='
    }]
  }
};

// Passed for nav
export const OPP_CARD_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'OPP_CARD_CUSTOM_FORM',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      operator: "="
    }, {
      field: "status",
      value: 2,
      operator: "="
    }]
  },
  filterValue: ['boardId']
};

// Passed for nav
export const ACTION_LIST_COUNT = {
  url: 'actionsApi/action/folder/overall/stat?',
  name: 'ACTION_LIST_COUNT',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "modelId",
      "operator": "="
    }, {
      "field": "modelName",
      "value": 45,
      "operator": "="
    }]
  },
  filterValue: ['cardId'],
};

export const CUSTOMER_ACTION_LIST_COUNT = {
  url: 'actionsApi/action/folder/overall/stat?',
  name: 'ACTION_LIST_COUNT',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "modelId",
      "operator": "="
    }, {
      "field": "modelName",
      "value": 10,
      "operator": "="
    }]
  },
  filterValue: ['customerId'],
};

export const RESOURCE_LIST_F = {
  url: 'resourceApi/resources/rnp/45/:cardId',
  name: 'resourcesF',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: 'in',
      value: [2]
    }],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  },
  params: ['cardId']
};

export const QUOTE_DETAIL = {
  url: "quoteApi/quotes/:quoteId",
  name: "contactDetail",
  method: 'GET',
  params: ['quoteId'],
};

export const CUSTOMER_SERVICE_HISTORY = {
  url: "contactApi/customers/history/:customerId",
  name: "CUSTOMER_SERVICE_HISTORY",
  method: 'GET',
  params: ['customerId'],
};

export const SALES_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'salesOrganization',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isSales",
      value: true,
      operator: "=",
    }]
  }
};

export const ASSIGNED_DASHBOARD = {
  url: 'dashboardApi/dashboard/resource/list?moduleName=45&',
  name: 'ASSIGNED_DASHBOARD',
  method: 'GET'
};

export const CUSTOMER_ASSIGNED_DASHBOARD = {
  url: 'dashboardApi/dashboard/resource/list?moduleName=10&',
  name: 'ASSIGNED_DASHBOARD',
  method: 'GET'
};

export const DASHBOARD_LIST = {
  url: 'dashboardApi/dashboard',
  name: 'DASHBOARD_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "moduleName",
      value: 45,
      operator: "=",
    }]
  }
};

export const CUSTOMER_DASHBOARD_LIST = {
  url: 'dashboardApi/dashboard',
  name: 'DASHBOARD_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "moduleName",
      value: 10,
      operator: "=",
    }]
  }
};

export const BLOCK_DETAIL = {
  url: 'blocksApi/block/:KBIBlockId',
  name: 'BLOCK_DETAIL',
  method: 'GET',
  params: ['KBIBlockId']
};

// export const REPORT_BLOCK = {
//   url: 'blocksApi/category/blocks?',
//   name: 'REPORT_BLOCK',
//   method: 'GET'
// };

// export const SALES_AGENT_LIST = {
//   url: 'resourceApi/resources',
//   name: 'salesagentlist',
//   method: 'GET',
//   filterCriteria: {
//     filters: [{
//         field: 'isArchived',
//         operator: '=',
//         value: false
//       },
//       {
//         field: 'status',
//         operator: '=',
//         value: 2
//       }, {
//         field: 'salesAgent',
//         value: true,
//         operator: "=",
//         type: "bool"
//       }
//     ]
//   }
// };

export const SALESAGENT = {
  url: 'resourceApi/resources/filter',
  name: 'salesagent',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "status",
      operator: "=",
      value: 2
    }, {
      field: "salesAgent",
      value: true,
      operator: "=",
      type: "bool"
    }],
    project: ["_id", "first_name", "last_name", "business_id", "images", "avatar"]
  }
};

export const leadStats = {
  url: 'leadGenerationApi/leadgeneration/search/stats',
  name: 'leadStats',
  method: 'GET'

};

export const BUSINESSSEARCH_LIST = {
  url: 'leadGenerationApi/leadgeneration/search/list',
  name: 'businesssearchlist',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }],
    "sort": [],
    skip: 0,
    limit: 15
  },
  pagination: 'BUSINESS_SEARCH_LIST'
};

export const BUSINESSSEARCH_DETAILS = {
  url: 'leadGenerationApi/leadgeneration/search/:searchId/details',
  name: 'businessSearchDetails',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }],
    "sort": [],
    skip: 0,
    limit: 15
  },
  params: ['searchId']
};

export const RECORDSFROM_SEARCH = {
  url: 'leadGenerationApi/leadgeneration/search/:searchId/places',
  name: 'recordsfromSearch',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "operator": "=",
      "value": false
    }],
    "sort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    skip: 0,
    limit: 15
  },
  params: ['searchId'],
  pagination: 'RECORDS_FROM_SEARCH'
};

export const ALL_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'ALL_ORGANIZATION',
  method: 'GET'
};

export const OPPORTUNITY_CARD_COMMUNICATION = {
  url: 'activityApi/activity/communication',
  name: 'communications',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "=",
    }, {
      field: "model",
      value: 45,
      operator: "="
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  filterValue: ['cardId'],
  onlyPagination: true
};

export const SEGMENTATION_FOR_CONVERSATION_LIST = {
  url: 'conversationChannelApi/conversation/segments',
  method: 'GET',
  name: 'segmentations',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
  // requiredFor: ['main.customers.segmentation', 'main.customers.segmentationList'],
  // forcedBackFor: {
  //   'main.customers.segmentationList': ['main.customers.segmentation'],
  //   'main.customers.addSegmentation': ['main.customers.segmentation', 'main.customers.segmentationList']
  // }
};

export const SEGMENTED_CONVERSATION_LIST = {
  url: 'conversationChannelApi/conversation/channel?stats=yes&',
  name: 'segmentedConversations',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const SEGMENTED_CONVERSATION = {
  url: 'conversationChannelApi/conversation/channel/:id',
  name: 'segment',
  method: 'GET',
  params: ['id']
};

export const SEGMENTED_CONVERSATION_NAVIGATION = {
  url: 'conversationChannelApi/conversation/channel/segment/nav/:segmentId',
  name: 'conversationNavigation',
  method: 'GET',
  params: ['segmentId'],
  requiredFor: ['main.customers.channelsPosts']
};

export const SEGMENTED_CONVERSATION_CUSTOMER_LIST = {
  url: 'conversationChannelApi/conversation/channel/segment/customers/:segmentId?stats=yes&',
  method: 'GET',
  name: 'customers',
  filterCriteria: {
    filters: [{
      field: 'status',
      operator: '!=',
      value: 3
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'CONTACT_LIST',
  params: ['segmentId'],
  requiredFor: ['main.customers.channelsCustomers']
};

export const SEGMENTED_CONVERSATION_REPORT_POST = {
  url: 'conversationChannelApi/conversation/channel/segment/bar/post/:segmentId',
  name: 'conversationReportPost',
  method: 'GET',
  params: ['segmentId'],
  requiredFor: ['main.customers.channelsReports']
};

export const SEGMENTED_CONVERSATION_REPORT_CUSTOMER = {
  url: 'conversationChannelApi/conversation/channel/segment/bar/cust/:segmentId',
  name: 'conversationReportCust',
  method: 'GET',
  params: ['segmentId'],
  requiredFor: ['main.customers.channelsReports']
};

export const SEGMENTED_CONVERSATION_REPORT_CHANNEL = {
  url: 'conversationChannelApi/conversation/channel/segment/pie/:segmentId',
  name: 'conversationReportChannel',
  method: 'GET',
  params: ['segmentId'],
  requiredFor: ['main.customers.channelsReports']
};

export const COMPANIES_LIST = {
  url: 'companyApi/company',
  method: 'GET',
  name: 'companies',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'COMPANY_LIST',
  requiredFor: ['main.customers.companies']
};

export const COMPANY_DETAIL = {
  url: 'companyApi/company/:id',
  method: 'GET',
  name: 'COMPANY_DETAIL',
  params: ['id']
};

export const COMPANY_ASSOCIATES = {
  url: 'companyApi/company/associates/:id?categoryId=:categoryId&',
  method: 'GET',
  name: 'COMPANY_ASSOCIATES',
  params: ['id', 'categoryId']
};

export const COMPANY_NURTURING = {
  url: 'companyApi/company/associates/nurturing/:id?categoryId=:categoryId&',
  method: 'GET',
  name: 'COMPANY_NURTURING',
  params: ['id', 'categoryId']
};

export const ALL_CAMPAIGN_CATEGORY = {
  url: 'campaignCategoryApi/campaign/category?',
  method: 'GET',
  name: 'ALL_CAMPAIGN_CATEGORY',
  filterCriteria: {
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  requiredFor: ['main.customers.nurturingCampaigns']
};
export const CAMPAIGN_CATEGORY = {
  url: 'campaignCategoryApi/campaign/category?stats=yes&',
  method: 'GET',
  name: 'campaignCategory',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'CAMPAIGN_CATEGORY_LIST',
  requiredFor: ['main.customers.nurturingCampaignsCategory']
};

export const CAMPAIGN_LIST = {
  url: 'campaignApi/campaign?stats=yes&',
  method: 'GET',
  name: 'campaign',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'CAMPAIGN_LIST',
  requiredFor: ['main.customers.nurturingCampaigns']
};

export const ALL_SEGMENTATION = {
  url: 'segmentationApi/segmentation?',
  method: 'GET',
  name: 'ALL_SEGMENTATION',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  }
};

export const CAMPAIGN_DETAIL = {
  url: 'campaignApi/campaign/:id',
  method: 'GET',
  name: 'CAMPAIGN_DETAIL',
  params: ['id']
};

export const ALL_CAMPAIGN_DRIP = {
  url: 'campaignDripApi/campaign/drip/:id?stats=yes&',
  method: 'GET',
  name: 'ALL_CAMPAIGN_DRIP',
  params: ['id']
};

export const CAMPAIGN_DRIP_DETAIL = {
  url: 'campaignDripApi/campaign/drip/:id/:dripId',
  method: 'GET',
  name: 'CAMPAIGN_DRIP_DETAIL',
  params: ['id', 'dripId']
};

export const EXPOSED_KEYS_TOKEN = {
  url: 'exposedKeysApi/exposedKeys/token',
  method: 'GET',
  name: 'EXPOSED_KEYS_TOKEN'
};

export const ALL_TEMPLATE_CATEGORIES = {
  url: 'templateBuilderApi/template/category',
  method: 'GET',
  name: 'ALL_TEMPLATE_CATEGORIES',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  }
};

export const RESOURCE_LIST = {
  url: 'resourceApi/resources',
  name: 'RESOURCE_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    sort: [{
      field: "firstName",
      order: 1
    }],
    project: ['images', 'avatar', 'first_name', 'middle_name', 'last_name', 'org_details.organizations.primary', 'org_details.organizations.secondary']
  }
};

export const CAMPAIGN_ACTION_LIST = {
  url: 'actionsApi/function/action',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }, {
      field: "modelId",
      operator: "="
    }, {
      field: "status",
      value: 1,
      operator: "="
    }, {
      field: "modelName",
      value: 69,
      operator: "="
    }, {
      field: "is_system",
      value: true,
      operator: "!="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  filterValue: [false, 'id'],
  pagination: 'ACTION_LIST',
  requiredFor: ['main.customers.campaignsActionList']
};

export const CAMPAIGN_ACTION_LIST_COUNT = {
  url: 'actionsApi/action/folder/overall/stat?',
  name: 'CAMPAIGN_ACTION_LIST_COUNT',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 69,
      operator: "="
    }]
  },
  filterValue: ['id'],
};

export const UNSUBSCRIBER_FORM_DETAIL = {
  url: 'campaignUnsubscribeApi/campaign/unsubscribe',
  method: 'GET',
  name: 'UNSUBSCRIBER_FORM_DETAIL',
};

export const campaignSubscriptionList = {
  url: 'campaignUnsubscribeApi/campaign/unsubscribe/list?stats=yes&',
  method: 'GET',
  name: 'campaignSubscriptionList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'CAMPAIGN_SUBSCRIPTION_LIST',
  requiredFor: ['main.customers.nurturingCampaigns']
};

export const CAMPAIGN_REPORT_PEFORMANCE = {
  url: 'campaignApi/campaign/report/:id',
  method: 'GET',
  name: 'CAMPAIGN_REPORT_PEFORMANCE',
  params: ['id']
};

export const CAMPAIGN_REPORT_STATUS = {
  url: 'campaignReportApi/report/campaign/pie/status/:id',
  method: 'GET',
  name: 'CAMPAIGN_REPORT_STATUS',
  params: ['id']
};

export const CAMPAIGN_REPORT_ENAGAGEMENT = {
  url: 'campaignReportApi/report/campaign/pie/engagement/:id',
  method: 'GET',
  name: 'CAMPAIGN_REPORT_ENAGAGEMENT',
  params: ['id']
};

export const CAMPAIGN_REPORT_USAGE = {
  url: 'campaignReportApi/report/campaign/pie/usage/:id',
  method: 'GET',
  name: 'CAMPAIGN_REPORT_USAGE',
  params: ['id']
};

export const CAMPAIGN_REPORT_SENT_ACTIVITES = {
  url: 'campaignReportApi/report/campaign/pie/sent/trend/:id',
  method: 'GET',
  name: 'CAMPAIGN_REPORT_SENT_ACTIVITES',
  params: ['id']
};

export const customerDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'customerDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 1,
      operator: "="
    }],
    // sort: [{
    //   field: "firstName",
    //   order: 1
    // }]
  },
  filterValue: ['customerId'],
  paramTypes: ['int'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const documentDetail = {
  url: 'assocDocApi/assoc/doc/:id',
  name: 'documentDetail',
  method: 'GET',
  params: ['id']
};

export const cardDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'cardDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 4,
      operator: "="
    }],
    // sort: [{
    //   field: "firstName",
    //   order: 1
    // }]
  },
  filterValue: ['cardId'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const customerAddressList = {
  url: 'additionalAddressApi/address/list/:customerId?type=1&',
  name: 'addressList',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  params: ['customerId'],
  onlyPagination: true,
  getSavedSettings: true

};

export const companyAddressList = {
  url: 'additionalAddressApi/address/list/:id?type=2&',
  name: 'addressList',
  method: 'GET',
  filterCriteria: {
    filters: [],
    sort: [],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  params: ['id'],
  onlyPagination: true,
  getSavedSettings: true
};

export const CUSTOMER_PRODUCTS_LIST = {
  url: 'serialNumbersApi/serial/contact/product/list',
  name: 'products',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: "isArchived",
        value: false,
        operator: "="
      },
      {
        field: "customerId",
        operator: "="
      },
      {
        field: "catType",
        operator: "=",
        value: "product"
      }, {
        field: "fulfillmentStatus",
        operator: "in",
        value: ["fully", "partial"]
      }
    ]
  },
  filterValue: [false, 'customerId'],
  paramTypes: [false, 'int'],
  pagination: 'CONTACT_PRODUCT_LIST'
};

export const CUSTOMER_MATERIALS_LIST = {
  url: 'serialNumbersApi/serial/contact/product/list',
  name: 'products',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }, {
      field: "customerId",
      operator: "="
    }, {
      field: "catType",
      operator: "=",
      value: "material"
    }, {
      field: "fulfillmentStatus",
      operator: "in",
      value: ["fully", "partial"]
    }]
  },
  filterValue: [false, 'customerId'],
  paramTypes: [false, 'int'],
  pagination: 'CONTACT_PRODUCT_LIST'
};