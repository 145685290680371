import customFormTemplate from './custom-form.html';
export default function(app) {
  app.directive('customForm', customForm);

  function customForm() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: customFormTemplate
    };
  }
}