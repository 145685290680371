import singleImageDisplayTemplate from './../../components/popup-view/single-image-display.html';
import multiImageDisplayTemplate from './../../components/popup-view/multiple-image-display.html';
import imageUploadTemplate from './../../components/popup-view/image-upload.html';
import imageMarkerTemplate from './../../components/popup-view/image-marker.html';

import {
  MarkerArea
} from 'markerjs';

export default function(app) {
  app.factory('imageService', imageService);

  function imageService($rootScope, $mdDialog, $q, $filter, messages, dataServices, sharedDataService, serverUrl, $mdSidenav, Upload, spinnerLoader, $timeout, $http, FILE_SIZES) {

    "ngInject";

    return {
      removeImage: removeImageByConfirm,
      imgToBase64: imgToBase64,
      showImagePopUp: showImagePopUp,
      initImageUpload: initImageUpload,
      showUploadImagePopUp: showUploadImagePopUp,
      showMultipleImagePopUp: showMultipleImagePopUp,
      isImageLoaded: isImageLoaded,
      showImageMarkerPopUp: showImageMarkerPopUp
    };

    function imgToBase64(im, imgVar) {
      if (im[imgVar] instanceof Object) {
        let reader = new FileReader();
        reader.readAsDataURL(im[imgVar]);
        reader.onload = function() {
          im[imgVar] = reader.result;
        };
        reader.onerror = function(error) {
          console.log('Error: ', error);
        };
      }
    }

    function isImageLoaded(imgUrl, returnBlob) {
      let deferred = $q.defer(),
        image = new Image();

      image.onload = function() {
        if (returnBlob) {
          let c = document.createElement("canvas");
          let ctx = c.getContext("2d");
          c.width = this.naturalWidth; // update canvas size to match image
          c.height = this.naturalHeight;
          ctx.drawImage(this, 0, 0); // draw in image
          c.toBlob(function(blob) { // get content as blob
            deferred.resolve(blob);
          });
        } else {
          deferred.resolve(true);
        }
      };

      image.onerror = function() {
        deferred.reject(false);
      };

      image.crossOrigin = "Anonymous";
      image.src = imgUrl;
      return deferred.promise;
    }

    function loadImage(vm, imgUrl) {
      $q.when(isImageLoaded(imgUrl)).then(function(response) {
        vm.loaded = true;
      }, function(reason) {
        vm.error = "Cannot load image";
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function showImagePopUp(ev, imgUrl, base64) {
      $mdDialog.show({
        controller: showSingleImageController,
        locals: {
          imgUrl: imgUrl,
          base64: base64
        },
        templateUrl: singleImageDisplayTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'ic',
        multiple: true
      });
    };

    function showSingleImageController(imgUrl, base64) {

      "ngInject";

      let ic = this;

      ic.imgUrl = imgUrl;
      ic.base64 = base64;

      if (base64) {
        ic.imgUrl = base64;
      } else if (imgUrl) {
        loadImage(ic, imgUrl);
      }

      ic.cancel = cancel;
    }

    function showMultipleImagePopUp(vm, ev, images, index) {
      $mdDialog.show({
        controller: showMultipleImageController,
        locals: {
          "vm": vm,
          "images": images,
          "index": index
        },
        templateUrl: multiImageDisplayTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'mc',
        multiple: true
      });
    };

    function showMultipleImageController(vm, images, index) {
      let mc = this;

      mc.images = images;
      mc.croppedImg = angular.isArray(vm.croppedImg) ? angular.copy(vm.croppedImg) : [];
      mc.removedImage = angular.isArray(vm.removedImage) ? angular.copy(vm.removedImage) : [];

      mc.loadImages = function(index) {
        mc.activeIndex = index;
        console.log(mc.images[index]);
        // if (!mc.croppedImg[index] && mc.images[index].webUrl) {
        //   mc.loaded = false;
        //   loadImage(mc, mc.images[index].webUrl);
        // }
      };

      mc.loadImages(index);

      mc.cancel = cancel;
    }

    function initImageUpload(vm, obj, img = 'images') {

      // Initialise image upload functions
      vm.removeImage = removeImageByConfirm;
      vm.showImagePopUp = showImagePopUp;
      vm.showUploadImagePopUp = showUploadImagePopUp;

      // Initialise image upload variables
      if (!vm[obj]) {
        vm[obj] = {};
      }
      vm[obj][img] = [];
      vm.croppedImg = [];
      vm.removedImage = [];
      vm.selectedIndex = [];
    }

    function removeImage(vm, obj, img, index, callback) {
      if (vm.croppedImg[index]) {
        vm.croppedImg[index] = undefined;
      }

      if (vm.initImg && vm.initImg[index] && vm.initImg[index].webUrl) {
        vm.removedImage[index] = true;
      }

      if (vm.removedAvatar) {
        vm[obj].avatar = vm.removedAvatar;
      }
      console.log('Data:', vm[obj]);

      if (angular.isFunction(callback)) {
        callback();
      }
    }

    function removeImageByConfirm(ev, vm, obj, img, index = 0, callback) {
      messages.mdconfirm(ev, "Are you sure to remove image? It will be removed at the time of save.")
        .then(function(result) {
          if (result) {
            removeImage(vm, obj, img, index, callback);
          }
        });
    }

    function showUploadImagePopUp(ev, vm, obj, img, index = 0, isAvatarEnabled = true, isCameraEnabled = true, isResizable, isJpegOnly) {

      $mdDialog.show({
        controller: imageUploadController,
        locals: {
          imgUrl: vm.removedImage[index] || !vm.initImg || vm.initImg && (!vm.initImg[index] || vm.initImg[index] && typeof vm.initImg[index] != "object") ? false : (vm.initImg[index] && vm.initImg[index].webUrl ? vm.initImg[index].webUrl : false),
          vm: vm,
          obj: obj,
          img: img,
          index: index,
          isAvatarEnabled: isAvatarEnabled,
          isCameraEnabled: isCameraEnabled,
          isResizable: isResizable,
          isJpegOnly: isJpegOnly
        },
        templateUrl: imageUploadTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'im',
        multiple: true
      });
    }

    function imageUploadController($scope, $q, $mdSidenav, imgUrl, vm, obj, img, index, isAvatarEnabled, isCameraEnabled, isResizable, isJpegOnly) {

      "ngInject";

      let im = this;
      $scope.vm = vm;

      im.accept = 'image/jpeg, image/png, image/gif';

      im.options = {
        viewport: {
          width: 200,
          height: 200,
          // width: 400,
          // height: 400,
          type: 'square'
        },
        boundary: {
          width: 270,
          height: 300
          // width: 500,
          // height: 500
        },
        showZoomer: true,
        mouseWheelZoom: true,
        enableResize: isResizable ? true : false,
        format: isJpegOnly ? 'jpeg' : 'png'
      };

      im.setSingleFileUpload = function() {
        im.isFromApp = sharedDataService.get('isFromApp');
        if (sharedDataService.get('isFromAndroidApp')) {
          AndroidInterface.setMultipleFileUploadFlag('NO');
        }
      };
      im.setSingleFileUpload();

      im.isAvatarEnabled = isAvatarEnabled;
      im.isCameraEnabled = isCameraEnabled;
      im.activeIndex = index;

      im.shortName = vm[obj].name ? $filter('imageAltText')(vm[obj].name) : (angular.isArray(vm.contacts) && vm.contacts[0] ? $filter('nameAbbreviation')(vm.contacts[0]) : '');

      im.avatarList = ['girl', 'boy', 'women', 'boy-specs', 'women-1', 'man', 'girl-2', 'man-specs', 'women-2', 'boy-1', 'girl-1', 'man-beard', 'women-3', 'man-halfbeard', 'girl-3'];

      im.setImage = function(imgUrl, imgObjName) {
        if (imgUrl) {
          spinnerLoader.start('image-upload-spinner');
          // Upload.urlToBlob(imgUrl).then(function(blob) {
          //   im[imgObjName] = blob;
          //   spinnerLoader.stop('image-upload-spinner');
          // });
          $q.when(isImageLoaded(imgUrl, true)).then(function(response) {
            im[imgObjName] = response;
            spinnerLoader.stop('image-upload-spinner');
          }, function(reason) {
            spinnerLoader.stop('image-upload-spinner');
            console.log(reason);
          });

          if (im.avatar) {
            im.removeAvatar();
          }
        } else {
          im[imgObjName] = im.fileOut = undefined;
          if (vm.removedAvatar) {
            im.avatar = vm.removedAvatar;
          }
        }
      };

      if (vm.croppedImg[index] || imgUrl) {
        // If avatar is not enabled for particular instance, upload tab index is 0, otherwise 1
        vm.selectedIndex[index] = im.isAvatarEnabled ? 1 : 0;
        // if (vm.croppedImg[index]) {
        //   im.files = Upload.dataUrltoBlob(vm.croppedImg[index], 'croppedFile');
        // } else {
        //   $timeout(function() {
        //     im.setImage(imgUrl, 'files');
        //   }, 0);
        // }

        $timeout(function() {
          im.setImage(vm.croppedImg[index] || imgUrl, 'files');
        }, 0);
      } else if (vm[obj].avatar) {
        vm.selectedIndex[index] = 0;
        im.avatar = im.avatarCopy = vm[obj].avatar;
      }

      im.uploadImageFile = function() {
        if (im.avatar) {
          vm[obj].avatar = im.avatar;
          removeImage(vm, obj, img, index);
          im.cancel();
        } else if (im.fileOut) {

          dataServices.upload({
              url: serverUrl.main + 'mediaApi/upload/base64',
              data: {
                data: angular.toJson([{
                  type: isJpegOnly ? 'jpeg' : 'png',
                  data: im.fileOut
                }])
              },
              spinnerName: 'image-upload-spinner'
            })
            .then(function(response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                // delete old image if the same image is edited and uploaded
                if (vm.initImg && vm.initImg[index] && vm.initImg[index].webUrl) {
                  removeImage(vm, obj, img, index);
                }
                // store new uploaded image in variables
                vm[obj][img][index] = data.result.success[0].fileId;
                // vm.croppedImg[index] = im.fileOut;
                vm.croppedImg[index] = data.result.success[0].webUrl;
                // clear avatar as image is uploaded for the index
                vm[obj].avatar = undefined;
                im.cancel();
                messages.simpleToast("Image uploaded.");
              }
            });

        } else {
          vm[obj].avatar = null;
          vm.croppedImg[index] = undefined;
          removeImage(vm, obj, img, index);
          messages.simpleToast("Image or Avatar is not set!");
          im.cancel();
        }
      };

      im.changeAvatar = function(index) {
        im.avatar = im.avatarList[index];
        im.setImage(false, im.files ? 'files' : 'bfiles');
      };

      im.removeAvatar = function() {
        vm.removedAvatar = im.avatar;
        im.avatar = undefined;
      };

      im.setShortName = function() {
        im.avatar = im.files = im.bfiles = im.fileOut = undefined;
      };

      im.onUpdate = function(data) {
        // console.log(data);
      };

      im.cameraOpened = undefined;
      im.toggleCamera = function(flag) {
        im.cameraOpened = flag;
        if (flag) {
          im.files = undefined;
        }
      };

      let shutter = new Audio();
      shutter.autoplay = false;
      shutter.src = require('_media/shutter' + (navigator.userAgent.match(/Firefox/) ? '.ogg' : '.mp3'));

      im.takeSnapshot = function() {
        shutter.play();
        $scope.makeSnapshot();
        im.toggleCamera(false);
      };

      let _video = null,
        patData = null;

      $scope.patOpts = {
        x: 0,
        y: 0,
        w: 25,
        h: 25
      };

      $scope.channel = {
        // the fields below are all optional
        videoHeight: 375,
        videoWidth: 500,
        video: null // Will reference the video element on success
      };

      $scope.webcamError = false;
      $scope.onError = function(err) {
        $scope.$apply(
          function() {
            $scope.webcamError = err;
          }
        );
      };

      $scope.onSuccess = function() {
        // The video element contains the captured camera data
        _video = $scope.channel.video;
        $scope.$apply(function() {
          $scope.patOpts.w = _video.width;
          $scope.patOpts.h = _video.height;
        });
      };

      $scope.onStream = function(stream) {
        // You could do something manually with the stream.
      };

      function getVideoData(x, y, w, h) {
        var hiddenCanvas = document.createElement('canvas');
        hiddenCanvas.width = _video.width;
        hiddenCanvas.height = _video.height;
        var ctx = hiddenCanvas.getContext('2d');
        ctx.drawImage(_video, 0, 0, _video.width, _video.height);
        return ctx.getImageData(x, y, w, h);
      };

      $scope.makeSnapshot = function() {
        if (_video) {
          var patCanvas = document.querySelector('#snapshot');
          if (!patCanvas) return;

          patCanvas.width = _video.width;
          patCanvas.height = _video.height;
          var ctxPat = patCanvas.getContext('2d');

          var idata = getVideoData($scope.patOpts.x, $scope.patOpts.y, $scope.patOpts.w, $scope.patOpts.h);
          ctxPat.putImageData(idata, 0, 0);

          showPreview(patCanvas.toDataURL());

          patData = idata;
        }
      };

      function showPreview(imgBase64) {
        im.files = Upload.dataUrltoBlob(imgBase64, 'cameraFile');
      };

      im.closePopup = function() {
        if (im.avatarCopy) {
          vm[obj].avatar = im.avatarCopy;
        }
      };

      im.onPicked = function(files, type) {

        if (type == 'dropbox') {
          im.setImage(files[0].link, 'files');
        } else if (type == 'googleDrive') {

          spinnerLoader.start('image-upload-spinner');
          $http({
            method: 'GET',
            url: "https://www.googleapis.com/drive/v3/files/" + files[0].id + '?alt=media',
            headers: {
              'Authorization': 'Bearer ' + $rootScope.gapiAccessToken
            },
            responseType: 'arraybuffer'
          }).then(function(response) {
            if (response.status == 200 && response.data) {
              im.files = new Blob([new Uint8Array(response.data)]);
            } else {
              messages.simpleToast('Technical Error! File cannot be processed!')
            }
            spinnerLoader.stop('image-upload-spinner');
          }, function(error) {
            messages.simpleToast('Technical Error! File downloading failed!')
            spinnerLoader.stop('image-upload-spinner');
          });
        }

      };

      let limit = 15,
        skip = 0,
        selectedCategory;
      im.mediaImages = [];
      im.repoCategories = ['All', 'Assigned', 'Unassigned', 'Tagged', 'Untagged'];
      im.selectedCategory = im.repoCategories[0];

      im.selectCategory = function(cat) {
        im.selectedCategory = cat;
        skip = 0;
        im.getRepoImagesByCategory();
      };

      im.getRepoImagesByCategory = function() {

        im.isListLoading = true;
        let filterCriteria = angular.toJson({
          "filters": [{
            "field": "isArchived",
            "operator": "=",
            "value": false
          }],
          "sort": [],
          "skip": skip,
          "limit": limit
        });

        dataServices.get({
            url: serverUrl.main + 'mediaApi/mediaRepository' + (im.selectedCategory ? ("?type=" + im.selectedCategory.toLowerCase() + "&") : "?") + 'filterCriteria=' + filterCriteria + '&',
            spinner: false
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              // im.mediaImages = data.result;
              if (selectedCategory != im.selectedCategory) {
                im.mediaImages = [];
              }
              $.merge(im.mediaImages, data.result);
              skip += limit;
              im.disablePagination = data.result.length < limit;
            }
            im.isListLoading = false;
            selectedCategory = im.selectedCategory;
          });
      };
      im.getRepoImagesByCategory();

      im.getMoreRepoImages = function() {
        if (im.disablePagination) {
          return;
        }
        im.getRepoImagesByCategory();
      };

      im.validate = function(file) {
        if (file.size > FILE_SIZES.max) {
          messages.simpleToast(`Maximum file upload size is ${FILE_SIZES.maxMB}`, 'warning');
          return false;
        } else if (file.size < FILE_SIZES.min) {
          messages.simpleToast(`Minimum file upload size is ${FILE_SIZES.minKB}`, 'warning');
          return false;
        }
        return true;
      };

      im.cancel = cancel;

      // im.toggleRight = function() {
      //   return $mdSidenav('mode').toggle();
      // };
      //
      // im.isCloseRight = function() {
      //   return $mdSidenav('mode').close();
      // };

    }

    function showImageMarkerPopUp(ev, vm, obj, img, index = 0) {

      $mdDialog.show({
        controller: imageMarkerController,
        locals: {
          imgUrl: vm.removedImage[index] || !vm.initImg || vm.initImg && (!vm.initImg[index] || vm.initImg[index] && typeof vm.initImg[index] != "object") ? false : (vm.initImg[index] && vm.initImg[index].webUrl ? vm.initImg[index].webUrl : false),
          vm: vm,
          obj: obj,
          img: img,
          index: index,
        },
        templateUrl: imageMarkerTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        controllerAs: 'im',
        multiple: true
      });
    }

    function imageMarkerController($scope, $mdSidenav, imgUrl, vm, obj, img, index) {

      "ngInject";

      let im = this;

      im.imageUrl = vm.croppedImg[index] || imgUrl;

      let mark, imgDataUrl;
      im.initMarker = function() {
        let img = document.getElementById('myImage');

        mark = new MarkerArea(img, {
          targetRoot: document.getElementsByClassName('image-marker')[0]
        });

        mark.show((dataUrl) => {
          img.src = imgDataUrl = dataUrl;
        });
      };

      im.saveImg = function() {
        if (imgDataUrl) {
          // imgDataUrl.replace(/^data\:image\/\w+\;base64\,/, '')
          dataServices.upload({
              url: serverUrl.main + 'mediaApi/upload/base64',
              data: {
                data: angular.toJson([{
                  type: 'png',
                  data: imgDataUrl
                }])
              },
              spinnerName: 'image-marker-spinner'
            })
            .then(function(response) {
              let data = response.data;
              if (data && data.response_code == 200) {
                vm[obj][img][index] = data.result.success[0].fileId;
                // vm.croppedImg[index] = imgDataUrl;
                vm.croppedImg[index] = data.result.success[0].webUrl;

                if (vm.initImg && vm.initImg[index] && vm.initImg[index].webUrl) {
                  vm.removedImage[index] = true;
                }

                im.cancel();
                messages.simpleToast("Image uploaded.");
              }
            });
        } else {
          im.cancel();
          messages.simpleToast("No changes are done to image!");
        }
      };

      im.cancel = cancel;

      // Close image marker
      $scope.$on('$destroy', function() {
        if (mark && $('.markerjs-toolbar').length) {
          mark.close();
        }
      });
    }
  }
}