import freedomFormTemplate from './freedom-form.html';
export default function(app) {
  app.directive('freedomForm', freedomForm)
    .controller("freedomFormController", freedomFormController);;

  function freedomForm() {
    return {
      scope: {
        vm: '='
      },
      restrict: 'E',
      templateUrl: freedomFormTemplate,
      controller: 'freedomFormController',
      controllerAs: 'fc'
    };
  }

  function freedomFormController($scope, $element, $attrs, $filter) {

    "ngInject";

    let fc = this;

    // $scope.isDisabled = $scope.vm.hasWriteAccess.form === false || !$scope.vm.formTab.action;
  }
}