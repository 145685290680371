export default function(app) {
  app.factory('cartDataService', cartDataService);

  let productInst = {
    attachService: {},
    promotion: {},
    instruction: {
      message: {},
      general: [],
      additions: {},
      preparation: {},
      notes: []
    }

  };

  function cartDataService(dataServices, serverUrl) {

    "ngInject";

    return {
      dataManipulation: dataManipulation,
      cartTotal: cartTotal,
      getCartList: getCartList,
      slideUpDown: slideUpDown
    };

    function init(vm) {
      //Used only in cart
      vm.globalJoinerId = vm.globalJoinerId || {};
      vm.globalJoinerIdArray = vm.globalJoinerIdArray || [];
      vm.selectProductTypeIds = vm.selectProductTypeIds || {};
    }
    // START : CART manipulation work
    function dataManipulation(vm, response, callback) {
      init(vm);
      // if (response.data && response.data.result && response.data.response_code == 200) {
      vm.cartList = [];
      vm.changesDetected = false;
      vm.removeServicesAndInstruction = {};
      vm.removeServicesAndInstructionCopy = {};
      vm.cartList = [];
      vm.cartListPackage = [];
      vm.cartListBundle = [];
      vm.cartItemQty = {};

      if (response.data.result.products.regular && response.data.result.products.regular.length) {
        vm.cartList = response.data.result.products.regular;
        // vm.removeServicesAndInstructionCopy = undefined;
        vm.removeServicesAndInstruction['regular'] = {};
        // START : If regular product is having attached service we are showing in cart also.we are showing checkbox if we attached service
        for (let i = 0; i < vm.cartList.length; i++) {
          let productId = vm.cartList[i].id;

          vm.cartItemQty[vm.cartList[i].id] = angular.copy(vm.cartList[i].number);

          vm.cartList[i].show = !(vm.cartList[i].flag != 'Unorderable');
          vm.removeServicesAndInstruction['regular'][productId] = {
            attachService: {},
            promotion: {},
            instruction: {
              message: {},
              general: [],
              additions: {},
              preparation: {},
              notes: []
            }
          };
          for (let k = 0; k < vm.cartList[i].serviceDetails.length; k++) {

            vm.removeServicesAndInstruction['regular'][productId].attachService[vm.cartList[i].serviceDetails[k].id] = (vm.cartList[i].serviceDetails[k].mandatory ? true : false);

          }

          for (let k = 0; k < vm.cartList[i].promotions.length; k++) {
            vm.removeServicesAndInstruction['regular'][productId].promotion[vm.cartList[i].promotions[k].code] = false;
          }

        }
        // console.log(vm.cartList);

        // END : If regular product is having attached service we are showing in cart also.we are showing checkbox if we attached service

        // START : If regular product is having product instruction attached to product we are anabling its flag to show checkbox in cart list.
        angular.forEach(productInst['instruction'], function(val, instructKeys) {
          for (let i = 0; i < vm.cartList.length; i++) {
            let productId = vm.cartList[i].id;
            let flag = true;
            if (vm.cartList[i].prodInstDtl && vm.cartList[i].prodInstDtl[instructKeys]) {
              if (instructKeys == 'general' && vm.cartList[i].prodInstDtl[instructKeys]) {
                for (let k = 0; k < vm.cartList[i].prodInstDtl[instructKeys].length; k++) {
                  vm.removeServicesAndInstruction['regular'][productId]['instruction'][instructKeys].push({
                    'globalId': vm.cartList[i].prodInstDtl[instructKeys][k][0].id,
                    'joinerId': vm.cartList[i].prodInstDtl[instructKeys][k][1].id
                  });
                  if (flag) {
                    console.log(vm.globalJoinerId);
                    vm.globalJoinerId[productId] = {};
                    flag = false;
                  }
                  vm.globalJoinerId[productId][vm.cartList[i].prodInstDtl[instructKeys][k][0].id] = false;
                  console.log("vm.globalJoinerId", vm.globalJoinerId);
                }
              } else {
                if (instructKeys == 'notes') {
                  vm.removeServicesAndInstruction['regular'][productId]['instruction'][instructKeys] = {
                    'id': true,
                    'value': vm.cartList[i].prodInstDtl[instructKeys]
                  };
                } else {
                  angular.forEach(vm.cartList[i].prodInstDtl[instructKeys], function(value, id) {
                    vm.removeServicesAndInstruction['regular'][productId]['instruction'][instructKeys][id] = false;
                  });
                }
              }
            }
          }
        });
        // END : If regular product is having product instruction attached to product we are anabling its flag to show checkbox in cart list.

        console.log("Remove", angular.copy(vm.removeServicesAndInstruction));

      }
      // console.log("vm.removeServicesAndInstruction", angular.copy(vm.removeServicesAndInstruction));

      if (response.data.result.products.bundle && response.data.result.products.bundle.length) {
        vm.cartListBundle = response.data.result.products.bundle;
        // console.log("vm.cartListBundle ", vm.cartListBundle);

        // START : If bundle product is having attached service we are showing in cart also.we are showing checkbox if we attached service
        vm.removeServicesAndInstruction['bundle'] = {};

        for (let i = 0; i < vm.cartListBundle.length; i++) {
          let bundleId = vm.cartListBundle[i].id;
          vm.cartItemQty[bundleId] = angular.copy(vm.cartListBundle[i].number);

          vm.cartListBundle[i].show = !(vm.cartListBundle[i].flag != 'Unorderable');

          vm.removeServicesAndInstruction['bundle'][bundleId] = {
            promotion: {}
          };

          // START  : If bundle product is having promotion attached to product we are anabling its flag to show checkbox in cart list.
          for (let l = 0; l < vm.cartListBundle[i].promotions.length; l++) {
            vm.removeServicesAndInstruction['bundle'][bundleId].promotion[vm.cartListBundle[i].promotions[l].code] = false;
          }
          // END  : If bundle product is having promotion attached to product we are anabling its flag to show checkbox in cart list.

          // END : If bundle product is having product instruction and service attached to product we are anabling its flag to show checkbox in cart list.
          for (let j = 0; j < vm.cartListBundle[i].items.length; j++) {

            let product = vm.cartListBundle[i].items[j];
            let prodId = product.itemId;
            vm.removeServicesAndInstruction['bundle'][bundleId][prodId] = {};
            vm.removeServicesAndInstruction['bundle'][bundleId][prodId] = {
              attachService: {},
              // promotion: {},
              instruction: {
                message: {},
                general: [],
                additions: {},
                preparation: {},
                notes: []
              }
            };

            // START : If bundle product is having service attached to product we are anabling its flag to show checkbox in cart list.
            for (let l = 0; l < vm.cartListBundle[i].items[j].serviceDetails.length; l++) {
              vm.removeServicesAndInstruction['bundle'][bundleId][prodId].attachService[vm.cartListBundle[i].items[j].serviceDetails[l].id] = (vm.cartListBundle[i].items[j].serviceDetails[l].mandatory ? true : false);
            }
            // END : If bundle product is having service attached to product we are anabling its flag to show checkbox in cart list.

            // START : If bundle product is having product instruction to product we are anabling its flag to show checkbox in cart list.
            angular.forEach(productInst['instruction'], function(val, instructKeys) {
              let flag = true;

              if (product.prodInstDtl && product.prodInstDtl[instructKeys]) {

                if (instructKeys == 'general' && product.prodInstDtl[instructKeys]) {
                  // START : Global and joiner type product instruction attached and deattached in a group so we are making it in GENERAL.
                  for (let k = 0; k < product.prodInstDtl[instructKeys].length; k++) {
                    vm.removeServicesAndInstruction['bundle'][bundleId][prodId]['instruction'][instructKeys].push({
                      'globalId': product.prodInstDtl[instructKeys][k][0].id,
                      'joinerId': product.prodInstDtl[instructKeys][k][1].id
                    });
                    if (flag) {
                      vm.globalJoinerId[bundleId] = {};
                      flag = false;
                    }
                    vm.globalJoinerId[bundleId][product.prodInstDtl[instructKeys][k][0].id] = false;
                    // console.log("vm.globalJoinerId", vm.globalJoinerId);
                  }
                } else {
                  if (instructKeys == 'notes') {
                    // START : if instruction type is NOTE then we are assign it directly. Because note is a single instruction.
                    vm.removeServicesAndInstruction['bundle'][bundleId][prodId]['instruction'][instructKeys] = {
                      'id': true,
                      'value': product.prodInstDtl[instructKeys]
                    };
                    // END : if instruction type is NOTE then we are assign it directly. Because note is a single instruction.

                  } else {
                    // START : If instruction is not the type of NOTE then it can be multiple.
                    angular.forEach(product.prodInstDtl[instructKeys], function(value, id) {
                      vm.removeServicesAndInstruction['bundle'][bundleId][prodId]['instruction'][instructKeys][id] = false;
                    });
                    // END : If instruction is not the type of NOTE then it can be multiple.

                  }
                }
              }
            });
            // END : If bundle product is having product instruction to product we are anabling its flag to show checkbox in cart list.

          }
          // END : If bundle product is having product instruction and service attached to product we are anabling its flag to show checkbox in cart list.
        }
        console.log("vm.removeServicesAndInstruction", angular.copy(vm.removeServicesAndInstruction));
      }

      if (response.data.result.products.package && response.data.result.products.package.length) {
        vm.cartListPackage = response.data.result.products.package;
        vm.removeServicesAndInstruction['package'] = {};

        // console.log("457");

        for (let i = 0; i < vm.cartListPackage.length; i++) {
          let packageProductSkuId = vm.cartListPackage[i].id;
          vm.cartItemQty[packageProductSkuId] = angular.copy(vm.cartListPackage[i].number);

          vm.cartListPackage[i].show = !(vm.cartListPackage[i].flag != 'Unorderable');

          // console.log(packageProductSkuId);
          vm.removeServicesAndInstruction['package'][packageProductSkuId] = {
            promotion: {}
          };

          // START  : If Package product is having promotion attached to product we are anabling its flag to show checkbox in cart list.
          for (let l = 0; l < vm.cartListPackage[i].promotions.length; l++) {
            vm.removeServicesAndInstruction['package'][packageProductSkuId].promotion[vm.cartListPackage[i].promotions[l].code] = false;
          }
          console.log(vm.removeServicesAndInstruction['package'][packageProductSkuId].promotion);
          // END  : If Package product is having promotion attached to product we are anabling its flag to show checkbox in cart list.

          angular.forEach(vm.cartListPackage[i].catalogDetails, function(detail, catalogId) {
            console.log(catalogId);
            vm.cartListPackage[i]['itemCatalog'] = vm.cartListPackage[i]['itemCatalog'] || {};
            vm.cartListPackage[i]['itemCatalog'][catalogId] = [];

            for (let j = 0; j < vm.cartListPackage[i].items.length; j++) {

              if (catalogId == vm.cartListPackage[i].items[j].catalogId) {
                vm.cartListPackage[i].items[j]['catalogName'] = detail.name;
                vm.cartListPackage[i]['itemCatalog'][catalogId].push(vm.cartListPackage[i].items[j]);
              }
            }

          });
          console.log(vm.cartListPackage[i]['itemCatalog']);

          // END : If bundle product is having product instruction and service attached to product we are anabling its flag to show checkbox in cart list.
          angular.forEach(vm.cartListPackage[i].itemCatalog, function(product, catalogId) {

            vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId] = {};

            for (let i = 0; i < product.length; i++) {
              let prodId = product[i].productId;

              console.log(product);

              vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId] = {};
              vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId] = {
                attachService: {},
                instruction: {
                  message: {},
                  general: [],
                  additions: {},
                  preparation: {},
                  notes: []
                }
              };

              for (let j = 0; j < product[i].serviceDetails.length; j++) {
                vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId].attachService[product[i].serviceDetails[j].id] = (product[i].serviceDetails[j].mandatory ? true : false);
              }

              console.log(angular.toJson(vm.removeServicesAndInstruction['package']));

              angular.forEach(productInst['instruction'], function(val, instructKeys) {
                let flag = true;

                if (product[i].prodInstDtl && product[i].prodInstDtl[instructKeys]) {
                  console.log("286 HERE");
                  if (instructKeys == 'general' && product[i].prodInstDtl[instructKeys]) {
                    for (let k = 0; k < product[i].prodInstDtl[instructKeys].length; k++) {

                      console.log(product[i].prodInstDtl[instructKeys][k]);

                      vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId]['instruction'][instructKeys].push({
                        'globalId': product[i].prodInstDtl[instructKeys][k][0].id,
                        'joinerId': product[i].prodInstDtl[instructKeys][k][1].id
                      });
                      if (flag) {
                        vm.globalJoinerId[packageProductSkuId] = {};
                        flag = false;
                      }
                      vm.globalJoinerId[packageProductSkuId] = vm.globalJoinerId[packageProductSkuId] || {};

                      vm.globalJoinerId[packageProductSkuId][catalogId] = vm.globalJoinerId[packageProductSkuId][catalogId] || {};

                      vm.globalJoinerId[packageProductSkuId][catalogId][prodId] = vm.globalJoinerId[packageProductSkuId][catalogId][prodId] || {};

                      // vm.globalJoinerId[packageProductSkuId][catalogId][prodId]['global'] = vm.globalJoinerId[packageProductSkuId][catalogId][prodId]['global'] || {};

                      vm.globalJoinerId[packageProductSkuId][catalogId][prodId][product[i].prodInstDtl[instructKeys][k][0].id] = false;

                      console.log(vm.globalJoinerId[packageProductSkuId]);
                    }
                  } else {
                    if (instructKeys == 'notes') {
                      vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId]['instruction'][instructKeys] = {
                        'id': true,
                        'value': product[i].prodInstDtl[instructKeys]
                      };
                    } else {

                      angular.forEach(product[i].prodInstDtl[instructKeys], function(value, id) {
                        vm.removeServicesAndInstruction['package'][packageProductSkuId][catalogId][prodId]['instruction'][instructKeys][id] = false;
                      });
                    }
                  }
                }
              });
            }
          });
          console.log("cartListPackage", vm.cartListPackage);
          // END : If bundle product is having product instruction and service attached to product we are anabling its flag to show checkbox in cart list.
        }

        console.log(vm.removeServicesAndInstruction['package']);
      }

      // console.log("vm.removeServicesAndInstruction", vm.removeServicesAndInstruction);
      console.log("vm.cartList", vm.cartList);
      console.log("vm.cartListBundle", vm.cartListBundle);
      console.log("vm.cartListPackage", vm.cartListPackage);
      vm.cartTotalItem = (vm.cartList.length + vm.cartListBundle.length + vm.cartListPackage.length);
      console.log("cartTotalItem", vm.cartTotalItem);
      // START : We are making copy ot product instruction and attached service. If user changes in service and product instruction then we can compare to detect what changes user do.
      vm.removeServicesAndInstructionCopy = angular.copy(vm.removeServicesAndInstruction);
      // END : We are making copy ot product instruction and attached service. If user changes in service and product instruction then we can compare to detect what changes user do.
      if (callback) {
        callback();
      }
    }

    // END : CART manipulation work

    function cartTotal(vm) {
      console.log(vm);
      vm.isLoading = true; 
      console.log(vm.serviceId)
      dataServices.get({
        url: serverUrl.main + 'quoteInventoryApi/quote/products/cart/price/' + vm.quoteId + '/' + vm.serviceId + '?prodType=["' + vm.stateName + '"]&',
        spinner: false
      }).then(function(response) {
        if (response.data && response.data.result) {
          vm.cartTotalDetail = response.data.result;
          console.log(vm.cartTotalDetail)
        }
      }).catch(() => {
        vm.isLoading = false;
      })
    }

    // START : Get cart item list
    function getCartList(vm, callback) {
      console.log(vm)
      console.log(vm.serviceId)
      dataServices.get({
        url: serverUrl.main + 'quoteInventoryApi/quotes/' + vm.quoteId + '/services/' + vm.serviceId + '/products?prodType=["' + vm.stateName + '"]&',
        spinnerName: 'cart-list'
      }).then(function(response) {
        if (response.data && response.data.result && response.data.response_code == 200) {
          dataManipulation(vm, response, vm.activeInactiveClass);
          cartTotal(vm);
          if (callback) {
            callback();
          }
        }
      });
    }
    // END : Get cart item list

    function slideUpDown() {
      $('.hidden-layer').slideToggle()
      // vm.showCostDetail = !vm.showCostDetail
    }
  }
}
