export const ASSIGNED_DASHBOARD = {
  url: 'dashboardApi/dashboard/resource/list?moduleName=99&',
  name: 'ASSIGNED_DASHBOARD',
  method: 'GET'
};

export const SALESAGENT = {
  url: 'resourceApi/resources/filter',
  name: 'salesagent',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "status",
      operator: "=",
      value: 2
    }, {
      field: "salesAgent",
      value: true,
      operator: "=",
      type: "bool"
    }],
    project: ["_id", "first_name", "last_name", "business_id", "images", "avatar"]
  }
};

export const DASHBOARD_DASHBOARD_LIST = {
  url: 'dashboardApi/dashboard',
  name: 'DASHBOARD_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "moduleName",
      value: 99,
      operator: "=",
    }]
  }
};

export const BLOCK_DETAIL = {
  url: 'blocksApi/block/:KBIBlockId',
  name: 'BLOCK_DETAIL',
  method: 'GET',
  params: ['KBIBlockId']
};

export const ACTIVITY = {
  url: 'activityBoardApi/activity/board/:id',
  name: 'activity',
  method: 'GET',
  params: ['id']
};

export const ACTIVITY_BOARDS = {
  url: 'activityBoardApi/activity/board',
  name: 'activityBoards',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: "seqId",
      order: 1
    }]
  }
};

export const SALES_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'salesOrganization',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isSales",
      value: true,
      operator: "=",
    }]
  }
};
