import {
  baseUrl,
  aucUrl,
  gServerUrl,
  gServerCommonAPIUrl,
  domainAddress,
  webSocketPort,
  emailBuilderUrl
} from "./../../env.js";

export default function(app) {
  app.constant("serverUrl", {
    domainAddress: domainAddress,
    base: baseUrl,
    auc: baseUrl + aucUrl,
    main: baseUrl + gServerUrl,
    common: baseUrl + gServerCommonAPIUrl,
    webSocketPort: webSocketPort,
    emailBuilderUrl: emailBuilderUrl
  });
}