export default function(app) {
  app.factory('offlineService', offlineService);

  function offlineService(serverUrl) {

    "ngInject";

    return {
      initiateChecks: initiateChecks
    };

    // initiate offline check and display warning
    function initiateChecks() {
      if (Offline) {
        Offline.options = {

          // Call this api to check connection
          checks: {
            xhr: {
              url: 'https://www.omdbapi.com/?t=a'
            }
          },

          // Should we check the connection status immediatly on page load.
          checkOnLoad: false,

          // Should we monitor AJAX requests to help decide if we have a connection.
          interceptRequests: false,

          // Should we store and attempt to remake requests which fail while the connection is down.
          requests: false,

          // Should we automatically retest periodically when the connection is down (set to false to disable).
          reconnect: {
            // How many seconds should we wait before rechecking.
            initialDelay: 3
          }

        };

        Offline.on('confirmed-up', function() {
          console.log('internet up');
        });

        Offline.on('confirmed-down', function() {
          console.log('internet down');
        });
      }
    }

  }

}