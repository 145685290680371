const mimeTypes = {
  'zip': 'application/zip',
  'xls': 'application/vnd.ms-excel',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'txt': 'text/plain',
  'pdf': 'application/pdf',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'csv': 'text/csv',
  'jpeg': 'image/jpeg',
  'jpg': 'image/jpeg',
  'png': 'image/png',
  'gif': 'image/gif'
};

const fileTypes = {
  'zip': {
    'zip': mimeTypes.zip
  },
  'doc': {
    'xls': mimeTypes.xls,
    'xlsx': mimeTypes.xlsx,
    'txt': mimeTypes.txt,
    'pdf': mimeTypes.pdf,
    'doc': mimeTypes.doc,
    'docx': mimeTypes.docx,
    'csv': mimeTypes.csv
  },
  'img': {
    'jpeg': mimeTypes.jpeg,
    'jpg': mimeTypes.jpg,
    'png': mimeTypes.png,
    'gif': mimeTypes.gif
  }
};

const fileSizes = {
  max: 1024 * 1024 * 30,
  min: 10240,
  maxMB: '30MB',
  minKB: '10KB'
}

export default function(app) {
  app
    .constant('MIME_TYPES', mimeTypes)
    .constant('FILE_TYPES', fileTypes)
    .constant('FILE_SIZES', fileSizes);
}