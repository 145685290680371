function RightCtrl($scope, $timeout, $mdSidenav, $log, $window) {
  "ngInject";
  $scope.close = function(id) {
    if (!id) {
      id = 'right';
    }

    $mdSidenav(id).close()
      .then(function() {
        $log.debug("close RIGHT is done", id);
      });
  };

  var sidenavHeight = $window.innerHeight;
  $scope.contentHeight = sidenavHeight - 275;
}
export default RightCtrl;