import bulkActionOperationsTemplate from './bulk-action-operations.html';
export default function(app) {
  app.directive('bulkActionOperations', bulkActionOperations);

  function bulkActionOperations() {
    return {
      restrict: 'E',
      templateUrl: bulkActionOperationsTemplate,
      // replace: true
    };
  }
}