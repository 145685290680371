export default function(app) {
  app.directive('updateInfo', updateInfo);

  function updateInfo() {
    return {
      restrict: 'E',
      scope: {
        item: '='
      },
      replace: true,
      template: '<div><span class="f3 sm-text" translate>UPDATED_BY</span>\
      <a ui-sref="main.resources.resourcesDetails({resourceId: item.updatedBy})">{{item.updatedByINFO.firstName}} {{item.updatedByINFO.lastName}}, </a>\
      <span class="f3 sm-text">{{item.lastUpdated | normalFormDate3}}</span></div>'
    };
  }
}