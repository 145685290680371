import confirmDialogTemplate from './../../components/popup-view/confirm-dialog.html';

export default function(app) {
  app.factory('unsavedChangesService', unsavedChangesService);

  function unsavedChangesService($mdDialog) {

    "ngInject";

    return {
      isFormChanged: isFormChanged,
      areFormsChanged: areFormsChanged,
      confirm: confirm
    };

    /**
     *  Return boolean value based on whether form is changed(form fields are changed)
     *
     *  vm: reference of current controller instance
     */
    function isFormChanged(vm, formName = 'myForm') {
      return function() {
        return vm[formName] && vm[formName].$dirty;
      };
    }

    /**
     *  Return boolean value based on whether forms are changed(form fields are changed)
     *
     *  vm: reference of current controller instance
     *  forms: array fo forms to be checked against changes
     */
    function areFormsChanged(vm, forms) {
      return function() {
        let len = forms.length;
        for (let i = 0; i < len; i++) {
          if (typeof forms[i] == 'string') {
            if (vm[forms[i]] && vm[forms[i]].$dirty) {
              return true;
            }
          } else if (typeof forms[i] == 'object' && forms[i].array) {
            let len = vm[forms[i].array].length;
            for (let j = 0; j < len; j++) {
              if (vm[forms[i]][j] && vm[forms[i]][j].$dirty) {
                return true;
              }
            }
          }
        }
        return false;

      };
    }

    function confirm(info) {
      $mdDialog.show({
        locals: {
          info
        },
        controller: confirmDialogController,
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        controllerAs: 'cd'
      });
    };

    function confirmDialogController($mdDialog, info) {

      let cd = this;

      cd.description = 'Unsaved changes!';
      cd.question = info.question;

      cd.confirm = function() {
        $mdDialog.cancel();
        if (angular.isFunction(info.successHandler)) {
          info.successHandler();
        }
      };

      cd.cancel = function() {
        $mdDialog.cancel();
      };
    }
  }
}