export default function(app) {
  app.factory('dateFactory', dateFactory);

  function dateFactory() {

    "ngInject";
    let receivedTimeItems = ['Now', 'One week ago', 'Two weeks ago', 'Three weeks ago', 'One month ago', 'Two months ago', 'Three months ago', 'Six months ago', 'Nine months ago', 'One year ago', 'Two years ago', 'Past'];

    return {
      toUTCTimeZone: toUTCTimeZone,
      toLocalTimeZone: toLocalTimeZone,
      startingDayEpoche: startingDayEpoche,
      endingDayEpoche: endingDayEpoche,
      getMonthStartEndDayEpoche: getMonthStartEndDayEpoche,
      getNextDayEpoch: getNextDayEpoch,
      getWeekStartEndDayEpoch: getWeekStartEndDayEpoch,
      getDayStartEndDayEpoch: getDayStartEndDayEpoch,
      getMinutetoString: getMinutetoString,
      getStringtoMinute: getStringtoMinute,
      checkTimeForStart: checkTimeForStart,
      checkTimeForEnd: checkTimeForEnd,
      getTimeOffset: getTimeOffset,
      changeStartDate: changeStartDate,
      convertToEpoche: convertToEpoche,
      getEpoche: getEpoche,
      getReceivedTime: getReceivedTime,
      getEpocheRanges: getEpocheRanges
    };

    function getEpoche(dateObj) {
      return angular.isDate(dateObj) ? Math.trunc(dateObj.getTime() / 1000) : null;
    }

    function convertToEpoche(dateObj, obj, key) {
      if (angular.isDate(dateObj)) {
        obj[key] = Math.trunc(dateObj.getTime() / 1000);
      } else {
        obj[key] = null;
      }
    }

    function changeStartDate(obj, startDate, endDate) {
      if (obj[endDate] < obj[startDate]) {
        obj[endDate] = obj[startDate];
      }
    }

    function pad(value) {
      return value < 10 ? '0' + value : value;
    }

    function getTimeOffset() {
      let date = new Date();
      let sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
      let offset = Math.abs(date.getTimezoneOffset());
      let hours = pad(Math.floor(offset / 60));
      let minutes = pad(offset % 60);
      return sign + hours + '' + minutes;
    }

    // convert date object to UTC Zero Epoche - (GMT) Western Europe Time, London, Lisbon, Casablanca
    function toUTCTimeZone(date) {
      return date ? Math.floor((date.getTime() - (date.getTimezoneOffset() * 60000)) / 1000) : undefined;
    }

    // convert UTC Zero Epoche to date object - (GMT) Local Time Zone
    function toLocalTimeZone(epoche) {
      if (!angular.isNumber(epoche)) return;
      let d = new Date(epoche * 1000);
      return new Date(d.getTime() - (d.getTimezoneOffset() * 60000));
    }

    function checkTimeForEnd(vm, timeKey) {
      if (!vm.endTime || !vm.startTime)
        return;
      else {
        let startDate = new Date(vm.startDate).setHours(0, 0, 0, 0),
          endDate = new Date(vm.endDate).setHours(0, 0, 0, 0),
          startTimeSplit = vm.startTime.split(":"),
          endTimeSplit = vm.endTime.split(":");
        // This condition will apply when, start date and end date should be equal.
        // startTime greater then endTime
        // Or startTime hour equal to endTime hour then check minute startTime and minute endTime
        if (startDate == endDate && parseInt(startTimeSplit[0]) > parseInt(endTimeSplit[0]) || (parseInt(startTimeSplit[0]) == parseInt(endTimeSplit[0]) && parseInt(startTimeSplit[1]) > parseInt(endTimeSplit[1])))
          vm.startTime = vm.endTime;
      }
    }

    function checkTimeForStart(vm, timeKey) {
      if (!vm.endTime || !vm.startTime) {
        return;
      } else {
        let startDate = new Date(vm.startDate).setHours(0, 0, 0, 0),
          endDate = new Date(vm.endDate).setHours(0, 0, 0, 0),
          startTimeSplit = vm.startTime.split(":"),
          endTimeSplit = vm.endTime.split(":");
        // Todo: add comment.
        if (startDate == endDate && parseInt(startTimeSplit[0]) > parseInt(endTimeSplit[0]) || (parseInt(startTimeSplit[0]) == parseInt(endTimeSplit[0]) && parseInt(startTimeSplit[1]) > parseInt(endTimeSplit[1]))) {
          vm.endTime = vm.startTime;
        }
      }
    }

    function getStringtoMinute(string) {
      let splitTime = string.split(':');
      return (parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]));
    }

    function getMinutetoString(minute) {
      let hrs = minute / 60;
      let hr = parseInt(hrs);
      let min = (hrs - parseInt(hrs)) * 60;
      min = (min < 10 ? '0' : '') + min;
      hr = (hr < 10 ? '0' : '') + hr;
      return hr + ':' + min;
    }

    function startingDayEpoche(epoche) {

    }

    function endingDayEpoche(epoche) {

    }

    function getMonthStartEndDayEpoche(currentDate, ifCurrentDayIsLastDay) {
      let d = currentDate.getDate();
      let m = currentDate.getMonth();
      let y = currentDate.getFullYear();
      let lastDay = new Date(y, m + 1, 0);
      console.log(lastDay);

      return [new Date(y, m, 1).getTime(), new Date(y, m + 1, 1).getTime()];
    }

    function getNextDayEpoch(currentDate) {
      let d = currentDate.getDate();
      let m = currentDate.getMonth();
      let y = currentDate.getFullYear();
      return [new Date(y, m, d + 1).setHours(0, 0, 0, 0), new Date(y, m, d + 1).setHours(23, 59, 59, 999)];
    }

    function getWeekStartEndDayEpoch(cDate) {
      let currentDate = new Date(cDate.setHours(0, 0, 0, 0));
      let first = currentDate.getDate() - currentDate.getDay(); // First day is the day of the month - the day of the week
      let last = first + 7; // last day is the first day + 6
      return [currentDate.setDate(first), currentDate.setDate(first + 7)];
    }

    function getDayStartEndDayEpoch(currentDate) {
      console.log(currentDate);
      let d = currentDate.getDate();
      let m = currentDate.getMonth();
      let y = currentDate.getFullYear();
      let lastDay = new Date(y, m, d + 1);
      return [currentDate.setHours(0, 0, 0, 0), lastDay.getTime()];
    }

    function getEpocheRanges() {
      let oneDay = 86400, //24 * 60 * 60,
        oneWeek = 7 * oneDay,
        oneMonth = 30 * oneDay,
        oneYear = 365 * oneDay;

      let d = new Date();
      d.setHours(23, 59, 59, 1000);

      let epoche = Math.trunc(d.getTime() / 1000);

      return {
        // 'Future': [epoche],
        'Now': [epoche, epoche - oneWeek],
        'One week ago': [epoche - oneWeek, epoche - (oneWeek * 2)],
        'Two weeks ago': [epoche - (oneWeek * 2), epoche - (oneWeek * 3)],
        'Three weeks ago': [epoche - (oneWeek * 3), epoche - oneMonth],
        'One month ago': [epoche - oneMonth, epoche - (oneMonth * 2)],
        'Two months ago': [epoche - (oneMonth * 2), epoche - (oneMonth * 3)],
        'Three months ago': [epoche - (oneMonth * 3), epoche - (oneMonth * 6)],
        'Six months ago': [epoche - (oneMonth * 6), epoche - (oneMonth * 9)],
        'Nine months ago': [epoche - (oneMonth * 9), epoche - oneYear],
        'One year ago': [epoche - oneYear, epoche - (oneYear * 2)],
        'Two years ago': [epoche - (oneYear * 2), epoche - (oneYear * 3)],
        'Past': [epoche - (oneYear * 3)]
      };
    }

    function getReceivedTime(communication) {
      let receivedTime, epocheRanges = getEpocheRanges();
      if (communication.createdAt < epocheRanges.Past[0]) {
        receivedTime = 'Past';
      } else {
        let len = receivedTimeItems.length;
        for (let i = 0; i < len - 1; i++) {
          if (communication.createdAt <= epocheRanges[receivedTimeItems[i]][0] && communication.createdAt > epocheRanges[receivedTimeItems[i]][1]) {
            receivedTime = receivedTimeItems[i];
            break;
          }
        }
      }

      communication.receivedTime = receivedTime;
      return receivedTime;
    };

  }
}