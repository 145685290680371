export default function(app) {
  app.constant('emojis', [{
    code: '✍',
    name: 'Writing Hand'
  }, {
    code: '✌',
    name: 'Victory'
  }, {
    code: '☝',
    name: 'Index Pointing Up'
  }, {
    code: '👍',
    name: 'Yes'
  }, {
    code: '👎',
    name: 'No'
  }, {
    code: '👌',
    name: 'Ok'
  }, {
    code: '👊',
    name: 'Fist'
  }, {
    code: '👋',
    name: 'Good bye'
  }, {
    code: '👐',
    name: 'openness'
  }, {
    code: '👆',
    name: 'Index Pointing Up'
  }, {
    code: '👇',
    name: 'Index Pointing Down'
  }, {
    code: '👉',
    name: 'Backhand Index Pointing Right'
  }, {
    code: '👈',
    name: 'Backhand Index Pointing Left'
  }, {
    code: '🙌',
    name: 'Celebration'
  }, {
    code: '🙏',
    name: 'Praying'
  }, {
    code: '👏',
    name: 'Clapping'
  }, {
    code: '💪',
    name: 'Muscles'
  }, {
    code: '😄',
    name: 'Happy Eyes'
  }, {
    code: '😊',
    name: 'Smile Eyes'
  }, {
    code: '😃',
    name: 'Happy Face'
  }, {
    code: '😉',
    name: 'Wink'
  }, {
    code: '😍',
    name: 'Heart-Eyes'
  }, {
    code: '😘',
    name: 'Kiss'
  }, {
    code: '😚',
    name: 'Kissing Face With Closed Eyes'
  }, {
    code: '😳',
    name: 'Flushed'
  }, {
    code: '😱',
    name: 'Screaming With Fear'
  }, {
    code: '😁',
    name: 'Laugh'
  }, {
    code: '😜',
    name: 'Winking Tongue out'
  }, {
    code: '😝',
    name: 'Face With Tongue'
  }, {
    code: '😒',
    name: 'Unamused'
  }, {
    code: '😏',
    name: 'Smirking'
  }, {
    code: '😓',
    name: 'Sweating'
  }, {
    code: '😔',
    name: 'Pensive'
  }, {
    code: '😞',
    name: 'Disappointed'
  }, {
    code: '😠',
    name: 'Angry'
  }, {
    code: '😡',
    name: 'Angry Face'
  }, {
    code: '😪',
    name: 'Tired'
  }, {
    code: '😷',
    name: 'Face With Medical Mask'
  }, {
    code: '👿',
    name: 'Devil'
  }, {
    code: '👽',
    name: 'Alien'
  }, {
    code: '💛',
    name: 'Yellow Heart'
  }, {
    code: '💙',
    name: 'Blue Heart'
  }, {
    code: '💜',
    name: 'Purple Heart'
  }, {
    code: '💗',
    name: 'Heart'
  }, {
    code: '💚',
    name: 'Green Heart'
  }, {
    code: '💔',
    name: 'Broken Heart'
  }, {
    code: '💓',
    name: 'Growing Heart'
  }, {
    code: '💘',
    name: 'Lovestruck'
  }, {
    code: '🎄',
    name: 'Christmas tree'
  }, {
    code: '🎁',
    name: 'Gift'
  }, {
    code: '🔔',
    name: 'Bell'
  }, {
    code: '🎉',
    name: 'Celebration'
  }, {
    code: '💡',
    name: 'Bulb'
  }, {
    code: '💰',
    name: 'Money Bag'
  }, {
    code: '🌈',
    name: 'Rainbow'
  }, {
    code: '🏁',
    name: 'Chequered Flag'
  }, {
    code: '🎯',
    name: 'Target'
  }, {
    code: '👑',
    name: 'Crown'
  }, {
    code: '❗',
    name: 'Exclamation Mark'
  }, {
    code: '🎬',
    name: 'Clapper Board'
  }, {
    code: '🎤',
    name: 'Microphone'
  }, {
    code: '🎧',
    name: 'Headphones'
  }, {
    code: '💌',
    name: 'Love letter'
  }, {
    code: '💥',
    name: 'Explosion'
  }, {
    code: '💫',
    name: 'Confused'
  }, {
    code: '💯',
    name: 'Hundred'
  }, {
    code: '📌',
    name: 'Pushpin'
  }, {
    code: '🔥',
    name: 'Fire'
  }, {
    code: '🌟',
    name: 'Glowing Star'
  }, {
    code: '💢',
    name: 'Anger Symbol'
  }, {
    code: '💤',
    name: 'Sleepy'
  }]);
}