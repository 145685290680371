export default function(app) {
  app.factory('saveObj', function() {

    let saveObject = {};
    return {
      get: getObj,
      set: setObj
    };

    function getObj() {
      return saveObject;
    }

    function setObj(obj) {
      saveObject = angular.copy(obj);
    }
  });
}