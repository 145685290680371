import sidenavBootomMenuTemplate from './sidenav-bottom-menu.html';

export default function(app) {
  app.directive("sidenavBootomMenu", sidenavBootomMenu)
    .controller("SidenavBootomMenuController", SidenavBootomMenuController);

  function sidenavBootomMenu() {
    return {
      restrict: 'E',
      templateUrl: sidenavBootomMenuTemplate,
      controller: 'SidenavBootomMenuController',
      controllerAs: 'sm',
      // replace: true
    };
  }

  function SidenavBootomMenuController($rootScope, sharedDataService) {

    "ngInject";

    let sm = this;

    sm.isFromApp = sharedDataService.get('isFromApp');
    sm.isOwnerOrAdmin = $rootScope.usrObj.isOwner || $rootScope.usrObj.isAdmin;
    sm.cbac = {
      addContact: 'customer_list',
      opportunities: 'customer_opportunity_boards',
      addQuote: 'quotes',
      action: 'team_action_items'
    };

    sm.getTips = function() {
      $('#footer-get-tips-cta').click();
    };

    sm.toggleNotifications = function() {
      $('#footer-notifications-cta').click();
    };

  }
}