export default function(app) {
  app.directive('ngHtmlCompile', function($compile) {

    "ngInject";

    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        scope.$watch(attrs.ngHtmlCompile, function(newValue, oldValue) {
          element.html(newValue);
          $compile(element.contents())(scope);
        });
      }
    };

  });
}