export default function(app) {
  app.factory('NavigationService', navigationService);

  function navigationService($window) {

    "ngInject";

    return {
      back: back,
      forward: forward
    };

    // Navigate Back
    function back() {
      $window.history.back();
    }

    // Navigate Forward
    function forward() {
      $window.history.forward();
    }

  }
}