export default function(app) {
  app.factory('StarterService', StarterService);

  function StarterService() {

    "ngInject";

    return {
      init: initStarter,
      toggle: toggleStarter
    };

    function slideToggle() {
      $('.starter-content').slideToggle();
    }

    // Initiate variables required
    let isInitStarter;

    function toggleStarter(vm) {
      return () => {
        slideToggle();
        vm.isStarterOpened = !vm.isStarterOpened;
      };
    }

    // Check spam and calculate it's percentage
    function initStarter(vm, recordCount) {
      if (!isInitStarter) {
        isInitStarter = true;
        vm.isStarterOpened = !recordCount;
        if (vm.isStarterOpened) {
          slideToggle();
        }
      }
    }

  }
}