import contactListTemplate from './contactListTemplate.html';

export default function (app) {
    app.directive('contactList', contactList);

    function contactList() {
        return {
            restrict: 'E',
            // replace: true,
            templateUrl: contactListTemplate
        };
    }
}
