export default function(app) {
  app.factory('ipDataService', ipDataService);

  function ipDataService($http, $state, $rootScope, $log) {

    "ngInject";

    return {
      // getCurrentPosition: getCurrentPosition,
      getIPData: getIPData
    };

    /**
     *  Get user's ip, location, threat information by endpoint https://api.ipdata.co/
     *  Block user in case of threat i.e. If any flag mentioned below is true
     *  "is_tor",
     *  "is_proxy",
     *  "is_anonymous",
     *  "is_known_attacker",
     *  "is_known_abuser",
     *  "is_threat",
     *  "is_bogon"
     */
    function getIPData() {
      $rootScope.threatDetected = false;
      $http.get('https://api.ipdata.co?api-key=41ea0f8ee4eda346e0f6bea197dd872ae26a3df4fef852afceef1125').then(function(response) {
        if (response.status == 200) {

          let result = $rootScope.ipData = response.data;
          $log.log(result);

          const threatTypes = ['is_anonymous', 'is_bogon', 'is_known_abuser', 'is_known_attacker', 'is_threat', 'is_tor'];

          for (let threatType in threatTypes) {
            if (result.threat.hasOwnProperty(threatType) && result.threat[threatType]) {

              $log.log(threatType, result.threat[threatType]);

              $rootScope.threatDetected = threatType;
              $state.go('threat');
              break;
            }
          }

        } else if (response.status == 400) {
          $log.warn('It is a private IP address!');
        } else if (response.status == 429) {
          $log.warn('You have exceeded your free tier limit of 1500 requests. Register for a paid plan at https://ipdata.co to make more requests.');
        }
      }, function(error) {
        $log.error(error);
      });
    }

    /**
     *  Get user's current location - latitude, longitude, and formatted address
     */
    // function getCurrentPosition() {
    //   // Try HTML5 geolocation.
    //   if (navigator.geolocation) {
    //     $log.log('navigator supported');
    //     navigator.geolocation.getCurrentPosition(function(position) {
    //       /* Current Coordinate */
    //       let lat = position.coords.latitude,
    //         lng = position.coords.longitude,
    //         google_map_pos = new google.maps.LatLng(lat, lng);
    //
    //       /* Use Geocoder to get address */
    //       let google_maps_geocoder = new google.maps.Geocoder();
    //       google_maps_geocoder.geocode({
    //           'latLng': google_map_pos
    //         },
    //         function(results, status) {
    //           if (status == google.maps.GeocoderStatus.OK && results[0]) {
    //             $rootScope.locationDetails = {
    //               lat: lat,
    //               lng: lng,
    //               formattedAddress: results[0].formatted_address
    //             };
    //             $log.log(results[0].formatted_address);
    //           }
    //         }
    //       );
    //
    //     }, function() {
    //       $rootScope.locationError = 'Unable to get location';
    //       $log.log('Unable to get location');
    //     });
    //   } else {
    //     // Browser doesn't support Geolocation
    //     $rootScope.locationError = 'Browser doesn\'t support Geolocation';
    //     $log.log('Browser doesn\'t support Geolocation');
    //   }
    // }

  }
}