export default function SaveTemplateController($mdDialog, $state, messages, Upload, validatorService, dataServices, serverUrl, templateData) {

  'ngInject';

  let st = this;
  st.isTemplate = true;
  st.save = validatorService.validateForm(st, saveTemplate);

  st.getCategories = () => {
    return st.templateCategories ? st.templateCategories : dataServices.get({
        url: serverUrl.main + 'drawingTemplateCategoriesApi/drawing/template/categories?',
        spinner: false
      })
      .then(function(response) {
        const data = response.data;
        if (data && data.response_code == 200) {
          st.templateCategories = data.result;
        }
      });
  };

  function saveTemplate() {
    const dataToSend = {
      name: st.name,
      category: st.category,
      thumbnail: templateData.thumbnail[0].id,
      type: templateData.type,
      data: templateData.data,
      links: templateData.links
    };

    dataServices.post({
        url: serverUrl.main + 'drawingTemplateApi/drawing/template',
        data: {
          data: angular.toJson(dataToSend)
        }
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          st.cancel();
          messages.simpleToast('Saved Successfully.');
        }
      });
  }

  st.cancel = function() {
    $mdDialog.hide();
  };
}