import forEach from 'lodash/forEach';

// Import provider based on different modules.
import loginResolver from './../../pages/login/login.provider';
import dashboardResolver from './../../pages/dashboard/dashboard.provider';
import quoteResolver from './../../pages/quotes/quote.provider';
import businessResolver from './../../pages/business/business.provider';
import settingsResolver from './../../pages/settings/settings.provider';
import customerResolver from './../../pages/customers/customers.provider';
import resourcesResolver from './../../pages/resources/resources.provider';
import serviceProvider from './../../pages/quotes/quote-cycle/service/service.provider';
import calendarResolver from './../../pages/calendar/calendar.provider';

import jobsResolver from './../../pages/jobs/jobs.provider';
import jobsServiceResolver from './../../pages/jobs/job-cycle/service/service.provider';

// set in resolver.provider module.
export default angular.module('resolver.providers', [])
  .provider('resolver', resolverProvider)
  .provider('loginResolver', loginResolver)
  .provider('dashboardResolver', dashboardResolver)
  .provider('quoteResolver', quoteResolver)
  .provider('businessResolver', businessResolver)
  .provider('settingsResolver', settingsResolver)
  .provider('customerResolver', customerResolver)
  .provider('resourcesResolver', resourcesResolver)
  .provider('serviceResolver', serviceProvider)
  .provider('calendarResolver', calendarResolver)
  .provider('jobsServiceResolver', jobsServiceResolver)
  .provider('jobsResolver', jobsResolver);

function resolverProvider() {

  this.$get = function() {
    return this;
  };

  this.stateResolve = stateResolve;
  this.loadQuillEditor = loadQuillEditor;
  this.loadSignaturePad = loadSignaturePad;
  this.loadNgCroppie = loadNgCroppie;
  this.loadWebcamJs = loadWebcamJs;
  // this.loadFlags = loadFlags;
  this.loadDragula = loadDragula;
  // this.loadUiCalendar = loadUiCalendar;
  this.loadDragDrop = loadDragDrop;
  this.loadCroppie = loadCroppie;
  this.loadFiltrr = loadFiltrr;
  this.loadJsZip = loadJsZip;
  this.loadJsZipUtil = loadJsZipUtil;
  this.loadImageCompression = loadImageCompression;
  this.loadNgMap = loadNgMap;
  this.loadAngularDragula = loadAngularDragula;
  this.loadAutoScroll = loadAutoScroll;
  this.loadAutoCompleteOverride = loadAutoCompleteOverride;
  this.loadAutoComplete = loadAutoComplete;
  this.loadAngularPayment = loadAngularPayment;
  this.loadNVD3 = loadNVD3;
  this.loadVideogular = loadVideogular;
  this.loadClipboard = loadClipboard;
  // this.ngTimePicker = ngTimePicker;
}
// function ngTimePicker($q, $ocLazyLoad, dynamicLoadModules) {
//   "ngInject";
//   const deferred = $q.defer();
//   require.ensure([], (require) => {
//     const asyncModule = require('./../load-on-demand-modules/time-picker');
//     $ocLazyLoad.load({
//       name: asyncModule.default.name
//     }).then(function() {
//       deferred.resolve(asyncModule);
//     });
//   }, (error) => {
//     // reload when chunk is not found after latest build
//     dynamicLoadModules.reloadFailedChunks(error);
//   }, 'ngTimePicker');
//   return deferred.promise;
// }

function loadClipboard($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/clipboard');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'clipboard');
  return deferred.promise;
}

function loadVideogular($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/videoagular');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'videoagular');
  return deferred.promise;
}

function loadAngularPayment($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    require('angular-stripe');
    const angularPayments = angular.module('angularStripe', ['angular-stripe'])
      .config(function(stripeProvider) {
        stripeProvider.setPublishableKey('pk_test_Q0N3fvRELr9Tznb1IzxlGjFp');
      });
    $ocLazyLoad.load({
      name: angularPayments.name
    }).then(function() {
      deferred.resolve(angularPayments);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'angularStripe');
  return deferred.promise;
}

function loadAutoComplete($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    // window.Jimp = require('jimp');
    const vsGoogleAutocomplete = require('./../load-on-demand-modules/google-autocomplete');
    // console.log("vsGoogleAutocomplete", vsGoogleAutocomplete);
    $ocLazyLoad.load({
      name: vsGoogleAutocomplete.default.name
    }).then(function() {
      deferred.resolve(vsGoogleAutocomplete);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'vsGoogleAutocomplete');
  return deferred.promise;
}

function loadAutoCompleteOverride($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    // window.Jimp = require('jimp');
    const vsGoogleAutocomplete = require('./../load-on-demand-modules/google-autocompleteOverride');
    // console.log("vsGoogleAutocomplete", vsGoogleAutocomplete);
    $ocLazyLoad.load({
      name: vsGoogleAutocomplete.default.name
    }).then(function() {
      deferred.resolve(vsGoogleAutocomplete);
    });
  }, (error) => {
    console.error(error);
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'vsGoogleAutocomplete');
  return deferred.promise;
}

// Added by other
function loadNgMap($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    // window.Jimp = require('jimp');
    const asyncModule = require('./../load-on-demand-modules/ngmap');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'GoogleMaps');
  return deferred.promise;
}

function loadImageCompression($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const imgCompression = require('compressorjs');
    window.Compressor = imgCompression;
    deferred.resolve(imgCompression);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'imgCompression');
  return deferred.promise;
}

function loadNVD3($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const d3 = require('d3');
    const nvd3 = require('nvd3');
    require('nvd3/build/nv.d3.css');
    deferred.resolve(nvd3);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'nvd3');
  return deferred.promise;
}

function loadJsZipUtil($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const JSZipUtils = require('jszip-utils');
    window.JSZipUtils = JSZipUtils;
    deferred.resolve(JSZipUtils);
  }, (error) => {
    // reload when chunk is not found after latest build
    // console.log(error);
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'JSZipUtils');
  return deferred.promise;
}

function loadJsZip($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const JSZip = require('jszip');
    window.saveAs = require('jszip/vendor/FileSaver');
    window.JSZip = JSZip;
    deferred.resolve(JSZip);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'JSZip');
  return deferred.promise;
}

function loadFiltrr($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const imageFilter = require('./../load-on-demand-modules/imagefilter');
    // console.log(imageFilter);
    window.ApplyEffects = imageFilter.default;
    deferred.resolve(imageFilter);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'imagefilter');
  return deferred.promise;
}

function loadCroppie($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const croppie = require('./../load-on-demand-modules/croppies');
    deferred.resolve(croppie);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'croppie');
  return deferred.promise;
}

function loadDragDrop($q, $ocLazyLoad, dynamicLoadModules) {

  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    // import signature pad module.
    const asyncModule = require('./../load-on-demand-modules/drag-drop');
    // console.log(asyncModule);
    $ocLazyLoad.load({
      // set module name in module.
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'mobile-drag-drop');
  return deferred.promise;
}

// This is using by other.
function loadAutoScroll($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    window.autoScroll = require('dom-autoscroller');
    deferred.resolve(autoScroll);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'autoscroll');
  return deferred.promise;
}

// This is using by other.
function loadDragula($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    window.dragula = require('dragula');
    require('dragula/dist/dragula.min.css');
    window.autoScroll = require('dom-autoscroller');
    deferred.resolve(autoScroll);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'dragula');
  return deferred.promise;
}

// This is using by other.
function loadAngularDragula($q, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/angular-dragula');
    $ocLazyLoad.load({
      // set module name in module.
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'angular-dragula');
  return deferred.promise;
}

// Load signature pad dynamically.
function loadSignaturePad($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  // Use promise
  const deferred = $q.defer();
  require.ensure([], (require) => {
    // import signature pad module.
    const asyncModule = require('./../load-on-demand-modules/signature-pad');
    // console.log(asyncModule);
    $ocLazyLoad.load({
      // set module name in module.
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'signature-pad');
  return deferred.promise;
}

function loadQuillEditor($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/quill');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    console.log(error);
    // dynamicLoadModules.reloadFailedChunks(error);
  }, 'quill');
  return deferred.promise;
}

// function loadUiCalendar($q, $ocLazyLoad, dynamicLoadModules) {
//   "ngInject";
//   const deferred = $q.defer();
//   require.ensure([], (require) => {
//     const asyncModule = require('../load-on-demand-modules/ui-calendar');
//     $ocLazyLoad.load({
//       name: asyncModule.default.name
//     }).then(function() {
//       deferred.resolve(asyncModule);
//     });
//   }, (error) => {
//     // reload when chunk is not found after latest build
//     dynamicLoadModules.reloadFailedChunks(error);
//   }, 'ui-calendar');
//   return deferred.promise;
// }

function loadNgCroppie($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/ng-croppie');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    console.error(error);
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'media-upload');
  return deferred.promise;
}

function loadWebcamJs($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./../load-on-demand-modules/webcam');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule);
    });
  }, (error) => {
    console.error(error);
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'media-upload');
  return deferred.promise;
}

// function loadFlags($q, $ocLazyLoad, dynamicLoadModules) {
//   "ngInject";
//   const deferred = $q.defer();
//   require.ensure([], (require) => {
//     const asyncModule = require('./../load-on-demand-modules/flags');
//     $ocLazyLoad.load({
//       name: asyncModule.default.name
//     }).then(function() {
//       deferred.resolve(asyncModule);
//     });
//   }, (error) => {
//     // reload when chunk is not found after latest build
//     dynamicLoadModules.reloadFailedChunks(error);
//   }, 'flags');
//   return deferred.promise;
// }

// This is very important function.
// Motive: All state have a common common params, so make a common provider. Routes only support provider services.
// 1. Url Name
// 2. html template name
// 3. Controller Name
// 4. a. API information
// 4. b. Lazy load module
function stateResolve(urlName, templateName, controllerName, lazyLoadObj, cbacSettings) {

  let stateInfo = {
    url: '/' + urlName,
    templateUrl: templateName,
    controller: controllerName,
    controllerAs: "vm",
    params: {
      data: null,
      tourName: null,
      noRetainFC: null,
      toggleDashboard: null,
      isResourceAdded: null
    },
  };

  // When stateInfo have no resolve, then initiate with blank object.
  if (!stateInfo.resolve) {
    stateInfo.resolve = {};
  }

  // If there have any module for lazy loading. When any state will call, Module will load lazily.
  if (lazyLoadObj && lazyLoadObj.lazyModule && lazyLoadObj.lazyModule.length) {
    forEach(lazyLoadObj.lazyModule, (loadMod, key) => {
      stateInfo.resolve['asyncPreloading' + key] = loadMod;
    });
  }

  // Tried for load files on demand. but this was not implemented yet.
  if (lazyLoadObj && lazyLoadObj.loadOnDemand && lazyLoadObj.loadOnDemand.length) {
    forEach(lazyLoadObj.loadOnDemand, function(loadLib, key) {
      stateInfo.resolve['loadOnDemand' + key] = loadLib;
    });
  }

  // If need to load preData from API.
  if (lazyLoadObj && lazyLoadObj.preData && lazyLoadObj.preData.length) {
    // How many API information have in array. that make as key in stateInfo resolve.
    angular.forEach(lazyLoadObj.preData, function(pre) {

      // console.log(pre);
      if (typeof pre == 'object') {
        // This is resolve function make as name send in predata.
        // Inject dataServices, sererURL, rootscope, stateparams and $q
        stateInfo.resolve[pre.name] = function(dataServices, serverUrl, $rootScope, $stateParams, $q, messages, localStorageService, authService) {

          "ngInject";

          if (cbacSettings && angular.isString(cbacSettings.cbac) && ($rootScope.accessData.cbac[cbacSettings.cbac] == 'no_license' || $rootScope.accessData.cbac[cbacSettings.cbac] == 'no_access')) {
            return;
          }

          let promise, url = pre.url;

          pre.isRequiredApi = angular.isArray(pre.requiredFor) && pre.requiredFor.indexOf($rootScope.currentStateName) != -1;

          // for debugging purpose
          // if ($rootScope.currentStateName == 'main.business.inventoryMaterials') {
          //   console.log(stateInfo.resolve);
          // }

          // Some Params which should add dynamically, like, quoteId=1231, So quoteId is dynamic, This quote id is taken from State param ui-router injected service.
          if (pre.params) {
            forEach(pre.params, function(param) {
              url = url.replace(':' + param, $stateParams[param]);
            });
          }

          // If parameter separator is not there then add parameter separator "?"
          if (url.indexOf('?') == -1) {
            url += '?';
          }

          pre.data = pre.data || {};
          // If pre.data is present, add params in GET API
          if (pre.data) {
            forEach(pre.data, function(val, key) {
              url += key + '=' + val + '&';
            });
          }

          // Set filterCriteria and pagination for list pages
          let filterCriteria;
          if (pre.filterCriteria) {
            filterCriteria = pre.filterCriteria ? angular.copy(pre.filterCriteria) : {};
          }

          if (pre.pagination || pre.getSavedSettings) {

            if (pre.pagination && $rootScope.screenConst) {
              filterCriteria.limit = $rootScope.screenConst[pre.pagination].screenPagination;
            }

            let listPagesFC = localStorageService.get('listPagesFC') || {};
            listPagesFC[$rootScope.currentStateName] = listPagesFC[$rootScope.currentStateName] || {};

            // Update filterCriteria before calling API for list pages in case of refresh or navigation
            // if (!$stateParams.noRetainFC) {
            let limit = filterCriteria.limit;
            filterCriteria = listPagesFC[$rootScope.currentStateName][pre.name] || filterCriteria;

            if (pre.pagination) {
              filterCriteria.skip = 0;
            }
            if ($stateParams.noRetainFC) {
              if (!pre.onlyPagination) {
                filterCriteria.limit = $rootScope.screenConst[pre.pagination].screenPagination;
              } else {
                filterCriteria.limit = limit;
              }
            }

            // For default internal sorting like latest history records
            filterCriteria.sort = filterCriteria.sort || [];
            pre.filterCriteria.defaultSort = pre.filterCriteria.defaultSort || [];
            if (!filterCriteria.sort.length && pre.filterCriteria.defaultSort.length) {
              filterCriteria.sort = angular.copy(pre.filterCriteria.defaultSort);
            }
            delete filterCriteria.defaultSort;

            if (filterCriteria.search) {
              url += 'search=' + filterCriteria.search + '&';
            }
            delete filterCriteria.search;
            // }
          }

          if (pre.filterCriteria) {
            // For dynamic filter values to be filled
            if (pre.filterValue && pre.filterValue.length) {
              forEach(pre.filterValue, function(param, index) {
                let paramVal = param ? $stateParams[param] : filterCriteria.filters[index].value;
                if (angular.isArray(pre.paramTypes) && pre.paramTypes[index] == 'int') {
                  // if(pre.paramTypes[index] == 'int') {
                  paramVal = parseInt(paramVal);
                  // }
                }
                filterCriteria.filters[index].value = paramVal;
              });
            }

            url += 'filterCriteria=' + angular.toJson(filterCriteria) + '&';
          }

          // retrieve promise from xhr call.
          promise = dataServices.get({
            url: serverUrl[pre.auc ? 'auc' : (pre.common ? 'common' : 'main')] + url,
            spinner: false,
            isRequiredApi: pre.isRequiredApi,
            auc: pre.auc,
            noVerData: pre.noVerData,
            addAccessDataForAuc: pre.addAccessDataForAuc
          });

          if (!promise) {
            return;
          }

          // After getting the promise sent it to resolver and add as server injector.
          return promise.then(function(response) {
            // console.log(response);
            if (response) {

              function rejectResponse(message) {

                if (message) {
                  $rootScope.isRequiredApiError = message;
                }

                if (pre.isRequiredApi) {

                  // console.log($rootScope.previousStateName, $rootScope.currentStateName, 'forcedBackFor', pre.forcedBackFor ? pre.forcedBackFor[$rootScope.previousStateName] : undefined);

                  let previousState = $rootScope.previousStateName.split('.');
                  let currentState = $rootScope.currentStateName.split('.');

                  // Do not fail nav from one to other module
                  if ($rootScope.previousStateName != $rootScope.currentStateName && previousState[1] == currentState[1]) {
                    // Do not fail nav from particular source state to destination state
                    if ($rootScope.previousStateName && !pre.forcedBackFor || ($rootScope.previousStateName && pre.forcedBackFor && !pre.forcedBackFor[$rootScope.previousStateName]) || (angular.isObject(pre.forcedBackFor) && pre.forcedBackFor[$rootScope.previousStateName] && (pre.forcedBackFor[$rootScope.previousStateName].indexOf($rootScope.currentStateName) == -1))) {
                      // code for handling api errors during state change
                      let deferred = $q.defer();
                      deferred.reject('REQUIRED_API_FAILED');
                      return deferred.promise;
                    }
                  }
                }
              }

              if (response.status == 200) {
                let data = response.data;
                if (pre.auc && data.result) {
                  return response;
                } else if (data && data.response_code == 200) {
                  return response;
                } else if (data && data.error) {
                  return rejectResponse(data.error || "Technical Error!");
                }
              } else if (response.status == 404) {
                return rejectResponse('Api not found!');
              } else if (response.status == -1) {
                return rejectResponse('Network issue or Connection problem!');
              }

            }
          });
        };
      } else if (typeof pre == 'string') {
        // console.log(pre);
        stateInfo.resolve[pre] = function() {
          return undefined;
        };
      }
    });
  }

  if (cbacSettings && angular.isString(cbacSettings.cbac)) {
    stateInfo.resolve.cbac = function($rootScope, $q) {

      "ngInject";

      const hidePrdAndMtrlRoutes = ['quote_service_products', 'quote_service_materials', 'job_service_products', 'job_service_materials'];

      let deferred = $q.defer();
      if (($rootScope.accessData.cbac[cbacSettings.cbac] === 'full_access' || ($rootScope.accessData.cbac[cbacSettings.cbac] === 'read_access' && !cbacSettings.isWrite)) && !(hidePrdAndMtrlRoutes.includes(cbacSettings.cbac) && $rootScope.bObj.hideProductsFromRec && $rootScope.bObj.behaviour_flags.hidePrdAndMtrl)) {
        console.log('resolve');
        deferred.resolve('PERMISSION_GRANTED');
      } else {
        console.log('reject', stateInfo, cbacSettings);
        if ($rootScope.accessData.cbac[cbacSettings.cbac] === 'no_license' && ($rootScope.usrObj.isOwner || $rootScope.usrObj.isAdmin)) {
          deferred.reject('UPGRADE_LICENSE');
        } else {
          deferred.reject('NO_PERMISSION');
        }
      }

      return deferred.promise;
    }
  }

  if (cbacSettings && cbacSettings.cbacCustomOtherResources) {
    stateInfo.resolve.cbacCustom = function($rootScope, $q, $stateParams) {

      "ngInject";

      let deferred = $q.defer();
      console.log($rootScope.usrObj.userId, $stateParams.resourceId, $rootScope.accessData.cbac.other_resources);
      if ($rootScope.usrObj.userId == $stateParams.resourceId || $rootScope.accessData.cbac.other_resources == 'full_access' || $rootScope.accessData.cbac.other_resources == 'read_access') {
        console.log('resolve');
        deferred.resolve('PERMISSION_GRANTED');
      } else {
        console.log('reject', stateInfo);
        deferred.reject('NO_PERMISSION_OTHER_RESOURCES');
      }

      return deferred.promise;
    }
  }

  return stateInfo;
}