import findIndex from 'lodash/findIndex';
import signatureTemplate from './../../components/custom-form/custom-form-signature-popup.html';

export default function(app) {
  app.factory('FreedomFormService', freedomFormService);

  function freedomFormService(dataServices, $filter, $sce, $q, $mdDialog, timeList, AttachDocumentService, FFFileUploadService) {

    "ngInject";

    return {
      init: init,
      initForUpdate: initForUpdate,
      beforeSave: beforeSave,
      prepareFieldsForViewScreen: prepareFieldsForViewScreen
    };

    function initDefaultVal(question) {
      question.fieldValue = question.fieldValue || [];
    }

    function toggleOption(item, list) {
      let idx = list.indexOf(item);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
    }

    function isOptionExists(item, question) {
      // return question.defaultValue.indexOf(item) > -1;
      if (question && angular.isArray(question.fieldValue) && item) {
        return question.fieldValue.indexOf(item) > -1;
      }
    }

    function init(vm, freedomForms = []) {
      vm.timeList = timeList;
      vm.toggleOption = toggleOption;
      vm.initDefaultVal = initDefaultVal;
      vm.isOptionExists = isOptionExists;
      vm.signaturePanelFreedomForm = signaturePanelFreedomForm;

      vm.close = AttachDocumentService.close;
      vm.isFileImage = AttachDocumentService.isFileImage;
      vm.imageViewPopup = AttachDocumentService.imageViewPopup;
      vm.toggleFileUploadSidebar = (form, index) => {
        vm.ffTarget = form.formTarget;
        vm.fIndex = index;
        FFFileUploadService.buildToggler(vm, 'fileUploadSidebar')();
      }
      FFFileUploadService.init(vm);
    }

    function initForUpdate(vm, freedomForms = []) {
      if (angular.isArray(freedomForms)) {
        vm.freedomForms = freedomForms;

        angular.forEach(vm.freedomForms, (form, i) => {
          form.signEnabled = form.sign;
          angular.forEach(form.customFields, function(field, index) {
            // console.log(form);
            if (field.fieldType === 'Upload Files / Images') {
              FFFileUploadService.initFiles(vm, form.formTarget, i, index);
            } else if (field.fieldType == 'Date' && field.fieldValue) {
              field.fieldValue = new Date(field.fieldValue * 1000);
            }

            if (field.fieldOptions && !field.fieldValue && field.defaultValue) {
              field.fieldValue = field.defaultValue;
            }
          });
        });
      }

      // console.log(vm.freedomForms);
    }

    function beforeSave(vm, dataToSave) {
      const deferred = $q.defer();

      if (vm.freedomForms) {
        FFFileUploadService.uploadFilesAndSave(vm, function() {
          angular.forEach(vm.freedomForms, (form, i) => {
            dataToSave[i] = dataServices.toSave(form, ['formTitle', 'formDescription', 'formTarget', 'customFields', 'sign', 'helpStatement', 'customFormSign']);

            angular.forEach(dataToSave[i].customFields, function(field, index) {
              if (field.fieldType === 'Date' && angular.isDate(field.fieldValue)) {
                field.fieldValue = field.fieldValue.getTime() / 1000;
              } else if (vm.status && vm.status[i] && field.fieldType === 'Upload Files / Images' && field.responseType === 'mandatory') {
                if (!vm.customFormFiles[form.formTarget][index].length) {
                  form.customFields[index].error = 'This field is required';
                  deferred.resolve(false);
                } else {
                  delete form.customFields[index].error;
                }
              }
            });
          });
          deferred.resolve(dataToSave);
        });
      } else {
        deferred.resolve(true);
      }

      return deferred.promise;
    }

    function prepareFieldsForViewScreen(vm, freedomForms) {
      if (angular.isArray(freedomForms) && freedomForms.length) {
        vm.freedomForms = angular.copy(freedomForms);
        angular.forEach(vm.freedomForms, (form, i) => {
          angular.forEach(form.customFields, function(field) {
            if (field.fieldValue) {
              if (field.fieldOptions && angular.isArray(field.fieldValue)) {
                field.fieldValue = field.fieldValue.join(', ');
              } else if (field.fieldType == 'Date') {
                field.fieldValue = $filter('normalFormDate3')(field.fieldValue);
              } else if (field.fieldType == 'Rich Text Format') {
                field.fieldValue = $sce.trustAsHtml(field.fieldValue);
              } else if (field.fieldType == 'Time') {
                field.fieldValue = $filter('getHoursFromMinutes')(parseInt(field.fieldValue), 'withSuffix');
              }
            }
          });
        });
      }
    }

    function signaturePanelFreedomForm(ev, form) {
      $mdDialog.show({
        templateUrl: signatureTemplate,
        controller: SignatureController,
        controllerAs: 'cd',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          form: form
        }
      });
    }

    function SignatureController($scope, serverUrl, messages, Upload, $mdDialog, dataServices, form) {

      "ngInject";

      let cd = this;

      cd.cancel = cancel;
      cd.save = save;
      cd.reset = reset;

      function assignedData() {
        if (form.customFormSign && form.customFormSign.webUrl) {
          Upload.urlToBlob(form.customFormSign.webUrl).then(function(blob) {
            Upload.base64DataUrl(blob).then(function(base64) {
              cd.dataUrl = $scope.dataUrl = base64;
            });
          });
        }
      }

      assignedData();

      function save() {
        let signature = $scope.accept();

        if (!cd.dataUrl && signature.isEmpty) {
          messages.simpleToast('Signature is not available for update.');
          return;
        }

        dataServices.upload({
            url: serverUrl.main + 'mediaApi/upload/base64',
            data: {
              data: angular.toJson([{
                type: 'png',
                data: $scope.dataUrl
              }])
            }
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              if (signature.isEmpty) {
                form.customFormSign = null;
              } else if (data.result.success) {
                form.customFormSign = data.result.success[0];
                form.customFormSign.date = moment().unix();
              }
              cd.cancel();
            }
          });
      }

      function cancel() {
        $mdDialog.cancel();
      }

      function reset() {
        $scope.dataUrl = angular.copy(cd.dataUrl);
      }
    }

  }
}