export default function(app) {
  app.factory('catalogService', catalogService);

  function catalogService(dataServices, serverUrl) {

    "ngInject";

    let filterCriteria = angular.toJson({
      filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      }, {
        field: 'status',
        operator: 'in',
        value: [2, 4]
      }],
      sort: [{
        field: "name",
        order: 1
      }]
    });

    return {
      showParentCatalogs: showParentCatalogs,
      showChildCatalogs: showChildCatalogs,
      showAllCatalogs: showAllCatalogs
    };

    function showAllCatalogs(vm, arrayName, productType = ["product", "material"]) {
      return dataServices.get({
          url: serverUrl.main + 'inventoryApi/catalogs?filterCriteria=' + filterCriteria + '&prodType=' + angular.toJson(productType) + '&',
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm[arrayName] = data.result;
          }
        });
    }

    function showParentCatalogs(vm, productType = ["product", "material"], arrayName = 'parentCategories') {

      let filterCriteria = angular.toJson({
        filters: [{
          field: 'hierarchyType',
          operator: '=',
          value: 'parent'
        }, {
          field: 'isArchived',
          operator: '=',
          value: false
        }, {
          field: 'status',
          operator: 'in',
          value: [2, 4]
        }],
        sort: [{
          field: "name",
          order: 1
        }]
      });

      return dataServices.get({
          url: serverUrl.main + 'inventoryApi/catalogs?prodType=' + angular.toJson(productType) + '&filterCriteria=' + filterCriteria + '&',
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm[arrayName] = data.result;
          } else {
            vm[arrayName] = [];
          }
        });
    }

    function showChildCatalogs(vm, parentId, childObj, productType = ['product', 'material'], arrayName = 'childCategories') {
      let filterCriteria = angular.toJson({
        filters: [{
          field: 'isArchived',
          operator: '=',
          value: false
        }, {
          field: 'status',
          operator: 'in',
          value: [2, 4]
        }, {
          field: 'parentId',
          operator: '=',
          value: parentId || vm.parentId
        }],
        sort: [{
          field: "name",
          order: 1
        }]
      });

      if (childObj) {
        childObj['childId'] = undefined;
      } else {
        vm.childId = undefined;
      }

      dataServices.get({
        url: serverUrl.main + 'inventoryApi/catalogs?prodType=' + angular.toJson(productType) + '&filterCriteria=' + filterCriteria + '&',
        spinner: false
      }).then(function(response) {
        let data = response.data;
        vm[arrayName] = undefined;
        if (data && data.response_code == 200) {
          vm[arrayName] = data.result;
          console.log(vm[arrayName]);
        } else {
          vm[arrayName] = [];
        }
      });
    };

  }
}
