export default function businessResolver() {
  this.loadBusinessModule = loadBusinessModule;

  this.$get = function() {
    return this;
  };
}

function loadBusinessModule($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./business.module');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule.default.controller);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    console.log(error);
    // dynamicLoadModules.reloadFailedChunks(error);
  }, 'business.module');
  return deferred.promise;
}