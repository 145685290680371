import saveAsTemplate from '../../components/popup-view/save-template.html';
import templateListTemplate from '../../components/popup-view/template-list.html';
import confirmTemplate from '../../components/popup-view/confirm-dialog.html';
export default function(app) {
  app.factory('TemplateService', TemplateService);

  function TemplateService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage) {

    "ngInject";

    return {
      saveTemplate: saveTemplate,
      getTemplateList: getTemplateList
      // confirmPopup: confirmPopup
    }

    function saveTemplate(vm, type) {
      /**
      START :
      Templates are implemented in 3 places.
      1. Estimate
      2. Product Cart
      3. Material Cart.

      so type parameter can be estimate , product and material to indentify the origin of saving templates.
      */

      $mdDialog.show({
        clickOutsideToClose: false,
        controller: SaveAsTemplateController,
        controllerAs: 'cd',
        templateUrl: saveAsTemplate,
        parent: angular.element(document.body),
        // targetEvent: ev,
        openFrom: {
          top: -50,
          width: 30,
          height: 80
        },
        closeTo: {
          left: 1500
        },
        locals: {
          vm: vm,
          type: type
        }
      });
    }

    function SaveAsTemplateController($scope, $mdDialog, $rootScope, messages, maxlength, errorMessage, validatorService, vm, type) {

      'ngInject';

      const cd = this;

      cd.errorMessages = errorMessage;
      cd.maxlength = maxlength;
      cd.confirm = validatorService.validateForm(cd, confirm);
      cd.cancel = cancel;

      cd.templateTotalCost = {
        cost: type == 'estimate' ? vm.estimateData.estTotalPrice : vm.cartTotalDetail.totalPrice,
        currencySymbol: vm[type == 'estimate' ? 'quoteDetail' : 'contactDetail'].currencySymbol
      };

      cd.getCategories = () => {
        return cd.templateCategories ? cd.templateCategories : dataServices.get({
            url: serverUrl.main + 'lineItemTemplateCatApi/template/category?stats=true&',
            spinner: false
          })
          .then(function(response) {
            const data = response.data;
            if (data && data.response_code == 200) {
              cd.templateCategories = data.result;
            }
          });
      };

      function confirm() {
        let data = {
          data: angular.toJson({
            quoteId: vm.quoteId,
            taskId: vm.serviceId,
            name: cd.templateName,
            category_id: cd.category,
            description: cd.templateDescription
          })
        };

        if (type != 'estimate') {
          data['prodType'] = angular.toJson([type]);
        }

        dataServices.post({
          url: serverUrl.main + (type == 'estimate' ? 'estimateTemplateApi' : 'lineItemTemplateApi') + '/template/' + (type == 'estimate' ? 'estimate' : 'product') + '/insert',
          data: data
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Template saved successfully !');
            cd.cancel();
          }
        })
      }

      function cancel() {
        $mdDialog.cancel();
      }
    }

    function getTemplateList(vm, type, hideInsert) {
      /**
      START :
      Templates are implemented in 3 places.
      1. Estimate
      2. Product Cart
      3. Material Cart.

      so type parameter can be estimate , product and material to indentify the origin of template licd.
      */
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: TemplateListTemplateController,
        controllerAs: 'cd',
        templateUrl: templateListTemplate,
        parent: angular.element(document.body),
        openFrom: {
          top: -50,
          width: 30,
          height: 80
        },
        closeTo: {
          left: 1500
        },
        // targetEvent: ev,
        locals: {
          vm: vm,
          type: type,
          hideInsert: hideInsert
        }
      });
    }

    function TemplateListTemplateController($scope, $mdDialog, $rootScope, $timeout, messages, errorMessage, validatorService, vm, type, hideInsert) {
      var cd = this;

      cd.type = type;
      cd.hideInsert = hideInsert
      cd.errorMessages = errorMessage;
      cd.confirm = confirm;
      cd.cancel = cancel;
      cd.deleteTemplate = deleteTemplate;
      cd.getAllTemplateList = getAllTemplateList;
      cd.isAnyTemplateDeleted = false
      cd.callFunction = {
        'estimate': 'getEstimateData',
        'product': 'getCartList',
        'material': 'getCartList'
      };

      function getTemplateCategories() {
        dataServices.get({
            url: serverUrl.main + 'lineItemTemplateCatApi/template/category?stats=true&',
            spinnerName: 'template-list-spinner'
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              cd.categories = data.result;
            }
          });
      }

      function getAllTemplateList() {
        let filterCriteria = {
          filters: [{
            field: "templateType",
            value: type,
            operator: "="
          }],
          sort: [{
            field: 'lastUpdated',
            order: -1
          }],
          project: ['name', 'business_id', 'desc', 'total_price', 'template_type', 'is_archived', 'currency_symbol', 'created_on', 'created_by', 'updated_by', 'last_updated']
        };

        if (cd.categoryId && cd.categoryId != 'all') {
          filterCriteria.filters.push({
            field: 'category_id',
            value: cd.categoryId,
            operator: '='
          });
        }

        if (type == 'estimate') {
          filterCriteria.filters.push({
            field: 'addressId',
            value: parseInt(vm.quoteDetail.addressId),
            operator: '='
          });
        }

        cd.isLoadedTemplates = false;

        dataServices.get({
          url: serverUrl.main + (type == 'estimate' ? 'estimateTemplateApi' : 'lineItemTemplateApi') + '/template/' + (type == 'estimate' ? 'estimate' : 'product') + '/list?filterCriteria=' + angular.toJson(filterCriteria) + '&' + (type != 'estimate' ? 'prodType=["' + type + '"]&quoteId=' + vm.quoteId + '&taskId=' + vm.serviceId + '&location=' + parseInt(vm.contactDetail.addressId) + '&' : ''),
          spinnerName: 'template-list-spinner'
        }).then(response => {
          if (response && response.data && response.data.result) {
            cd.templateList = response.data.result;
          }

          cd.isLoadedTemplates = true;
        }, error => {
          cd.isLoadedTemplates = true;
        });
      }

      $timeout(function() {
        getAllTemplateList();
        getTemplateCategories();
      }, 0);

      function deleteTemplate(id) {
        let data = {};
        if (type != 'estimate') {
          data['prodType'] = angular.toJson([type]);
        }
        dataServices.delete({
          url: serverUrl.main + (type == 'estimate' ? 'estimateTemplateApi' : 'lineItemTemplateApi') + '/template/' + (type == 'estimate' ? 'estimate' : 'product') + '/delete/' + id,
          data: data,
          spinnerName: 'template-list-spinner'
        }).then(function(response) {
          if (response && response.data && response.data.result) {
            messages.simpleToast('Template deleted successfully !');
            cd.editedItemIndex = undefined;
            cd.isAnyTemplateDeleted = true
            getAllTemplateList();
          }
        })
      }

      function confirm(callback) {

        if (cd.selectForInsert == undefined) {
          messages.simpleToast('Please select a template to insert !', 'warning');
          return
        }
        console.log(vm[type == 'estimate' ? 'estimatesLength' : 'cartTotalItem']);
        if (vm[type == 'estimate' ? 'estimatesLength' : 'cartTotalItem'] > 0 && !cd.isForcelyOverwrite) {
          cd.isForcelyOverwrite = true;
          // START : If data is overwriting the current data then we need to confirm with user consent.
          confirmPopup(vm, cd);
          // END : If data is overwriting the current data then we need to confirm with user consent.

        } else {
          let data = {
            data: angular.toJson({
              taskId: vm.serviceId,
              quoteId: vm.quoteId,
              templateId: cd.templateList[cd.selectForInsert].id
            })
          };
          if (type != 'estimate') {
            data['prodType'] = angular.toJson([type]);
          }

          dataServices.post({
            url: serverUrl.main + (type == 'estimate' ? 'estimateTemplateApi' : 'lineItemTemplateApi') + '/template/' + (type == 'estimate' ? 'estimate/apply' : 'product/insert/lineitem'),
            data: data
          }).then(function(response) {
            if (response && response.data && response.data.result) {
              messages.simpleToast('Template inserted successfully !');

              /**
              START :
              After inserting the saved template we need to call a function to show inserted data from the template. we defined three different functions for different origin. you can see in object name "cd.callFunction" above.
              */
              console.log("hanumanaaaaaaaaaaaaaaaaaa")
              console.log(cd.type)
              console.log(type)
              console.log(cd.callFunction[cd.type])
              console.log((type != 'estimate' ? vm : ''))
              if (type != 'estimate') {
                if (vm.fromCartController == true) {
                  vm.cartDetailfetch()
                } else {
                  vm[cd.callFunction[cd.type]](type != 'estimate' ? vm : '');

                }
              }

              console.log("hanumanaaaaaaaaaaaaaaaaaa")

              // END

              cd.cancel();

              if (callback) {
                callback();
              }
              cd.isForcelyOverwrite = false;
            } else {

            }
          });
        }
      }

      function cancel() {
        console.log('called list');
        $mdDialog.cancel();
        if (type == 'estimate' && cd.isAnyTemplateDeleted) {
          vm.getAllTemplateList();
        }
        if ((type == 'product' || 'material') && cd.isAnyTemplateDeleted) {
          vm.getAllTemplateList();
        }

      }
    }

    function confirmPopup(vm, cd) {
      $mdDialog.show({
        clickOutsideToClose: false,
        controller: confirmPopupController,
        controllerAs: 'cd',
        templateUrl: confirmTemplate,
        parent: angular.element(document.body),
        // targetEvent: ev,
        multiple: true,
        locals: {
          vm: vm,
          ccd: cd
        }
      });
    }

    function confirmPopupController($scope, $mdDialog, $rootScope, $filter, vm, ccd) {
      var cd = this;
      cd.confirm = confirm;

      cd.description = $filter('camelcase')(ccd.type) + " already has cost built. Applying the following template will overwrite the " + ccd.type + " entirely.";

      cd.question = "Do you want to replace the " + ccd.type + " ? ";

      cd.cancel = function() {
        console.log('called confirm');
        ccd.isForcelyOverwrite = false;
        $mdDialog.cancel();
      };

      function confirm() {
        ccd.confirm(cd.cancel);
      }
    }
  }
}