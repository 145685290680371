import businessCardTemplate from './businessContactCard.html';
export default function(app) {
  app.directive('businessCard', businessCard);

  function businessCard() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: businessCardTemplate
    };
  }
}
