import dashboardFrameTemplate from './dashboard-frame/dashboard-frame.html';
import gettingStartedTemplate from './getting-started/getting-started.html';
import myWorkboardTemplate from './my-workboard/my-workboard.html';
import highlightsTemplate from './highlights/business-highlights.html';
import dashboardTemplate from './../../components/dashboard-template/opportunity-dashboard.html';
import addReportBlockTemplate from '../../components/dashboard-template/addReportBlock.html'
import manageIndicatorTemplate from '../../components/dashboard-template/manageIndicator.html';
import activitiesTemplate from './activities/activities.html';
import updateActivityTemplate from './activities/update-activity/update-activity.html';
import activityBoardsTemplate from './activities/activity-boards/activity-boards.html';
import getSupportTemplate from './get-support/get-support.html';
import mainDashboardTemplate from './dashboard-setup/mainDashboardList.html';
import {
  ASSIGNED_DASHBOARD,
  SALESAGENT,
  DASHBOARD_DASHBOARD_LIST,
  BLOCK_DETAIL,
  ACTIVITY,
  ACTIVITY_BOARDS,
  SALES_ORGANIZATION
} from './dashboard.resolve.obj';

export default function dashboardRoute($urlRouterProvider, $stateProvider, dashboardResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;
  $stateProvider
    .state('main.dashboard', resolver('dashboard', dashboardFrameTemplate, 'DashboardFrameController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
    }, {
      cbac: 'main_dashboards'
    }))

    // sales page - no cbac
    .state('main.dashboard.gettingstarted', resolver('getting-started', gettingStartedTemplate, 'GettingStartedController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      loadOnDemand: [resolverProvider.loadVideogular]
    }))

    .state('main.dashboard.myworkboard', resolver('my-workboard', myWorkboardTemplate, 'MyWorkboardController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule]
    }, {
      cbac: 'main_dashboard_my_work_board'
    }))

    .state('main.dashboard.highlights', resolver('business-highlights', highlightsTemplate, 'HighlightsController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      loadOnDemand: [resolverProvider.loadNVD3]
    }, {
      cbac: 'main_dashboard_highlights'
    }))

    .state('main.dashboard.activities', resolver('activity-board', activitiesTemplate, 'ActivitiesController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'main_dashboard_activities'
    }))

    .state('main.dashboard.addActivity', resolver('add-activity', updateActivityTemplate, 'UpdateActivityController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      preData: [ACTIVITY.name, SALES_ORGANIZATION]
    }, {
      cbac: 'main_dashboard_activities',
      isWrite: true
    }))

    .state('main.dashboard.updateActivity', resolver('update-activity/:id', updateActivityTemplate, 'UpdateActivityController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      preData: [ACTIVITY, SALES_ORGANIZATION]
    }, {
      cbac: 'main_dashboard_activities',
      isWrite: true
    }))

    // sales page - no cbac
    .state('main.dashboard.getsupport', resolver('get-support', getSupportTemplate, 'GetSupportController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule]
    }))

    .state('main.dashboard.activitiesBoards', resolver('activity-boards', activityBoardsTemplate, 'ActivityBoardsController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      preData: [ACTIVITY_BOARDS]
    }, {
      cbac: 'main_dashboard_activities'
    }))

    .state('main.dashboard.mydashboard', resolver('my-dashboard', dashboardTemplate, 'DashboardController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      loadOnDemand: [resolverProvider.loadDragula, resolverProvider.loadNVD3],
      preData: [ASSIGNED_DASHBOARD, SALESAGENT]
    }, {
      cbac: 'main_dashboard_my_dashboard'
    }))

    .state('main.dashboard.dashboardList', resolver('common-dashboard-list', mainDashboardTemplate, 'MainDashboardListController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [DASHBOARD_DASHBOARD_LIST]
    }, {
      cbac: 'main_dashboard_my_dashboard'
    }))

    .state('main.addReportBlock', resolver('common-report-block/:dashboardId', addReportBlockTemplate, 'AddReportBlockController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule]
    }, {
      cbac: 'main_dashboard_my_dashboard',
      isWrite: true
    }))

    .state('main.addManageIndicator', resolver('common-manage-indicator/:dashboardId/:KBIBlockId', manageIndicatorTemplate, 'ManageIndicatorController', {
      lazyModule: [dashboardResolverProvider.loadDashboardModule],
      preData: [BLOCK_DETAIL]
    }, {
      cbac: 'main_dashboard_my_dashboard',
      isWrite: true
    }));
}
