export default function(app) {
  app.factory('DeviceDetectionService', DeviceDetectionService);

  function DeviceDetectionService($rootScope, sharedDataService) {
    "ngInject";

    return {
      init: init
    };

    function init() {
      let isFromApp = navigator.userAgent.indexOf('customuasb') != -1;
      sharedDataService.set('isFromApp', isFromApp);
      if (isFromApp) {
        sharedDataService.set('isFromAndroidApp', navigator.userAgent.indexOf('androidcustomuasb') != -1);
        sharedDataService.set('isFromIOSApp', navigator.userAgent.indexOf('ioscustomuasb') != -1);
      }

      let client = new ClientJS(); // Create A New Client Object
      sharedDataService.set('isFromMobile', client.isMobile());
    }

  }
}