// Import all locale language json files.
import translation_en_us from '../../languages/locale-en-us.json';
import translation_en_ca from '../../languages/locale-en-ca.json';
import translation_en_gb from '../../languages/locale-en-gb.json';
import translation_en_hk from '../../languages/locale-en-hk.json';
import translation_en_in from '../../languages/locale-en-in.json';

// Import all svg files
import nav from '_images/icons/left-nav.svg';
import set_a from '_images/icons/set-a.svg';
import set_b from '_images/icons/set-b.svg';
import set_c from '_images/icons/set-c.svg';
import set_d from '_images/icons/set-d.svg';
import set_e from '_images/icons/set-e.svg';
import set_f from '_images/icons/set-f.svg';
import set_g from '_images/icons/set-g.svg';
import set_h from '_images/icons/set-h.svg';
import set_i from '_images/icons/set-i.svg';
import new_set from '_images/icons/new-set.svg';
import visuals from '_images/icons/visuals.svg';
import media_icon from '_images/icons/media-icon.svg';
import changeImage from '_images/icons/change-image.svg';
import svgBasic from '_images/icons/header-footer-svg.svg';
import dashboardSet from '_images/icons/dashboard-set.svg';

// KeepaliveProvider, IdleProvider,
function config($httpProvider, $logProvider, $compileProvider, $locationProvider, $translateProvider, $mdIconProvider, $mdThemingProvider, $mdAriaProvider, localStorageServiceProvider, usSpinnerConfigProvider, DropBoxSettingsProvider, googleDriveSettingProvider, $uibTooltipProvider) {

  'ngInject';

  $locationProvider.html5Mode(true);
  $httpProvider.interceptors.push('authInterceptor');

  // Session management
  // Production params - Set to higher values as of now. Change it before release
  // IdleProvider.idle(3600);
  // IdleProvider.timeout(300);
  // KeepaliveProvider.interval(300);
  // Testing params
  // IdleProvider.idle(30);
  // IdleProvider.timeout(10);
  // KeepaliveProvider.interval(10);

  // add prefix as "jt-app" in local-storage key.
  localStorageServiceProvider
    .setPrefix('jacktrade')
    .setStorageType('localStorage')
    .setNotify(false, false);

  // set basic name of svg name, which will add in md-svg-icon.
  $mdIconProvider
    .iconSet('nav', nav)
    .iconSet('set-d', set_d)
    .iconSet('set-a', set_a)
    .iconSet('set-i', set_i)
    .iconSet('set-h', set_h)
    .iconSet('set-g', set_g)
    .iconSet('set-f', set_f)
    .iconSet('set-e', set_e)
    .iconSet('set-c', set_c)
    .iconSet('set-b', set_b)
    .iconSet('basic', svgBasic)
    .iconSet('visuals', visuals)
    .iconSet('new-set', new_set)
    .iconSet('media', media_icon)
    .iconSet('image', changeImage)
    .iconSet('dashboard', dashboardSet);

  // Theme for toast.
  $mdThemingProvider.theme('danger-toast');
  $mdThemingProvider.theme('success-toast');
  $mdThemingProvider.theme('warning-toast');

  // Refer to material website for this.
  let neonOrangeMap = $mdThemingProvider.extendPalette('orange', {
    '400': 'FF9501',
    'contrastDefaultColor': 'light'
  });
  // Refer to material website for this.
  let whiteGreyMap = $mdThemingProvider.extendPalette('grey', {
    '50': 'FFFFFF'
  });

  // Register the new color palette map with the name <code>neonRed</code>
  $mdThemingProvider.definePalette('neonOrange', neonOrangeMap);
  $mdThemingProvider.definePalette('whiteGrey', whiteGreyMap);
  // Use that theme for the primary intentions
  $mdThemingProvider.theme('default')
    .primaryPalette('neonOrange')
    .backgroundPalette('whiteGrey', {
      'default': '50'
    });

  // $translateProvider.useStaticFilesLoader({
  //   prefix: "language/locale-",
  //   suffix: '.json'
  // });

  // Default spinner configuration.
  usSpinnerConfigProvider.setDefaults({
    lines: 6, // The number of lines to draw
    length: 0, // The length of each line
    width: 7, // The line thickness
    radius: 13, // The radius of the inner circle
    scale: 1.2, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1.5, // Rounds per second
    rotate: 90, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#ff9501', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    // shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
  });

  // default locale translate, currently its import only en_us
  $translateProvider
    .translations('en-us', translation_en_us)
    .preferredLanguage('en-us')
    .useLoaderCache(true)
    .useSanitizeValueStrategy('escapeParameters');

  // Debug enabled.
  $logProvider.debugEnabled(true);

  // Debug disabled when its in production mode.
  if (process.env.NODE_ENV === 'production') {
    $logProvider.debugEnabled(false);
    $compileProvider.debugInfoEnabled(false);
    // Globally disables all ARIA warnings.
    $mdAriaProvider.disableWarnings();
  }

  // Setting for dropbox
  DropBoxSettingsProvider.configure({
    linkType: 'direct', //dropbox link type
    multiselect: true, //dropbox multiselect
    extensions: ['.jpg', '.png', '.gif', '.xls', '.xlsx', '.txt', '.pdf', '.doc', '.docx', '.csv', '.zip']
  });

  // Google Drive setting, Its not working now. because Need all configuration from Google API console.
  // googleDriveSettingProvider.configure({
  //   apiKey: 'KmgJHAVMp1_anqYnEqhAicHx',
  //   clientId: '661430010180-fj471697bg8unl4fia1h5j97cf4ot99n.apps.googleusercontent.com'
  // });

  $uibTooltipProvider.options({
    placement: 'left',
    appendToBody: true,
    trigger: 'mouseenter'
  });
}

export default config;