export const owner = {
  url: 'businessOwnerApi/business/owner',
  method: 'GET',
  name: 'owner'
};

export const entities = {
  url: 'businessEntityApi/business/entity',
  method: 'GET',
  name: 'entities'
};

export const exportDataSets = {
  url: 'dataExportApi/export/list/models',
  method: 'GET',
  name: 'exportDataSets'
};

export const exportDataSet = {
  url: 'exportSetupApi/setup/:id',
  method: 'GET',
  name: 'dataSet',
  params: ['id']
};

export const regions = {
  url: 'businessRegionApi/business/region',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'name',
      order: 1
    }]
  },
  name: 'regions'
};

export const REGIONS_LIST = {
  url: 'businessRegionApi/business/region',
  method: 'GET',
  name: 'regions',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'name',
      order: 1
    }]
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const LOCATIONS_LIST = {
  url: 'businessLocationApi/business/location',
  method: 'GET',
  name: 'locations',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'LOCATION_LIST'
};

export const LOCATION = {
  url: 'businessLocationApi/business/location/:id',
  method: 'GET',
  name: 'location',
  params: ['id']
};

export const invoices = {
  url: 'businessInvoiceApi/business/invoice',
  method: 'GET',
  name: 'invoices'
};

export const workorders = {
  url: 'workOrdersApi/business/workOrder',
  method: 'GET',
  name: 'workorders'
};

export const workorder = {
  url: 'workOrdersApi/business/workOrder/:id',
  method: 'GET',
  name: 'workorder',
  params: ['id']
};

export const OPPORTUNITY_BOARD = {
  url: 'opportunityApi/opportunity/boards',
  method: 'GET',
  name: 'OPPORTUNITY_BOARD',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  }
};

export const webConversionList = {
  url: 'webConversionSettingApi/web/conversion/setting',
  method: 'GET',
  name: 'webConversionList',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  onlyPagination: true
};

export const webConversionActivity = {
  url: 'webConversionStatusApi/web/conversion/status',
  method: 'GET',
  name: 'webConversionActivity',
  filterCriteria: {
    filters: [{
      field: 'settingId',
      operator: '=',
    }],
    sort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  filterValue: ['id'],
};

export const webConversionDetail = {
  url: 'webConversionSettingApi/web/conversion/setting/:id',
  method: 'GET',
  name: 'webConversionDetail',
  params: ['id']
};

export const FETCH_INBOX_ID = {
  url: 'mailBoxApi/mailbox/fetch',
  method: 'GET',
  name: 'FETCH_INBOX_ID'
};

export const emailSetups = {
  url: 'emailSetupApi/email/setup',
  method: 'GET',
  name: 'emailSetups'
};

export const emailProviders = {
  url: 'emailProviderApi/email/provider',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'serviceProvider',
      order: 1
    }]
  },
  name: 'emailProviders'
};

export const calSettings = {
  url: 'notificationSettingsApi/settings',
  method: 'GET',
  name: 'calSettings'
};

export const emailBrandings = {
  url: 'emailBrandingApi/email/branding',
  method: 'GET',
  name: 'emailBrandings'
};

export const EMAIL_TEMPLATES = {
  url: 'emailTemplateApi/email/template?stats=yes&',
  method: 'GET',
  name: 'emailTemplates',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: "consent",
      value: true,
      operator: "="
    }, {
      field: "modelName",
      value: 10,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const ASSIGNMENT_NOTIFICATIONS = {
  url: 'emailTemplateApi/email/template?stats=yes&',
  method: 'GET',
  name: 'assignmentNotifications',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: "consent",
      value: false,
      operator: "="
    }, {
      field: "modelName",
      value: 10,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const fetchTemplates = {
  url: 'emailTemplateApi/email/template',
  name: 'templates',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "mdl",
      value: 20,
      operator: "="
    }]
  }
};

export const listBizServices = {
  url: 'businessApi/business/services',
  method: 'GET',
  name: 'bizServices'
};

export const listScopeOfWork = {
  url: 'businessApi/business/scopeOfWork',
  method: 'GET',
  name: 'scopeOfWork',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'SOW_LIST'
};

export const scopeOfWork = {
  url: 'businessApi/business/scopeOfWork/single/:id/sow',
  method: 'GET',
  name: 'sow',
  params: ['id']
};

export const listReasonTypes = {
  url: 'reasonListApi/reasonList',
  method: 'GET',
  name: 'reasons',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'REASON_LIST'
};

export const aliases = {
  url: 'aliasesApi/alias/list',
  method: 'GET',
  name: 'aliases'
};

export const behaviourSettings = {
  url: 'behaviourFlagsApi/behaviour/flag/list',
  method: 'GET',
  name: 'behaviourSettings'
};

export const listServiceRates = {
  url: 'businessApi/business/serviceRates',
  method: 'GET',
  name: 'serviceRates',
  requiredFor: ['main.settings.structureServiceRates'],
};

export const fetchAllPromotion = {
  url: 'promotionApi/promotions',
  method: 'GET',
  name: 'promotions'
};

export const promotionListNotArchived = {
  url: 'promotionApi/promotions?stats=yes&',
  method: 'GET',
  name: 'promotionData',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'PROMOTION_LIST'
};

export const fetchPromotion = {
  url: "promotionApi/promotions/:id",
  method: 'GET',
  name: "promotion",
  params: ['id'],
  requiredFor: ['main.business.updatePromotion'],
  forcedBackFor: {
    'main.quotes.updatePromotion': ['main.settings.automationPromotionsListView'],
  }
};

export const fetchPolicy = {
  url: "recurringPlanApi/recurringPlans/:id",
  method: 'GET',
  name: "policy",
  params: ['id']
};

export const fetchAttributePolicy = {
  url: "attributePolicyApi/attributePolicy/:id",
  method: 'GET',
  name: "attributePolicy",
  params: ['id']
};

export const fetchSerialNumberDetail = {
  url: "inventoryApi/serialNumAttributePolicy/:id",
  method: 'GET',
  name: "fetchSerialNumberDetail",
  params: ['id']
};

export const policiesList = {
  url: 'recurringPlanApi/policies?stats=yes&',
  name: 'policies',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: 'type',
        operator: '=',
        value: 9
      }
    ],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'POLICY_LIST'
};

// Passed for nav
export const mediaTagsList = {
  url: 'tagApi/tags/category/:category',
  name: 'mediaTags',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'category',
      operator: '='
    }, {
      field: 'isArchived',
      operator: '=',
      value: false
    }]
  },
  filterValue: ['category'],
  params: ['category'],
  paramTypes: ['int']
};

export const GUIDED_ACTION_LIST = {
  url: 'guidedActionPlanApi/guidedActionPlans?stats=yes&',
  method: 'GET',
  name: 'guidedActionPlans',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ACTION_PLAN_LIST',
  requiredFor: ['main.settings.automationGuidedActions', 'main.settings.automationGuidedActionsList'],
  forcedBackFor: {
    'main.settings.automationGuidedActionsList': ['main.settings.automationGuidedActions'],
    'main.settings.addGuidedAction': ['main.settings.automationGuidedActions', 'main.settings.automationGuidedActionsList']
  }
};

export const NOTIFICATIONS_LIST = {
  url: 'guidedActionPlanApi/notification/rule?stats=yes&',
  method: 'GET',
  name: 'notifications',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
  },
  pagination: 'NOTIFICATION_LIST',
  requiredFor: ['main.settings.automationNotifications'],
  forcedBackFor: {
    'main.settings.automationNotificationsView': ['main.settings.automationNotifications'],
    'main.settings.addNotification': ['main.settings.automationNotifications']
  }
};

export const GUIDED_ACTION = {
  url: 'guidedActionPlanApi/guidedActionPlans/:id',
  method: 'GET',
  name: 'guidedActionPlan',
  params: ['id'],
  requiredFor: ['main.settings.automationGuidedActionsView', 'main.settings.updateGuidedAction'],
  forcedBackFor: {
    'main.settings.updateGuidedAction': ['main.settings.automationGuidedActionsView']
  }
};

export const NOTIFICATION = {
  url: 'guidedActionPlanApi/notification/rule/:id',
  method: 'GET',
  name: 'rule',
  params: ['id'],
  requiredFor: ['main.settings.automationNotificationsView', 'main.settings.updateNotification'],
  forcedBackFor: {
    'main.settings.updateNotification': ['main.settings.automationNotificationsView']
  }
};

// Passed for nav
export const GUIDED_ACTION_RULES = {
  url: 'guidedActionPlanApi/actionPlans/rules',
  method: 'GET',
  name: 'rules',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'actionPlanId',
      operator: '='
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  filterValue: [false, 'id'],
  pagination: 'ACTION_RULE_LIST'
};

export const EXPOSED_KEYS = {
  url: 'exposedKeysApi/exposedKeys/segmentation',
  method: 'GET',
  name: 'exposeKeys',
  requiredFor: ['main.settings.automationGuidedActionsItemview', 'main.settings.updateGuidedActionItem'],
  forcedBackFor: {
    'main.settings.updateGuidedActionItem': ['main.settings.automationGuidedActionsItemview']
  }
};

export const EXPOSED_KEYS_DUE_DATE = {
  url: 'exposedKeysApi/exposedKeys/date/token',
  method: 'GET',
  name: 'exposeKeysDueDate',
  requiredFor: ['main.settings.automationGuidedActionsItemview', 'main.settings.updateGuidedActionItem'],
  forcedBackFor: {
    'main.settings.updateGuidedActionItem': ['main.settings.automationGuidedActionsItemview']
  }
};

export const EXPOSED_KEYS_TOKEN = {
  url: 'exposedKeysApi/exposedKeys/token',
  method: 'GET',
  name: 'exposeKeysToken',
  requiredFor: ['main.settings.structureCommunication']
};

export const GUIDED_ACTION_RULE = {
  url: 'guidedActionPlanApi/actionPlans/rules/:itemId',
  method: 'GET',
  name: 'rule',
  params: ['itemId'],
  requiredFor: ['main.settings.automationGuidedActionsItemview', 'main.settings.updateGuidedActionItem'],
  forcedBackFor: {
    'main.settings.updateGuidedActionItem': ['main.settings.automationGuidedActionsItemview']
  }
};

// Passed for nav
export const GUIDED_ACTION_RULE_HISTORY = {
  url: 'activityHistoryApi/history',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_id",
      "operator": "="
    }, {
      "field": "function_name",
      "value": "actionRule",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['itemId']
};

// Passed for nav
export const GUIDED_ACTION_PLAN_HISTORY = {
  url: 'activityHistoryApi/history',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "function_id",
      operator: "="
    }, {
      field: "function_name",
      value: "actionPlan",
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id']
};

export const PaymentPolicys = {
  url: 'PaymentPolicyApi/payment_policy/:id',
  method: 'GET',
  name: 'PaymentPolicys',
  params: ['id']
};

export const TrackingPolicy = {
  url: 'inventoryApi/clockIn/:id',
  method: 'GET',
  name: 'policy',
  params: ['id']
};

export const PayPolicy = {
  url: 'inventoryApi/policy/:id',
  method: 'GET',
  name: 'policy',
  params: ['id']
};

export const ALL_ORGANIZATION = {
  url: 'resourceApi/organizations?',
  name: 'allOrganization',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: 'orgName',
      order: 1
    }]
  },
  requiredFor: ['main.settings.structureServices']
};

// export const RES_TYPE = {
//   url: 'resourceApi/organizations/resourceTypes?',
//   name: 'orgResType',
//   method: 'GET',
//   requiredFor: ['main.settings.structureServices']
// };

export const LIST_CUSTOM_FORM = {
  url: 'customFieldsApi/customFields?',
  name: 'customForms',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isFreedomForm',
      operator: '=',
      value: false
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  requiredFor: ['main.settings.dataCustomForms']
};

export const CUSTOM_FORM_DETAIL = {
  url: 'customFieldsApi/customFields/:id',
  name: 'CUSTOM_FORM_DETAIL',
  method: 'GET',
  params: ['id'],
  requiredFor: ['main.settings.updateCustomForm']
};

export const CUSTOM_FORM_TYPE_LIST = {
  url: 'customFieldsApi/customFields/forms',
  name: 'CUSTOM_FORM_TYPE_LIST',
  method: 'GET',
  requiredFor: ['main.settings.updateCustomForm']
};

export const LIST_FREEDOM_FORM = {
  url: 'freedomFormsApi/freedomForm/forms?',
  name: 'freedomForms',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    "skip": 0,
    "limit": 15
  },
  pagination: 'FREEDOM_FORM_LIST',
  requiredFor: ['main.settings.dataCustomFormsFreedomforms']
};

export const serviceRatesHistory = {
  url: 'activityHistoryApi/history',
  name: 'serviceRatesHistory',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_name",
      "value": "SettingServiceRate",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  requiredFor: ['main.settings.structureServiceRates']
};

// Passed for nav
export const attributeHistory = {
  url: 'activityHistoryApi/history',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_id",
      "operator": "="
    }, {
      "field": "function_name",
      "value": "attributePolicyBusiness",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id'],
  requiredFor: ['main.settings.structurePoliciesAttributeDetails']
};

// Passed for nav
export const promotionHistory = {
  url: 'activityHistoryApi/history',
  name: 'promotionHistory',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "function_id",
      "operator": "="
    }, {
      "field": "function_name",
      "value": "businessPromotion",
      "operator": "="
    }],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id'],
  requiredFor: ['main.settings.automationPromotionsView']
};

// Passed for nav
export const recurringPlanHistory = {
  url: 'activityHistoryApi/history',
  name: 'recurringPlanHistory',
  method: 'GET',
  filterCriteria: {
    "filters": [{
        "field": "function_id",
        "operator": "="
      },
      {
        "field": "function_name",
        "value": "RecurringPlan",
        "operator": "="
      }
    ],
    "defaultSort": [{
      "field": "lastUpdated",
      "order": -1
    }],
    "skip": 0,
    "limit": 15
  },
  onlyPagination: true,
  getSavedSettings: true,
  filterValue: ['id'],
  requiredFor: ['main.settings.structurePoliciesDetails']
};

export const paymentGatewayList = {
  url: 'quotesPaymentApi/gateway/list',
  name: 'paymentGatewayList',
  method: 'GET'
};

export const transactionLists = {
  url: 'quotesPaymentApi/gateway/transactions',
  name: 'transactionLists',
  method: 'GET',
  filterCriteria: {
    skip: 0,
    limit: 10,
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'regionId',
      operator: '='
    }, {
      field: 'paymentModes',
      operator: '=',
      value: 'credit'
    }],
    defaultSort: [{
      "field": "transDate",
      "order": -1
    }]
  },
  filterValue: [false, 'regionId'],
  pagination: 'GATEWAY_TRANSACTIONS'
};

export const allTransaction = {
  url: 'quotesPaymentApi/gateway/transactions',
  name: 'allTransaction',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'paymentModes',
      operator: '=',
      value: 'credit'
    }, {
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      "field": "transDate",
      "order": -1
    }]
  },
  pagination: 'GATEWAY_TRANSACTIONS'
};

export const warrantyDetails = {
  url: "inventoryApi/warranty/:id",
  method: 'GET',
  name: "warrantyDetails",
  params: ['id']
};

export const POLICY_PRODUCTS = {
  url: 'attributePolicyApi/attributePolicy/product/:id',
  method: 'GET',
  name: 'aproducts',
  params: ['id'],
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ATTRIBUTE_POLICY_PRODUCT_LIST'
};
export const SERIAL_NUMBER_ATTACH_PRODUCTS = {
  url: 'inventoryApi/serialNumAttributePolicy/product/:id',
  method: 'GET',
  name: 'aproducts',
  params: ['id'],
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'ATTRIBUTE_POLICY_PRODUCT_LIST'
};

export const detailsRegion = {
  url: 'quotesPaymentApi/gateway/region/detail/:id',
  name: 'detailsRegion',
  method: 'GET',
  params: ['id'],
};

export const DELIVERY_ZONE_LIST = {
  url: 'deliveryZoneApi/delivery/zone?method=true&',
  name: 'DELIVERY_ZONE_LIST',
  method: 'GET'
};

export const DELIVERY_ZONE_DETAIL = {
  url: 'deliveryZoneApi/delivery/zone/:zoneId',
  name: 'DELIVERY_ZONE_DETAIL',
  method: 'GET',
  params: ['zoneId']
};

export const DELIVERY_METHOD_LIST = {
  url: 'deliveryMethodApi/delivery/policy/method/:zoneId',
  name: 'DELIVERY_METHOD_LIST',
  method: 'GET',
  params: ['zoneId']
};

export const DELIVERY_METHOD_DETAIL = {
  url: 'deliveryMethodApi/delivery/policy/method/:zoneId/:methodId',
  name: 'DELIVERY_METHOD_DETAIL',
  method: 'GET',
  params: ['zoneId', 'methodId']
};

export const ACTIVE_DELIVERY_METHOD_LIST = {
  url: 'deliveryMethodApi/delivery/policy/method/:zoneId',
  name: 'ACTIVE_DELIVERY_METHOD_LIST',
  method: 'GET',
  params: ['zoneId'],
  filterCriteria: {
    filters: [{
      field: "status",
      value: 2,
      operator: "="
    }]
  }
};

export const TAX_CATEGORY = {
  url: 'taxCategoryApi/tax/category',
  name: 'category',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'TAX_CATEGORY_LIST'
};
export const CATEGORY_DETAILS = {
  url: 'taxCategoryApi/tax/category/:id',
  name: 'CATEGORY_DETAILS',
  method: 'GET',
  params: ['id']
};

export const TAX_CLASSIFICATION = {
  url: 'taxClassificationApi/tax/classification',
  name: 'classification',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'TAX_CLASSIFICATION_LIST'
};
export const CLASSIFICATION_DETAILS = {
  url: 'taxClassificationApi/tax/classification/:id',
  name: 'CLASSIFICATION_DETAILS',
  method: 'GET',
  params: ['id']
};

export const TAX_ZONE_LIST = {
  url: 'taxZoneApi/tax/zone?method=true&',
  name: 'taxZoneList',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'TAX_ZONE_LIST'
};

export const TAX_ZONE_DETAILS = {
  url: 'taxZoneApi/tax/zone/:taxZoneId',
  name: 'TAX_ZONE_DETAILS',
  method: 'GET',
  params: ['taxZoneId']
};

export const TAX_CODE_LIST = {
  url: 'taxCategoryApi/tax/category',
  name: 'TAX_CODE_LIST',
  method: 'GET',
  filterCriteria: {
    sort: [{
      field: "category",
      order: 1
    }]
  }
};

export const ZONE_METHOD_DETAIL = {
  url: 'taxMethodApi/tax/method/:taxZoneId/:methodId',
  name: 'ZONE_METHOD_DETAIL',
  method: 'GET',
  params: ['taxZoneId', 'methodId']

};
export const ZONE_METHOD_LIST = {
  url: 'taxMethodApi/tax/method/:taxZoneId?',
  name: 'ZONE_METHOD_LIST',
  method: 'GET',
  params: ['taxZoneId'],
  filterCriteria: {
    sort: [{
      field: "seqId",
      order: 1
    }]
  }
};

export const EXEMPT_CUSTOMER_LIST = {
  url: 'taxExemptApi/tax/exempt/customer/:taxZoneId',
  name: 'EXEMPT_CUSTOMER_LIST',
  method: 'GET',
  params: ['taxZoneId']
};

export const METHOD_CLASSIFICATION_DETAIL = {
  url: 'taxMethodClassificationApi/tax/method/classification/:taxZoneId/:methodId',
  name: 'METHOD_CLASSIFICATION_DETAIL',
  method: 'GET',
  params: ['taxZoneId', 'methodId']
};

export const METHOD_CALCULATION_DETAIL = {
  url: 'taxMethodCalculationApi/tax/method/calculation/single/:taxZoneId/:methodId',
  name: 'METHOD_CALCULATION_DETAIL',
  method: 'GET',
  params: ['taxZoneId', 'methodId']
};

export const accountListQB = {
  url: 'quickBookSettingsApi/settings',
  method: 'GET',
  name: 'accountListQB',
  filterCriteria: {
    filters: [],
    defaultSort: []
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const QB_ACCOUNT_DETAIL = {
  url: 'quickBookSettingsApi/settings/:accountId',
  method: 'GET',
  name: 'QB_ACCOUNT_DETAIL',
  params: ['accountId']
};

export const ACCOUNT_AUTHENTICATE_DETAIL = {
  url: 'quickBookSettingsApi/settings/check/authentication/:accountId',
  method: 'GET',
  name: 'ACCOUNT_AUTHENTICATE_DETAIL',
  params: ['accountId']
};

export const SETTING_LOCATION = {
  url: 'quickBookSettingsApi/settings/quickbook/locations/:accountId',
  method: 'GET',
  name: 'SETTING_LOCATION',
  params: ['accountId']
};

export const NEW_ACCOUNT_SETTING_LOCATION = {
  url: 'quickBookSettingsApi/settings/quickbook/locations/newSetting',
  method: 'GET',
  name: 'SETTING_LOCATION'
};

export const REMOTE_LOGIN_DETAIL = {
  url: 'resourceApi/resources/remote/details',
  method: 'GET',
  name: 'REMOTE_LOGIN_DETAIL'
};

export const RESOURCE_LIST = {
  url: 'resourceApi/resources',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    project: [
      "email",
      "images",
      "status",
      "avatar",
      "role_id",
      "first_name",
      "last_name",
      "middle_name",
      "last_updated"
    ],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  },
  pagination: 'RESOURCE_LIST'
};

export const ROLE = {
  url: 'role/:businessId/:id',
  name: 'role',
  auc: true,
  noVerData: true,
  addAccessDataForAuc: true,
  method: 'GET',
  params: ['businessId', 'id']
};

export const CLONE_ROLE = {
  url: 'role/clone/:businessId/:id',
  name: 'role',
  auc: true,
  noVerData: true,
  addAccessDataForAuc: true,
  method: 'GET',
  params: ['businessId', 'id']
};

export const exposedApiKeyList = {
  url: 'api-key?skip=0&limit=15',
  name: 'exposedApiKeyList',
  auc: true,
  noVerData: true,
  addAccessDataForAuc: true,
  method: 'GET'
};

export const apiKeyDetail = {
  url: 'api-key/:id',
  name: 'apiKeyDetail',
  auc: true,
  noVerData: true,
  addAccessDataForAuc: true,
  method: 'GET',
  params: ['id']
};

export const apiSetup = {
  url: 'exposedApi/exposed/api-access-setup/:apiKey',
  method: 'GET',
  name: 'apiSetup',
  params: ['apiKey']
};

export const accessLog = {
  url: 'exposedApi/exposed/access-logs',
  method: 'GET',
  name: 'accessLog',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: 'apiKey',
        operator: '=',
      }
    ],
    defaultSort: [{
      field: 'createdOn',
      order: -1
    }],
    skip: 0,
    limit: 15
  },
  filterValue: [false, 'apiKey'],
  onlyPagination: true,
  getSavedSettings: true,
  params: ['id', 'apiKey'],

};

export const smsSetup = {
  url: 'smsSetupApi/sms/setup/:id',
  method: 'GET',
  name: 'smsSetup',
  params: ['id']
}

export const smsTemplate = {
  url: 'smsTemplateApi/sms/template/:id',
  method: 'GET',
  name: 'smsTemplate',
  params: ['id']
}