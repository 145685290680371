export const COMPANIES_LIST = {
  url: 'companyApi/company',
  method: 'GET',
  name: 'companies',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: 'lastUpdated',
      order: -1
    }]
  },
  pagination: 'COMPANY_LIST'
};

export const ORDER_IMPORT_LIST = {
  url: 'orderImportSetupApi/setups',
  method: 'GET',
  name: 'orderImportList',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }],
    sort: [{
      field: "lastUpdated",
      order: -1
    }],
    skip: 0,
    limit: 15
  },

};

export const ORDER_IMPORT_DETAIL = {
  url: 'orderImportSetupApi/setup/:orderImportId',
  method: 'GET',
  name: 'orderImportData',
  data: {
    type: "detail"
  },
  params: ['orderImportId', 'quoteId'],
};

export const ORDER_IMPORT_SETUP = {
  url: 'orderImportSetupApi/setup/import/:importId',
  method: 'GET',
  name: 'orderImportSetupData',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }],
    skip: 0,
    limit: 15
  },

  params: ['importId'],
};

export const policiesList = {
  url: 'recurringPlanApi/policies?stats=yes&',
  name: 'policies',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      },
      {
        field: "type",
        value: 10,
        operator: "="
      }

    ],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'POLICY_LIST'
};

export const CONVERSATION_NAVIGATION = {
  url: 'conversationApi/conversations/nav/:customerId',
  name: 'conversationNavigation',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'modelId',
      operator: '='
    }]
  },
  filterValue: ['quoteId'],
  params: ['customerId'],
  requiredFor: ['main.quotes.notes']
};

export const entities = {
  url: 'businessEntityApi/business/entity',
  method: 'GET',
  name: 'entities'
};

export const QUOTE_LIST = {
  url: 'quoteApi/quotes/list?list=true&location=true&stats=yes&',
  name: 'quotes',
  method: 'GET',
  data: {
    type: 'quote'
  },
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isJob',
      operator: '=',
      value: false
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'QUOTE_LIST',
  requiredFor: ['main.quoteList']
};

export const JOB_LIST = {
  url: 'quoteApi/quotes/list?location=true&stats=yes&',
  name: 'quotes',
  method: 'GET',
  data: {
    type: 'job'
  },
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isJob',
      operator: '=',
      value: true
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  pagination: 'JOB_LIST',
  requiredFor: ['main.jobList']
};

// export const JOB_LIST = {
//   url: 'quoteApi/quotes/list',
//   name: 'quotes',
//   //if method is POST then optional. 'GET type is mandatory'
//   method: 'GET',
//   data: {
//     type: 'job'
//   },
//   filterCriteria: {
//     filters: [{
//       field: 'isArchived',
//       operator: '=',
//       value: false
//     }, {
//       field: 'status',
//       operator: '=',
//       value: 30
//     }]
//   },
//   pagination: 'JOB_LIST'
// };

export const QUOTE_MAP_LIST = {
  url: 'quoteApi/quotes/list?stats=yes&map=true&location=true&',
  name: 'quoteDataMap',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isJob',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '!=',
      value: 10
    }]
  },
  pagination: 'QUOTE_LIST'
};

export const JOB_MAP_LIST = {
  url: 'quoteApi/quotes/list?stats=yes&map=true&',
  name: 'quoteDataMap',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isJob',
      operator: '=',
      value: true
    }]
  },
  pagination: 'JOB_LIST'
};

export const GUIDER = {
  url: 'quoteApi/quotes/:quoteId',
  name: 'guiderData',
  // This is static type data
  data: {
    type: 'guider'
  },
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.editGuider'],
};

export const SALES_AGENT = {
  url: 'resourceApi/resources',
  method: 'GET',
  name: 'salesAgents',
  requiredFor: ['main.quotes.editGuider', 'main.quotes.createQuoteGuider']

};

export const VISIT_LIST = {
  url: 'onsiteApi/visits/quotes/:quoteId?stats=yes&',
  name: 'visits',
  method: 'GET',
  params: ['quoteId'],

  filterCriteria: {
    "filters": [{
      "field": "isArchived",
      "value": false,
      "operator": "="
    }]
  },
  pagination: 'VISIT_LIST',
  requiredFor: ['main.quotes.meetings'],

};

export const VISIT_DETAIL = {
  url: 'onsiteApi/visits/:visitId/quotes/:quoteId/details',
  name: 'visitDetail',
  method: 'GET',
  params: ['quoteId', 'visitId'],
  requiredFor: ['main.quotes.visitDetail', 'main.quotes.editVisit', 'main.quotes.addVisit'],
  forcedBackFor: {
    'main.quotes.visitDetail': ['main.quotes.meetings'],
    'main.quotes.editVisit': ['main.quotes.meetings'],
    'main.quotes.addVisit': ['main.quotes.meetings']
  }
};

export const QUOTE_DETAIL = {
  url: "quoteApi/quotes/:quoteId",
  name: "contactDetail",
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.editVisit'],
  forcedBackFor: {
    'main.quotes.editVisit': ['main.quotes.meetings']
  }
};

export const SYSTEM_TAGS = {
  url: "tagApi/tag/show/:quoteId",
  name: "SYSTEM_TAGS",
  method: 'GET',
  params: ['quoteId'],
};

export const QUOTE_TRANSACTION_HISTORY = {
  url: 'quoteApi/quotes/:quoteId/transaction',
  name: 'QUOTE_TRANSACTION_HISTORY',
  method: 'GET',
  params: ['quoteId'],
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }]
  }
};

export const CONTACT = {
  url: 'contactApi/customers/quote/:quoteId?flow=quote&',
  name: 'contact',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.editQuote', 'main.quotes.contact'],
  forcedBackFor: {
    'main.quotes.editQuote': ['main.quoteList'],
    'main.quotes.contact': ['main.quoteList']
  }
};

function pad(value) {
  return value < 10 ? '0' + value : value;
}

function createOffset(date) {
  let sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
  let offset = Math.abs(date.getTimezoneOffset());
  let hours = pad(Math.floor(offset / 60));
  let minutes = pad(offset % 60);
  return sign + hours + '' + minutes;
}

export const SUMMARY = {
  url: 'invoiceApi/quote/:quoteId/invoice/regular?action=preview&showPrice=true&timeOffset=' + createOffset(new Date()) + '&',
  name: 'summary',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.summary']
};

export const SUBMIT = {
  url: 'quoteApi/quotes/:quoteId',
  name: 'estimate',
  method: 'GET',
  data: {
    type: 'header'
  },
  params: ['quoteId'],
  requiredFor: ['main.quotes.submit']
};

export const FETCH_TEMPLATES = {
  url: 'emailTemplateApi/email/template?variations=true&',
  name: 'templates',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "modelName",
      "value": [20, 28],
      "operator": "in"
    }, {
      "field": "status",
      "value": 2,
      "operator": "="
    }, {
      "field": "type",
      "value": ['submit', 'void', 'cancel', 'accept'],
      "operator": "nin"
    }]
  },
  requiredFor: ['main.quotes.submit']
};

// Passed for nav
export const quoteHistory = {
  url: 'activityApi/activity',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'quote_id',
      operator: '='
    }, {
      field: 'model',
      operator: 'in',
      value: ["quote", "quote_action_item", "quote_recurring_estimate", "quote_recurring_material", "quote_recurring_product", "quote_recurring_promotion", "quote_recurring_service", "quote_regular_estimate", "quote_regular_material", "quote_regular_product", "quote_regular_promotion", "quote_regular_service", "quote_visit", "quote_regular_payment", "quote_recurring_payment"]
    }, {
      field: 'activity_type',
      operator: '=',
      value: 'general'
    }],
    skip: 0,
    limit: 15
  },
  filterValue: ['quoteId'],
  pagination: 'ACTIVITY_LIST',
  requiredFor: ['main.quotes.history']
};

export const jobHistory = {
  url: 'activityApi/activity',
  name: 'history',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'quote_id',
        operator: '=',
      },
      {
        field: 'model',
        operator: 'in',
        value: ["quote", "quote_action_item", "quote_recurring_estimate", "quote_recurring_material", "quote_recurring_product", "quote_recurring_promotion", "quote_recurring_service", "quote_regular_estimate", "quote_regular_material", "quote_regular_product", "quote_regular_promotion", "quote_regular_service", "quote_visit", 'job', 'job_action_item', 'job_recurring_estimate', 'job_recurring_material', 'job_recurring_material_fulfillment', , 'job_recurring_product', 'job_recurring_product_fulfillment', 'job_recurring_promotion', 'job_recurring_service', 'job_regular_estimate', 'job_regular_material', 'job_regular_material_fulfillment', 'job_regular_product', 'job_regular_product_fulfillment', 'job_regular_promotion', 'job_regular_service', 'job_visit', 'quote_recurring_payment', 'quote_regular_payment']
      }, {
        field: 'activity_type',
        operator: '=',
        value: 'general'
      }
    ],
    skip: 0,
    limit: 15
  },
  filterValue: ['quoteId'],
  pagination: 'ACTIVITY_LIST',
  requiredFor: ['main.jobs.history']
};

// export const jobHistory = {
//   url: 'activityApi/activity',
//   name: 'history',
//   method: 'GET',
//   filterCriteria: {
//     filters: [{
//       field: 'model_id',
//       operator: '=',
//     }, {
//       field: 'model',
//       operator: '=',
//       value: 'quote'
//     }, {
//       field: 'activity_type',
//       operator: '=',
//       value: 'general'
//     }],
//     skip: 0,
//     limit: 15
//   },
//   filterValue: ['quoteId'],
//   requiredFor: ['main.quotes.history'],
//   pagination: 'ACTIVITY_LIST',
// };

export const JOB_ACTION_LIST = {
  url: 'actionsApi/function/action',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }, {
      field: "modelId",
      operator: "="
    }, {
      field: "status",
      value: 1,
      operator: "="
    }, {
      field: "modelName",
      value: 21,
      operator: "="
    }, {
      field: "is_system",
      value: false,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  filterValue: [false, 'quoteId'],
  pagination: 'ACTION_LIST',
  requiredFor: ['main.quotes.action']
};

// Passed for nav
export const QUOTE_ACTION_LIST = {
  url: 'actionsApi/function/action',
  name: 'actionActive',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      value: false,
      operator: "="
    }, {
      field: "modelId",
      operator: "="
    }, {
      field: "status",
      value: 1,
      operator: "="
    }, {
      field: "modelName",
      value: 20,
      operator: "="
    }, {
      field: "is_system",
      value: false,
      operator: "="
    }],
    defaultSort: [{
      field: "lastUpdated",
      order: -1
    }]
  },
  filterValue: [false, 'quoteId'],
  pagination: 'ACTION_LIST',
  requiredFor: ['main.quotes.action']
};

export const CUSTOM_FORM_QUOTE = {
  url: 'customFieldsApi/customFields?',
  name: 'CUSTOM_FORM_QUOTE',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'quotes_guider_form',
      operator: "="
    }, {
      field: "status",
      value: 2,
      operator: "="
    }]
  }
};

export const CUSTOM_FORM_CUSTOMER = {
  url: 'customFieldsApi/customFields?',
  name: 'CUSTOM_FORM_CUSTOMER',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "formTarget",
      value: 'contact_form',
      operator: "="
    }, {
      field: "status",
      value: 2,
      operator: "="
    }]
  }
};

// Passed for nav
export const ACTION_LIST_COUNT = {
  url: 'actionsApi/action/folder/overall/stat?',
  name: 'ACTION_LIST_COUNT',
  method: 'GET',
  filterCriteria: {
    "filters": [{
      "field": "modelId",
      "operator": "=",
    }]
  },
  filterValue: ['quoteId']
};

export const quoteInfo = {
  url: 'quotesPaymentApi/quote/:quoteId/regular',
  name: 'quoteInfo',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const quoteSeriveInfo = {
  url: 'quotesPaymentApi/quote/:quoteId/regular/service',
  name: 'quoteSeriveInfo',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const paymentInfo = {
  url: 'quotesPaymentApi/quote/:quoteId/regular/payment',
  name: 'paymentInfo',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const autoCycles = {
  url: 'quotesPaymentApi/quote/:quoteId/regular/auto/cycles',
  name: 'autoCycles',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const quoteRecurringSeriveInfo = {
  url: 'quotesPaymentApi/quote/:quoteId/recurring/service',
  name: 'quoteRecurringSeriveInfo',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const recurringAutoCycles = {
  url: 'quotesPaymentApi/quote/:quoteId/recurring/auto/cycles',
  name: 'recurringAutoCycles',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const quotesPaymentTranscation = {
  url: 'quotesPaymentApi/payment/transactions/:quoteId',
  name: 'quotesPaymentTranscation',
  method: 'GET',
  params: ['quoteId'],
  requiredFor: ['main.quotes.payment']
};

export const GET_ALL_RESOURCE_LIST_F = {
  url: 'resourceApi/resources/rnp/20/:quoteId',
  name: 'resourcesF',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: 'in',
      value: [2, 3]
    }],
    sort: [{
      field: "firstName",
      order: 1
    }]
  },
  params: ['quoteId'],
  // requiredFor: ['main.resources.resources']
};

export const RESOURCE_LIST_F = {
  url: 'resourceApi/resources/rnp/20/:quoteId',
  name: 'resourcesF',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: 'in',
      value: [2, 3]
    }],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  },
  params: ['quoteId'],
  pagination: 'RESOURCE_LIST'
  // requiredFor: ['main.resources.resources']
};

export const RESOURCE_LIST = {
  url: 'resourceApi/resources',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'salesAgent',
      value: true,
      operator: "=",
      type: "bool"
    }]
  },
  // params: ['quoteId'],
  pagination: 'RESOURCE_LIST',
};

export const SALES_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'salesOrganization',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isSales",
      value: true,
      operator: "=",
    }],
    sort: [{
      field: 'orgName',
      order: 1
    }]
  }
};

export const ALL_ORGANIZATION = {
  url: 'resourceApi/organizations',
  name: 'ALL_ORGANIZATION',
  method: 'GET'
};

export const RECURRING_SERVICE_LIST = {
  url: 'taskApi/services/quotes/:quoteId?stats=yes&',
  method: 'GET',
  name: 'recurringServices',
  data: {
    type: 'list'
  },
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'taskScheduleType',
      operator: '=',
      value: 3
    }],
    defaultSort: [{
      field: "createdAt",
      order: -1
    }]
  },
  params: ['quoteId'],
  pagination: 'SERVICE_LIST',
  requiredFor: ['main.quotes.services']
};

export const PREP_CYCLE_STATUS = {
  url: 'taskApi/quotes/prep-cycle-status/:quoteId',
  method: 'GET',
  name: 'PREP_CYCLE_STATUS',
  params: ['quoteId']
};

export const RECURRING_SERVICE_LIST_INVOICE = {
  url: 'taskApi/services/quotes/:quoteId?stats=yes&',
  method: 'GET',
  name: 'recurringServicesInvoice',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'taskScheduleType',
      operator: '=',
      value: 3
    }, {
      field: "isMaster",
      operator: "!=",
      value: true
    }],
    defaultSort: [{
      field: "cycleNumber",
      order: 1
    }]
  },
  params: ['quoteId'],
};

export const ASSIGNED_DASHBOARD = {
  url: 'dashboardApi/dashboard/resource/list?moduleName=20&',
  name: 'ASSIGNED_DASHBOARD',
  method: 'GET'
};
export const DASHBOARD_LIST = {
  url: 'dashboardApi/dashboard',
  name: 'DASHBOARD_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "moduleName",
      value: 20,
      operator: "=",
    }]
  }
};

export const ASSIGNED_DASHBOARD_JOBS = {
  url: 'dashboardApi/dashboard/resource/list?moduleName=28&',
  name: 'ASSIGNED_DASHBOARD_JOBS',
  method: 'GET'
};
export const DASHBOARD_LIST_JOBS = {
  url: 'dashboardApi/dashboard?',
  name: 'DASHBOARD_LIST_JOBS',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "moduleName",
      value: 28,
      operator: "=",
    }]
  }
};

export const BLOCK_DETAIL = {
  url: 'blocksApi/block/:KBIBlockId',
  name: 'BLOCK_DETAIL',
  method: 'GET',
  params: ['KBIBlockId']
};

export const SALES_AGENT_LIST = {
  url: 'resourceApi/resources/filter',
  name: 'SALES_AGENT_LIST',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "isArchived",
      operator: "=",
      value: false
    }, {
      field: "salesAgent",
      value: true,
      operator: "=",
      type: "bool"
    }],
    project: ["_id", "first_name", "last_name", "business_id"]
  }
};

export const INVOICE_TEMPLATE_LIST = {
  url: 'invoiceApi/quote/:quoteId/invoice/template',
  name: 'INVOICE_TEMPLATE_LIST',
  method: 'GET',
  params: ['quoteId']
};

export const quoteDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'quoteDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 2,
      operator: "="
    }],
    // sort: [{
    //   field: "firstName",
    //   order: 1
    // }]
  },
  filterValue: ['quoteId'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const jobDocument = {
  url: 'assocDocApi/assoc/doc',
  name: 'quoteDocument',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: "modelId",
      operator: "="
    }, {
      field: "modelName",
      value: 3,
      operator: "="
    }],
    // sort: [{
    //   field: "firstName",
    //   order: 1
    // }]
  },
  filterValue: ['quoteId'],
  pagination: 'ASSOCIATED_DOCUMENT_LIST',
};

export const documentDetail = {
  url: 'assocDocApi/assoc/doc/:id',
  name: 'documentDetail',
  method: 'GET',
  params: ['id']
};

export const QUOTE_FLAG = {
  url: 'quotesPaymentApi/quote/web/payment/webDetails/:quoteId',
  name: 'QUOTE_FLAG',
  method: 'GET',
  params: ['quoteId']
};

export const SCHEDULED_SERVICE_LIST = {
  url: 'resourceApi/scheduleTask?type=tasks&',
  name: 'quoteScheduleService',
  method: 'GET',
  filterCriteria: {
    filters: [{
        field: 'isArchived',
        operator: '=',
        value: false
      }, {
        field: 'isMaster',
        operator: '!=',
        value: true
      }, {
        field: 'startTime',
        operator: 'dtrange',
        value: []
      },
      // {
      //   field: 'status',
      //   operator: 'in',
      //   value: [10, 11, 20, 25, 30, 42, 50, 55]
      // }
    ],
    defaultSort: [{
      field: "startTime",
      order: -1
    }],
  },
  pagination: 'SERVICE_SCHEDULING_LIST'
};