export const AVAILABLE_TEMPLATES = {
  url: 'drawingTemplateApi/drawing/templates?default=true&',
  method: 'GET',
  name: 'availTemplates',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isSystem',
      operator: '=',
      value: true
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const SAVED_TEMPLATES = {
  url: 'drawingTemplateApi/drawing/templates',
  method: 'GET',
  name: 'savedTemplates',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'isSystem',
      operator: '!=',
      value: true
    }],
    skip: 0,
    limit: 15
  },
  onlyPagination: true,
  getSavedSettings: true
};

export const DIAGRAM_CATEGORIES = {
  url: 'drawingTemplateCategoriesApi/drawing/template/categories',
  name: 'categories',
  method: 'GET'
};

export const DRAWING_TEMPLATE = {
  url: 'drawingTemplateApi/drawing/template/:templateId',
  name: 'template',
  method: 'GET',
  params: ['templateId']
};

export const DRAWING = {
  url: 'drawingsApi/drawings/:drawingId',
  name: 'drawing',
  method: 'GET',
  params: ['drawingId']
};