import {
  DOCUMENT_LIST_WO,
  SERVICE_DETAIL_WO,
  documentDetail
} from './../../components/update-workorder/wo.resolve.obj';

export const RESOURCE_LIST = {
  url: 'resourceApi/resources?stats=yes&',
  name: 'resources',
  method: 'GET',
  filterCriteria: {
    filters: [{
      field: 'isArchived',
      operator: '=',
      value: false
    }, {
      field: 'status',
      operator: '=',
      value: 2
    }],
    project: ['images', 'avatar', 'first_name', 'middle_name', 'last_name', 'last_updated', 'bz_add', 'pri_phone', 'org_details.organizations.primary', 'org_details.organizations.secondary'],
    defaultSort: [{
      field: "firstName",
      order: 1
    }]
  }
};

import calendarFrameTemplate from './calendar-frame/calendar-frame.html';
import calendarTemplate from './calendar/calendar.html';
import updateWorkorderCommonTemplate from './../../components/update-workorder/update-workorder-common.html';
import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';

export default function calendarRoute($urlRouterProvider, $stateProvider, calendarResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.calendar', resolver('calendar', calendarFrameTemplate, 'CalendarFrameController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule]
    }, {
      cbac: 'calendar'
    }))

    .state('main.calendarEvents', resolver('calendar-events', calendarTemplate, 'CalendarController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [RESOURCE_LIST]
    }, {
      cbac: 'calendar'
    }))

    .state('main.calendar.workorder', resolver('work-order/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'calendar_personal_work_order'
    }))

    .state('main.calendar.workorderDocumentDetail', resolver('wo-document/:quoteId/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule],
      preData: [documentDetail]
    }, {
      cbac: 'calendar_personal_work_order_documents'
    }))

    .state('main.calendar.addWoDocument', resolver('wo-document/:quoteId/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'calendar_personal_work_order_documents',
      isWrite: true
    }))

    .state('main.calendar.updateWoDocument', resolver('wo-document/:quoteId/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [calendarResolverProvider.loadCalendarModule],
      preData: [documentDetail]
    }, {
      cbac: 'calendar_personal_work_order_documents',
      isWrite: true
    }));
}