import customFormSignatureTemplate from './custom-form-signature.html';
export default function(app) {
  app.directive('customFormSignature', customFormSignature);

  function customFormSignature() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: customFormSignatureTemplate
    };
  }
}