import syncInformationTemplate from '../../components/popup-view/sync-information-template.html';
export default function(app) {
  app.factory('QuickbookService', QuickbookService);

  function QuickbookService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage) {

    "ngInject";

    return {
      syncNow: syncNow
    }

    function syncNow(ev, vm, settingId) {
      $mdDialog.show({
        clickOutsideToClose: false,
        controller: SyncNowController,
        controllerAs: 'cd',
        templateUrl: syncInformationTemplate,
        parent: angular.element(document.body),
        targetEvent: ev,
        multiple: true,
        locals: {
          vm: vm,
          settingId: settingId
        }
      });
    }

    function SyncNowController($scope, $mdDialog, $rootScope, $filter, vm, settingId) {
      var cd = this;
      cd.confirm = confirm;
      cd.cancel = cancel;

      function cancel() {
        $mdDialog.cancel();
      }

      function confirm() {
        cd.cancel();
        vm.startSyn[settingId] = true;
        dataServices.post({
          url: serverUrl.main + 'quickBookApi/sync/jobs/' + settingId + '?',
          spinner: false
        }).then(function(response) {
          if (response && response.data) {
            messages.simpleToast('Account sync successfully.');
            vm.startSyn[settingId] = false;
          }
        });
      }
    }
  }
}
