import clockTimePerResourceTemplate from './clock-time-per-resource.html'

export default function(app) {
  app.directive('clockTimePerResource', clockTimePerResource);

  function clockTimePerResource() {
    return {
      restrict: 'E',
      templateUrl: clockTimePerResourceTemplate,
      replace: true
    };
  }
}