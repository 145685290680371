import detailAddressTemplate from './detailAddressTemplate.html';
export default function(app) {
  app.directive('detailAddress', detailAddress);

  function detailAddress() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: detailAddressTemplate
    };
  }
}
