import searchToolbarTemplate from './search-toolbar.html';

export default function(app) {
  app.directive('searchToolbar', searchToolbar)
    .controller("searchToolbarController", searchToolbarController);

  function searchToolbar() {
    return {
      restrict: 'E',
      // replace: true, // replace needs single root element
      templateUrl: searchToolbarTemplate,
      controller: 'searchToolbarController',
      controllerAs: 'st'
    };
  }

  function searchToolbarController(ListService) {
    "ngInject";

    let st = this;

    document.onkeydown = function(e) {
      if (e.keyCode === 27) {
        ListService.hideSearch();
      }
    };
  }
}