import forEach from 'lodash/forEach';

export default function(app) {
  app.factory('messages', messages);

  function messages($mdDialog, $mdToast, $q, $filter) {

    "ngInject";

    return {
      simpleToast: simpleToast,
      showActionToast: showActionToast,
      mdalert: mdalert,
      mdconfirm: mdconfirm,
      nativeAlert: nativeAlert,
      confirm: confirm,
    };

    // msg: message to be shown in toast
    // theme: color theme to be shown(warning or danger or success)
    // time: time duration toast is shown
    function simpleToast(msg, theme, time = 3000) {
      $mdToast.show(
        $mdToast.simple()
        .textContent($filter('translate')(msg))
        .position('bottom right')
        .hideDelay(time)
        .theme(theme ? theme + "-toast" : "")
        .parent(".wrapper")
      );
    }

    function showActionToast(msg, action, theme, time = 6000, callback, position = 'bottom right') {
      const toast = $mdToast.simple()
        .textContent($filter('translate')(msg))
        .action($filter('translate')(action))
        .highlightAction(true)
        .highlightClass('md-primary') // Accent is used by default, this just demonstrates the usage.
        .position(position)
        .hideDelay(time)
        .theme(theme ? theme + "-toast" : "")
        .parent(".wrapper");

      $mdToast.show(toast).then(function(response) {
        if (response == 'ok') {
          if (angular.isFunction(callback)) {
            callback();
          }
        }
      }, function(reason) {
        // console.log(reason);
      });
    }

    function mdalert(msg) {
      $mdDialog.show(
        $mdDialog.alert()
        .clickOutsideToClose(true)
        .title($filter('translate')('ALERT'))
        .textContent($filter('translate')(msg))
        .ariaLabel($filter('translate')('ALERT_DIALOG'))
        .ok($filter('translate')('OK'))
      );
    }

    function mdconfirm(ev, content, confirmText, cancelText, titleText) {
      var deferred = $q.defer();
      var confirm = $mdDialog.confirm()
        .title($filter('translate')(titleText || 'CONFIRM'))
        .textContent($filter('translate')(content))
        .ariaLabel($filter('translate')('CONFIRM'))
        .clickOutsideToClose(true)
        .ok($filter('translate')(confirmText || 'YES'))
        .cancel($filter('translate')(cancelText || 'CANCEL'))
        .multiple(true);

      if (ev) {
        confirm.targetEvent(ev);
      }

      $mdDialog.show(confirm).then(function() {
        deferred.resolve(true);
      }, function() {
        deferred.resolve(false);
      });
      return deferred.promise;
    }

    function nativeAlert(msg) {
      if (navigator.notification) {
        if (typeof msg == 'string') {
          navigator.notification.alert($filter('translate')(msg), null, $filter('translate')("ALERT"), $filter('translate')("OK"));
        } else if (typeof msg == 'object') {
          var fullMsg = '';
          forEach(msg, function(val) {
            fullMsg += $filter('translate')(val) + ' ';
          });
          navigator.notification.alert(fullMsg, null, $filter('translate')("ALERT"), $filter('translate')("OK"));
        }
      } else {
        alert($filter('translate')(msg));
      }
    }

    function confirm(msg, confirmText, confirmTitle) {
      var message = '';
      if (typeof(msg) == 'string') {
        message = msg;
      } else if (typeof(msg) == 'object') {
        forEach(msg, function(str) {
          message += ' ' + $filter('translate')(str);
        });
      }

      return navigator.notification.confirm(message, function(result) {
        return result;
      }, $filter('translate')(confirmTitle || 'CONFIRM'), [$filter('translate')(confirmText), $filter('translate')('CANCEL')]);
    }
  }
}