export default function ProposalCategoriesController($scope, $mdDialog, $timeout, messages, maxlength, errorMessage, dataServices, serverUrl, validatorService, vm) {

  'ngInject';

  let dc = this;

  dc.maxlength = maxlength;
  dc.errorMessages = errorMessage;
  dc.save = validatorService.validateForm(dc, saveCategories);

  function getCategories() {
    dataServices.get({
        url: serverUrl.main + 'documentBuilderApi/proposal-document/category?',
        spinnerName: 'proposal-categories-spinner'
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          dc.categories = data.result;
        }
      });
  }

  $timeout(function() {
    getCategories();
  }, 0);

  function saveCategories() {
    dc.categoryData = {
      add: [],
      update: []
    };

    angular.forEach(dc.categories, function(category) {
      if (category.id) {
        dc.categoryData.update.push(dataServices.toSave(category, ['id', 'name']));
      } else {
        dc.categoryData.add.push(dataServices.toSave(category, ['name']));
      }
    });

    dataServices.post({
        url: serverUrl.main + "documentBuilderApi/proposal-document/category",
        data: {
          data: angular.toJson(dc.categoryData)
        },
        spinnerName: 'proposal-categories-spinner'
      })
      .then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          // dc.categories = data.result;
          // getCategories();
          messages.simpleToast('Categories updated.');
          dc.cancel();
        }
      });
  }

  dc.addCategory = function() {
    dc.categories.push({});
  };

  dc.deleteCategory = function(ev, index) {
    let category = dc.categories[index];
    if (category.hasOwnProperty('id')) {
      messages.mdconfirm(ev, 'Deleting a proposal category is permanent. Are you sure you want to delete this category?')
        .then(function(flag) {
          if (flag) {
            dataServices.delete({
                url: serverUrl.main + 'documentBuilderApi/proposal-document/category/' + category.id,
                spinnerName: 'proposal-categories-spinner'
              })
              .then(function(response) {
                let data = response.data;
                if (data && data.response_code == 200) {
                  dc.categories = data.result;
                  getCategories();
                  messages.simpleToast('Category Deleted Successfully.');
                }
              });
          }
        });
    } else {
      dc.categories.splice(index, 1);
    }
  };

  dc.cancel = function() {
    $mdDialog.cancel();
  };
}