import contactAddressListTemplate from './contactAddressListTemplate.html';

export default function (app) {
    app.directive('contactAddressList', contactAddressList);

    function contactAddressList() {
        return {
            restrict: 'E',
            // replace: true,
            templateUrl: contactAddressListTemplate
        };
    }
}
