export default function(app) {
  app.factory('commonService', common);

  function common($state, serverUrl, dataServices, $rootScope, $filter, $timeout, MIME_TYPES, FILE_TYPES) {

    "ngInject";

    return {
      setTitle: setTitle,
      getServerUrl: getServerUrl,
      insertAtCursor: insertAtCursor,
      convertToEpoche: convertToEpoche,
      validateFileExt: validateFileExt,
      getFileMimeType: getFileMimeType,
      imageValidation: imageValidation,
      validateMultiFileExt: validateMultiFileExt,
      compressImageOrPushFile: compressImageOrPushFile
    };

    function compressImageOrPushFile(file, fileArray, callback) {
      let extension = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (FILE_TYPES.img[extension] && extension != 'gif') {
        new Compressor(file, {
          quality: .6,
          strict: true,
          // convertSize: Infinity,
          success(compressedFile) {
            $timeout(function() {
              console.log(compressedFile, compressedFile.size / 100000);
              fileArray.push((compressedFile.size / 1024) > 20 ? compressedFile : file);
              if (angular.isFunction(callback)) {
                callback();
              }
            }, 0);
          },
          error(e) {
            console.log(e);
          }
        });
      } else {
        fileArray.push(file);
      }
    }

    function getFileMimeType(file) {
      let extension = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (!extension) {
        return;
      } else {
        extension = extension.toLowerCase();
      }

      return MIME_TYPES[extension];
    }

    // Set title to be displayed in browser
    function setTitle() {
      let currentState = $rootScope.currentStateName.split('.'),
        len = currentState.length;

      if (len > 1) {
        $rootScope.title = $filter('translate')(currentState.pop().split(/(?=[A-Z])/).join('_').toUpperCase());
      } else if (len == 1) {
        $rootScope.title = $filter('translate')(currentState[0].toUpperCase());
      }
    }

    // used in media repo
    function imageValidation(fileName) {
      let extension = fileName.slice(fileName.lastIndexOf('.') + 1);
      // for (let imgExt in FILE_TYPES.img) {
      //   if (extension && imgExt == extension) {
      //     return true;
      //   }
      // }
      return Boolean(FILE_TYPES.img[extension]);
    }

    function convertToEpoche(date) {
      return new Date(date).getTime();
    }

    // validate file extension and mime type for upload [input: file object]
    function validateFileExt(file, type) {
      let extension = file.name.slice(file.name.lastIndexOf('.') + 1);
      console.log(extension, file);
      if (!extension) {
        return;
      } else {
        extension = extension.toLowerCase();
      }

      for (var apiExt in FILE_TYPES) {
        for (var fileExt in FILE_TYPES[apiExt]) {
          if (extension == fileExt) {
            if (type == 'googleDrive' || type == 'dropbox') {
              return apiExt;
            } else if (file.type == FILE_TYPES[apiExt][fileExt]) {
              return apiExt;
            }
          }
        }
      }
      return;
    }

    function validateMultiFileExt(files, callback) {
      var exts = [];
      for (var i = 0; i < files.length; i++) {
        let extension = files[i].name.slice(files[i].name.lastIndexOf('.') + 1);
        if (!extension) {
          continue;
        } else {
          extension = extension.toLowerCase();
        }
        for (var apiExt in FILE_TYPES) {
          for (var fileExt in FILE_TYPES[apiExt]) {
            if (extension == fileExt && files[i].type == FILE_TYPES[apiExt][fileExt]) {
              exts.push(apiExt);
            }
          }
        }
      }
      callback(files.length == exts.length ? exts : false);
    }

    function getServerUrl() {
      var url = serverUrl.main.split('/');
      url.splice(-2, 2);
      return url.join('/');
    }

    function insertAtCursor(textFieldId, textToInsert) {
      let textField = document.getElementById(textFieldId);
      if (!textField) {
        return;
      }

      //IE support
      if (document.selection) {
        textField.focus();
        sel = document.selection.createRange();
        sel.text = textToInsert;
      }

      //MOZILLA and others
      else if (textField.selectionStart || textField.selectionStart == '0') {
        var startPos = textField.selectionStart;
        var endPos = textField.selectionEnd;
        return textField.value.substring(0, startPos) +
          textToInsert +
          textField.value.substring(endPos, textField.value.length);
      } else {
        return textField.value + textToInsert;
      }
    }
  }
}