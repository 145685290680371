'use strict';

// Import all css files
import "_stylesheets/webpack-app.scss";

// Import run configs
import config from './config/index.config';
import run from './config/index.run';

// Import all components - reused templates
import componentModule from './components/index.component';
// Import core module
import coreModule from './core/core.module';
// Import route lists
import indexRoutes from './config/index.routes';
// Import template cache list
import datePickerTemplates from './templateCache/templateCacheList';
// import firebase from './core/firebase/firebase.module';
// Dragula - Drag and drop library
import angularDragula from 'angularjs-dragula';
import 'angularjs-dragula/dist/dragula.css';

const App = angular.module(
  "myNgApp", [
    // plugins
    "ui.router",
    "ngSanitize",
    "ngMaterial",
    "ngMessages",
    "oc.lazyLoad",
    "pascalprecht.translate",
    "LocalStorageModule",
    // "ng.deviceDetector",
    "angularSpinner",
    "ui.bootstrap.module.accordion",
    "ui.bootstrap.module.datepicker",
    "ui.bootstrap.module.tooltip",
    "ui.bootstrap.module.popover",
    "ngFileUpload",
    // "ngIdle",
    angularDragula(angular),
    "ui.calendar",
    // core
    coreModule.name,
    // routes
    indexRoutes.name,
    // Add all components like footer menu, left side nav, right Side nav
    componentModule.name,
    // Inject module name of datepickerTemplate.
    datePickerTemplates.name
  ]
).factory('$exceptionHandler', function($log, ErrorHandler) {

  "ngInject";

  return function myExceptionHandler(exception, cause) {
    $log.log('error caught');
    $log.error(exception);
    // TODO: Uncomment this later
    // if (process.env.NODE_ENV === 'production') {
    //   ErrorHandler.saveErrorToBackend(exception, cause);
    // }
  };
});

App
  .config(config)
  .run(run);

export default App;