export default function(app) {
  app.constant('tours', {
    main_menu_tour: {
      name: 'Main Menu Tour',
      icon: 'main-menu-tour',
      state: 'main.dashboard.mydashboard',
      description: 'Take a quick tour of main modules. Learn what you can do in each.'
    },
    quote_dashboard_tour: {
      name: 'Dashboard Tour',
      icon: 'dashboard-tour',
      state: 'main.quotes.editQuoteDashboard',
      description: 'Dashboard are spread across each module. Take a tour to learn more.'
    },
    settings_tour: {
      name: 'Settings Tour',
      icon: 'settings-tour',
      state: 'main.settings.setupProfileDetails',
      description: 'Powerful Settings that help setup your business the way you prefer.'
    }
  });
}