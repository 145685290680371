import ServiceListTemplate from './service/service-list.html';
import ServiceDetailTemplate from './service/service-detail.html';
import EditServiceTemplate from './service/editServices.html';
import estimateTemplate from './estimate/servicesEstimateDetail.html';
import updateEstimateTemplate from './estimate/updateEstimate.html';
import viewOrAddSOWTemplate from './estimate/view-add-sow/view-add-sow.html';

import EditRecurringServiceTemplate from "./service/editRecurringService.html";

import ProductsTemplate from "./products/product-list/products.html";
import SavePromotionTemplate from "./service/saving/save-edit.html";
import ProductsDetailTemplate from './products/product-list/regular-product-details/regular-product-detail.html';

import AddBundleTemplate from './products/product-list/add-bundle/add-bundle.html';
import AddPackageTemplate from './products/product-list/add-package/add-package.html';

import PackageProductCustomizeTemplate from './products/product-list/add-package/package-product-customize/customize-product.html';
import CartDashboardTemplate from './products/cartDashboard.html';

import updateWorkorderCommonTemplate from '../../../../components/update-workorder/update-workorder-common.html';
import updateDocumentTemplate from '../../../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from '../../../../components/associate-documents/documentDetailTemplate.html';

import {
  SERVICE_LIST,
  SERVICE_DETAIL,
  ESTIMATE_DETAIL,
  ScopeOfWorks,
  LIST_SERVICE_RATES,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_CART,
  BUNDLE_DETAIL,
  BUNDLE_DETAIL_CART,
  PACKAGE_DETAIL,
  PACKAGE_DETAIL_CART,
  PACKAGE_PRODUCT_DETAIL,
  REGULAR_LIST,
  suppliersList,
  policiesList,
  fetchAllPromotion,
  CART_LIST,
  CART_TOTAL,
  STOCK_VIEW_DATA_PRODUCT,
  STOCK_VIEW_DATA_MATERIAL,
  CART_LIST_MATERIAL,
  CART_TOTAL_MATERIAL,
  REGULAR_LIST_MATERIAL,
  PACKAGE_DETAIL_MATERIAL,
  PACKAGE_DETAIL_CART_MATERIAL,
  PACKAGE_PRODUCT_DETAIL_MATERIAL,
  PRODUCT_DETAIL_MATERIAL,
  PRODUCT_DETAIL_CART_MATERIAL,
  BUNDLE_DETAIL_MATERIAL,
  BUNDLE_DETAIL_CART_MATERIAL,
  RECURRING_PLANS,
  SAVING_SUMMARY,
  PACKAGE_PRODUCT_SELECTED_SKU_DETAIL
} from './service.resolver.obj.js';

import {
  QUOTE_DETAIL,
  RECURRING_SERVICE_LIST,
  PREP_CYCLE_STATUS
} from './../../quote.resolve.obj.js';

import {
  DOCUMENT_LIST_WO,
  SERVICE_DETAIL_WO,
  documentDetail
} from '../../../../components/update-workorder/wo.resolve.obj';

export default function loginRoute($urlRouterProvider, $stateProvider, quoteResolverProvider, resolverProvider, serviceResolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.quotes.services', resolver('service-list/:quoteId', ServiceListTemplate, 'ServiceListController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [SERVICE_LIST, PREP_CYCLE_STATUS, QUOTE_DETAIL]
    }, {
      cbac: 'quote_services'
    }))

    .state('main.quotes.servicesDetail', resolver('service-detail/:quoteId/:serviceId', ServiceDetailTemplate, 'ServiceDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL, QUOTE_DETAIL, RECURRING_PLANS]
    }, {
      cbac: 'quote_services'
    }))

    .state('main.quotes.editServices', resolver('edit-service/:quoteId/:serviceId', EditServiceTemplate, 'EditServiceController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [SERVICE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.addServices', resolver('add-service/:quoteId', EditServiceTemplate, 'EditServiceController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL.name]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.servicesEstimateDetail', resolver('estimate-detail/:quoteId/:serviceId', estimateTemplate, 'EstimateDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [ESTIMATE_DETAIL, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_services'
    }))

    .state('main.quotes.updateServicesEstimates', resolver('quote-update-estimate/:quoteId/:serviceId', updateEstimateTemplate, 'UpdateEstimateController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [ESTIMATE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.updateServicesEstimatesSow', resolver('quote-update-estimate-sow/:quoteId/:serviceId/:regionId/:locationId', viewOrAddSOWTemplate, 'ViewOrAddSOWController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [ScopeOfWorks, ESTIMATE_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.addRecurringServices', resolver('add-rec-service/:quoteId', EditRecurringServiceTemplate, 'EditRecurringService', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL.name]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.editRecurringServices', resolver('edit-rec-service/:quoteId/:serviceId', EditRecurringServiceTemplate, 'EditRecurringService', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_services',
      isWrite: true
    }))

    .state('main.quotes.servicesProductsAdd', resolver('products/:quoteId/:locationId/:serviceId/', ProductsTemplate, 'ProductsController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [QUOTE_DETAIL, REGULAR_LIST, suppliersList, policiesList, fetchAllPromotion, CART_TOTAL, CART_LIST, SERVICE_DETAIL]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addProductDetail', resolver('products-add/:quoteId/:serviceId/:locationId/:productId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL, SERVICE_DETAIL, QUOTE_DETAIL, PRODUCT_DETAIL_CART.name],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addProductDetailCart', resolver('products-detail-cart/:quoteId/:serviceId/:locationId/:productId/:lineItemId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL, SERVICE_DETAIL, QUOTE_DETAIL, PRODUCT_DETAIL_CART]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addBundleDetail', resolver('bundle-detail/:quoteId/:serviceId/:locationId/:catalogId/:bundleId/?childCatalogId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART.name, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addBundleProductDetailCart', resolver('bundle-detail-cart/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL, BUNDLE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    // START : Package route
    .state('main.quotes.addPackageDetail', resolver('package-detail/:quoteId/:serviceId/:locationId/:packageId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART.name, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addPackageDetailCart', resolver('package-detail-cart/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL, PACKAGE_DETAIL_CART, QUOTE_DETAIL, SERVICE_DETAIL]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addPackageProductCustomize', resolver('package-product-detail/:quoteId/:serviceId/:locationId/:packageId/:productId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL, QUOTE_DETAIL, PACKAGE_PRODUCT_SELECTED_SKU_DETAIL.name],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))

    .state('main.quotes.addPackageProductSelectedCustomize', resolver('package-product-selected-sku-detail/:quoteId/:serviceId/:locationId/:packageId/:productId/:catalogId/:lineItemId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL, QUOTE_DETAIL, PACKAGE_PRODUCT_SELECTED_SKU_DETAIL],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_products',
      isWrite: true
    }))
    // END : Package route

    .state('main.quotes.editServicesPromotion', resolver('save-promotion/:quoteId/:serviceId', SavePromotionTemplate, 'SavePromotionController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL, ESTIMATE_DETAIL, SAVING_SUMMARY, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_promotions'
    }))

    .state('main.quotes.servicesWorkorder', resolver('work-order/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadJsZip, resolverProvider.loadJsZipUtil, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'quote_service_work_order'
    }))

    .state('main.quotes.servicesWorkorderDocumentDetail', resolver('wo-document/:quoteId/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [documentDetail]
    }, {
      cbac: 'quote_service_work_order_documents'
    }))

    .state('main.quotes.addWoDocument', resolver('wo-document/:quoteId/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'quote_service_work_order_documents',
      isWrite: true
    }))

    .state('main.quotes.updateWoDocument', resolver('wo-document/:quoteId/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [documentDetail]
    }, {
      cbac: 'quote_service_work_order_documents',
      isWrite: true
    }))

    .state('main.quotes.servicesProductDashboard', resolver('cart-dashboard/:quoteId/:locationId/:serviceId/', CartDashboardTemplate, 'CartDashboardController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [CART_LIST, CART_TOTAL, QUOTE_DETAIL, STOCK_VIEW_DATA_PRODUCT]
    }, {
      cbac: 'quote_service_products'
    }))

    .state('main.quotes.servicesMaterialDashboard', resolver('cart-dashboard-material/:quoteId/:locationId/:serviceId/', CartDashboardTemplate, 'CartDashboardController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [CART_LIST_MATERIAL, CART_TOTAL_MATERIAL, QUOTE_DETAIL, STOCK_VIEW_DATA_PRODUCT]
    }, {
      cbac: 'quote_service_materials'
    }))

    .state('main.quotes.servicesMaterialsAdd', resolver('material/:quoteId/:locationId/:serviceId/', ProductsTemplate, 'ProductsController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [QUOTE_DETAIL, REGULAR_LIST_MATERIAL, suppliersList, policiesList, fetchAllPromotion, CART_TOTAL_MATERIAL, CART_LIST_MATERIAL, SERVICE_DETAIL],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addPackageDetailMaterial', resolver('package-detail-material/:quoteId/:serviceId/:locationId/:packageId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL_MATERIAL, PACKAGE_DETAIL_CART_MATERIAL.name, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addPackageDetailCartMaterial', resolver('package-detail-cart-material/:quoteId/:serviceId/:locationId/:packageId/:lineItemId', AddPackageTemplate, 'AddPackageController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_DETAIL_MATERIAL, PACKAGE_DETAIL_CART_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addPackageProductCustomizeMaterial', resolver('package-material-detail/:quoteId/:serviceId/:locationId/:packageId/:productId', PackageProductCustomizeTemplate, 'PackageProductCustomizeController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PACKAGE_PRODUCT_DETAIL_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addProductDetailMaterial', resolver('material/:quoteId/:serviceId/:locationId/:productId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL_MATERIAL, SERVICE_DETAIL, QUOTE_DETAIL, PRODUCT_DETAIL_CART.name],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addProductDetailCartMaterial', resolver('material-detail-cart/:quoteId/:serviceId/:locationId/:productId/:lineItemId', ProductsDetailTemplate, 'ProductsDetailController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [PRODUCT_DETAIL_MATERIAL, QUOTE_DETAIL, SERVICE_DETAIL, PRODUCT_DETAIL_CART_MATERIAL],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addBundleDetailMaterial', resolver('bundle-material-detail/:quoteId/:serviceId/:locationId/:catalogId/:bundleId/?childCatalogId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL_MATERIAL, BUNDLE_DETAIL_CART_MATERIAL.name, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }))

    .state('main.quotes.addBundleProductDetailCartMaterial', resolver('bundle-detail-cart/:quoteId/:serviceId/:locationId/:bundleId/:lineItemId', AddBundleTemplate, 'AddBundleController', {
      lazyModule: [serviceResolverProvider.loadServiceModule],
      preData: [BUNDLE_DETAIL_MATERIAL, BUNDLE_DETAIL_CART_MATERIAL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_service_materials',
      isWrite: true
    }));
}