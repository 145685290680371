// Import all routes from different from modules.
import loginRoute from './../../pages/login/login.routes';
import dashboardRoute from './../../pages/dashboard/dashboard.route';
import quoteRoute from './../../pages/quotes/quote.route';
import businessRoute from './../../pages/business/business.routes';
import settingsRoute from './../../pages/settings/settings.route';
import customersRoute from './../../pages/customers/customers.route';
import resourcesRoute from './../../pages/resources/resources.route';
import servicesRoute from './../../pages/quotes/quote-cycle/service/service.route';
import calendarRoute from './../../pages/calendar/calendar.route';

import jobRoute from './../../pages/jobs/jobs.route';
import jobService from './../../pages/jobs/job-cycle/service/service.route';

export default {
  loginRoute: loginRoute,
  dashboardRoute: dashboardRoute,
  quoteRoute: quoteRoute,
  businessRoute: businessRoute,
  settingsRoute: settingsRoute,
  customersRoute: customersRoute,
  resourcesRoute: resourcesRoute,
  servicesRoute: servicesRoute,
  calendarRoute: calendarRoute,
  jobRoute: jobRoute,
  jobService: jobService
};