export default function(app) {
  app.factory('CopyService', CopyService);

  function CopyService($window, messages) {
    var body = angular.element($window.document.body);
    var textarea = angular.element('<textarea/>');
    textarea.css({
      position: 'fixed',
      opacity: '0'
    });

    return function(toCopy) {
      textarea.val(toCopy);
      body.append(textarea);
      textarea[0].select();

      try {
        let successful = document.execCommand('copy');
        if (successful) {
          messages.simpleToast('Copied to clipboard !');
        } else {
          throw successful;
        }
      } catch (err) {
        window.prompt("Copy to clipboard: Ctrl+C, Enter", toCopy);
      }

      textarea.remove();
    }
  }
}