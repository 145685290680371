import communicationHistoryTemplate from './communication-history.html'

export default function(app) {
  app.directive('communicationHistory', communicationHistory);

  function communicationHistory() {
    return {
      restrict: 'E',
      templateUrl: communicationHistoryTemplate
      // replace: true,
    };
  }
}