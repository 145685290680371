export default function(app) {
  app.constant('protips', {
    mainboard: [{
      category: 'Usability',
      description: 'Open up multiple browser tabs to work on the different areas of the product. You get the most done this way.'
    }, {
      category: 'Usability',
      description: 'Check out assignment notifications in settings. Users will receive email notification when something is assigned to them.'
    }, {
      category: 'Usability',
      description: 'Pressing the back button on top left navigates you back to the previous screen.'
    }, {
      category: 'Usability',
      description: 'Any element that supports drag and drop will be left that item represented in 6 dots.'
    }, {
      category: 'Progress',
      description: 'Test one notification and see how comfortable you are.'
    }, {
      category: 'Progress',
      description: 'Improve your email content as you make progress.'
    }, {
      category: 'Progress',
      description: 'The business tool is as good as you use it. If you are not using the tool you will have a harder time being effective.'
    }, {
      category: 'Functional',
      link: 'main.settings.setupProfileDetails',
      linkText: 'Profile Details',
      description: 'Save your terms and conditions in the settings.'
    }],
    settings: [{
      category: 'Functional',
      description: 'Locations are easily removable as long as their is no data like Opportunities, Quotes, etc. associated with it. In the case where there is assoicated data, you can disable the location if its not in used.'
    }],
    guider: [{
      category: 'Functional',
      description: 'If you add a new customer in Quotes, it automatically adds to the Customers module.'
    }, {
      category: 'Functional',
      description: 'Payments are taken per services.'
    }],
    opportunity: [{
      category: 'Functional',
      description: 'If you add a new customer in Quotes, it automatically adds to the Customers module.'
    }, {
      category: 'Functional',
      description: 'Payments are taken per services.'
    }]
  });
}