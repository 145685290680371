'use strict';

// Directives Imports
import directives from './directives/directiveList';

// Constant Imports
import constantList from './constants/constantList';

// Provider Imports
// import resolverProvider from './providers/resolver.provider';
import dropboxSetting from './../config/providers/dropboxProvider';
import googleDriveSetting from './../config/providers/googleDrive.provider';

// Factories & Services Imports
import serviceList from './services/serviceList';

// Filters
import filters from './filters/filterList';

// Add controllers
import controllerList from './controllers/controllerList';

// Angular Commong shared modules.
const shared = angular.module('core.shared', [controllerList.name]);

// Directive Setters
directives(shared);

// Constants Setters
constantList(shared);

// Factories & Services Setters
serviceList(shared);
// Filters
filters(shared);

// Provider
dropboxSetting(shared);
googleDriveSetting(shared);

export default shared;