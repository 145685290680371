import assignedQuoteJobTemplate from './assigned-quote-job.html'

export default function(app) {
  app.directive('assignedQuoteJob', assignedQuoteJob);

  function assignedQuoteJob() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: assignedQuoteJobTemplate
    };
  }
}
