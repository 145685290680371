export default function(app) {
  app.directive('ngClickCopy', ['CopyService', function(CopyService) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.bind('click', function(e) {
          CopyService(attrs.ngClickCopy);
        });
      }
    }
  }]);
}