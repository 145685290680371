import searchToolbarSidenavTemplate from './search-toolbar-sidenav.html';

export default function(app) {
  app.directive('searchToolbarSidenav', searchToolbarSidenav)
    .controller("searchToolbarSidenavController", searchToolbarSidenavController);

  function searchToolbarSidenav() {
    return {
      restrict: 'E',
      // replace: true, // replace needs single root element
      scope: {
        listName: '@',
        vm: '='
      },
      templateUrl: searchToolbarSidenavTemplate,
      controller: 'searchToolbarSidenavController',
      controllerAs: 'st'
    };
  }

  function searchToolbarSidenavController($attrs, ListService) {
    "ngInject";

    let st = this;

    st.listName = $attrs.listname;

    document.onkeydown = function(e) {
      if (e.keyCode === 27) {
        ListService.hideSearch(true);
      }
    };
  }
}