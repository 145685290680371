import footerTemplate from './footer-nav.html';

export default function(app) {
  app.directive("footerNav", footerNav)
    .controller("FooterNavController", FooterNavController);

  function footerNav() {
    return {
      restrict: 'EA',
      templateUrl: footerTemplate,
      controller: 'FooterNavController',
      controllerAs: 'fn'
    };
  }

  function FooterNavController($scope, $rootScope, $window, $timeout, authService, footerMenu, $state, messages, $mdSidenav, dataServices, serverUrl, sidenavMenu, localStorageService, sharedDataService, tours, tipScreens, TourService, imageService) {

    "ngInject";

    let fn = this;

    fn.authService = authService;
    fn.isOwnerOrAdmin = $rootScope.usrObj.isOwner || $rootScope.usrObj.isAdmin;
    fn.isFromMobile = sharedDataService.get('isFromMobile');
    fn.isFromApp = sharedDataService.get('isFromApp');
    fn.goToResource = goToResource;

    fn.footerMenu = [];

    // scroll all the way to top
    fn.scrollTop = () => {
      $('[infinite-scroll]').animate({
        scrollTop: 0
      }, 'fast');
    };

    angular.forEach(footerMenu, (module, name) => {
      if ($rootScope.accessData.cbac[module.cbac] == 'full_access' || $rootScope.accessData.cbac[module.cbac] == 'read_access' || ($rootScope.accessData.cbac[module.cbac] == 'no_license' && fn.isOwnerOrAdmin)) {
        module.name = name;
        fn.footerMenu.push(module);
      }
    });

    function goToResource() {
      $state.go('main.resources.resourcesDetails', {
        resourceId: $rootScope.usrObj.resourceId
      });
    }

    const permissions = $rootScope.accessData.cbac;
    // try modules in footer permissions here
    // permissions.jobs = 'no_license';

    function isAllowedToAccess(stateName) {
      return permissions[stateName] == 'full_access' || permissions[stateName] == 'read_access';
    }

    function checkRoutesInSubmodule(subModules) {
      let stateName;
      for (let i in subModules) {
        if (stateName = subModules[i].cbac ? (isAllowedToAccess(subModules[i].cbac) ? subModules[i].click : '') : subModules[i].click) {
          break;
        }
      }
      return stateName;
    }

    function getEntryState(moduleName, module) {
      // Set landing page based on user priority - list or dashboard
      let stateName;

      if (moduleName == 'quotes') {
        stateName = $rootScope.usrObj.dashboard[20] && sharedDataService.get('dashboardDisableQuote') && isAllowedToAccess(module.cbacDashboard) ? module.dashBoard : (isAllowedToAccess(module.cbacTarget) ? module.target : '');
      } else if (moduleName == 'jobs') {
        stateName = $rootScope.usrObj.dashboard[28] && sharedDataService.get('dashboardDisableJob') && isAllowedToAccess(module.cbacDashboard) ? module.dashBoard : (isAllowedToAccess(module.cbacTarget) ? module.target : '');
      } else if (moduleName == 'customers') {
        stateName = $rootScope.usrObj.dashboard[10] && sharedDataService.get('dashboardDisableCustomer') && isAllowedToAccess(module.cbacDashboard) ? module.dashBoard : (isAllowedToAccess(module.cbacTarget) ? module.target : '');

        if (!stateName && isAllowedToAccess('customer_opportunity_dashboards')) {
          stateName = sharedDataService.get('dashboardDisableOpportunities') && isAllowedToAccess('customer_opportunity_dashboards') ? 'main.customers.opportunitiesDashboard' : (isAllowedToAccess('customer_opportunity_boards') ? 'main.customers.opportunities' : '');
        }
      }

      return stateName;
    }

    function gotoStateName(stateName) {
      if (stateName) {
        if ($state.href(stateName)) {
          console.log(stateName);
          $state.go(stateName, {
            noRetainFC: true
          });
        } else {
          messages.nativeAlert('WORK_IN_PROGRESS.');
        }
      } else {
        messages.nativeAlert('Not enough permissions! Check permissions setup.');
      }
    }

    fn.goToModule = function(moduleName, module) {

      let stateName = getEntryState(moduleName, module);

      if (stateName) {
        gotoStateName(stateName)
        return;
      }

      // Set landing page based on permissions
      if (module.cbac) {
        if (isAllowedToAccess(module.cbac) && (module.cbacTarget && isAllowedToAccess(module.cbacTarget) || module.mainEntry)) {
          stateName = module.target;
          if (module.cbac == 'teams' && permissions.other_resources && !isAllowedToAccess('other_resources')) {
            if (isAllowedToAccess('my_assignments')) {
              $state.go('main.resources.massignmentAllassigned');
            } else {
              fn.goToResource();
            }
            return;
          }
        } else if (fn.isOwnerOrAdmin && permissions[module.cbac] == 'no_license') {
          stateName = module.salesPage || 'main.upgradeLicense';
        }
      }

      console.log(stateName, sidenavMenu[moduleName]);
      // Check further routes if landing page has no access
      if (!stateName && sidenavMenu[moduleName]) {
        const menu = sidenavMenu[moduleName];
        for (let i in menu.modules) {
          if (isAllowedToAccess(menu.modules[i].cbac)) {
            if (menu.modules[i].click) {
              stateName = menu.routePrefix + menu.modules[i].click;
              break;
            } else if (stateName = checkRoutesInSubmodule(menu.modules[i].subModules)) {
              stateName = menu.routePrefix + stateName;
              break;
            }
          }
        }
      }

      gotoStateName(stateName);

    };

    fn.toggleFooter = function() {
      fn.footerExpanded = !fn.footerExpanded;
      $(".custom-chat-widget").toggleClass('footer-open');
      $("iframe[type='chat-window']").attr('footer-open', fn.footerExpanded.toString());
    };

    fn.toggleChat = function() {
      // Tawk_API.toggleVisibility()
      $('.md-button.md-fab.scroll-top').toggleClass('chat-hidden');
      if (Tawk_API.isChatHidden()) {
        Tawk_API.showWidget();
      } else {
        Tawk_API.hideWidget();
      }
    };

    fn.requestHelp = function() {
      fn.toggleRequest();
    };

    fn.supportRequest = function() {
      $window.open('https://docs.google.com/forms/d/e/1FAIpQLSe9ad4TlR7lghfGv0rE0a6KDv7zsA6u9FMpzlOCzn2KPwdoZA/viewform', '_blank');
    };

    fn.featureRequest = function() {
      $window.open('https://docs.google.com/forms/d/e/1FAIpQLSe9ad4TlR7lghfGv0rE0a6KDv7zsA6u9FMpzlOCzn2KPwdoZA/viewform', '_blank');
    }

    fn.bugReport = function() {
      $window.open('https://docs.google.com/forms/d/e/1FAIpQLSc-YvG6JqN4hSgAZUs7aPvYzIbR1ueDKhZZOjrsnmIqK0Za8w/viewform', '_blank');
    };

    // Tours logic starts
    fn.tours = tours;

    fn.goToTour = function(tour, tourName) {
      fn.closeRight('toursTab');

      if ($rootScope.currentStateName != tour.state) {
        $state.go(tour.state, {
          tourName: tourName
        });
      } else {
        TourService.startTour(tourName);
      }
    };

    fn.noOfToursCompleted = 0;
    fn.noOfTours = Object.keys(fn.tours).length;
    fn.toggleToursSidebar = function() {
      dataServices.get({
          url: serverUrl.main + 'toursApi/tours/' + $rootScope.usrObj.userId + '?',
          spinnerName: 'tours-spinner'
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            $rootScope.toursProgress = data.result;
            fn.toursProgress = {};
            if (angular.isObject($rootScope.toursProgress) && angular.isObject($rootScope.toursProgress.tours)) {
              fn.noOfToursCompleted = 0;
              angular.forEach($rootScope.toursProgress.tours, function(value, tourName) {
                fn.toursProgress[tourName] = value;
                if (value && fn.tours[tourName]) {
                  fn.noOfToursCompleted++;
                }
              });
            }
          }
        });
      buildToggler('toursTab')();
    };
    // Tours logic ends

    // Tips logic starts
    fn.nextPage = function() {
      fn.currentIndex = fn.currentIndex + 1;
    };

    fn.backPage = function() {
      fn.currentIndex = fn.currentIndex - 1;
    };

    fn.getTips = window.getTips = function() {

      fn.currentIndex = 0;
      fn.toggleRight();
      fn.tips = [];
      fn.isRequiredApiFailed = false;

      console.log($rootScope.currentStateName);
      console.log(tipScreens[$rootScope.currentStateName]);
      if (!tipScreens[$rootScope.currentStateName]) {
        return;
      }

      let filterCriteria = angular.toJson({
        filters: [{
          field: 'status',
          operator: '=',
          value: 2
        }]
      });

      dataServices.get({
          url: serverUrl.main + "contentMgmtApi/contentMgmt/" + tipScreens[$rootScope.currentStateName] + "/page?filterCriteria=" + filterCriteria + '&',
          spinnerName: 'tips-spinner'
        })
        .then(function(response) {
          if (response.data && response.data.response_code == 200) {
            fn.tips = response.data.result || [];

            $timeout(function() {
              $('.tips-sidebar img').on('click', function(ev) {
                imageService.showImagePopUp(ev, $(this).attr('src'));
              });
            }, 0);
          } else {
            fn.isRequiredApiFailed = true;
          }
        });
    };

    fn.functionTypes = {
      20: {
        name: 'Quotes',
        icon: 'quotes'
      },
      28: {
        name: 'Jobs',
        icon: 'jobs'
      },
      31: {
        name: 'Estimate',
        icon: 'quotes'
      },
      44: {
        name: 'Procurement',
        icon: 'operations'
      },
      45: {
        name: 'Opportunity',
        icon: 'customers'
      },
      56: {
        name: 'Quote Payment',
        icon: 'quotes'
      },
      57: {
        name: 'PO Payment',
        icon: 'operations'
      },
      66: {
        name: 'Action List',
        icon: 'teams'
      },
      99: {
        name: 'Dashboard',
        icon: 'mainboard'
      }
    };

    fn.fetchNotifications = function(type) {

      if (type == 'new') {
        fn.limit = 15;
        fn.skip = 0;
        fn.notifications = [];
      }

      let filters = [{
        field: 'isArchived',
        operator: '=',
        value: false
      }];

      if (fn.functionType && fn.functionType != 'All') {
        filters.push({
          field: 'model',
          operator: '=',
          value: parseInt(fn.functionType)
        });
      }

      let filterCriteria = angular.toJson({
        filters: filters,
        sort: [{
          field: 'lastUpdated',
          order: -1
        }],
        skip: fn.skip,
        limit: fn.limit
      });

      fn.isLoadingNotifications = true;

      dataServices.get({
        url: serverUrl.main + 'bzNotificationApi/business/notifications?filterCriteria=' + filterCriteria + '&',
        spinner: false
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          fn.notificationData = data.result.data;
          fn.notifications = fn.notifications.concat(data.result.records);

          // pagination calculations
          fn.pagination = data.result.records.length == fn.limit;
          fn.skip += fn.limit;

        } else {
          fn.pagination = false;
        }

        fn.isLoadingNotifications = false;
      }, function() {
        fn.isLoadingNotifications = false;
      });
    };

    fn.readNotification = function(notification) {
      if (notification.isRead) {
        return;
      }

      notification.isRead = true;
      dataServices.patch({
        url: serverUrl.main + 'bzNotificationApi/business/notification/' + notification.id + '/mark/read',
        spinner: false
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          $rootScope.usrObj.notificationCount--;
        } else {
          notification.isRead = false;
        }
      });
    };

    fn.toggleRight = buildToggler('tipsTab');
    fn.toggleNotifications = buildToggler('notifications');

    fn.goToSupport = function() {
      $window.open('https://jacktrade.co/support/', '_blank');
    };

    fn.toggleRequest = buildToggler('request');

    function buildToggler(navID) {
      return function() {
        if (navID == 'notifications') {
          fn.fetchNotifications('new');
        }
        $mdSidenav(navID).toggle();
      };
    }

    fn.closeRight = function(id) {
      return $mdSidenav(id).close();
    };
  }
}