import datePicker from "./datepicker.html.js";
// console.log(datePicker);
import day from "./day.html.js";
import month from "./month.html.js";
import year from "./year.html.js";
// import main from "./../pages/main/main.html.js";
// import quote_frame from "./../pages/quotes/quote-cycle/quote-info-frame/quote-frame.html.js";
// import footer_nav from "./../components/footer-nav/footer-nav.html.js";
// import list_header from "./../components/list-header/list-header.html.js";
// import file_attachment from "./../components/file-attachment-sidebar/file-attchment-right-side-nav.html.js";

// console.log(datePicker);
export default angular.module('date-picker-templateCache', [datePicker.name, day.name, month.name, year.name]);