import timeUnitConstant from './time.units';
import serverConstant from './server-url.constant';
import keyConstant from './key.constant';
import menuConstant from './menu.constant';
import statusIcons from './statusIcons';
import commonConstant from './common.constant';
// import headerConstant from './header.constant';
// import countryCodeConstant from './country.code.constant';
import apiVersionConstant from './apiVersions.constant';
import footerHideScreens from './footerHideScreens.constant';
import noAuthScreens from './noAuthScreens.constant';
import errorMessage from './errorMessage.constant';
import regxMatch from './regxMatch.constant';
import tipScreens from './tip-screens-aliases.constant';
import maxlength from './maxlength.constant';
import visuals from './visuals.constant';
import timeZone from './timeZone.constant';
import tours from './tours.constant';
import alerts from './alerts.constant';
import protips from './protips.constant';
import emojis from './emojis.constant';
import mimeTypes from './mimetypes.constant';
import eventTypeIcons from './eventTypeIcons.constant';
import fulfillmentActions from './fulfillment-actions.constant';

export default function(app) {
  timeUnitConstant(app);
  serverConstant(app);
  keyConstant(app);
  menuConstant(app);
  statusIcons(app);
  commonConstant(app);
  // headerConstant(app);
  // countryCodeConstant(app);
  apiVersionConstant(app);
  footerHideScreens(app);
  noAuthScreens(app);
  errorMessage(app);
  regxMatch(app);
  tipScreens(app);
  maxlength(app);
  visuals(app);
  timeZone(app);
  tours(app);
  alerts(app);
  protips(app);
  emojis(app);
  mimeTypes(app);
  eventTypeIcons(app);
  fulfillmentActions(app)
}