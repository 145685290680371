export default function(app) {
  app.factory('TourService', TourService);

  let steps, driver, tourName;

  function TourService(dataServices, serverUrl, messages, $rootScope, $state, tours) {

    "ngInject";

    return {
      startTour: startTour
    };

    function init() {
      driver = new Driver({
        allowClose: false,
        className: 'popover-dialog',
        stageBackground: '#FAB963',
        onHighlighted: (Element) => {
          $(Element.options.element).css({
            'pointer-events': 'none'
          });
        },
        onDeselected: (Element) => {
          $(Element.options.element).css({
            'pointer-events': 'auto'
          });
        },
        onReset: (Element) => {
          console.log('OnReset');
          if ($('#saveDashboard').length) {
            $('#saveDashboard').click();
          }

          let toursProgress = {},
            isUpdate;
          if (angular.isObject($rootScope.toursProgress) && $rootScope.toursProgress.id) {
            isUpdate = true;
          }

          // setting completion status to false by default for email triggers in BE
          angular.forEach(tours, (item, tourName) => {
            toursProgress[tourName] = $rootScope.toursProgress && $rootScope.toursProgress.tours && $rootScope.toursProgress.tours[tourName] || false;
          });

          toursProgress[tourName] = true;

          dataServices.post({
              url: serverUrl.main + 'toursApi/tours' + (isUpdate ? ('/' + $rootScope.toursProgress.id) : ''),
              data: {
                data: angular.toJson({
                  userId: $rootScope.usrObj.userId,
                  tours: toursProgress
                })
              },
              method: isUpdate ? 'PUT' : '',
              spinner: false
            })
            .then(function(response) {
              let data = response.data;
              if (data && (data.response_code == 200 || data.response_code == 201)) {
                // messages.simpleToast('Saved Progress.');
                $state.go('main.dashboard.gettingstarted');
              }
            });
        }
      });

      steps = {
        'quote_dashboard_tour': [{
            element: '#mainboard',
            popover: {
              title: 'Dashboard',
              description: "This is main dashboard to review critical elements of your business. It can be customized and assigned to your team as desired. ",
              position: 'top-left'
            }
          },
          {
            element: '#listBtn',
            popover: {
              title: 'Toggle',
              description: "Click to toggle between dashboard and list view.",
              position: 'bottom-right'
            }
          },
          {
            element: '#updateBtn',
            popover: {
              title: 'Dashboard Setup',
              description: "Edit to setup 1 or more dashboards and give permissions to your team.",
              position: 'bottom-right'
            },
            onNext: () => {
              // Prevent moving to the next step
              driver.preventMove();

              // Perform some action or create the element to move to
              // And then move to that element
              $('#updateBtn').click();
              driver.moveNext();
            }
          },
          {
            element: '.cards-container .drag-container:first-of-type .draggble',
            popover: {
              title: 'Report Block',
              description: "Each block is configurable and movable for a desired layout. See what works best for your business.",
              position: 'bottom-right'
            },
            onPrevious: () => {
              driver.preventMove();
            }
          }
        ],
        'main_menu_tour': [{
            element: '#dashboard',
            popover: {
              title: 'Dashboard',
              description: "Dashboards can be personalized to each individual. Review critical elements as it matters to you and your team. Play with the layout when you get a chance.",
              position: 'top-left'
            }
          },
          {
            element: '#customers',
            popover: {
              title: 'Customers',
              description: "Manage and grow relationship with your customers. Communicate with them quickly and track what is happening with them in conversations.",
              position: 'top-left'
            }
          },
          {
            element: '#quotes',
            popover: {
              title: 'Quotes',
              description: "Quotes is your starting point where you provide an estimate to your clients. Manage tasks, build multiple services, focus on bigger leads, and automatically remind your team to follow-up.",
              position: 'top-left'
            }
          },
          {
            element: '#jobs',
            popover: {
              title: 'Jobs',
              description: "We love Jobs module as it is the single source where you get paid.  Manage your services, invoicing, tasks, fulfillment and payments in one go.",
              position: 'top-left'
            }
          },
          {
            element: '#calendar',
            popover: {
              title: 'Calendar',
              description: "Calendar provides view to both, your entire events in ‘business calendar’, and what is assigned to you in ‘individual calendar’.",
              position: 'top-left'
            }
          },
          {
            element: '#teams',
            popover: {
              title: 'Teams',
              description: "Manage your business resources and schedule Jobs. Collaborate with your team on tasks, and other related work.",
              position: 'top-left'
            }
          },
          {
            element: '#operations',
            popover: {
              title: 'Operations',
              description: "Manage your inventory (catalog, products and materials), procurement cycles, and financials of the business.",
              position: 'top-left'
            }
          },
          {
            element: '#settings',
            popover: {
              title: 'Settings',
              description: "Settings is where you can setup your business and all your processes. We have made configuration easy to understand with lots of setup already pre-done.",
              position: 'top-left'
            }
          }
        ],
        'settings_tour': [{
            element: '#SETUP',
            popover: {
              title: 'Business Profile',
              description: "This important section lets you setup your business details, locations, regions, payment gateway and all necessary communication settings.",
              position: 'right'
            }
          },
          {
            element: '#STRUCTURE',
            popover: {
              title: 'Business Structure',
              description: "Configure our services, rates, charge policy, recurring policies, promotions, delivery policy. A lot of pre-configuration already exists. Have fun building data.",
              position: 'right'
            },
            onNext: () => {
              // Prevent moving to the next step
              driver.preventMove();

              // Perform some action or create the element to move to
              // And then move to that element
              $('#AUTOMATION').click();
              driver.moveNext();
            }
          },
          {
            element: '#GUIDED_ACTIONS',
            popover: {
              title: 'Guided Actions',
              description: "Ah! We wanted to give this one special attention because it will save you tons of energy. Create repeated tasks here to auto insert into action items based on your business workflow. ",
              position: 'top-left'
            }
          }
          // ,
          // {
          //   element: '#sublist',
          //   popover: {
          //     title: 'Business Profile',
          //     description: "We know how important your brand is to you. You can brand your creations and outbound emails. Follow to build 1) Business Entity 2) Locations 3) Invoice.",
          //     position: 'left-bottom'
          //   },
          //   onNext: () => {
          //
          //     driver.preventMove();
          //
          //
          //     $('#update').click();
          //     driver.moveNext();
          //   },
          //   onPrevious: () => {
          //     driver.preventMove();
          //   }
          // }
        ]
      };
    }

    function startTour(_tourName) {
      tourName = _tourName;

      if (!driver) {
        init();
      }

      // Define the steps for introduction
      driver.defineSteps(steps[tourName]);
      // Start the introduction
      driver.start();
    }

  }

}