import loginTemplate from './login/login.html';
import changePasswordTemplate from './change-password/change-password.html';
import forgetPasswordTemplate from './forget-password/forget-password.html';
import resetPasswordTemplate from './reset-password/reset-password.html';
import threatTemplate from './threat/threat.html';

export default function loginRoute($urlRouterProvider, $stateProvider, loginResolverProvider, resolverProvider) {
  "ngInject";
  $stateProvider
    .state('/', resolverProvider.stateResolve('', loginTemplate, 'LoginController', {
      lazyModule: [loginResolverProvider.asyncPagePrealoading],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride]
    }))
    .state('change-password', resolverProvider.stateResolve('change-password', changePasswordTemplate, 'ChangePasswordController', {
      lazyModule: [loginResolverProvider.asyncPagePrealoading]
    }))
    .state('threat', resolverProvider.stateResolve('threat', threatTemplate, 'ThreatController', {
      lazyModule: [loginResolverProvider.asyncPagePrealoading]
    }))
    .state('forget-password', resolverProvider.stateResolve('forget-password', forgetPasswordTemplate, 'ForgetPasswordController', {
      lazyModule: [loginResolverProvider.asyncPagePrealoading]
    }))
    .state('reset-password', resolverProvider.stateResolve('reset-password?email', resetPasswordTemplate, 'ResetPasswordController', {
      lazyModule: [loginResolverProvider.asyncPagePrealoading]
    }));
}