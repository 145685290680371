export default function(app) {
  app.constant('visuals', {
    alerts: {
      name: 'Alerts',
      // 'billboard', 'biohazard', 'bomb', 'cancel', 'fire-truck', 'firefighter', 'nuclear', 'poison-1', 'poison', 'police-car', 'policeman', 'traffic-sign-1'
      icons: [
        'alarm-clock', 'alerts', 'bolt', 'flame', 'forbidden', 'hold', 'traffic-sign', 'warning-1', 'warning'
      ]
    },
    business: {
      name: 'Business',
      // 'analytics', 'briefcase-2', 'briefcase-3', 'briefcase-4', 'briefcase-5', 'briefcase', 'businessman-2', 'businessman-3', 'businessman-5', 'businessman', 'businesswoman-1', 'businesswoman', 'clipboard-3', 'clipboard-4', 'clipboard-5', 'clipboard', 'diamond-2', 'exchange-1', 'exchange-2', 'exchange', 'folder-1', 'folder-2', 'folder-3', 'folder-4', 'hierarchical-structure-1', 'hourglass-1', 'hourglass-2', 'line-chart', 'loss-1', 'loss-2', 'loss-3', 'loss', 'man-1', 'man', 'monitor-1', 'monitor', 'payment-2', 'payment-3', 'payment', 'percentage',  'networking-2', 'pie-chart', 'profits-1', 'profits-2', 'profits-4', 'profits-5', 'profits', 'puzzle', 'sales-1', 'settings-1', 'shield', 'smartphone-1', 'smartphone', 'stamp', 'team-2', 'team', 'telemarketer', 'user-1', 'user-2', 'user-5', 'user-6', 'user', 'users-1', 'users-2', , 'worldwide-2', 'worldwide'
      icons: [
        'airplane', 'analytics-1', 'analytics-2', 'balance', 'bar-chart', 'bow-tie', 'briefcase-1', 'briefcase-6', 'browser', 'businessman-1', 'businessman-4', 'calculator', 'calendar', 'chat', 'clipboard-1', 'clipboard-2', 'clipboard-6', 'diamond-1', 'diamond', 'diploma', 'exchange-3', 'folder', 'handshake', 'hierarchical-structure', 'hourglass', 'infographic', 'knight', 'line-chart-1', 'loss-4', 'networking-1', 'networking', 'payment-1', 'pie-chart-1', 'profits-3', 'sales', 'settings', 'shopping-bag', 'smartphone-2', 'smartphone-3', 'startup', 'strategy', 'switch', 'tablet-1', 'tablet', 'team-1', 'telemarketer-1', 'user-3', 'user-4', 'user-7', 'users', 'worldwide-1'
      ]
    },
    emotions: {
      name: 'Emotions',
      icons: [
        // 'bored-2', 'bored', 'confused-1', 'happy-3', 'ninja', 'secret', 'surprised-1', 'suspicious-1', 'tongue-out', 'unhappy'
        'angry-1', 'angry', 'bored-1', 'confused', 'crying-1', 'crying', 'embarrassed', 'emoticons', 'happy-1', 'happy-2', 'happy-4', 'happy', 'ill', 'in-love', 'kissing', 'mad', 'nerd', 'quiet', 'sad', 'smart', 'smile', 'smiling', 'surprised', 'suspicious', 'tongue-out-1', 'wink'
      ]
    },
    priority: {
      name: 'Priority Pack',
      icons: [
        'circle-dark-grey', 'circle-green', 'circle-light-grey', 'circle-light-red', 'circle-pink', 'circle-purple', 'circle-red', 'circle-yellow', 'heart-dark-grey', 'heart-green', 'heart-light-grey', 'heart-light-red', 'heart-pink', 'heart-purple', 'heart-red', 'heart-yellow', 'star-dark-grey', 'star-green', 'star-light-grey', 'star-light-red', 'star-pink', 'star-purple', 'star-red', 'star-yellow'
      ]
    },
    projects: {
      name: 'Projects',
      // 'briefcase', 'businessman-2', 'businessman-3', 'checklist-1', 'checklist-2', 'checklist', 'computer-1', 'computer', 'conference', 'connection', 'curriculum', 'customer-service', 'diagram-1', 'diagram-2', 'diagram-3', 'diagram-4', 'diagram', 'diamond', 'document-1', 'document', 'dollar-1', 'dollar', 'flask', 'glasses', 'mail-1', 'mail', 'medal', 'meeting', 'monitor-1', 'monitor', 'panels', 'podium', 'presentation', 'search', 'share', 'startup', 'statistics', 'tablet-1', 'tablet',  'traffic-light', 'user-1', 'user-2', 'user-3', 'user-4', 'user', 'web'
      icons: [
        'architect', 'bars', 'boss', 'box', 'browser', 'bubble', 'bulb', 'businessman-1', 'businessman-4', 'businessman', 'calendar', 'chat', 'chess', 'conference-1', 'conference-2', 'dialog', 'document-2', 'e-learning', 'filing-cabinet', 'folder', 'loupe', 'network', 'sand-clock', 'target'
      ]
    },
    ranks: {
      name: 'Ranks',
      // 'chevron-1', 'chevron-10', 'chevron-11', 'chevron-12', 'chevron-19', 'chevron-2', 'chevron-20', 'chevron-21', 'chevron'
      icons: [
        'chevron-13', 'chevron-14', 'chevron-15', 'chevron-16', 'chevron-17', 'chevron-18', 'chevron-3', 'chevron-4', 'chevron-5', 'chevron-6', 'chevron-7', 'chevron-8', 'chevron-9'
      ]
    },
    winning: {
      name: 'Winning',
      // 'award-02', 'award-03', 'award-04', 'badge', 'beer', 'champagne', 'clover', 'cup-02', 'cup', 'diploma-02', 'fireworks', 'flag-01', 'flag', 'garlands', 'gift-voucher', 'gift', 'glove', 'goal', 'medal-02', 'medal-03', 'medal-04', 'medal-06', 'medal-07', 'medal-08', 'medal', 'money', 'podium', 'race', 't-shirt', 'trophy', 'trumpet', 'winner-02', 'world'
      icons: [
        'award-01', 'award', 'banner', 'confetti', 'diploma', 'flag-02', 'flag-03', 'medal-01', 'medal-05', 'money-bag', 'podium-02', 'podium-03', 'toast', 'trophy-02', 'winner-01', 'winner-03', 'winner-04'
      ]
    }
  });
}