import assignedServicesTemplate from './assigned-services.html'

export default function(app) {
  app.directive('assignedServices', assignedServices);

  function assignedServices() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: assignedServicesTemplate
    };
  }
}
