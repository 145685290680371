import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';

export default function(app) {
  app.factory('ErrorHandler', errorHandler);

  function errorHandler($window, $log, serverUrl, apiVersions, loginKey) {

    "ngInject";

    return {
      saveErrorToBackend: saveErrorToBackend
    };

    function decryptCrypto(data) {
      return aes.decrypt(data, loginKey).toString(utf8);
    }

    function saveErrorToBackend(exception, cause) {

      // now try to log the error to the server side.
      try {

        // Extract API Name
        let url = serverUrl.main + 'errorManagementApi/error/management';
        let temp = url.split("Api")[0];
        let apiName = temp.substr(temp.lastIndexOf("/") + 1);
        // console.log(apiName);

        let accessData = localStorage.getItem('jacktrade.accessData');
        let bObj = localStorage.getItem('jacktrade.bObj');
        let rObj = localStorage.getItem('jacktrade.rObj');

        // Set Accept Header with API Version
        if (!apiVersions[apiName] && !accessData && !bObj && !rObj) {
          throw "Api Version or Credential missing";
        }

        accessData = angular.fromJson(accessData);
        access_token = angular.fromJson(accessData.access_token);
        bObj = angular.fromJson(bObj);
        rObj = angular.fromJson(rObj);

        // use AJAX (in this example jQuery) and NOT
        // an angular service such as $http
        $.ajax({
          type: 'POST',
          url: url,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Accept', 'application/jacktrade.v' + apiVersions[apiName] + '+json');
          },
          // contentType: 'application/json',
          data: {
            data: angular.toJson({
              productName: 'jacktrade',
              url: $window.location.href,
              string: exception.toString(),
              message: exception.message,
              lineNumber: exception.lineNumber,
              stack: exception.stack,
              cause: cause
            }),
            userId: decryptCrypto(accessData.user_id),
            access_token: decryptCrypto(access_token),
            bzobjver: bObj.ver,
            reasonObjVer: rObj.ver
          }
        });
      } catch (loggingError) {
        $log.warn("Error server-side logging failed");
        $log.warn(loggingError);
      }
    }
  }
}