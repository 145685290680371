import contactCardTemplate from './contactCard.html';
export default function(app) {
  app.directive('contactCard', contactCard);

  function contactCard() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: contactCardTemplate
    };
  }
}