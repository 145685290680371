import {
  initializeApp
} from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage
} from "firebase/messaging";

export default function(app) {
  app.factory('FirebaseMessagingService', FirebaseMessagingService);

  function FirebaseMessagingService(sharedDataService) {

    "ngInject";

    return {
      init: init
    };

    function init() {
      // TODO: Replace the following with your app's Firebase project configuration
      const firebaseConfig = {
        apiKey: "AIzaSyAID1QoYb5ihmFRApNYGKvZ4ITFNRbXiDo",
        authDomain: "push-notification-305312.firebaseapp.com",
        projectId: "push-notification-305312",
        storageBucket: "push-notification-305312.appspot.com",
        messagingSenderId: "1092642505439",
        appId: "1:1092642505439:web:121b606a764bdcf9ff9f6a",
        measurementId: "G-4LE4TRBPLP"
      };

      const app = initializeApp(firebaseConfig);

      // do not initialize FCM on mobile devices
      if (sharedDataService.get('isFromApp')) {
        return;
      }

      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      const messaging = getMessaging();

      getToken(messaging, {
        vapidKey: 'BFN7lj9HZhgX36TR9jK1vGa-mkVRXcpqpSAAY15fxya-BLZgv6-LLP3E-EpYAaB6SyOLjRO4Xmpgrgzp1mHEOEE'
      }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log(currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: payload.notification.icon,
          image: payload.notification.image
        };

        console.log(Notification.permission);

        if (!("Notification" in window)) {
          console.log("This browser does not support system notifications");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          const notification = new Notification(notificationTitle, notificationOptions);
          notification.onclick = function(event) {
            if (location.pathname !== payload.data.url) {
              event.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(payload.data.url, '_blank');
              notification.close();
            }
          }
        }
      });
    }

  }
}