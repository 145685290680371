export default function quoteResolver() {
  this.loadJobsModule = loadJobsModule;

  this.$get = function() {
    return this;
  };
}

function loadJobsModule($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";
  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./jobs.module');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    }).then(function() {
      deferred.resolve(asyncModule.default.controller);
    });
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'jobs.module');
  return deferred.promise;
}