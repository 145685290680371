import taggingMachineTemplate from './tagging-machine.html';
export default function(app) {
  app.directive('taggingMachine', taggingMachine);

  function taggingMachine() {
    return {
      restrict: 'E',
      templateUrl: taggingMachineTemplate,
      replace: true
    };
  }
}
