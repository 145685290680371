import futureEvent from './future-event.html';

export default function(app) {
  app.directive('futureEvent', function() {
    return {
      restrict: 'E',
      templateUrl: futureEvent,
      replace: true
    };
  });
}