import contactCardTemplate from './companyCard.html';
export default function(app) {
  app.directive('companyCard', companyCard);

  function companyCard() {
    return {
      restrict: 'E',
      // replace: true,
      templateUrl: contactCardTemplate
    };
  }
}
