export default function(app) {
  app.directive('allowAlphabets', function() {
    return {
      require: 'ngModel',
      restrict: 'A',
      scope: {
        inputLength: "="
      },
      link: function(scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits = val.replace(/[0-9]/g, '');

            if(scope.inputLength && digits.length > parseInt(scope.inputLength)){
              digits = digits.substring(0, scope.inputLength);
            }

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            
            return digits;
          }
          return undefined;
        }
        ctrl.$parsers.push(inputValue);
      }
    };
  });
}
