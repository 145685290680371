export default function loginResolver() {
  this.asyncPagePrealoading = asyncPagePrealoading;
  this.$get = function() {
    return this;
  };
}

function asyncPagePrealoading($q, $ocLazyLoad, dynamicLoadModules) {
  "ngInject";

  const deferred = $q.defer();
  require.ensure([], (require) => {
    const asyncModule = require('./login.module');
    $ocLazyLoad.load({
      name: asyncModule.default.name
    });
    deferred.resolve(asyncModule.default.controller);
  }, (error) => {
    // reload when chunk is not found after latest build
    dynamicLoadModules.reloadFailedChunks(error);
  }, 'login.module');
  return deferred.promise;
}
