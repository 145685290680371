export default function(app) {
  app.factory('ProtipsService', ProtipsService);

  function ProtipsService(dataServices, serverUrl, messages, $rootScope, protips) {

    "ngInject";

    return {
      init: init,
      setInitialStatus: setInitialStatus
    };

    let vm, functionName;

    function setStatus(isTipsHidden, tipsStatus = {}) {
      vm.isTipsHidden = tipsStatus[functionName] = isTipsHidden;
      vm.dropdownMenu.items['Show Tips'].disabled = !isTipsHidden;
    }

    function setInitialStatus() {
      setStatus(getStatus(functionName));
    }

    function getStatus(_functionName) {
      return $rootScope.toursProgress && $rootScope.toursProgress.tips && $rootScope.toursProgress.tips[_functionName] || false;
    }

    function updateTipsStatus(isTipsHidden) {

      let isUpdate, tipsStatus = {};
      if (angular.isObject($rootScope.toursProgress) && $rootScope.toursProgress.id) {
        isUpdate = true;
      }

      angular.forEach(protips, (tips, _functionName) => {
        tipsStatus[_functionName] = getStatus(_functionName);
      });

      setStatus(isTipsHidden, tipsStatus);

      dataServices.post({
          url: serverUrl.main + 'toursApi/tours' + (isUpdate ? ('/' + $rootScope.toursProgress.id) : ''),
          data: {
            data: angular.toJson({
              userId: $rootScope.usrObj.userId,
              tips: tipsStatus
            })
          },
          method: isUpdate ? 'PUT' : '',
          spinner: false
        })
        .then(function(response) {
          let data = response.data;
          if (data && (data.response_code == 200 || data.response_code == 201)) {
            // messages.simpleToast('Saved Progress.');
          } else {
            vm.isTipsHidden = false;
          }
        }, function() {
          vm.isTipsHidden = false;
        });
    }

    function hideTips() {
      updateTipsStatus(true);
    }

    function showTips() {
      updateTipsStatus(false);
    }

    function nextTip() {
      vm.tipIndex++;
    }

    function previousTip() {
      vm.tipIndex--;
    }

    function getTours() {
      vm.isLoaded = vm.isLoaded || {};
      dataServices.get({
          url: serverUrl.main + 'toursApi/tours/' + $rootScope.usrObj.userId + '?',
          spinner: false
        })
        .then(function(response) {
          vm.isLoaded.tours = true;
          let data = response.data;
          if (data && data.response_code == 200) {
            $rootScope.toursProgress = data.result;
            setInitialStatus();
          } else {
            vm.toursApiFailed = true;
          }
        }, function() {
          vm.isLoaded.tours = vm.toursApiFailed = true;
        });
    }

    function init(_vm, _functionName) {
      vm = _vm;
      functionName = _functionName;

      vm.tipIndex = 0;
      vm.protips = protips[functionName];
      vm.noOfTips = vm.protips.length;
      vm.nextTip = nextTip;
      vm.previousTip = previousTip;
      vm.hideTips = hideTips;
      vm.showTips = showTips;

      getTours();
    }

  }
}